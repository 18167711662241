import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import React from 'react'

const TablePagination = ({ page, setPage, customContacts = null, contacts, limit }) => {
  const getEndLimitForPages = () => Math.ceil((customContacts || contacts.length) / limit)

  const toPreviousPage = () => {
    if (page <= 1) return
    setPage((prevPage) => prevPage - 1)
  }

  const toNextPage = () => {
    if (page * limit >= (customContacts || contacts)?.length) return
    setPage((prevPage) => prevPage + 1)
  }

  const toSpecificPage = (pageNumber) => setPage(pageNumber)

  const isValidPage = (pageNumber) => pageNumber > 0

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#FCFCFA',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div>
        <p className='text-sm text-gray-700 pl-8 pt-4'>
          Showing{' '}
          <span className='font-medium'>
            {(customContacts || contacts).length !== 0 ? (page - 1) * limit + 1 : 0}
          </span>{' '}
          to{' '}
          <span className='font-medium'>
            {page * limit < (customContacts || contacts).length ? page * limit : (customContacts || contacts).length}
          </span>{' '}
          of <span className='font-medium'>{(customContacts || contacts)?.length}</span> results
        </p>
      </div>
      <div className='pr-8 py-4 bg-gray-50'>
        <nav
          className='isolate inline-flex -space-x-px rounded-md shadow-sm'
          aria-label='Pagination'
        >
          <a
            onClick={toPreviousPage}
            className='relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
          >
            <span className='sr-only'>Previous</span>
            <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
          </a>
          {isValidPage(
            page + 1 < getEndLimitForPages() - 1
              ? page + 1
              : getEndLimitForPages() - 2
          )
            ? (
            <a
              onClick={() =>
                toSpecificPage(
                  page < getEndLimitForPages() - 2
                    ? page
                    : getEndLimitForPages() - 3
                )
              }
              aria-current='page'
              className={`relative z-10 inline-flex items-center border border-gray-300 ${
                page ===
                (page < getEndLimitForPages() - 2
                  ? page
                  : getEndLimitForPages() - 3)
                  ? 'bg-indigo-50 text-indigo-600'
                  : 'bg-white text-gray-500'
              } px-4 py-2 text-sm font-medium focus:z-20`}
            >
              {page < getEndLimitForPages() - 2
                ? page
                : getEndLimitForPages() - 3}
            </a>
              )
            : null}
          {isValidPage(
            page + 1 < getEndLimitForPages() - 1
              ? page + 1
              : getEndLimitForPages() - 2
          )
            ? (
            <a
              onClick={() =>
                toSpecificPage(
                  page + 1 < getEndLimitForPages() - 1
                    ? page + 1
                    : getEndLimitForPages() - 2
                )
              }
              className={`relative inline-flex items-center border border-gray-300 ${
                page ===
                (page + 1 < getEndLimitForPages() - 1
                  ? page + 1
                  : getEndLimitForPages() - 2)
                  ? 'bg-indigo-50 text-indigo-600'
                  : 'bg-white text-gray-500'
              } px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-20`}
            >
              {page + 1 < getEndLimitForPages() - 1
                ? page + 1
                : getEndLimitForPages() - 2}
            </a>
              )
            : null}
          {(customContacts || contacts).slice((page - 1) * limit, limit * page).length >
            limit - 1 &&
          (page + 1 < getEndLimitForPages() - 1
            ? page + 1
            : getEndLimitForPages() - 2) !==
            getEndLimitForPages() - 2
            ? (
            <>
              <span className='relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700'>
                ...
              </span>
              <a className='relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex'>
                ...
              </a>
            </>
              )
            : null}
          {isValidPage(getEndLimitForPages() - 1)
            ? (
            <a
              onClick={() => toSpecificPage(getEndLimitForPages() - 1)}
              className={`relative inline-flex items-center border border-gray-300 ${
                page === getEndLimitForPages() - 1
                  ? 'bg-indigo-50 text-indigo-600'
                  : 'bg-white text-gray-500'
              } px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-20`}
            >
              {getEndLimitForPages() - 1}
            </a>
              )
            : null}
          <a
            onClick={() => toSpecificPage(getEndLimitForPages())}
            className={`relative inline-flex items-center border border-gray-300 ${
              page === getEndLimitForPages()
                ? 'bg-indigo-50 text-indigo-600'
                : 'bg-white text-gray-500'
            } px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-20`}
          >
            {getEndLimitForPages()}
          </a>
          <a
            onClick={toNextPage}
            className='relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
          >
            <span className='sr-only'>Next</span>
            <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
          </a>
        </nav>
      </div>
    </div>
  )
}

export default TablePagination
