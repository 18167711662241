// @ts-nocheck
import React from 'react'
import clsx from 'clsx'

import { formatMoney } from 'utils/formatters'

import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'

import { ReactComponent as CheckedCheckbox } from 'assets/icons/CheckedCheckbox.svg'

const UpgradeAndOptionsCard = ({
  id = 1,
  isActive = true,
  imgSrc,
  price = 10000,
  description = 'Here should go the description of the Upgrade. The length shouldn’t be more than 3 rows.',
  count = 0,
  increaseCount,
  decreaseCount,
  checked,
  setChecked,
  checkboxTitle = 'Default checkbox',
  containerClasses,
  title
}) => {
  return (
    <div className={clsx(`${containerClasses}`, {
      'border-2 border-softBlack': isActive
    })}>
      <div className='h-60'>
        <img className='w-full h-full' src={imgSrc} alt={title} />
      </div>
      <div className='border-2 border-softBlack my-4 w-full' />
      <p className='font-bold text-2xl my-0 break-words'>{title}</p>
      <p className='text-sm font-medium mt-1'>{formatMoney(price)}</p>
      <p className='mt-1 mb-0 text-base text-softBlack'>
        {description}
      </p>
      <div className='flex items-center justify-between mt-4'>
        <div className="form-check flex items-center justify-between">
          {checked ? <CheckedCheckbox className='cursor-pointer mr-3 transition duration-200 w-6 h-6' onClick={setChecked} /> : <div className='cursor-pointer w-6 h-6 border border-softBlack30 rounded-sm mr-3 transition duration-200' onClick={setChecked} />}
          <label className="mt-1 mb-0 text-base text-softBlack font-medium" htmlFor="flexCheckDefault">
            {checkboxTitle}
          </label>
        </div>
        <div className={clsx('flex items-center justify-between gap-5', {
          'opacity-50 cursor-not-allowed': !checked
        })}>
          <span onClick={decreaseCount} className={clsx('rounded-sm cursor-pointer h-6 w-6 border border-softBlack70 flex justify-center items-center font-bold text-softBlack', {
            'opacity-50 cursor-not-allowed': !checked
          })}><MinusIcon /></span>
          <p className='my-0 min-w-6 text-base text-softBlack font-medium'>{count}</p>
          <span onClick={increaseCount} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} className={clsx('rounded-sm cursor-pointer h-6 w-6 flex justify-center items-center font-bold text-softBlack bg-volt', {
            'opacity-50 cursor-not-allowed': !checked
          })}><PlusIcon /></span>
        </div>
      </div>
    </div>
  )
}
export default UpgradeAndOptionsCard
