/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from 'react'
import propTypes from 'prop-types'
import { ADMIN_CREATE_PROJECT, LOGOUT } from 'routes'
import { useHistory } from 'react-router'

import {
  Modal,
  // Switch
} from 'ui'
import {
  LeftPanelContainer,
  LogoContainer,
  BottomLine,
  ProjectMenu,
  LeftPanelMenuContainer,
  CreateProjectContainer,
  FooterMenuContainer,
  CreateProjectButton,
  LogoutButton,
  BottomLineContainer
} from './styles'
import AdminLogo from 'components/AdminLogo'
import { PlusIcon, TLogoutIcon } from '../Icons'
import { useDispatch, useSelector } from 'react-redux'
// import { HOVER_TOGGLER } from 'store/actions/actionTypes'
// import standardStyle from 'assets/css/standardStyle'

const LeftPanel = (props) => {
  const didMount = useRef(false)
  let initAppProjectId = null
  const { projects, changeAppProject, appProject, handleLogout } = props
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (appProject !== initAppProjectId) {
      if (!didMount.current) {
        initAppProjectId = appProject
        didMount.current = true
      }
    }
  }, [appProject])

  const [selectedProduct, setSelectedProduct] = useState('6022955f8df61a00192c9f3f')
  const [selectedDeveloperId, setSelectedDeveloperId] = useState('602292ed8df61a00192c9f32')
  const isHover = useSelector((state) => state.appReducer.isHover)

  // const handleHoverToggle = () => {
  //   dispatch({ type: HOVER_TOGGLER, payload: (isHover ? false : true) })
  // }

  useEffect(() => {
    if (!didMount.current) return
    if (appProject) return
    if (!projects || !projects.length) return
    changeAppProject({ projectId: projects?.[0]?._id, developerId: projects?.[0]?.developerId, newProject: false })
  }, [appProject, projects, changeAppProject])

  const onChangeAppProject = (selectedproject) => {
    const projectId = selectedproject._id
    setSelectedProduct(projectId)
    const developerId = projects.filter(project => project._id === projectId)[0].developerId
    setSelectedDeveloperId(developerId)
    changeAppProject({ projectId, developerId, newProject: false })
  }

  const logout = useCallback(() => {
    handleLogout()
    history.push(LOGOUT)
  }, [handleLogout, history])

  const confirmLogoutModal = useCallback(() => {
    return (
      <Modal
        showModal={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        modalType='alert'
        title='Are you sure?'
        subtitle='Do you want to quit?'
        onOk={() => logout()}
      />
    )
  }, [logout, showLogoutModal, setShowLogoutModal])

  const createEditPage = (_id, pathname) => {
    history.push({
      pathname,
      state: {
        _id
      }
    })
  }

  //  console.log(useSelector((state) => state.appReducer.appProject))
  return (
    <LeftPanelContainer>
      <div>
        <LogoContainer>
          <AdminLogo />
          <BottomLineContainer>
            <BottomLine />
          </BottomLineContainer>
        </LogoContainer>
        <LeftPanelMenuContainer>
          {projects?.map((project) => (
            <ProjectMenu
              isActive={appProject === project?._id}
              onClick={(e) => onChangeAppProject(project)}
              key={project._id}
            >
              {project.projectName[0]}
            </ProjectMenu>
          ))}
        </LeftPanelMenuContainer>
        <CreateProjectContainer>
          <CreateProjectButton
            onClick={(e) => {
              changeAppProject({ projectId: selectedProduct, developerId: selectedDeveloperId, newProject: true })
              createEditPage('', ADMIN_CREATE_PROJECT)
            }}
          >
            <PlusIcon />
          </CreateProjectButton>
        </CreateProjectContainer>
      </div>
      <FooterMenuContainer>
        <BottomLine />
        <LogoutButton
          onClick={() => setShowLogoutModal(true)}
        >
          <TLogoutIcon />
        </LogoutButton>
      </FooterMenuContainer>
      {/* <Switch checked={isHover} onChange={() => handleHoverToggle()} style={{ background: isHover && standardStyle.colors.volt, outline: '1px solid black' }} /> */}
      {confirmLogoutModal()}
    </LeftPanelContainer>
  )
}

export default LeftPanel

LeftPanel.propTypes = {
  projects: propTypes.array,
  changeAppProject: propTypes.func,
  appProject: propTypes.string,
  handleLogout: propTypes.func
}
