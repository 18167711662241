import {
  ADMIN_DASHBOARD,
  ADMIN_PROJECT_MANAGEMENT,
  SALES_PIPELINE,
  ADMIN_CONTACTS,
  // ALLOCATE_INVENTORY,
  ADMIN_OFFERS,
  PAGE_UNDER_CONSTRUCTION,
  EMAIL_INBOX,
  CALENDAR,
  CONTRACT_TEMPLATES,
  UNITS_INVENTORY,
  ADMIN_TEAM_SALES_REPS,
  ADMIN_TEAM_COOP_BROKER,
  ADMIN_BUILDINGS,
  ADMIN_PROJECT_IMAGES,
  ADMIN_PROJECT_PARKING,
  ADMIN_PROJECT_COLORS,
  ADMIN_PROJECT_UPGRADES,
  ADMIN_PROJECT_PAYMENTS,
  ADMIN_CREATE_INVENTORY_ALLOCATION,
  ALLOCATIONS,
  ADMIN_PROJECT_ALLOCATIONS,
  ADMIN_PROJECT_UNIT_INVENTORY_GRAPH,
  ADMIN_PROJECT_FILES,
  ADMIN_OPPORTUNITIES
} from 'routes'
export const TITLE = 'Hudson 8'

export const MENU_CATEGORY = [
  {
    title: 'General',
    key: 'general',
    value: 'general',
    routes: [ADMIN_DASHBOARD, SALES_PIPELINE, ADMIN_CONTACTS, ADMIN_OFFERS, ADMIN_OPPORTUNITIES, PAGE_UNDER_CONSTRUCTION, EMAIL_INBOX, CALENDAR, CONTRACT_TEMPLATES, ADMIN_TEAM_SALES_REPS, ADMIN_TEAM_COOP_BROKER, ADMIN_CREATE_INVENTORY_ALLOCATION, UNITS_INVENTORY, ALLOCATIONS]
  },
  {
    title: 'Project',
    key: 'project',
    value: 'project',
    routes: [ADMIN_PROJECT_MANAGEMENT, ADMIN_PROJECT_IMAGES, ADMIN_PROJECT_PARKING, ADMIN_PROJECT_COLORS, ADMIN_PROJECT_UPGRADES, ADMIN_PROJECT_PAYMENTS, ADMIN_BUILDINGS, ADMIN_PROJECT_ALLOCATIONS, ADMIN_PROJECT_FILES, ADMIN_PROJECT_UNIT_INVENTORY_GRAPH]
  }
]
