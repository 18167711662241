import React from 'react'
import Modal from 'react-modal'

const defaultStyles = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  zIndex: '4999',
  border: 'none',
  padding: '0',
  maxHeight: '95%'
}

const CustomModal = ({
  isOpen,
  closeModal,
  children,
  contentLabel = '',
  customStyles = {}
}) => {
  if (!isOpen) {
    return null
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{ content: { ...defaultStyles, ...customStyles } }}
      contentLabel={contentLabel || 'Modal'}
      ariaHideApp={false}
      overlayClassName='bg-black60 fixed inset-0 z-4999 w-full h-full'
    >
      {children}
    </Modal>
  )
}

export default CustomModal
