import React, { useEffect, useState } from 'react'

const CustomTextArea = ({
  label,
  value,
  onChange,
  placeholder = 'Describe here',
  labelClasses,
  textareaClasses,
  containerClasses,
  errorMessage,
  onBlur = () => {},
  style,
  error = false,
  ...props
}) => {
  const [isShowError, setIsShowError] = useState(false)
  const [isError, setIsError] = useState(false)
  const handleBlur = () => {
    if (!isShowError) {
      setIsShowError(true)
    }
    onBlur?.()
  }
  const handleValidation = async () => {
    if (isShowError && props?.required === 'required' && value === '') {
      const isValid = true
      setIsError(isValid)
    } else {
      const isValid = false
      setIsError(isValid)
    }
  }
  useEffect(() => {
    handleValidation()
  }, [value, isShowError])
  useEffect(() => {
    if (error) {
      setIsShowError(true)
      handleValidation()
      onBlur?.()
    } else {
      setIsShowError(false)
    }
  }, [error])
  return (
    <div className={`text-left ${containerClasses}`}>
      <p className={`mt-0 mb-2 text-softBlack70 text-sm font-medium uppercase ${labelClasses}`} style={{ letterSpacing: '0.16px' }}>{label}</p>
      <textarea value={value} placeholder={placeholder} onBlur={handleBlur} className={`resize-none rounded h-24 font-normal border border-softBlack30 w-full text-base text-grey-9 ${textareaClasses}`} {...props} onChange={onChange} style={{ ...style }} />
      {isError && isShowError && props.required === 'required' && <p className='my-0 text-xs mt-1 text-red-500'>{ errorMessage || 'This field is required' }</p>}
    </div>
  )
}

export default CustomTextArea
