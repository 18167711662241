import CategoryButton from 'components/AdminSideBar/CategoryButton'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { PageHeader, TFilledPlusIcon } from 'components'
import { ADMIN_CREATE_EVENT_PAGE } from 'routes'
import { useHistory, useParams } from 'react-router-dom'
import { getEvents, getImage } from 'store/actions/eventsActions'
import AddAttendeeModal from './components/AddAttendeeModal'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 px-2 rounded flex items-center bg-volt'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const EventDetailPage = (props) => {
  const EVENT_DETAIL_BAR = [
    {
      title: 'RSPV-YES',
      key: 'rspv-yes',
      value: 'rspv-yes'
    },
    {
      title: 'All Invitees',
      key: 'all-invitees',
      value: 'all-invitees'
    },
    {
      title: 'Add Attendee(s)',
      key: 'add-attendees',
      value: 'add-attendees'
    }
  ]
  const [selectedCategory, setSelectedCategory] = useState(
    EVENT_DETAIL_BAR[0].value
  )
  const history = useHistory()
  const { eventId } = useParams()
  const [event, setEvent] = useState()
  const [heroImage, setHeroImage] = useState()
  const [date, setDate] = useState()
  const [isShowCreateAttendeeModal, setIsShowCreateAttendeeModal] = useState(false)

  useEffect(() => {
    if (eventId) {
      getEvents(eventId).then((data) => {
        setEvent(data)
      })
    }
  }, [eventId])

  useEffect(() => {
    if (event) {
      var startTime = new Date(event.startDate)
      var endTime = new Date(event.endDate)
      var formattedStartDate = startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
      var formattedEndDate = endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
      setDate(formattedStartDate + ' - ' + formattedEndDate)
      if (event.heroImage) {
        getImage(event.heroImage).then((res) => setHeroImage(res)).catch(() => setHeroImage())
      }
    }
  }, [event])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <Button onClick={() => history.push(ADMIN_CREATE_EVENT_PAGE)} >
        <TFilledPlusIcon className='h-6 w-3' />
        Add New Events
      </Button>
    </div>
  )

  return (
    <>
      <PageHeader title='Events' rightContent={<RightContent />} />
      <div className='flex flex-col items-center justify-center relative w-full'>
        <img
          src='/event-detail-hero.png'
          className='w-full object-cover max-h-sm'
        />
        <div className='absolute border-black border-[1px] rounded-[4px]  grid grid-cols-12 col-span-12 gap-4 w-[calc(100%-80px)] mx-auto left-0 right-0 mt-20 mb-10 top-[80px] bg-white pt-6 '>
          {!event && <p className='col-span-12 text-center font-medium text-xl'>Event detail is loading...</p>}
          {event && <>
            <div className='col-span-12 grid grid-cols-12 gap-x-4 px-10 pb-4 '>
              <div className='col-span-6'>
                <img
                  src={heroImage?.url || '/event-detail-image.png'}
                  alt={`${event.name} hero image`}
                  className='w-full object-contain h-full lg:h-sm lg:max-h-sm'
                />
              </div>
              <div className='col-span-6 flex flex-col'>
                <div className='flex justify-between items-center'>
                  <div>
                    <div className='font-bold text-[28px]'>{event.name}</div>
                  </div>
                  <div>
                    <button
                      onClick={() => setIsShowCreateAttendeeModal(true)}
                      className='bg-[#EDDF65] flex items-center gap-2 px-4 py-2 rounded-[3px]'
                    >
                      <img src='/person-grp.png' />
                      <div className='font-bold italic text-[11.86px]'>
                        Add Attendees
                      </div>
                    </button>
                  </div>
                </div>
                <div className='flex justify-between mt-4'>
                  <div className='flex flex-col italic text-[18px]'>
                    <div>{date}</div>
                    <div>{event.startTime + ' - ' + event.endTime}</div>
                  </div>
                  <div>
                    <button onClick={() => history.push(`/admin/events/edit/${eventId}`)} className='border-[1px] flex gap-2 md:px-4 md:py-2 border-[rgba(46,43,46,0.7)] rounded-[3px]'>
                      <img src='/edit-pen-icon.png' />
                      <div className='text-[14px] font-light italic'>
                        Edit Event
                      </div>
                    </button>
                  </div>
                </div>
                <div className='flex items-center gap-2 mt-4'>
                  <img src='/location-black.png' />
                  <div className='text-[18px]'>{event.locationAddress}</div>
                </div>
                <div className='grid col-span-12 grid-cols-12 gap-4 mt-4'>
                  <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
                    <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
                      INVITED
                    </div>
                    <div className='text-[16px] text-[#2e2b2e] font-bold'>
                      300
                    </div>
                  </div>
                  <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
                    <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
                      RSVP YES
                    </div>
                    <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
                  </div>
                  <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
                    <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
                      RSVP YES
                    </div>
                    <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
                  </div>
                  <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
                    <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
                      RSVP YES
                    </div>
                    <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
                  </div>
                  <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
                    <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
                      RSVP YES
                    </div>
                    <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
                  </div>
                  <div className='bg-[#f2f2f1] border-[1px] col-span-4 basis- p-4  border-black rounded-[3px]'>
                    <div className='text-[12px] text-[rgba(46,43,46,0.7)] italic'>
                      RSVP YES
                    </div>
                    <div className='text-[16px] text-[#2e2b2e] font-bold'>88</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-span-12 rounded-b-[4px] grid grid-cols-12 gap-4 px-10 pt-6 pb-6 bg-[#F2F2F1]'>
              <div className='w-full col-span-12 container mx-auto flex justify-between'>
                {EVENT_DETAIL_BAR.map((category, index) => (
                  <CategoryButton
                    key={category.title}
                    category={category}
                    selected={selectedCategory === category?.value}
                    setSelectedCategory={setSelectedCategory}
                  >
                    <div className='py-2 border-[1px] border-black font-light italic text-[16px]'>
                      {category.title}
                    </div>
                  </CategoryButton>
                ))}
              </div>
              <div className='col-span-12 grid grid-cols-12 gap-4'>
                <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
                  <div className='col-span-2 text-[14px] italic font-light'>
                    Omar Bator
                  </div>
                  <div className='col-span-2 text-[14px] italic font-light'>
                    Buyer
                  </div>
                  <div className='  col-span-3 items-center flex w-full  gap-x-6 col-start-8 lg:col-start-9'>
                    <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
                      RSVP YES
                    </button>
                    <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
                      PENDING
                    </button>
                  </div>
                  <button className='col-span-1  col-start-12'>
                    {/*   <img src='/three-dot-dark.png' className='h-6 w-6 mr-8' /> */}
                  </button>
                </div>
                <div className='w-full h-[1px] bg-black col-span-12'></div>
                <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
                  <div className='col-span-2 text-[14px] italic font-light'>
                    Alena Donin
                  </div>
                  <div className='col-span-2 text-[14px] italic font-light'>
                    Broker
                  </div>
                  <div className='col-span-3 items-center justify-between flex w-full   gap-x-6 col-start-8 lg:col-start-9'>
                    <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
                      RSVP YES
                    </button>
                    <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
                      PENDING
                    </button>
                  </div>
                  <button className='col-span-1 flex  lg:justify-center col-start-12'>
                    <img src='/three-dot-dark.png' className='h-6 w-6 md:mr-8' />
                  </button>
                </div>
                <div className='w-full h-[1px] bg-black col-span-12'></div>
                <div className='col-span-12 grid grid-cols-12 items-center gap-x-4'>
                  <div className='col-span-2 text-[14px] italic font-light'>
                    Lindsey Calzoni
                  </div>
                  <div className='col-span-2 text-[14px] italic font-light'>
                    Sales Rep
                  </div>
                  <div className='  col-span-3 items-center flex w-full  gap-x-6 col-start-8 lg:col-start-9'>
                    <button className='bg-[#B8E04A] py-2 w-full text-[16px] font-bold px-5'>
                      RSVP YES
                    </button>
                    <button className='bg-[#FFDEAE] py-2 w-full text-[16px] font-bold px-5'>
                      PENDING
                    </button>
                  </div>
                  <button className='col-span-1  col-start-12'>
                    {/*   <img src='/three-dot-dark.png' className='h-6 w-6 mr-8' /> */}
                  </button>
                </div>
                <div className='w-full h-[1px] bg-black col-span-12'></div>
              </div>
            </div>
          </>
          }
        </div>
      </div>
      <AddAttendeeModal
        show={isShowCreateAttendeeModal}
        onClose={() => setIsShowCreateAttendeeModal(false)}
        eventId={eventId}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailPage)
