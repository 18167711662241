/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import UnitListView from './UnitListView'
import { classNames } from 'utils'

const groupBy = (list, keyGetter) => {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const ListView = ({
  units,
  gettingUnits,
  buildings,
  getBuildings,
  isPublicPage = false,
  refetchUnits
}) => {
  const [data, setData] = useState([])
  const [building, setBuilding] = useState('')
  // const [availabilityFilter, setAvailabilityFilter] = useState([])
  const [floorSortByAsc, setFloorSortByAsc] = useState(true)

  useEffect(() => {
    if (units.length > 0) {
      handleData()
    }
  }, [units, building, floorSortByAsc])

  const handleData = () => {
    let uCards = [...units]
    if (building.length > 0) {
      uCards = uCards.filter((_uCard) =>
        building.includes(_uCard?.building._id)
      )
    }
    const grouped = groupBy(uCards, (unit) => unit.floorNumber)
    // @ts-ignore
    const groupedValues = [...grouped.values()]
    if (floorSortByAsc) {
      setData([...groupedValues])
    } else {
      setData([...groupedValues.reverse()])
    }
  }

  return (
    <div
      className={classNames(
        isPublicPage ? 'w-full h-full p-2' : 'w-full mx-8 pb-4'
      )}
    >
      <UnitListView
        units={units}
        gettingUnits={gettingUnits}
        buildings={buildings}
        getBuildings={getBuildings}
        type={'inventoryUnit'}
        isPublicPage={isPublicPage}
        refetchUnits={refetchUnits}
      />
    </div>
  )
}

export default ListView
