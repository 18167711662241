/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'

import { TPlusPrimaryIcon, TFilleMinusIcon } from '../Icons'

const CustomNumberInput = ({
  label,
  value = 0,
  onChange,
  decrementHandler,
  incrementHandler,
  placeholder,
  containerClasses,
  labelClassName = '',
  classes,
  onBlur = () => {},
  errorMessage = '',
  error = false,
  ...props
}) => {
  const [isShowError, setIsShowError] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    handleValidation()
  }, [value, isShowError])

  const handleValidation = async () => {
    if (isShowError && props?.required === 'required') {
      const isValid = await Yup.number().min(0).isValid(value)
      setIsError(!isValid)
    }
  }
  const handleBlur = () => {
    if (!isShowError) {
      setIsShowError(true)
    }
    onBlur?.()
  }

  useEffect(() => {
    if (error) {
      setIsShowError(true)
      handleValidation()
      onBlur?.()
    } else {
      setIsShowError(false)
    }
  }, [error])

  return (
    <div className={`text-left ${containerClasses}`}>
      <p className={`mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase ${labelClassName}`} style={{ letterSpacing: '0.16px' }}>{label}</p>
      <div className='relative'>
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={`border h-12 rounded text-softBlack text-center placeholder-softBlack70 border-softBlack30 ${classes}`}
          onBlur={handleBlur}
          {...props}
        />
        <button className="absolute left-auto bg-volt" style={{ right: '13px', top: '11px', borderRadius: '2px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} onClick={incrementHandler}><TPlusPrimaryIcon className="w-6 h-6" /></button>
        <button className="absolute right-auto text-softBlack_70 border border-softBlack_70" style={{ left: '13px', top: '13px', borderRadius: '2px' }} onClick={decrementHandler}><TFilleMinusIcon className="w-5 h-5" /></button>
      </div>
      {isError && isShowError && props.required === 'required' && <p className='my-0 text-xs mt-1 text-red-500'>This field is required</p>}
    </div>
  )
}

export default CustomNumberInput
