/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import picture from '../../../../assets/img/buyerBackgroundImage.jpg'
import EditIcon from '../../../../assets/icons/edit.svg'
import { SlimPlusIcon, TFilledLocationMarkerIcon } from 'components/Icons'

import { ADMIN_ADD_BUILDING } from 'routes'

import LargeTabs from 'components/LargeTabs'
import AddBlock from 'components/AddBlock'
import useGetBuildings from 'hooks/useGetBuildigs'
import AddFloorPlanModal from '../Forms/AddFloorPlan'
import AddUnitGroupModal from '../Forms/AddUnitGroupModal'
import Button from 'components/Buttons'
import useGetFloorPlans from 'hooks/useGetFloorPlans'
import { ImageUploaderBlock } from 'components/ImageUploader'
import useGetUnitGroups from 'hooks/useGetUnitGroups'
import CustomUnitDetail from '../components/CustomUnitDetail'
import useGetUnits from 'hooks/useGetUnits'
import { deleteFloorPlan } from 'store/actions/floorPlanActions'
import { deleteUnitGroup } from 'store/actions/unitGroupsActions'
import { deleteUnit, downloadUnitsCSV } from 'store/actions/unitActions'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import { AddButton } from 'components/AddBlock/styles'
import AddBulkUnits from 'components/AddBulkUnits'
import CustomParkingDetail from '../components/CustomParkingDetail'
import AddParkingModal from '../components/AddParkingModal'
import AddUnitModal from '../Forms/AddUnitModal'
import AddBulkParkings from 'components/AddBulkParkings'
import useGetParkings from 'hooks/useGetParkings'
import { deleteParking, downloadParkingsCSV } from 'store/actions/parkingActions'

const LoadingDetails = ({ title }) => (
  <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
    <Searching title={title} />
  </FadedAnimatedDiv>
)

const AdminEditBuilding = (props) => {
  const types = ['Floor Plans', 'Unit Groups', 'Units', 'Parkings']
  const buildingId = props.history.location.state.id
  const history = useHistory()

  // const { appProject: projectId } = useSelector((state) => state.appReducer);

  const {
    loading,
    buildings: { data: buildings }
  } = useGetBuildings(buildingId)
  const { loading: floorsLoading, floorPlans, refetch } = useGetFloorPlans()
  const {
    loading: unitGroupLoading,
    unitGroups,
    refetch: unitGroupsRefetch
  } = useGetUnitGroups()
  const { loading: unitsLoading, units, refetch: unitsRefetch } = useGetUnits()
  const { loading: parkingsLoading, parkings, refetch: parkingsRefetch } = useGetParkings()
  const [active, setActive] = useState(types[0])

  const [showFloorModal, setFloorModal] = useState(false)
  const [showUnitGroupModal, setUnitGroupModal] = useState(false)
  const [showUnitModal, setUnitModal] = useState(false)
  const [showParkingModal, setParkingModal] = useState(false)
  const [showBulkUnitModal, setShowBulkUnitModal] = useState(false)
  const [showBulkParkingModal, setShowBulkParkingModal] = useState(false)
  const [modelDetails, setModelDetails] = useState({})

  const [downloadingUnits, setDownloadingUnits] = useState(false)
  const [downloadingParkings, setDownloadingParkings] = useState(false)

  const downloadUnits = useCallback(async () => {
    setDownloadingUnits(true)
    if (buildingId?.length > 0) {
      try {
        await downloadUnitsCSV(buildingId)
      } catch (e) {}
    }
    setDownloadingUnits(false)
  }, [buildingId])

  const downloadParkings = useCallback(async () => {
    setDownloadingParkings(true)
    if (buildingId?.length > 0) {
      try {
        await downloadParkingsCSV(buildingId)
      } catch (e) {}
    }
    setDownloadingParkings(false)
  }, [buildingId])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <button
        className='cursor-pointer hover:text-yellow-400'
        onClick={() => history.push(ADMIN_ADD_BUILDING)}
      >
        <span className='text-2xl mr-2'>+</span>
        <span className='italic text-base font-light font-openSans'>
          Add Building
        </span>
      </button>
    </div>
  )

  const handleUnitEdit = (item) => {
    setModelDetails({
      type: 'Units',
      _id: item._id,
      unitNumber: item.unitNumber,
      fullAddress: item?.fullAddress || '',
      strataLotNumber: item?.strataLotNumber || '',
      status: item.status,
      price: item.price,
      priceCurrencyType: item.priceCurrencyType,
      floorPlan: item.floorPlan._id,
      floorNumber: item.floorNumber,
      numberOfBathrooms: item.numberOfBathrooms,
      numberOfBedrooms: item.numberOfBedrooms,
      balcony: item.balcony,
      unitGroup: item.unitGroup._id
    })
    setUnitModal(true)
  }

  const handleParkingEdit = (item) => {
    setModelDetails({
      type: 'Parkings',
      _id: item._id,
      unitNumber: item.unitNumber,
      fullAddress: item?.fullAddress || '',
      strataLotNumber: item?.strataLotNumber || '',
      status: item.status,
      price: item.price,
      priceCurrencyType: item.priceCurrencyType,
      floorPlan: item.floorPlan._id,
      floorNumber: item.floorNumber,
      numberOfBathrooms: item.numberOfBathrooms,
      numberOfBedrooms: item.numberOfBedrooms,
      balcony: item.balcony,
      unitGroup: item.unitGroup._id
    })
    setParkingModal(true)
  }

  const handleDeleteUnit = (id) => {
    deleteUnit(id)
      .then(() => {
        unitsRefetch()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDeleteParking = (id) => {
    deleteParking(id)
      .then(() => {
        parkingsRefetch()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleFloorPlanSelect = (floorPlan) => {
    setModelDetails({
      type: 'Floor Plans',
      _id: floorPlan._id,
      name: floorPlan.name,
      parkingQty: floorPlan.parkingQty,
      parkingType: floorPlan.parkingType,
      image: floorPlan.image
    })
    setFloorModal(true)
  }

  const handleDeleteFloorPlan = (id) => {
    deleteFloorPlan(id)
      .then(() => {
        refetch()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleDeleteUnitGroups = (id) => {
    deleteUnitGroup(id).then(() => {
      unitGroupsRefetch()
    })
  }
  const handleUnitGroupSelect = (unitGroup) => {
    setModelDetails({
      type: 'Unit Groups',
      _id: unitGroup._id,
      name: unitGroup.name,
      description: unitGroup.description
    })
    setUnitGroupModal(true)
  }

  const renderFloorPlans = useCallback(() => {
    if (active !== 'Floor Plans') {
      return null
    }

    if (floorsLoading) {
      return <LoadingDetails title={'Loading Floor Plans'} />
    }
    return (
      <>
        <AddBlock
          label={`Click to start adding ${active.toLowerCase()}`}
          btnText={`Add ${active.slice(0, -1)}`}
          setAddBlockClick={() => setFloorModal(true)}
          showButtonOnly
          backgroundColor={floorPlans.length > 0 ? 'transparent' : '#fff'}
        />
        {floorPlans?.map((floorPlan) => (
          <ImageUploaderBlock
            key={floorPlan._id}
            imgsrc={floorPlan.image}
            imgTitle={floorPlan.name}
            edit
            description={`${floorPlan.parkingQty} Parking Slot, ${floorPlan.parkingType}`}
            parking
            onUpload={() => handleFloorPlanSelect(floorPlan)}
            onDelete={() => handleDeleteFloorPlan(floorPlan._id)}
          />
        ))}
      </>
    )
  }, [floorsLoading, floorPlans, active])

  const renderUnitGroups = useCallback(() => {
    if (active !== 'Unit Groups') {
      return null
    }

    if (unitGroupLoading) {
      return <LoadingDetails title='Loading unit groups' />
    }
    return (
      <>
        <AddBlock
          label={`Click to start adding ${active.toLowerCase()}`}
          btnText={`Add ${active.slice(0, -1)}`}
          setAddBlockClick={() => setUnitGroupModal(true)}
          showButtonOnly
          backgroundColor={unitGroups.length > 0 ? 'transparent' : '#fff'}
        />
        {unitGroups?.map((unitGroup) => (
          <div className='mt-4'>
            <CustomUnitDetail
              key={unitGroup._id}
              unit={unitGroup}
              handleEdit={() => handleUnitGroupSelect(unitGroup)}
              handleDelete={() => handleDeleteUnitGroups(unitGroup._id)}
            />
          </div>
        ))}
      </>
    )
  }, [unitGroupLoading, unitGroups, active])

  const renderUnits = useCallback(() => {
    if (active !== 'Units') {
      return null
    }
    if (unitsLoading) {
      return <LoadingDetails title='Loading units' />
    }
    return (
      <>
        <div className='flex justify-center items-center content-center gap-3 mb-3'>
          <AddButton onClick={() => setUnitModal(true)}>
            <SlimPlusIcon className={'h-3 w-3'} />
            Add Unit
          </AddButton>
          <AddButton onClick={() => setShowBulkUnitModal(true)}>
            <SlimPlusIcon className={'h-3 w-3'} />
            Add Bulk Units
          </AddButton>
          <AddButton onClick={() => downloadUnits()}>
            {downloadingUnits ? 'Downloading...' : 'Download Units'}
          </AddButton>
        </div>
        {units?.map((item) => (
          <CustomUnitDetail
            key={item._id}
            unit={{
              name: 'Unit #' + item.unitNumber,
              price: item.priceCurrencyType + '$' + item.price,
              status: item.status,
              bedrooms: item.bedrooms,
              bathrooms: item.bathrooms,
              balcony: item.balcony
            }}
            handleEdit={() => handleUnitEdit(item)}
            handleDelete={() => handleDeleteUnit(item._id)}
          />
        ))}
      </>
    )
  }, [unitsLoading, units, active, downloadingUnits])

  const renderParkings = useCallback(() => {
    if (active !== 'Parkings') {
      return null
    }
    if (parkingsLoading) {
      return <LoadingDetails title='Loading parkings' />
    }
    return (
      <>
        <div className='flex justify-center items-center content-center gap-3 mb-3'>
          <AddButton onClick={() => setParkingModal(true)}>
            <SlimPlusIcon className={'h-3 w-3'} />
            Add Parking
          </AddButton>
          <AddButton onClick={() => setShowBulkParkingModal(true)}>
            <SlimPlusIcon className={'h-3 w-3'} />
            Add Bulk Parkings
          </AddButton>
          <AddButton onClick={() => downloadParkings()}>
            {downloadingParkings ? 'Downloading...' : 'Download Parkings'}
          </AddButton>
        </div>
        {parkings?.map((item) => (
          <CustomParkingDetail
            key={item._id}
            unit={{
              name: 'Parking #' + item.unitNumber,
              price: item.priceCurrencyType + '$' + item.price,
              status: item.status,
              bedrooms: item.bedrooms,
              bathrooms: item.bathrooms,
              balcony: item.balcony
            }}
            handleEdit={() => handleParkingEdit(item)}
            handleDelete={() => handleDeleteParking(item._id)}
          />
        ))}
      </>
    )
  }, [parkingsLoading, parkings, active, downloadingParkings])

  const RenderTabs = () => (
    <div className='mt-10 mb-6'>
      <LargeTabs
        types={types}
        active={active}
        setActive={setActive}
        styles={{
          fontWeight: 300,
          padding: '20px 0px',
          fontFamily: 'Open Sans',
          fontStyle: 'italic'
        }}
      />
      <div className='mt-8'>
        {renderFloorPlans()}
        {renderUnitGroups()}
        {renderUnits()}
        {renderParkings()}
      </div>
      {showFloorModal && (
        <AddFloorPlanModal
          show={showFloorModal}
          setShow={setFloorModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          refetch={refetch}
        />
      )}
      {showUnitGroupModal && (
        <AddUnitGroupModal
          show={showUnitGroupModal}
          setShow={setUnitGroupModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          refetch={unitGroupsRefetch}
        />
      )}
      {showUnitModal && (
        <AddUnitModal
          show={showUnitModal}
          setShow={setUnitModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          refetch={unitsRefetch}
          building={buildingId}
        />
      )}
      {showParkingModal && (
        <AddParkingModal
          show={showParkingModal}
          setShow={setParkingModal}
          editDetails={modelDetails}
          setEditDetails={setModelDetails}
          refetch={parkingsRefetch}
          building={buildingId}
        />
      )}
      {showBulkUnitModal && (
        <AddBulkUnits
          show={showBulkUnitModal}
          setShow={setShowBulkUnitModal}
          onSuccess={() => {}}
        />
      )}
      {showBulkParkingModal && (
        <AddBulkParkings
          show={showBulkParkingModal}
          setShow={setShowBulkParkingModal}
          onSuccess={() => {}}
        />
      )}
    </div>
  )

  const RenderContent = () => (
    <div className='p-6 bg-grey-8 rounded'>
      <img src={picture} alt='building' className='h-80 w-full' />
      <div className='border-b-2 border-black mt-5' />
      <div className='flex flex-col'>
        <div>
          <div className='mt-6 font-bold text-2xl text-softBlack font-openSans'>
            {buildings?.name}
          </div>
          <div className='font-light font-openSans italic text-softBlack text-base'>
            {buildings?.totalDollarValueCurrencyType}${' '}
            {buildings?.totalDollarValue}
          </div>
        </div>
        <div
          className='self-end absolute mt-6'
          onClick={() =>
            props.history.push({
              pathname: ADMIN_ADD_BUILDING,
              state: { id: buildingId }
            })
          }
        >
          <Button
            label='Edit Building'
            type='Secondary'
            state='Pressed'
            buttonClasses='bg-grey-8 font-openSans w-full text-softBlack'
            fontWeight='300'
            size='Long'
            buttonIcon={EditIcon}
          />
        </div>
        <div className='mt-4 flex text-softBlack font-normal text-base font-openSans leading-none'>
          <TFilledLocationMarkerIcon className='h-4 w-4 mr-1' />
          {buildings?.fullAddress}
        </div>
      </div>
      <RenderTabs />
    </div>
  )

  return (
    <>
      <div className='bg-menuGray min-h-screen relative'>
        <div className='p-6 flex justify-between items-center'>
          <span className='font-bold text-3xl font-openSans'>Buildings</span>
          <RightContent />
        </div>
        <div className='border-b-2 border-black' />
        {loading && (
          <div
            className='flex items-center justify-center w-full h-screen'
            style={{ gridColumn: '1 / -1' }}
          >
            <LoadingDetails title='Loading building details' />
          </div>
        )}
        {!loading && (
          <div className='p-8'>
            <RenderContent />
          </div>
        )}
      </div>
    </>
  )
}

export default AdminEditBuilding
