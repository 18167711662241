/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState, createContext, useRef } from 'react'

import { getUsers } from 'store/actions/usersActions'

import useBuyers from 'hooks/useGetBuyers'

import { useGetContactLists, useGetFilteredContactLists } from 'hooks/useGetContactLists'

import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const ContactContext = createContext({
  loading: false,
  buyers: [],
  contacts: [],
  setContacts: (val) => {},
  isLoading: false,
  getUsersData: () => {},
  contactRatingFilters: {},
  setContactRatingFilters: () => {},
  filteredContacts: [],
  setFilteredContacts: () => {},
  resetFilters: () => {},
  updateContact: (contact) => {},
  setIsLoading: (value) => {},
  saveList: [],
  setSaveList: (val) => {},
  page: 1,
  limit: 30,
  selected: {},
  setSelected: () => {},
  contactsLists: [],
  getContacts: () => {},
  selectedContact: null,
  setSelectedContact: (value) => {},
  selectedContactObject: null,
  setSelectedContactObject: (contact) => {},
  fetchProjectsForInfoTab: () => {},
  dropdownData: null,
  projectsLoading: false,
  projects: null,
  setProjects: (value) => {},
  updateContactObject: (contact) => {},
  setContact: (val) => {},
  listsChecked: null
})

export const ContactProvider = ({ children }) => {
  const mountedRef = useRef(true)
  const contextProjects = useSelector(state => state.appReducer.listOfProjects)
  const { loading, buyers, getBuyersData } = useBuyers()
  const [contacts, setContacts] = useState([])
  const [filteredContacts, setFilteredContacts] = useState([])
  const [selectedWord, setSelectedWord] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [viewType, setViewType] = useState(isMobile ? 'cardView' : 'listView')
  // Initial States
  const showFiltersInitialState = { onlyLeadBrokers: false, showAll: true }
  const [showFilters, setShowFilters] = useState(showFiltersInitialState)

  // Tailwind Table States
  const [tableContact, setTableContact] = useState(contacts)
  const [saveList, setSaveList] = useState([])
  const [limit] = useState(30)
  const [selected, setSelected] = useState({})
  const { contactsLists, getContacts } = useGetContactLists()
  const { contacts: searchedContacts, getContacts: getSearchContacts, loading: isSearchingContacts } = useGetFilteredContactLists()
  const [contactsListsChecked, setContactsListsChecked] = useState({})
  const [listsChecked, setListsChecked] = useState({})
  const [userType, setUserType] = useState({})
  const [allListsChecked, setAllListsChecked] = useState(false)
  const [selectedSalesType, setSelectedSalesType] = useState('buyer')

  // Selected Contact State
  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedContactObject, setSelectedContactObject] = useState(null)

  // projects state
  const [projects, setProjects] = useState(null)

  // Infotab state
  const hasFetchedProjects = useRef(false)
  const [projectsLoading] = useState(false)
  const [dropdownData, setDropdownData] = useState({
    budgetOptions: [
      { label: 'under $200K' },
      { label: '$200K - $500K' },
      { label: '$500K - $1M' },
      { label: '$1M+' }
    ],
    timelineToPurchase: [
      { label: 'Ready now' },
      { label: '1-3 Months' },
      { label: '3-6 Months' },
      { label: '6 Months +' }
    ],
    needFinancing: [
      { label: 'Yes' },
      { label: 'I have Financing in place' },
      { label: 'No' }
    ],
    unitInterests: [
      { id: 0, value: 'Studio' },
      { id: 1, value: 'One Bed' },
      { id: 2, value: '2 bed' },
      { id: 3, value: '3 bed' },
      { id: 4, value: 'Townhouse' },
      { id: 5, value: 'Penthouse' }
    ],
    projectInterests: [
      { id: 1, value: 'Zoho Zen' },
      { id: 2, value: 'Other Zoho Project' },
      { id: 3, value: 'Other' }
    ],
    floorInterests: [
      { id: 0, value: '1' },
      { id: 1, value: '2' },
      { id: 2, value: '3' },
      { id: 3, value: '4' },
      { id: 4, value: '5' },
      { id: 5, value: '6' },
      { id: 6, value: '7' },
      { id: 7, value: '8' },
      { id: 8, value: '9' }
    ]
  })

  const { id } = useParams()

  const resetFilters = () => {
    setShowFilters(showFiltersInitialState)
    setContactRatingFilters(contactRatingInitialState)
  }
  const [isLoading, setIsLoading] = useState(true)
  const contactRatingInitialState = {
    A: true,
    B: true,
    C: true,
    D: true,
    Unrated: true
  }

  const [contactRatingFilters, setContactRatingFilters] = useState(
    contactRatingInitialState
  )

  useEffect(() => {
    if (Object.keys(listsChecked)?.length === contactsLists?.length) return
    const contactsListsCheckedTemp = {}
    const listsCheckedTemp = {}
    for (let i = 0; i < contactsLists?.length; i++) {
      const users = contactsLists[i].users
      listsCheckedTemp[contactsLists[i]._id] = false
      for (const userId in users) {
        if (!contactsListsCheckedTemp[users[userId]]) {
          contactsListsCheckedTemp[users[userId]] = false
        }
      }
      setAllListsChecked(false)
    }

    setContactsListsChecked(contactsListsCheckedTemp)
    setListsChecked(listsCheckedTemp)
  }, [contactsLists])

  useEffect(() => {
    getUsersData()
    getBuyersData()
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getUserById = (id) => {
    setIsLoading(true)
    getUsers(id, '').then((users) => {
      setSelectedContactObject({ ...users, id: users?._id })
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (!selectedContact) return
    getUserById(selectedContact)
  }, [selectedContact])

  const getUsersData = () => {
    if (!mountedRef.current) return null
    if (id) {
      setContacts([])
      setIsLoading(false)
      return
    }
    const filters = '?userType=Buyer&userType=LeadBroker'
    getUsers('', filters).then((users) => {
      if (!mountedRef.current) return null
      setContacts(users)
      setIsLoading(false)
    })
  }

  const updateContactObject = (contact) => {
    let temp = [...contacts]
    temp = temp.map(c => c?._id === contact?._id ? contact : c)
    setContacts(temp)
  }

  const updateContact = (contact) => {
    setSelectedContactObject(contact)
  }

  const fetchProjectsForInfoTab = () => {
    const tmpProjects = contextProjects?.map?.((project) => ({
      id: project._id,
      value: project.projectName
    }))
    const dropdownTemp = {
      ...dropdownData,
      projectInterests: tmpProjects
    }
    setProjects(projects)
    setDropdownData(dropdownTemp)
  }

  useEffect(() => {
    if (!selectedContact) return
    if (hasFetchedProjects.current) return
    fetchProjectsForInfoTab()
  }, [selectedContact])

  return (
    <ContactContext.Provider
      value={{
        loading,
        buyers,
        contacts,
        setContacts,
        isLoading,
        contactRatingFilters,
        setContactRatingFilters,
        resetFilters,
        showFilters,
        setShowFilters,
        filteredContacts,
        setFilteredContacts,
        selectedWord,
        setSelectedWord,
        searchFilter,
        setSearchFilter,
        getUsersData,
        updateContact,
        viewType,
        setViewType,
        saveList,
        setSaveList,
        limit,
        selected,
        setSelected,
        tableContact,
        setTableContact,
        contactsLists,
        contactsListsChecked,
        setContactsListsChecked,
        listsChecked,
        setListsChecked,
        allListsChecked,
        setAllListsChecked,
        getContacts,
        selectedContact,
        setSelectedContact,
        setIsLoading,
        userType,
        setUserType,
        selectedContactObject,
        setSelectedContactObject,
        fetchProjectsForInfoTab,
        dropdownData,
        projectsLoading,
        projects,
        setProjects,
        updateContactObject,
        searchedContacts,
        getSearchContacts,
        isSearchingContacts,
        selectedSalesType,
        setSelectedSalesType
      }}
    >
      {children}
    </ContactContext.Provider>
  )
}
