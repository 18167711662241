import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { DynamicDropdownContext } from '../context'

const CreateButton = () => {
  const { isEdit, label } = useContext(DynamicDropdownContext)
  const { handleSubmit } = useFormikContext()
  return (
    <Button
      onClick={() => handleSubmit()}
      style={{
        backgroundColor: '#EDDF65',
        width: '100%'
      }}
      variant='contained'
    >
      {isEdit ? 'Update' : 'Create New'} {label}
    </Button>
  )
}

export default CreateButton
