/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from 'react'
import { Select } from 'antd'

import { ReactComponent as DownIcon } from 'assets/icons/down.svg'

import useGetLeadBrokers from 'hooks/useGetLeadBrokers'

import { isMobile } from 'react-device-detect'
import MobileCard from './MobileCard'
import SalesRepSelect from './MobileCardComponents/SalesRepSelect'
import ContactDeleteModal from 'pages/contacts/popups/ContactDelete'
import AddToListModal from 'components/TailwindTable/components/AddToListModal'
import ContactListCreateModal from 'components/TailwindTable/components/ContactListCreateModal'
import useCreateContactList from 'hooks/useCreateContactList'
import useGetSalesReps from 'hooks/useGetSalesReps'
import useBrokerageCompanies from 'hooks/useBrokerageCompanies'
import { useSelector } from 'react-redux'
import useGetReferralAgent from 'hooks/useGetReferralAgent'
import useLeadSource from 'hooks/useLeadSource'
import useLeadStatus from 'hooks/useLeadStatus'
import { useHistory } from 'react-router-dom'
import useBuyerScore from 'hooks/useBuyerScore'
import useBrokerScore from 'hooks/useBrokerScore'
import useReasonForBuyerLost from 'hooks/useReasonForBuyerLost'
import useLeadApproachForBuyer from 'hooks/useLeadApproachForBuyer'

export const defaultRatingOptions = [
  {
    label: 'A',
    value: 'A',
    type: 'contact_rating_status',
    color: '#10B981'
  },
  {
    label: 'B',
    value: 'B',
    type: 'contact_rating_status',
    color: '#F59E0B'
  },
  {
    label: 'C',
    value: 'C',
    type: 'contact_rating_status',
    color: '#EF4444'
  },
  {
    label: 'D',
    value: 'D',
    type: 'contact_rating_status',
    color: '#F59E0B'
  },
  {
    label: 'Unrated',
    value: '',
    type: 'contact_rating_status',
    color: '#2e2b2eb3'
  }
]

export const HorizontalCardProfile = ({
  imgsrc = '',
  name = '',
  userType = '',
  onUpload = () => { },
  onDelete = () => { }
}) => {
  return (
    <div className='flex justify-between pb-5 border-b-2 border-b-black items-center'>
      <div className='flex space-x-4'>
        <img
          src={imgsrc}
          alt='Avatar'
          className='h-18 w-18 border rounded-full'
        />
        <div>
          <div className='font-bold text-lg'>{name}</div>
          <div className='text-xs mt-2 flex gap-1 items-center'>
            <svg
              width='16'
              height='16'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15.8333 4.16667H14.1667V2.5H5.83333V4.16667H4.16667C3.25 4.16667 2.5 4.91667 2.5 5.83333V6.66667C2.5 8.79167 4.1 10.525 6.15833 10.7833C6.68333 12.0333 7.80833 12.975 9.16667 13.25V15.8333H5.83333V17.5H14.1667V15.8333H10.8333V13.25C12.1917 12.975 13.3167 12.0333 13.8417 10.7833C15.9 10.525 17.5 8.79167 17.5 6.66667V5.83333C17.5 4.91667 16.75 4.16667 15.8333 4.16667ZM4.16667 6.66667V5.83333H5.83333V9.01667C4.86667 8.66667 4.16667 7.75 4.16667 6.66667ZM15.8333 6.66667C15.8333 7.75 15.1333 8.66667 14.1667 9.01667V5.83333H15.8333V6.66667Z'
                fill='#2E2B2E'
              />
            </svg>
            {userType}
          </div>
        </div>
      </div>
      <div className='flex space-x-5 text-xs font-semibold'>
        <button
          onClick={onUpload}
          className='hover:bg-gray-100 leading-none h-9 px-4 border shadow border-black rounded flex space-x-2 items-center'
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M8.25016 14.6667H13.7502V9.16667H17.4168L11.0002 2.75L4.5835 9.16667H8.25016V14.6667ZM4.5835 16.5H17.4168V18.3333H4.5835V16.5Z'
              fill='#2E2B2E'
            />
          </svg>
          <span>Upload</span>
        </button>
        <button
          onClick={onDelete}
          className='hover:bg-gray-100 leading-none h-9 px-6 border shadow border-black rounded flex space-x-2 items-center'
        >
          <svg
            width='12'
            height='16'
            viewBox='0 0 14 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
              fill='#2E2B2E'
            />
          </svg>

          <span>Delete</span>
        </button>
      </div>
    </div>
  )
}

export const HorizontalCardContact = ({
  backgroundImage = '',
  firstName = '',
  lastName = '',
  email = '',
  salesRepOwner = '',
  userType = '',
  rating = '',
  broker = '',
  referralAgent = '',
  setBroker = (value) => { },
  leadStatus = '',
  onClickDelete,
  handleRatingChange = (value) => { },
  handleLeadStatusChange = (value) => { },
  handleLeadSourceChange = (value) => { },
  handleReasonForBuyerLostChange = (value) => { },
  handleBuyerLeadApproachChange = (value) => { },
  handleSalesRepChange = (type, params) => { },
  handleBrokerageCompanyChange = (company) => { },
  handleBrokerChange = (type) => { },
  handleReferralAgentChange = (type) => { },
  buyerData,
  developerAdminId,
  projectId,
  buyerEmail,
  _id,
  isSalesRep,
  leadBrokerData
}) => {
  const { createSaveList: createContactList, loading } = useCreateContactList()
  const { salesReps, refetch } = useGetSalesReps()
  const { companies } = useBrokerageCompanies()
  const history = useHistory()
  const isLeadSourceAvailable = userType === 'Buyer'
  const isReasonForBuyerLostAvailable = userType === 'Buyer'
  const isBuyerLeadApproachAvailable = userType === 'Buyer'
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { data: brokers, refetchBrokers } =
    userType === 'Lead' || userType === 'Buyer' ? useGetLeadBrokers() : { data: null, refetchBrokers: (value) => { } }
  const { data: referralAgents, refetch: refetchReferralAgents } = useGetReferralAgent()
  const { formattedLeadsources: leadSources } = useLeadSource()
  const { formattedReasonForBuyerLost: reasonsForBuyerLost } = useReasonForBuyerLost()
  const { formattedLeadApproachesForBuyer: leadApproachesForBuyer } = useLeadApproachForBuyer()
  const { formattedLeadStatuses: leadStatuses } = useLeadStatus()
  const { formattedScores: scores } =
    userType === 'Buyer' ? useBuyerScore() : useBrokerScore()
  const [selectedLeadSource, setSelectedLeadSource] = useState(
    isLeadSourceAvailable ? buyerData?.leadSource ?? 'None' : '' || ''
  )
  const [selectedReasonForBuyerLost, setSelectedReasonForBuyerLost] = useState(
    isReasonForBuyerLostAvailable ? buyerData?.reasonForLost : '' || ''
  )
  const [selectedLeadApproachForBuyer, setSelectedLeadApproachForBuyer] = useState(
    isBuyerLeadApproachAvailable ? buyerData?.leadApproach : '' || ''
  )
  const [salesrep, setSalesRep] = useState()
  // eslint-disable-next-line no-unused-vars
  const [showDeleteContact, setShowDeleteContact] = useState(false)
  const [showAddLabels, setShowAddLabels] = useState(false)
  const [openAddToList, setOpenAddToList] = useState(false)
  const [openCreateList, setOpenCreateList] = useState(false)
  const [brokerageCompany, setBrokerageCompany] = useState('')

  const salesRepChange = (type, params) => {
    const temp = salesReps?.find?.(rep => rep?._id === params?.salesRep)
    setSalesRep(temp)
    handleSalesRepChange(type, params)
  }

  const showDeleteContactModal = () => setShowDeleteContact(true)
  const showDeleteButton = userObject?.userType === 'DeveloperAdmin' || isSalesRep

  useEffect(() => {
    if (!brokers?.length) refetchBrokers()
    if (!referralAgents?.length) refetchReferralAgents()
  }, [])

  useEffect(() => {
    if (!salesReps?.length) refetch()
  }, [salesReps])

  useEffect(() => {
    if (leadBrokerData?.brokerageCompany) { setBrokerageCompany(leadBrokerData.brokerageCompany) }
  }, [leadBrokerData])

  useEffect(() => {
    const rep = salesReps?.find?.(rep => rep?._id === buyerData?.assignments?.[0]?.salesRep)
    setSalesRep(rep)
    if (!rep) {
      const assign = leadBrokerData?.assignments?.[0]?.salesRep
      const rep = salesReps.find(obj => obj?._id === assign)
      setSalesRep(rep)
    }
  }, [buyerData, salesReps])

  useEffect(() => {
    if (brokers?.length > 0) {
      let brokerFoundTemp = false
      for (let i = 0; i < brokers.length; i++) {
        const brokerTemp = brokers[i]
        if (brokerTemp._id === broker) {
          brokerFoundTemp = true
          break
        }
      }
      if (!brokerFoundTemp) {
        setBroker('')
      }
    }
  }, [brokers])

  return (
    <div className='flex justify-between pb-5 border-b-2 border-b-black items-start relative'>
      {showDeleteButton && showDeleteContact && (
        <ContactDeleteModal
          contactName={`${firstName} ${lastName}`}
          isOpen={showDeleteContact}
          onClose={() => setShowDeleteContact(false)}
          onDelete={() => {
            onClickDelete(_id)
            setShowDeleteContact(false)
          }}
        />
      )}
      <ContactListCreateModal
        open={openCreateList}
        setOpen={setOpenCreateList}
        createContactList={createContactList}
        savingContacts={loading}
        showBackButtonToAddList
        setAddToListOpen={setOpenAddToList}
      />
      <AddToListModal
        open={openAddToList}
        setOpen={setOpenAddToList}
        selectedContactId={_id}
        showCreateListButton
        setOpenCreateListModal={setOpenCreateList}
      />
      {!isMobile && <>
        <div className='absolute right-0'>
          <div className='flex space-x-5 text-xs font-semibold'>
            {showDeleteButton &&
              <button
                onClick={showDeleteContactModal}
                className='hover:bg-gray-100 leading-none h-9 px-5 border shadow border-black rounded flex space-x-2 items-center'
              >
                <svg
                  width='12'
                  height='16'
                  viewBox='0 0 14 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                    fill='#2E2B2E'
                  />
                </svg>
                <span>Delete</span>
              </button>
            }
          </div>
        </div>
        <div className='flex space-x-4'>
          {backgroundImage === ''
            ? (
              <div className='h-18 w-18 border rounded-full flex items-center justify-center bg-gray-200 text-text-gray-500 font-bold'>
                {firstName[0]}
                {lastName[0]}
              </div>
              )
            : (
              <img
                src={backgroundImage}
                alt='Avatar'
                className='h-18 w-18 border rounded-full'
              />
              )}
          <div>
            <div className='flex flex-row gap-4 justify-start items-center'>
              <div>
                <div className='font-bold text-lg mt-2'>
                  {firstName} {lastName}
                </div>
                <div className='text-xs text-gray-600 mt-[6px]'>{email}</div>
              </div>
              <div className='flex flex-row gap-4'>
                <div className='flex items-center justify-center gap-2'>
                  <svg
                    width='14'
                    height='14'
                    viewBox='0 0 14 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.99967 6.99992C8.84134 6.99992 10.333 5.50825 10.333 3.66659C10.333 1.82492 8.84134 0.333252 6.99967 0.333252C5.15801 0.333252 3.66634 1.82492 3.66634 3.66659C3.66634 5.50825 5.15801 6.99992 6.99967 6.99992ZM6.99967 8.66658C4.77467 8.66658 0.333008 9.78325 0.333008 11.9999V12.8333C0.333008 13.2916 0.708008 13.6666 1.16634 13.6666H12.833C13.2913 13.6666 13.6663 13.2916 13.6663 12.8333V11.9999C13.6663 9.78325 9.22467 8.66658 6.99967 8.66658Z'
                      fill='#2E2B2E'
                    />
                  </svg>

                  <span>Type: {userType}</span>
                </div>
              </div>
            </div>
            <div className={'flex flex-wrap text-xs my-4 items-center mt-10 '}>
              {isLeadSourceAvailable
                ? <div
                  className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Lead Source
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={selectedLeadSource}
                    onChange={(value) => {
                      setSelectedLeadSource(value)
                      handleLeadSourceChange(value)
                    }}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {leadSources?.map?.((_item, index) => (
                      <Select.Option value={_item.value} key={index} label={_item.label}>
                        <div className='flex gap-2 items-center'>
                          <span>{_item.label}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                : null}
              <SalesRepSelect
                buyerData={buyerData}
                buyerEmail={buyerEmail}
                developerAdminId={developerAdminId}
                handleSalesRepChange={salesRepChange}
                projectId={projectId}
                salesRep={salesReps}
                salesrep={salesrep}
                setSalesRep={setSalesRep}
                userType={userType}
                // @ts-ignore
                leadBrokerData={leadBrokerData}
              />
              {userType === 'LeadBroker'
                ? <div
                  className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Brokerage Company
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={brokerageCompany}
                    onChange={(value) => {
                      setBrokerageCompany(value)
                      handleBrokerageCompanyChange(value)
                    }}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {companies?.values?.map?.((_item, index) => (
                      <Select.Option value={_item.value} key={index} label={_item.label}>
                        <div className='flex gap-2 items-center' style={{ zIndex: '1500 !important' }}>
                          <span>{_item}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                : null}
              {userType === 'Buyer' && (
                <div
                  className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Lead Status
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={leadStatus}
                    onChange={handleLeadStatusChange}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {leadStatuses?.map((_item, index) => (
                      <Select.Option value={_item.value} key={index} label={_item.label}>
                        <div className='flex gap-2 items-center'>
                          <span>{_item.label}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              <div
                  className='overflow-visible relative mr-2.5 mb-5'
              >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                  Rating
                </div>
                <Select
                  suffixIcon={<DownIcon />}
                  showArrow
                  value={rating || ''}
                  onChange={handleRatingChange}
                  className='rating-select text-sm bg-white border border-softBlack30 h-8 w-32 rounded text-softBlack placeholder-softBlack70 py-1'
                  getPopupContainer={(node) => node.parentNode}
                >
                  {scores?.map((_item, index) => (
                    <Select.Option value={_item.value} key={index} label={_item.label}>
                      <div className='flex gap-2 items-center'>
                        <div
                          className='min-w-4 min-h-4 w-4 h-4 rounded-full'
                          style={{ backgroundColor: _item?.color }}
                        />
                        <span>{_item.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div
                  className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Referral Agent
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={referralAgent?.length > 0 ? referralAgent : 'Select Referral Agent'}
                    onChange={(value) => handleReferralAgentChange(value)}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {referralAgents?.map((_item, index) => (
                      <Select.Option value={_item._id} key={index} label={`${_item.firstName} ${_item.lastName}`}>
                        <div className='flex gap-2 items-center'>
                          <span>{_item.firstName} {_item.lastName}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              {userType === 'Buyer'
                ? <div
                    className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Broker
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={broker?.length > 0 ? broker : 'Select Broker'}
                    onChange={(value) => handleBrokerChange(value)}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {brokers?.map((_item, index) => (
                      <Select.Option value={_item._id} key={index} label={`${_item.firstName} ${_item.lastName}`}>
                        <div className='flex gap-2 items-center'>
                          <span>{_item.firstName} {_item.lastName}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                : null}
              {userType === 'Lead' && (
                <div
                    className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Broker
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={broker}
                    onChange={(value) => setBroker(value)}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {brokers?.map((_item, index) => {
                      return (
                        <Select.Option value={_item.id} key={index} label={`${_item.firstName} ${_item.lastName}`}>
                          <div className='flex gap-2 items-center'>
                            <span>
                              {_item.firstName} {_item.lastName}
                            </span>
                          </div>
                        </Select.Option>
                      )
                    })}
                  </Select>
                </div>
              )}
              {isReasonForBuyerLostAvailable
                ? <div
                  className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Reason For Lost
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={selectedReasonForBuyerLost}
                    onChange={(value) => {
                      setSelectedReasonForBuyerLost(value)
                      handleReasonForBuyerLostChange(value)
                    }}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {reasonsForBuyerLost?.map?.((_item, index) => (
                      <Select.Option value={_item.value} key={index} label={_item.label}>
                        <div className='flex gap-2 items-center'>
                          <span>{_item.label}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                : null}
              {isBuyerLeadApproachAvailable
                ? <div
                  className='overflow-visible relative mr-2.5 mb-5'
                >
                  <div
                    className='text-softBlack70 absolute w-full text-lg top-6 font-light ml-2.5 '
                    style={{
                      top: '-25px',
                      fontSize: '18px',
                      marginBottom: '10px'
                    }}
                  >
                    Lead Approach
                  </div>
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={selectedLeadApproachForBuyer}
                    onChange={(value) => {
                      setSelectedLeadApproachForBuyer(value)
                      handleBuyerLeadApproachChange(value)
                    }}
                    className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {leadApproachesForBuyer?.map?.((_item, index) => (
                      <Select.Option value={_item.value} key={index} label={_item.label}>
                        <div className='flex gap-2 items-center'>
                          <span>{_item.label}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                : null}
              <div className='overflow-hidden flex gap-4' style={{ marginLeft: '11px', height: '38px', marginBottom: '25px' }}>
                <button
                  onClick={() => setOpenAddToList(true)}
                  type='button'
                  style={{ fontStyle: 'italic', padding: '5px' }}
                  className='inline-flex items-center justify-left rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
                >
                  <span
                    style={{
                      marginLeft: '10px',
                      fontSize: '14px',
                      marginRight: '10px'
                    }}
                  >
                    + Add To List
                  </span>
                </button>
              </div>
              <div className='overflow-hidden flex gap-4' style={{ marginLeft: '11px', height: '38px', marginBottom: '25px' }}>
                <button
                  // @ts-ignore
                  onClick={() => history.push('/admin/reservations')}
                  type='button'
                  style={{ fontStyle: 'italic', padding: '5px' }}
                  className='inline-flex items-center justify-left rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
                >
                  <span
                    style={{
                      marginLeft: '10px',
                      fontSize: '14px',
                      marginRight: '10px'
                    }}
                  >
                   Create Reservation
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>}
      {isMobile && <MobileCard
        broker={broker}
        brokers={brokers}
        setBroker={setBroker}
        referralAgent={referralAgent}
        referralAgents={referralAgents}
        setReferralAgent={handleReferralAgentChange}
        leadStatuses={leadStatuses}
        scores={defaultRatingOptions}
        onClickDelete={onClickDelete}
        salesRepOwner={salesRepOwner}
        email={email} firstName={firstName} lastName={lastName}
        leadSources={leadSources}
        reasonsForBuyerLost={reasonsForBuyerLost}
        leadApproachesForBuyer={leadApproachesForBuyer}
        leadStatus={leadStatus}
        handleLeadSourceChange={handleLeadSourceChange}
        handleReasonForBuyerLostChange={handleReasonForBuyerLostChange}
        handleBuyerLeadApproachChange={handleBuyerLeadApproachChange}
        handleLeadStatusChange={handleLeadStatusChange}
        handleRatingChange={handleRatingChange}
        imgsrc={backgroundImage}
        rating={rating}
        selectedLeadSource={selectedLeadSource}
        setSelectedLeadSource={setSelectedLeadSource}
        selectedReasonForBuyerLost={selectedReasonForBuyerLost}
        setSelectedReasonForBuyerLost={setSelectedReasonForBuyerLost}
        selectedBuyerLeadApproach={selectedLeadApproachForBuyer}
        setSelectedBuyerLeadApproach={setSelectedLeadApproachForBuyer}
        userType={userType}
        buyerData={buyerData}
        buyerEmail={buyerEmail}
        developerAdminId={developerAdminId}
        handleSalesRepChange={handleSalesRepChange}
        projectId={projectId}
        salesRep={salesReps}
        salesrep={salesrep}
        setSalesRep={setSalesRep}
        setOpenAddToList={setOpenAddToList}
      />}
    </div>
  )
}
