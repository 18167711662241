import { SlimPlusIcon, SquareFilledPlusIcon } from '../Icons'
import React from 'react'
import propTypes from 'prop-types'
import {
  AddBlockContainer,
  AddBlockContent,
  PlusIconContainer,
  TextLabel,
  AddButton
} from './styles'

const AddBlock = ({ label, btnText, setAddBlockClick, showButtonOnly, backgroundColor = 'transparent' }) => {
  return (
    <AddBlockContainer style={showButtonOnly && { border: 0, height: 100, background: backgroundColor }}>
      <AddBlockContent>
        {!showButtonOnly && <><PlusIconContainer>
          <SquareFilledPlusIcon className={'w-8 h-8'} />
        </PlusIconContainer><TextLabel>
            {label}
          </TextLabel></>}
        <AddButton
          onClick={(e) => setAddBlockClick(e)}
        >
          <SlimPlusIcon className={'h-3 w-3'} />{' '}
          {btnText}
        </AddButton>
      </AddBlockContent>
    </AddBlockContainer>
  )
}

export default AddBlock

AddBlock.propTypes = {
  label: propTypes.string,
  btnText: propTypes.string,
  setAddBlockClick: propTypes.func
}
