import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getUsers } from 'store/actions/usersActions'

const useGetUsers = () => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { data, isLoading, refetch } = useQuery(
    ['getUsers'],
    () => getUsers(),
    { refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false, enabled: !!userObject?._id })
  return { users: data, isLoading, refetch }
}

export default useGetUsers
