// @ts-nocheck
// @ts-ignore

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '@material-ui/core'
import moment from 'moment'
import { Oval } from 'react-loader-spinner'
import { message } from 'antd'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

import CustomInput from 'components/CustomInput'
import CustomTextArea from 'components/CustomTextArea'

import { createOrUpdateCall } from 'store/actions/callsActions'

// import { AddParticipants } from './AddParticipants'
// import CustomTimeInput from 'components/CustomTimeInput'

const initialEmptyValues = {
  description: '',
  date: '',
  startedAt: '',
  finishedAt: '',
  notes: '',
  participants: []
}

export const CallModal = ({ call, show, setClose, contact, onSuccess }) => {
  const appProject = useSelector((state) => state.appReducer.appProject)
  const userObject = useSelector(state => state.authReducer.userObject)

  const [loading, setLoading] = useState(false)

  const initialValues = {
    description: call?.description ?? initialEmptyValues.descriptions,
    date: call?.date ? moment(call?.date).format('YYYY-MM-DD') : initialEmptyValues.date,
    startedAt: call?.startedAt ? moment(new Date(call?.startedAt)).format('HH:mm') : initialEmptyValues.startedAt,
    finishedAt: call?.finishedAt ? moment(new Date(call?.finishedAt)).format('HH:mm') : initialEmptyValues.finishedAt,
    notes: call?.notes ?? initialEmptyValues.notes,
    participants: call?.participants.length > 0 ? call?.participants.filter((_p) => _p !== contact._id) : initialEmptyValues.participants
  }

  const [callDetails, setCallDetails] = useState({
    ...initialValues
  })

  const onChange = (fieldName, value) => {
    setCallDetails({ ...callDetails, [fieldName]: value })
  }

  const onValidate = () => {
    const requiredFields = ['description', 'date']
    for (let i = 0; i < requiredFields.length; i++) {
      if (!callDetails[requiredFields[i]]?.length) {
        return false
      }
    }
    return true
  }

  const checkValidation = () => {
    const requiredFields = ['description', 'date']
    for (let i = 0; i < requiredFields.length; i++) {
      if (!callDetails[requiredFields[i]]?.length) {
        message.error('Please fill all the required field')
        return false
      }
    }
    return true
  }

  const onSave = async () => {
    if (!checkValidation()) {
      return
    }
    const params = {
      _id: call?._id,
      project: appProject,
      host: userObject._id,
      ...callDetails,
      participants: [...callDetails.participants, contact._id]
    }

    // check finishedAt greater than startedAt
    // if (params.finishedAt <= params.startedAt) {
    //   message.error('finished time must be greater than start time')
    //   return false
    // }

    // covert finishedAt and startedAt into date object
    if (params.startedAt) {
      params.startedAt = new Date(
        `${moment().format('YYYY-MM-DD')} ${params.startedAt}`
      )
    }
    if (params.finishedAt) {
      params.finishedAt = new Date(
        `${moment().format('YYYY-MM-DD')} ${params.finishedAt}`
      )
    }

    setLoading(true)
    try {
      await createOrUpdateCall(params)
      if (!call?._id) {
        setCallDetails({ ...initialEmptyValues })
      }
      setClose(false)
      setLoading(false)
      onSuccess()
    } catch (err) {
      setLoading(false)
      message.error(err)
      console.error(err)
    }
  }

  const handleClose = () => {
    setCallDetails({ ...initialValues })
    setClose(false)
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg"
        style={{ width: 544 }}
      >
        <button className='absolute right-10' onClick={handleClose}><CrossIcon /></button>
        <h1 className="font-bold text-xl text-center p-0 m-0">{ call?._id ? 'Update Call' : 'New Call' }</h1>
        <div className="py-8 flex flex-col gap-6">
          <CustomInput
            label="Description *"
            placeholder="Enter description"
            value={callDetails.description}
            onChange={(e) => {
              onChange('description', e.target.value)
            }}
          />
          <CustomInput
            label="Call Date *"
            placeholder="MM/DD/YYYY"
            value={callDetails.date}
            onChange={(e) => {
              onChange('date', e.target.value)
            }}
            type="date"
            classes="uppercase"
            min={moment().format('YYYY-MM-DD')}
          />
          {/* <div className="grid grid-cols-2 gap-6">
            <CustomTimeInput
              label="started at *"
              value={callDetails.startedAt}
              onChange={(value) => {
                onChange('startedAt', value)
              }}
            />
            <CustomTimeInput
              label="FINISHED AT *"
              value={callDetails.finishedAt}
              onChange={(value) => {
                onChange('finishedAt', value)
              }}
            />
          </div> */}
          <CustomTextArea
            label="Notes"
            placeholder="Enter notes"
            value={callDetails.notes}
            onChange={(e) => {
              onChange('notes', e.target.value)
            }}
            style={{ color: '#2E2B2E' }}
          />
          {/* <AddParticipants
            selectedParticipants={callDetails.participants}
            setSelectedParticipants={(value) => {
              onChange('participants', value)
            }}
          /> */}
          <button
            className="py-3 px-6 text-base bg-volt rounded font-medium m-auto flex gap-2 items-center"
            onClick={onSave}
            disabled={!onValidate()}
          >
            {loading && <Oval height="18" width="18" color="black" />}
            {call?._id ? 'Update' : 'Save'}
          </button>
        </div>
      </div>
    </Modal>
  )
}
