import React from 'react'
import propTypes from 'prop-types'
import { TButton } from 'ui'
import { useHistory } from 'react-router-dom'
import { ADMIN_SCHEDULER_MEETINGS, ADMIN_SCHEDULER_SETTINGS } from 'routes'

const buttonStyle = {}

const NavigateButtons = (props) => {
  const history = useHistory()
  return (
    <div className="space-x-3">
      <TButton onClick={() => props.onNavigate('PREV')} {...buttonStyle}>
        Back
      </TButton>
      <TButton onClick={() => props.onNavigate('TODAY')} {...buttonStyle}>
        Today
      </TButton>
      <TButton onClick={() => props.onNavigate('NEXT')} {...buttonStyle}>
        Next
      </TButton>
      <TButton
        onClick={() => history.push(ADMIN_SCHEDULER_SETTINGS)}
        {...buttonStyle}
      >
        Scheduler Settings
      </TButton>
      <TButton
        onClick={() => history.push(ADMIN_SCHEDULER_MEETINGS)}
        {...buttonStyle}
      >
        Meeting Links
      </TButton>
    </div>
  )
}

const SelectedLabel = (props) => (
  <span className="flex-grow px-2.5 text-center text-2xl">{props.label}</span>
)

const PeriodFormat = (props) => (
  <div className="space-x-3">
    <TButton onClick={() => props.onView('month')} {...buttonStyle}>
      Month
    </TButton>
    <TButton onClick={() => props.onView('week')} {...buttonStyle}>
      Week
    </TButton>
    <TButton onClick={() => props.onView('day')} {...buttonStyle}>
      Day
    </TButton>
    <TButton onClick={() => props.onView('agenda')} {...buttonStyle}>
      Agenda
    </TButton>
  </div>
)

const CalendarToolbar = (props) => (
  <div className="flex flex-wrap justify-center items-center m-2">
    <NavigateButtons {...props} />
    <SelectedLabel {...props} />
    <PeriodFormat {...props} />
  </div>
)

export default CalendarToolbar

CalendarToolbar.propTypes = {}

SelectedLabel.propTypes = {
  label: propTypes.string
}

NavigateButtons.propTypes = {
  onNavigate: propTypes.func
}

PeriodFormat.propTypes = {
  onView: propTypes.func
}
