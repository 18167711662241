import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getUpcomingTasks } from 'store/actions/taskActions'
import { getClosedTasks, getPendingTasks } from 'store/actions/usersActions'

const useGetTasks = () => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)

  const { data: upcommingTasks, refetch: refetchUpcomingTask } = useQuery(
    ['upcommingTasks', userObject?._id],
    ({ queryKey }) => getUpcomingTasks(queryKey[1]),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: userObject?._id && userObject?.userType === 'SalesRep'
    }
  )

  const { data: pendingTasks, refetch: refetchPendingTask } = useQuery(
    ['pendingTasks', userObject?._id],
    ({ queryKey }) => getPendingTasks(queryKey[1]),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: userObject?._id && userObject?.userType === 'SalesRep'
    }
  )

  const { data: closedTasks, refetch: refetchClosedTask } = useQuery(
    ['closedTasks', userObject?._id],
    ({ queryKey }) => getClosedTasks(queryKey[1]),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: userObject?._id && userObject?.userType === 'SalesRep'
    }
  )

  const refetch = useCallback(() => {
    refetchUpcomingTask()
    refetchPendingTask()
    refetchClosedTask()
  }, [refetchUpcomingTask, refetchPendingTask, refetchClosedTask])

  return { upcommingTasks, pendingTasks, closedTasks, refetch }
}

export default useGetTasks
