import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  getCampaign,
  getCampaignSubscriberActivity
} from 'store/actions/emailCampaignActions'
import standardStyle from 'assets/css/standardStyle'
import PageHeader from 'components/PageHeader'
import Button from 'components/Buttons'

const Card = ({ title, value }) => (
  <div
    className='bg-white rounded'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='flex flex-col space-y-2 p-6'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>{value}</div>
    </div>
  </div>
)

export default function EmailCampaignDetail (props) {
  // @ts-ignore
  const { id: campaignId } = useParams()
  const [campaign, setCampaign] = useState(null)
  const [subscriberActivity, setSubscriberActivity] = useState([])
  const [loadingContacts, setLoadingContacts] = useState(true)
  useEffect(() => {
    getCampaign(campaignId)
      .then((report) => {
        delete report._links
        delete report.timeseries
        setCampaign(report)
      })
      .catch(() => {
        // redirect to campaigns screen
      })
  }, [campaignId])

  useEffect(() => {
    getCampaignSubscriberActivity(campaignId)
      .then((activity) => {
        setSubscriberActivity(activity)
        setLoadingContacts(false)
      })
      .catch()
  }, [campaignId])
  if (!campaign) {
    return <div>Loading</div>
  }
  return (
      <div>
          <PageHeader
              title={campaign.report.subject_line}
              rightContent={
                  <a
                      target={'_blank'}
                      href={campaign.long_archive_url}
                      rel='noreferrer'
                  >
                      <Button size='Long' label='Live Preview'></Button>
                  </a>
              }
          />
          <div className='grid grid-cols-6 gap-4 pt-4 mx-4'>
              <Card
                  title={'Date'}
                  value={new Date(campaign.send_time).toLocaleDateString()}
              />
              <Card title={'Total Email Sent'} value={campaign.emails_sent} />
              <Card
                  title={'Total opened'}
                  value={campaign.report.opens.opens_total}
              />
              <Card
                  title={'Total clicked'}
                  value={campaign.report.clicks.clicks_total}
              />
              <Card
                  title={'Total Bounces'}
                  value={
                      campaign.report.bounces.hard_bounces +
                      campaign.report.bounces.soft_bounces
                  }
              />
              <Card
                  title={'Total Unsubscribed'}
                  value={campaign.report.unsubscribed}
              />
          </div>
          <div className='px-4'>
              <div className='grid grid-cols-2 gap-4'>
                  <div className='mt-6 bg-white pt-6 px-6 rounded'>
                      <div className='flex flex-col'>
                          {[
                            ['Successful Deliveries', campaign.emails_sent - campaign.report.bounces.hard_bounces - campaign.report.bounces.soft_bounces],
                            ['Total Opens', campaign.report.opens.opens_total],
                            ['Last Opened', new Date(campaign.report.opens.last_open).toLocaleString()],
                            ['Forwarded', campaign.report.forwards.forwards_count]
                          ].map(([title, value]) => {
                            return (
                              <div key={title} className='flex justify-between'>
                                  <p>{title}</p>
                                  <p>{value}</p>
                              </div>
                            )
                          })}
                      </div>
                  </div>
                  <div className='mt-6 bg-white pt-6 px-6 rounded'>
                      <div className='flex flex-col'>
                          {[
                            ['Click per unique opens', (campaign.report.clicks.unique_clicks / campaign.report.opens.unique_opens) * 100 + ' %'],
                            ['Total Clicks', campaign.report.clicks.clicks_total],
                            ['Last Clicked', new Date(campaign.report.clicks.last_click).toLocaleString()],
                            ['Abuse reports', campaign.report.abuse_reports]
                          // @ts-ignore
                          ].map(([title, value]) => {
                            return (
                              <div key={title} className='flex justify-between'>
                                  <p>{title}</p>
                                  <p>{value}</p>
                              </div>
                            )
                          })}
                      </div>
                  </div>
              </div>
          </div>
          <div className='mt-6 mx-4 px-4 bg-white rounded'>
              {loadingContacts && <p>Loading....</p>}
              {!loadingContacts && subscriberActivity.length === 0 && (
                  <p>No Emails to your contacts</p>
              )}
              {!loadingContacts && subscriberActivity.length > 0 && (
                  <table className='min-w-full divide-y divide-gray-300 '>
                      <thead>
                          <tr>
                              <th
                                  scope='col'
                                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                              >
                                  Email
                              </th>
                              <th
                                  scope='col'
                                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                              >
                                  Sales rep
                              </th>
                              <th
                                  scope='col'
                                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                              >
                                  Opened
                              </th>
                              <th
                                  scope='col'
                                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                              >
                                  Open Date
                              </th>
                              <th
                                  scope='col'
                                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                              >
                                  Clicked
                              </th>
                              <th
                                  scope='col'
                                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                              >
                                  Click Date
                              </th>
                          </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200'>
                          {subscriberActivity
                          // @ts-ignore
                            ?.sort(
                              (a, b) =>
                                // @ts-ignore
                                a.activity.length > b.activity.length
                            )
                            ?.map((data, index) => {
                              const activities = data.activity
                              const saleRep = data.saleRep
                              let isOpened = false
                              let isClicked = false
                              let openDate = null
                              let clickDate = null
                              for (let i = 0; i < activities.length; i++) {
                                const activity = activities[i]
                                if (
                                  activity.action === 'open' &&
                                  !isOpened
                                ) {
                                  isOpened = true
                                  openDate = new Date(
                                    activity.timestamp
                                  )
                                }
                                if (
                                  activity.action === 'click' &&
                                    !isClicked
                                ) {
                                  isClicked = true
                                  clickDate = new Date(
                                    activity.timestamp
                                  )
                                }
                              }
                              // @ts-ignore
                              const email = data.email_address
                              return (
                                      <tr key={campaign.id + '' + index}>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                                              {email}
                                          </td>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                                              {saleRep && (
                                                  <p>
                                                      {saleRep.firstName}{' '}
                                                      {saleRep.lastName}
                                                  </p>
                                              )}
                                          </td>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                                              {isOpened && 'YES'}
                                          </td>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                                              {isOpened &&
                                                  openDate.toLocaleDateString()}
                                          </td>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                                              {isClicked && 'YES'}
                                          </td>
                                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                                              {isClicked &&
                                                  clickDate.toLocaleDateString()}
                                          </td>
                                      </tr>
                              )
                            })}
                      </tbody>
                  </table>
              )}
          </div>
      </div>
  )
}
