import FilterBySearch from 'pages/contacts/FilterBySearch'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { TButton } from 'ui'

const EmailDate = ({ date }) => {
  const today = new Date()
  const emailDate = new Date(date * 1000)
  const showYear = today.getFullYear() !== emailDate.getFullYear()
  return `${emailDate.getMonth() + 1}/${emailDate.getDate()}${
    showYear ? `/${emailDate.getFullYear()}` : ''
  }`
}

const EmailMessagesList = ({
  showFilterOption = true,
  showLoadMore = true,
  gettingListOfEmails,
  setShowMessageList,
  refLocalListOfEmails,
  selectedThreadId,
  setSelectedEmailIndex,
  searchFilter,
  setSearchFilter,
  getThreads,
  getEmails,
  refFilterBySearch
}) => {
  return (
    <aside className={`flex flex-shrink-0 h-full ${isMobile ? 'w-full' : ''}`}>
      <div
        className={`h-full relative flex flex-col ${
          isMobile ? 'w-full' : 'w-96'
        } border-r border-gray-200 bg-gray-100`}
      >
        <div className='flex-shrink-0'>
          <div className='h-16 bg-white px-6 flex flex-col justify-center'>
            <div className='flex flex-row gap-2'>
              <div className='flex flex-row items-baseline space-x-3 flex-1'>
                <h2 className='text-lg font-medium text-gray-900'>Inbox</h2>
                <p className='text-sm font-medium text-gray-500'>
                  {refLocalListOfEmails.current.length} messages
                </p>
              </div>
              <TButton
                className='overflow-hidden'
                loading={gettingListOfEmails}
                onClick={() => {
                  if (showFilterOption) {
                    refFilterBySearch.current?.clearQuery?.()
                  }
                  if (searchFilter.length) {
                    setSearchFilter('')
                  } else {
                    if (getThreads) {
                      getThreads(true, false)
                    } else {
                      getEmails()
                    }
                  }
                }}
              >
                Refresh
              </TButton>
              {isMobile && (
                <TButton onClick={() => setShowMessageList(false)}>
                  Back
                </TButton>
              )}
            </div>
          </div>
          {showFilterOption && (
            <FilterBySearch
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              label={'Search Email'}
              loading={gettingListOfEmails}
              ref={refFilterBySearch}
            />
          )}
          <div className='border-t border-b border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500'>
            Sorted by date
          </div>
        </div>

        <nav
          id='listOfEmails'
          aria-label='Message list'
          className='min-h-0 overflow-y-scroll w-full h-screen'
        >
          <ul className='border-b border-gray-200 divide-y divide-gray-200 w-full'>
            {refLocalListOfEmails.current.map((email, index) => (
              <li
                key={email.messages?.[0]?.thread_id ?? index}
                onClick={() => setSelectedEmailIndex(index)}
                className={`relative bg-white py-5 px-6 ${
                  email.messages[0].thread_id === selectedThreadId &&
                  'bg-gray-100'
                }
                hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600`}
              >
                <div className='flex justify-between space-x-3'>
                  <div className='min-w-0 flex-1'>
                    <div className='block focus:outline-none'>
                      <span className='absolute inset-0' aria-hidden='true' />
                      <p className='text-sm font-medium text-gray-900 truncate'>
                        {`${email.messages[0].from[0].name} - ${email.messages[0].from[0].email}`}
                      </p>
                      {email?.subject?.length && (
                        <p className='text-sm text-gray-500 truncate'>
                          {email.subject}
                        </p>
                      )}
                    </div>
                  </div>
                  <time
                    dateTime={email.last_message_timestamp}
                    className='flex-shrink-0 whitespace-nowrap text-sm text-gray-500'
                  >
                    {EmailDate({ date: email.lastMessageTimestamp })}
                  </time>
                </div>
                {email?.snippet?.length && (
                  <p className='text-sm text-gray-600 truncate'>
                    {email.snippet}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </nav>
        {!searchFilter.length && showLoadMore && (
          <div className='flex w-full'>
            <div className='flex w-full items-center justify-center content-center p-2 bg-white'>
              <TButton
                className='overflow-hidden'
                loading={gettingListOfEmails}
                onClick={() => {
                  if (showFilterOption) {
                    refFilterBySearch.current?.clearQuery?.()
                  }
                  if (getThreads) {
                    getThreads(false, true)
                  } else {
                    getEmails()
                  }
                }}
              >
                Load More
              </TButton>
            </div>
          </div>
        )}
      </div>
    </aside>
  )
}

export default EmailMessagesList
