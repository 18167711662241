// @ts-nocheck
import React, { useState } from 'react'
import TaskRow from 'components/TaskCard'
import TaskModal from 'components/TaskModal'
import Skeleton from 'react-loading-skeleton'
import useGetUserTasks from 'hooks/useGetUserTasks'

export const TaskTab = ({ contact }) => {
  const { tasks, loading, refetch } = useGetUserTasks(contact?._id)
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {loading && (
        <div className="flex flex-col gap-6 mt-8">
          <Skeleton height={100} />
        </div>
      )}

      {!loading && tasks.length === 0 &&
        <div className="font-openSans flex flex-col justify-center items-center h-96 w-full bg-white mt-8 mb-8 rounded">
          <div className='flex flex-col justify-center items-center'>
            <svg
              width="24"
              height="24"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 6.5H15V4.5H10V6.5ZM10 13.5H15V11.5H10V13.5ZM16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18ZM3 8H8V3H3V8ZM4 4H7V7H4V4ZM3 15H8V10H3V15ZM4 11H7V14H4V11Z"
                fill="#2E2B2E"
              />
            </svg>
            <div className="font-semibold mb-2 mt-4">
              You don't have any tasks yet
            </div>
            <div className="text-sm text-softBlack70">
              Create your first task to Aaron
            </div>
            <button
              onClick={() => {
                setOpen(true)
              }}
              className="bg-yellow-200 font-semibold px-4 py-2 rounded mt-6"
            >
              Create Task
            </button>
          </div>
        </div>
      }
      {tasks.length > 0 && (
        <div>
          <div className="my-8 flex flex-col gap-6">
          <div className='text-center'>
            <button
              onClick={() => {
                setOpen(true)
              }}
              className="bg-yellow-200 font-semibold px-4 py-2 rounded mt-4 m-auto"
            >
              Create Task
            </button>
          </div>
            {tasks.map((task, index) => {
              return (
                <TaskRow
                  key={`${task?._id}${index}`}
                  task={task}
                  refetch={refetch}
                />
              )
            })}
          </div>
        </div>
      )}
      <TaskModal
        show={open}
        setClose={handleClose}
        contact={contact}
        refetch={refetch}
      />
    </>
  )
}
