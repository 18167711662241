import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Oval } from 'react-loader-spinner'
import * as Yup from 'yup'
import { message, Select } from 'antd'
import { Button } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import CustomRadio from 'components/CustomRadio'
import CustomModal from 'components/Modal'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ContactContext } from 'pages/contacts/context/Contacts'
import useGetLeadBrokers from 'hooks/useGetLeadBrokers'
import useGetSalesReps from 'hooks/useGetSalesReps'
import useBrokerageCompanies from 'hooks/useBrokerageCompanies'
import useUpdateUsers from 'hooks/useUpdateUsers'
import useLeadSource from 'hooks/useLeadSource'

const initialEmptyValues = {
  firstName: '',
  lastName: '',
  email: '',
  notes: '',
  phoneNumber: '',
  userType: 'Buyer'
}

const buttonStyle = {
  backgroundColor: '#EDDF65',
  height: '45px'
}

const AddOrEditContact = ({
  show,
  setShow,
  contact,
  onSuccess,
  broker = '',
  userType,
  invalideCacheOnUpdate = false
}) => {
  const { createOrUpdateUser } = useUpdateUsers(invalideCacheOnUpdate)
  const projectSelector = state => state.appReducer.appProject
  const appProject = useSelector(projectSelector)
  const userSelector = state => state.authReducer.userObject
  const userObject = useSelector(userSelector)
  const initialValues = {
    ...initialEmptyValues,
    userType: userType?.length ? userType : 'Buyer'
  }
  const { setSelectedContact, setIsLoading } = useContext(ContactContext)
  const { data: brokersData, refetchBrokers } = useGetLeadBrokers()
  const { formattedLeadsources: leadSources } = useLeadSource()
  const [selectedLeadSource, setSelectedLeadSource] = useState('None')
  const [brokers, setBrokers] = useState([])
  const [selectedBroker, setSelectedBroker] = useState('')
  const [brokerageCompany, setBrokerageCompany] = useState('')
  const [state, setState] = useState({ ...initialValues })
  const [loading, setLoading] = useState(false)
  const [selectedSalesRep, setSelectedSalesRep] = useState(null)
  const [newBrokerageCompany, setNewBrokerageCompany] = useState('')
  const { salesReps, refetch } = useGetSalesReps()
  const { companies, update } = useBrokerageCompanies()

  useEffect(() => {
    setBrokers(brokersData)
  }, [brokersData])

  useEffect(() => {
    setSelectedBroker(broker)
  }, [broker])

  useEffect(() => {
    if (show && !brokersData?.length) refetchBrokers()
    if (
      show &&
      !salesReps?.length &&
      userObject?.userType === 'DeveloperAdmin'
    ) {
      refetch()
    }
  }, [brokersData?.length, refetch, refetchBrokers, salesReps?.length, show, userObject?.userType])

  const onChange = useCallback((fieldName, value) => {
    setState(prevState => ({ ...prevState, [fieldName]: value }))
  }, [setState])

  const handleClose = () => {
    if (loading) {
      return
    }
    setState({ ...initialValues })
    setShow(false)
  }

  const onValidate = () => {
    const requiredFields = ['firstName', 'lastName', 'email']
    for (let i = 0; i < requiredFields.length; i++) {
      if (state[requiredFields[i]] === '') {
        return false
      }
    }
    return true
  }

  const checkValidation = async () => {
    const isEmailValid = await Yup.string().email().isValid(state.email)
    if (!isEmailValid) {
      message.error('Please fill all the required field')
      return false
    }
    return true
  }

  const onSave = async () => {
    if (loading || !(await checkValidation())) {
      return
    }
    let success = false
    // If string has no valid number than return true
    if (!isNaN(parseInt(selectedLeadSource))) {
      success = true
    }
    let leadSource
    if (!success) {
      leadSource = leadSources?.[0]?.label
    } else {
      leadSource = leadSources?.[selectedLeadSource]?.label
    }
    const salesRep =
      userObject?.userType === 'SalesRep' && state.userType !== 'Buyer'
        ? userObject?._id
        : selectedSalesRep

    const buyerParams = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      project: appProject,
      userType: state.userType,
      buyerData: {
        phoneNumber: state.phoneNumber,
        additionalFields: [
          {
            fieldType: 'LeadForm',
            name: 'Notes',
            project: appProject,
            value: state.notes
          }
        ],
        leadSource,
        assignments: [
          {
            salesRep
          }
        ]
      },
      leadBroker: selectedBroker || undefined,
      salesRep
    }
    const brokerParams = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      userType: state.userType,
      leadBrokerData: {
        phoneNumber: state.phoneNumber,
        notes: state.notes,
        project: appProject,
        leadSource,
        brokerageCompany: brokerageCompany || undefined,
        assignments: [
          {
            salesRep
          }
        ]
      },
      project: appProject,
      salesRep
    }
    const params = state.userType === 'Buyer' ? buyerParams : brokerParams
    try {
      setLoading(true)
      const res = await createOrUpdateUser(params)
      if (!contact?._id) {
        setState({ ...initialEmptyValues })
      }
      try {
        setShow?.(false)
        setLoading(false)
        onSuccess?.(res._id)
        setSelectedContact?.(res._id)
        setIsLoading(true)
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  useEffect(() => {
    if (newBrokerageCompany) {
      setBrokerageCompany(newBrokerageCompany)
    }
  }, [companies, newBrokerageCompany])

  const heading = useMemo(() => {
    if (userType?.length) {
      if (userType === 'LeadBroker') {
        return contact?._id ? 'Update Broker' : 'New Broker'
      } else if (userType === 'Buyer') {
        return contact?._id ? 'Update Buyer' : 'New Buyer'
      }
    }
    return contact?._id ? 'Update Contact' : 'New Contact'
  }, [contact?._id, userType])

  useEffect(() => {
    onChange('userType', userType)
  }, [onChange, userType])

  return (
    <CustomModal
      isOpen={show}
      closeModal={handleClose}
      contentLabel='Add Or Edit Contact Modal'
    >
      <div className='font-openSans relative bg-grey-8 p-10 rounded shadow-lg w-full'>
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0'>{heading}</h1>
        {!userType?.length && (
          <div>
            <p
              className={'mt-0  text-softBlack70 text-xs font-medium uppercase'}
              style={{ letterSpacing: '0.16px' }}
            >
              Is Broker ?
            </p>
            <div className='flex'>
              <CustomRadio
                label='Yes'
                checked={state.userType === 'LeadBroker'}
                onClick={(e) => {
                  onChange('userType', 'LeadBroker')
                }}
              />
              <div className='ml-4'></div>
              <CustomRadio
                label='No'
                checked={state.userType === 'Buyer'}
                onClick={(e) => {
                  onChange('userType', 'Buyer')
                }}
              />
            </div>
          </div>
        )}
        <div className='flex flex-row gap-5 mt-5'>
          <div className='flex flex-1 flex-col  border-2 rounded-md border-gray-1 py-4 px-8'>
            <div className='text-xl font-bold'>Personal Info</div>
            <div className='py-8 flex flex-col gap-6'>
              <CustomInput
                label='First Name'
                placeholder='Enter first name'
                value={state.firstName}
                onChange={(e) => {
                  onChange('firstName', e.target.value)
                } }/>
              <CustomInput
                label='Last Name'
                placeholder='Enter last name'
                value={state.lastName}
                onChange={(e) => {
                  onChange('lastName', e.target.value)
                } }/>
              <CustomInput
                label='Email'
                placeholder='Enter email'
                value={state.email}
                onChange={(e) => {
                  onChange('email', e.target.value)
                } }
                type='email'/>
              <CustomInput
                type='phone'
                label='Phone Number'
                placeholder='Enter phone number'
                value={state.phoneNumber}
                onChange={(e) => {
                  onChange('phoneNumber', e.target.value)
                } }/>
            </div>
            <button
              className='py-3 px-6 text-base bg-volt rounded font-medium m-auto flex gap-2 items-center'
              onClick={onSave}
              disabled={!onValidate()}
            >
              {loading && <Oval height='18' width='18' color='black' />}
              {contact?._id ? 'Update' : 'Save'}
            </button>
          </div>
          <div className='flex flex-1 flex-col gap-5 border-2 rounded-md border-gray-1 py-4 px-8'>
            <div className='text-xl font-bold pb-3'>Lead Data</div>
            {state?.userType === 'LeadBroker' && (
              <div className='w-full'>
                <p
                  className={
                    'mt-0  text-softBlack70 text-xs font-medium uppercase'
                  }
                  style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                >
                  Brokerage Companies
                </p>
                <Select
                  value={brokerageCompany}
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setBrokerageCompany(value)
                  }}
                  className='ant-picker-input1 w-full'
                  getPopupContainer={(node) => node.parentNode}
                >
                  {companies?.values?.map?.((_item, index) => (
                    <Select.Option key={index} label={_item} value={_item}>
                      <div className='flex gap-2 items-center'>
                        <span>{_item}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            {state?.userType === 'LeadBroker' && (
              <div className='w-full flex flex-row gap-2 items-end'>
                <div className='flex flex-1 flex-col'>
                  <CustomInput
                    label='Add New Brokerage Company'
                    placeholder='Enter Brokerage Company'
                    value={newBrokerageCompany}
                    onChange={(e) => {
                      setNewBrokerageCompany(e.target.value)
                    } }
                    type='text'/>
                </div>
                <Button
                  onClick={() => update({ name: newBrokerageCompany })}
                  style={buttonStyle}
                >
                  Submit
                </Button>
              </div>
            )}
            {state.userType === 'Buyer' && (
              <div className='w-full'>
                <p
                  className={
                    'mt-0  text-softBlack70 text-xs font-medium uppercase'
                  }
                  style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                >
                  Broker
                </p>
                <Select
                  showSearch={true}
                  value={selectedBroker}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setSelectedBroker(value)
                  }}
                  className='ant-picker-input1 w-full'
                  getPopupContainer={(node) => node.parentNode}
                >
                  {brokers?.map?.((_item, index) => (
                    <Select.Option
                      key={index}
                      label={`${_item?.firstName} ${_item?.lastName}`}
                      value={_item._id}
                    >
                      <div className='flex gap-2 items-center'>
                        <span>{`${_item?.firstName} ${_item?.lastName}`}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            {userObject?.userType === 'DeveloperAdmin' && (
              <div className='w-full'>
                <p
                  className={
                    'mt-0  text-softBlack70 text-xs font-medium uppercase'
                  }
                  style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                >
                  SalesRep
                </p>
                <Select
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  value={selectedSalesRep}
                  onChange={(value) => {
                    setSelectedSalesRep(value)
                  }}
                  className='ant-picker-input1 w-full'
                  getPopupContainer={(node) => node.parentNode}
                >
                  {salesReps?.map?.((_item, index) => (
                    <Select.Option
                      key={index}
                      label={`${_item?.firstName} ${_item?.lastName}`}
                      value={_item._id}
                    >
                      <div className='flex gap-2 items-center'>
                        <span>{`${_item?.firstName} ${_item?.lastName}`}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}
            <div className='flex'>
              <div className='w-full'>
                <p
                  className={
                    'mt-0  text-softBlack70 text-xs font-medium uppercase'
                  }
                  style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                >
                  Lead Source
                </p>
                <Select
                  value={selectedLeadSource}
                  showSearch={true}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    setSelectedLeadSource(value)
                  }}
                  className='ant-picker-input1 w-full'
                  getPopupContainer={(node) => node.parentNode}
                >
                  {leadSources?.map?.((_item, index) => (
                    <Select.Option
                      value={_item?.value}
                      key={index}
                      label={_item?.label}
                    >
                      <div className='flex gap-2 items-center'>
                        <span>{_item.label}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default AddOrEditContact
