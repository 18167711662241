import React, { useMemo, useCallback, useState, useEffect } from 'react'
import background from '../../assets/img/bg.png'
import Card from 'pages/dashboard/components/HorizontalStats/components/Card'
import { isMobile } from 'react-device-detect'
import {
  AVAILABILITY_BG_COLOR,
  classNames,
  formatDate,
  formatMoney
} from 'utils'
import { Select } from 'antd'
import { connect, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { GrayText, TextBold } from './styles'
import ProgressBar from 'components/ProgressBar'
import { ADMIN_OFFER } from 'routes'
import { useHistory, withRouter, useParams } from 'react-router-dom'
import RecordPaymentModal from './components/RecordPaymentModal'
import { getUnits } from 'store/actions/unitActions'
import useUpdateOffers from 'hooks/useUpdateOffers'
import PaymentReceipt from './components/PaymentReceipt'
import { deletePrivateDocs } from 'store/actions/privateDocumentsActions'

export const ItemType = {
  NORMAL: 'Normal',
  GRAPH: 'Graph'
}

export const TableTitle = ({ isStatic, title = '', date = '' }) => {
  return (
    <div className='flex justify-between flex-col'>
      <TextBold className='tracking-[0.24px] font-bold text-xl'>{`${title} ${
        isStatic ? '(static)' : ''
      }`}</TextBold>
      <GrayText className='text-xs pt-2'>{date}</GrayText>
    </div>
  )
}

export const TableListItem = ({ title = '', value, type, vertical = true }) => {
  if (type === ItemType.GRAPH) {
    return (
      <>
        <div className='flex gap-6 mb-4 flex-wrap'>
          {value.data.map((item, index) => (
            <div className='flex items-center gap-2' key={index}>
              <div
                className={'w-3 h-3 rounded-sm'}
                style={{ backgroundColor: item.progressColor }}
              ></div>
              <span className='font-normal text-sm capitalize'>
                {item.title}
              </span>
            </div>
          ))}
        </div>
        <>
          {value.data.map((item, index) => (
            <ProgressBar
              key={index}
              currentPercent={item.value / value.total}
              progressColor={item.progressColor}
              backgroundColor={item.progressBackgroundColor}
              height={'30px'}
            />
          ))}
        </>
      </>
    )
  } else {
    if (vertical) {
      return (
        <div className='flex justify-between flex-col'>
          <TextBold className='tracking-[0.24px] text-xl font-bold'>
            {value}
          </TextBold>
          <GrayText className='text-xs pt-2'>{title}</GrayText>
        </div>
      )
    } else {
      return (
        <div className='flex justify-between flex-row border border-softBlack_70 p-1'>
          <GrayText className='text-xs'>{title}</GrayText>
          <TextBold className='tracking-[0.24px] text-xl font-bold'>
            {value}
          </TextBold>
        </div>
      )
    }
  }
}

const DocumentUploadButton = ({
  title,
  projectId,
  onUploadDocument,
  onDocumentDeleteClicked,
  onUploadDocumentDone,
  type,
  offerData
}) => {
  const [showModal, setShowModal] = useState(null)
  const [documentList, setDocumentList] = useState([])
  useEffect(() => {
    if (offerData?.[type]) {
      setDocumentList([...offerData?.[type]])
    }
  }, [offerData])

  const DocumentItem = ({ value, onDelete }) => {
    if (value?.url?.length > 0) {
      return (
        <div className='border relative'>
          <div className='h-auto min-h-48 flex flex-col items-center p-1 bg-white font-openSans rounded border border-dashed border-softBlack_30 overflow-hidden'>
            <div className='flex flex-col gap-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center'>
              <a
                href={value.url}
                target='_blank'
                style={{
                  textDecoration: 'none'
                }}
                className='px-6 py-5 text-sm font-openSans italic bg-volt rounded-lg font-bold w-48 uppercase text-black'
              >
                {value.fileName.includes('pdf') ? 'OPEN PDF' : 'OPEN IMAGE'}
              </a>
              <button
                onClick={() => onDelete?.(value._id || value.id)}
                className='px-6 py-5 text-sm font-openSans italic bg-volt rounded-lg font-bold w-48 uppercase'
              >
                Delete
              </button>
            </div>
            {!value.fileName.includes('pdf') && (
              <img src={value.url} className='object-fill' />
            )}
          </div>
        </div>
      )
    }
    return (
      <div className='border'>
        <div className='flex flex-col items-center p-6 bg-white font-openSans rounded border border-dashed border-softBlack_30'>
          <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M21.8333 7.33329V11.32C21.8333 11.32 19.18 11.3333 19.1667 11.32V7.33329H15.1667C15.1667 7.33329 15.18 4.67996 15.1667 4.66663H19.1667V0.666626H21.8333V4.66663H25.8333V7.33329H21.8333ZM17.8333 12.6666V8.66663H13.8333V4.66663H3.16667C1.7 4.66663 0.5 5.86663 0.5 7.33329V23.3333C0.5 24.8 1.7 26 3.16667 26H19.1667C20.6333 26 21.8333 24.8 21.8333 23.3333V12.6666H17.8333ZM3.16667 23.3333L7.16667 18L9.83333 22L13.8333 16.6666L19.1667 23.3333H3.16667Z'
              fill='#2E2B2E'
            />
          </svg>
          <h1 className='font-light text-sm mt-4 font-openSans italic'>
            {'Upload Files Here'}
          </h1>
          <div className='font-semibold font-openSans'>
            <button
              onClick={() => setShowModal(value.id)}
              className='px-8 py-2 border border-softBlack_70 font-light italic mt-1 font-openSans bg-volt'
              style={{
                borderRadius: '4px',
                border: '1px solid rgba(46, 43, 46, 0.7)'
              }}
            >
              Browse
            </button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='flex-1 flex-col'>
      <div className='flex flex-row justify-center items-center content-center h-9 w-full gap-4'>
        <span className='text-lg text-center font-bold mt-0 mb-4'>{title}</span>
        <button
          className='bg-volt rounded-full py-2 px-4'
          onClick={() => {
            setDocumentList([
              ...documentList,
              {
                id: `${type}-${documentList.length + 1}`
              }
            ])
          }}
        >
          +
        </button>
      </div>
      <PaymentReceipt
        title={`Document Upload (${type})`}
        type={type}
        show={showModal?.length}
        onClose={() => {
          setShowModal(null)
        }}
        onSucess={(data) => {
          onUploadDocumentDone?.(type, data)
        }}
        projectId={projectId}
        onUploadDocument={onUploadDocument}
      />
      <div className='mb-6 col-span-2 grid grid-cols-2 gap-2'>
        {documentList?.length > 0 &&
          documentList.map((item) => {
            return (
              <DocumentItem
                value={item}
                onDelete={(id) => {
                  onDocumentDeleteClicked?.(type, id)
                }}
              />
            )
          })}
      </div>
      {!documentList?.length && (
        <div className='flex w-full h-9 justify-center items-center content-center text-center my-4'>
          <span className='flex-1 font-thin text-sm p-4'>
            No documents found, Please add one
          </span>
        </div>
      )}
    </div>
  )
}

const PreViewButton = ({ reservationId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const data = []
        // await getDocusignDocumentPreview(reservationId)
        setData(data)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
      }
    })()
  }, [reservationId])

  const previewURL = data?.preview?.url ?? null
  if (isLoading && !previewURL?.length && !reservationId?.length) {
    return null
  }

  return (
    <div className='flex justify-end w-1/3'>
      <div className='flex justify-center items-center text-base font-bold py-3 text-softBlack decoration-softBlack_70  w-full ml-8 border border-black rounded cursor-pointer'>
        <a
          href={previewURL}
          target='_blank'
          className='text-softBlack decoration-softBlack'
        >
          Download Document
        </a>
      </div>
    </div>
  )
}

// const tempUnitItem = [
//   { title: 'One Zen Zoho City' },
//   { title: '1,210 sq. ft' },
//   { title: '2 Bedrooms' },
//   { title: '2 Bathrooms' },
//   { title: 'Balcony' },
//   { title: '1 Parking Slot' }
// ]

const UnitItem = ({ unit }) => {
  const ListItem = ({ title }) => {
    return (
      <div className='flex items-start gap-2.5 mt-2'>
        <div className='h-5 w-5'>
          <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M16.666 2.88086H9.99935C9.54102 2.88086 9.16602 3.25586 9.16602 3.71419V5.05586C9.16602 5.05586 9.16602 5.05586 9.17435 5.06419L13.341 8.81419C13.866 9.28086 14.166 9.96419 14.166 10.6725V11.2142H15.8327V12.8809H14.166V14.5475H15.8327V16.2142H14.166V17.8809H16.666C17.1243 17.8809 17.4993 17.5059 17.4993 17.0475V3.71419C17.4993 3.25586 17.1243 2.88086 16.666 2.88086ZM12.4993 6.21419H10.8327V4.54753H12.4993V6.21419ZM15.8327 9.54753H14.166V7.88086H15.8327V9.54753ZM15.8327 6.21419H14.166V4.54753H15.8327V6.21419Z'
              fill='#2E2B2E'
            />
            <path
              d='M12.5 17.0472V10.6722C12.5 10.4389 12.4 10.2139 12.225 10.0555L8.05833 6.30553C7.9 6.15553 7.7 6.08887 7.5 6.08887C7.3 6.08887 7.1 6.16387 6.94167 6.30553L2.775 10.0555C2.6 10.2055 2.5 10.4305 2.5 10.6722V17.0472C2.5 17.5055 2.875 17.8805 3.33333 17.8805H5C5.45833 17.8805 5.83333 17.5055 5.83333 17.0472V13.7139H9.16667V17.0472C9.16667 17.5055 9.54167 17.8805 10 17.8805H11.6667C12.125 17.8805 12.5 17.5055 12.5 17.0472Z'
              fill='#2E2B2E'
            />
          </svg>
        </div>
        <p className='my-0 font-normal text-softBlack text-base'>{title}</p>
      </div>
    )
  }

  return (
    <div className='bg-white rounded p-2'>
      <div className='h-60'>
        <img
          className='w-full h-full'
          src={unit?.floorPlan?.image}
          alt='floor plan'
        />
      </div>
      <div className='my-4 w-full' />
      <p className='font-bold text-2xl my-0'>{unit?.unitNumber}</p>
      <div className='flex flex-col gap-1 px-2'>
        <ListItem title={unit.building.name} />
        <ListItem title={`${unit.numberOfBathrooms} Bathrooms`} />
        <ListItem title={`${unit.numberOfBedrooms} Bedrooms`} />
        <ListItem title={`${unit.floorPlan.parkingQty} Parking Slots`} />
      </div>
    </div>
  )
}

export const paymentStatusArr = ['Cancelled', 'Pending', 'Complete']
const sginatureStatusArr = ['Cancelled', 'Pending', 'Complete']
const contractStatusArr = [
  'OfferCreated',
  'ContractCancelled',
  'OfferSent',
  'OfferAccepted',
  'Signed',
  'Paid',
  'SoldFirm',
  'SevenDaysRescission',
  'OfferRejected'
]

const OfferPreview = ({ appProject }) => {
  const { id: offerId } = useParams()
  const reservation = {}
  const {
    data: offerData,
    loading,
    update: updateOffer,
    updateContractStatus: updateContractStatusAPI,
    refetch: fetchOfferData,
    uploadDocument
  } = useUpdateOffers()
  const history = useHistory()
  const [unitData, setUnitData] = useState(null)
  useEffect(() => {
    if (offerData?.unit) {
      getUnits(offerData.unit._id).then((res) => {
        setUnitData(res)
      })
    }
  }, [offerData])

  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)

  useEffect(() => {
    if (offerId) {
      fetchOfferData?.(offerId)
    }
  }, [offerId, fetchOfferData])

  // const _cancelReservation = useCallback(async () => {
  //   // const toastId = toast.loading('Cancelling Reservation...')
  //   // try {
  //   //   toast.dismiss(toastId)
  //   //   toast.success('Cancelled reservation successfully')
  //   // } catch {
  //   //   toast.dismiss(toastId)
  //   // }
  // }, [reservation])

  const [isOpenRecordPaymentModal, setIsOpenRecordPaymentModal] =
    useState(false)

  // const uploadedDoc = useMemo(() => {
  //   const result = reservation?.documents?.filter(
  //     (doc) =>
  //       doc?.documentName?.includes('ID') || doc?.type?.includes('id_document')
  //   )?.[0]
  //   return result
  // }, [reservation])

  // const uploadedReceipt = useMemo(() => {
  //   const result = reservation?.documents?.filter(
  //     (doc) =>
  //       doc?.documentName?.includes('Receipt') ||
  //       doc?.type?.includes('payment_receipt')
  //   )?.[0]
  //   return result
  // }, [reservation])

  // const uploadedAgreement = useMemo(() => {
  //   const result = reservation?.documents?.filter(
  //     (doc) =>
  //       doc?.documentName?.includes('Agreement') ||
  //       doc?.type?.includes('agreement')
  //   )?.[0]
  //   return result
  // }, [reservation])

  const isSignatureMode = useMemo(
    () =>
      reservation?.signatureMode?.length
        ? reservation?.signatureMode === 'docusign'
        : true,
    [reservation]
  )

  const updateOfferObject = useCallback(
    (key, value) => {
      updateOffer({
        _id: offerData?._id,
        [key]: value
      })
    },
    [offerData]
  )

  const updateContractStatus = useCallback(
    (endpoint) => {
      updateContractStatusAPI({
        _id: offerData?._id,
        status: endpoint
      })
    },
    [offerData]
  )

  const tempContractData = useMemo(() => {
    return [
      {
        title: 'Sold Firm Date',
        value: 'N/A',
        type: ItemType.NORMAL
      },
      {
        title: 'BUYER',
        value: !offerData?.buyer?.[0]
          ? 'N?A'
          : offerData?.buyer?.[0]?.firstName +
            ' ' +
            offerData?.buyer?.[0]?.lastName,
        type: ItemType.NORMAL
      },
      {
        title: 'Purchse Price',
        value: formatMoney(offerData?.totalCost ?? 0),
        type: ItemType.NORMAL
      },
      {
        title: 'List Price',
        value: formatMoney(unitData?.price ?? 0),
        type: ItemType.NORMAL
      },
      {
        title: 'Discount Option',
        value: 'N/A',
        type: ItemType.NORMAL
      },
      {
        title: 'Parking',
        value: 'N/A',
        type: ItemType.NORMAL
      },
      {
        title: 'Upgrades and Options',
        value: 'N/A',
        type: ItemType.NORMAL
      },
      {
        title: 'Total Deposite Paid',
        value: 'N/A',
        type: ItemType.NORMAL
      }
    ]
  }, [offerData, unitData])

  const onDocumentUploaded = useCallback(
    (type, data) => {
      if (offerData) {
        updateOfferObject(type, [...offerData[type], data?._id])
      }
    },
    [offerData]
  )

  const onDocumentDeleteClicked = useCallback(
    async (type, id) => {
      if (offerData) {
        const toastId = toast.loading('Deleting Document...')
        try {
          await deletePrivateDocs(id)
          updateOfferObject(type, [
            ...offerData[type].filter((item) => {
              return item?._id !== id
            })
          ])
          toast.success('Document deleted successfully')
        } catch (e) {
        } finally {
          toast.dismiss(toastId)
        }
      }
    },
    [offerData]
  )

  return (
    <div className='relative w-full'>
      <div className='absolute w-full h-[500px]'>
        <img
          alt='background'
          className='object-cover object-right-bottom h-[500px] w-full'
          src={background}
        />
      </div>
      <div className='absolute top-4 left-4'>
        {!loading && (
          <button
            className='py-3 px-6 text-base rounded font-medium bg-gray-50 m-auto flex gap-2 items-center border border-softBlack_70'
            onClick={() => {
              history.goBack()
            }}
          >
            Go Back
          </button>
        )}
      </div>
      <div className='absolute top-4 right-4'>
        {!loading && (
          <button
            className='py-3 px-6 text-base bg-volt rounded font-medium m-auto flex gap-2 items-center'
            onClick={() => {
              history.push({
                pathname: ADMIN_OFFER,
                state: {
                  appProject,
                  offerId
                }
              })
            }}
          >
            Edit Contract
          </button>
        )}
      </div>
      {!loading && offerData && Object.keys(offerData)?.length && (
        <>
          <div className='absolute top-64 bg-red-50 z-10 h-full w-full'>
            <div className='grid grid-cols-2 gap-2'>
              <div className='col-span-1'>
                <div
                  className={classNames('w-full pb-3 grid grid-cols-2 gap-2')}
                >
                  <div className='col-span-1 px-2'>
                    <UnitItem unit={offerData?.unit} />
                  </div>
                  <div className='space-y-2 col-span-1'>
                    <div
                      onClick={() => {}}
                      className={
                        'cursor-pointer flex flex-col gap-2.5 p-5 overflow-hidden rounded'
                      }
                      style={{
                        minWidth: isMobile ? undefined : '220px',
                        backgroundColor:
                          AVAILABILITY_BG_COLOR?.[
                            unitData?.status?.toLowerCase()
                          ]
                      }}
                    >
                      <span className='text-xs text-zinc-500 uppercase italic'>
                        Status
                      </span>
                      <span className='text-xl text-zinc uppercase bold'>
                        {unitData?.status}
                      </span>
                      <span className='font-bold text-base'>
                        {formatDate(offerData.updatedAt)}
                      </span>
                    </div>
                    <div className='p-4 bg-[#fcfcfa] gap-4'>
                      <TableTitle
                        title='Contract Details'
                        date={formatDate(offerData?.createdAt)}
                        isStatic={false}
                      />
                      <div className='border-b-2 border-black mt-4' />
                      <div>
                        {tempContractData?.map((cItem, cIndex) => {
                          return (
                            <div className='my-4'>
                              <TableListItem {...cItem} />
                              <div className='border-b-2 border-gray mt-4' />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-span-1'>
                <div
                  className={classNames(
                    'w-full pb-3',
                    isMobile && 'grid grid-cols-2 gap-2 mt-2',
                    !isMobile && 'flex flex-row gap-3'
                  )}
                >
                  <Card
                    key='pp'
                    item={{
                      title: 'Purchase Price',
                      number: formatMoney(offerData?.totalCost)
                    }}
                    index={0}
                    active={false}
                    setActive={null}
                  />
                  <Card
                    key='pp'
                    item={{
                      title: 'Parking',
                      number: formatMoney(
                        offerData?.options
                          ?.filter((item) => item.type === 'parking')
                          ?.reduce((acc, item) => acc + item.value, 0) ?? 0
                      )
                    }}
                    index={0}
                    active={false}
                    setActive={null}
                  />
                  <Card
                    key='pp'
                    item={{
                      title: 'Upgrades',
                      number: formatMoney(
                        offerData?.options
                          ?.filter((item) => item.type !== 'parking')
                          ?.reduce((acc, item) => acc + item.value, 0) ?? 0
                      )
                    }}
                    index={0}
                    active={false}
                    setActive={null}
                  />
                </div>
                <div>
                  <div
                    className={classNames(
                      'pr-2 text-lg font-bold grid gap-x-12 gap-y-4 h-full grid-cols-1'
                    )}
                  >
                    <div className='bg-gray-100 flex flex-col gap-10 justify-center items-center rounded p-4'>
                      <div className='flex flex-col gap-6 w-full items-center'>
                        <div
                          className='flex items-center justify-start'
                          style={{ width: '90%' }}
                        >
                          <div className='w-1/3'>Contract Status</div>
                          <div className='w-1/3'>
                            <Select
                              suffixIcon={<DownIcon />}
                              showArrow
                              placeholder='Select'
                              value={offerData.status}
                              onChange={(value) => {
                                if (
                                  ['ContractCancelled'].indexOf(value) !== -1
                                ) {
                                  updateContractStatus('cancel')
                                } else if (['SoldFirm'].indexOf(value) !== -1) {
                                  updateContractStatus('complete')
                                } else if (
                                  ['OfferRejected'].indexOf(value) !== -1
                                ) {
                                  updateContractStatus('reject')
                                } else {
                                  updateOfferObject('status', value)
                                }
                              }}
                              style={{ height: '40px', width: '100%' }}
                              className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                            >
                              {contractStatusArr.map?.((_item, index) => (
                                <Select.Option value={_item} key={index}>
                                  <div
                                    className='flex gap-2 items-center'
                                    style={{ zIndex: '1500 !important' }}
                                  >
                                    <span>{_item}</span>
                                  </div>
                                </Select.Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        {isSignatureMode && (
                          <div
                            className='flex items-center'
                            style={{ width: '90%' }}
                          >
                            <div className='w-1/3'>
                              Signature Status
                              <p className='font-normal text-sm'>
                                {reservation?.primaryBuyerSignatureDate
                                  ?.length > 0 &&
                                  new Date(
                                    reservation?.primaryBuyerSignatureDate
                                  ).toLocaleString()}
                              </p>
                            </div>
                            <div className='flex w-1/3'>
                              <Select
                                suffixIcon={<DownIcon />}
                                showArrow
                                placeholder='Select'
                                value={offerData.signatureStatus}
                                onChange={(value) => {
                                  updateOfferObject('signatureStatus', value)
                                }}
                                style={{ height: '40px', width: '100%' }}
                                className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                              >
                                {sginatureStatusArr.map?.((_item, index) => (
                                  <Select.Option value={_item} key={index}>
                                    <div
                                      className='flex gap-2 items-center'
                                      style={{ zIndex: '1500 !important' }}
                                    >
                                      <span>{_item}</span>
                                    </div>
                                  </Select.Option>
                                ))}
                              </Select>
                            </div>
                            <PreViewButton
                              reservationId={reservation?._id ?? null}
                            />
                          </div>
                        )}
                        <div
                          className='flex items-center'
                          style={{ width: '90%' }}
                        >
                          <div className='w-1/3'>
                            Payment Status
                            <p className='font-normal text-sm'>
                              {reservation?.paymentReceivedDate?.length > 0 &&
                                new Date(
                                  reservation?.paymentReceivedDate
                                ).toLocaleString()}
                            </p>
                          </div>
                          <div className='flex  w-1/3'>
                            <Select
                              suffixIcon={<DownIcon />}
                              showArrow
                              placeholder='Select'
                              value={offerData.paymentStatus}
                              onChange={(value) => {
                                updateOfferObject('paymentStatus', value)
                              }}
                              style={{ height: '40px', width: '100%' }}
                              className='rating-select flex align-center w-full text-sm p-2 bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70'
                            >
                              {paymentStatusArr.map?.((_item, index) => (
                                <Select.Option value={_item} key={index}>
                                  <div
                                    className='flex gap-2 items-center'
                                    style={{ zIndex: '1500 !important' }}
                                  >
                                    <span>{_item}</span>
                                  </div>
                                </Select.Option>
                              ))}
                            </Select>
                          </div>
                          <div className='flex justify-end w-1/3'>
                            <div
                              className='flex justify-center items-center text-center font-bold py-3 w-full ml-8 border border-black rounded cursor-pointer'
                              onClick={() => setIsOpenRecordPaymentModal(true)}
                            >
                              Record Payment
                            </div>
                          </div>
                        </div>
                        <div className='flex w-full flex-col gap-2 mt-5'>
                          <DocumentUploadButton
                            offerData={offerData}
                            projectId={projectId}
                            title='Signed Contract'
                            onUploadDocument={uploadDocument}
                            type='signedContracts'
                            onUploadDocumentDone={onDocumentUploaded}
                            onDocumentDeleteClicked={onDocumentDeleteClicked}
                          />
                          <DocumentUploadButton
                            offerData={offerData}
                            projectId={projectId}
                            title='ID Document'
                            onUploadDocument={uploadDocument}
                            type='userIdDocs'
                            onUploadDocumentDone={onDocumentUploaded}
                            onDocumentDeleteClicked={onDocumentDeleteClicked}
                          />
                          <DocumentUploadButton
                            offerData={offerData}
                            projectId={projectId}
                            title='Payment Receipt'
                            onUploadDocument={uploadDocument}
                            type='paymentReceipts'
                            onUploadDocumentDone={onDocumentUploaded}
                            onDocumentDeleteClicked={onDocumentDeleteClicked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isOpenRecordPaymentModal && (
            <RecordPaymentModal
              offerData={offerData}
              show={isOpenRecordPaymentModal}
              setShow={setIsOpenRecordPaymentModal}
              onSubmit={(data) => {
                updateOffer({
                  _id: offerData?._id,
                  ...data
                })
              }}
            />
          )}
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  appProject: state.authReducer.appProject
})

const mapDispatchToProps = {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferPreview)
)
