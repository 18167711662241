// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// @ts-ignore
// import { TFullFilledRightArrowIcon } from 'components/Icons'
import { ReactComponent as ExpandMore } from '../../assets/icons/icn_expand_more.svg'
import { useDispatch } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { SHOW_HIDE_SIDEBAR } from 'store/actions/actionTypes'

// import { SHOW_HIDE_SIDEBAR } from 'store/actions/actionTypes'

const MenuItem = (props) => {
  const { menu, selectedItem, setSelectedItem } = props

  const [expanded, setExpended] = useState(false)
  // const [selected, setSelected] = useState(selectedItem === route)
  const dispatch = useDispatch()

  useEffect(() => {
    if (menu?.hasSubMenus) {
      const index = menu?.subMenus?.findIndex?.((_subMenu) => _subMenu?.route === selectedItem)
      if (index !== -1) {
        setExpended(true)
      }
    }
  }, [selectedItem])

  const selectMenu = (_value) => {
    if (menu?.hasSubMenus) {
      setExpended(prev => !prev)
      setSelectedItem(menu?.subMenus?.[0]?.route)
      menu?.subMenus?.[0]?.onClick?.()
      return
    }
    menu?.onClick?.()
    setSelectedItem(_value)
    // dispatch({ type: SHOW_HIDE_SIDEBAR, payload: false })
  }

  const selectSubMenu = (_value, clickHandler) => {
    setSelectedItem(_value)
    clickHandler?.()
    // dispatch({ type: SHOW_HIDE_SIDEBAR, payload: false })
  }

  let selected = selectedItem === menu?.route
  if (menu?.hasSubMenus) {
    selected = menu?.subMenus.findIndex((_subMenu) => _subMenu.route === selectedItem) !== -1
  }

  useEffect(() => {
    if (window.location.pathname === menu?.route && !menu?.hasSubMenus) {
      setSelectedItem(menu?.route)
    } else if (menu?.hasSubMenus) {
      if (menu?.subMenus?.find?.(_subMenu => _subMenu.route === window.location.pathname)) { setSelectedItem(window.location.pathname) }
    }
  }, [window.location.pathname])

  return (
    <div>
      <div onClick={() => {
        selectMenu(menu?.route)
        if (!menu.hasSubMenus && isMobile) {
          dispatch({ type: SHOW_HIDE_SIDEBAR, payload: false })
        }
      }} className={`relative w-full h-11 p-2.5 flex items-center gap-3 rounded cursor-pointer ${selected && !menu?.hasSubMenus ? 'bg-gray-2' : 'bg-transparent'}`}>
        {menu?.Icon && <menu.Icon className={`
          ${selected
            ? 'text-softBlack'
            : 'text-softBlack70 group-hover:text-gray-500 hover:text-gray-900'
          }`}
        />}
        <span className={`font-sans text-base whitespace-nowrap font-medium truncate ${selected ? 'text-softBlack' : 'text-softBlack70'}`}>{menu?.title}</span>
        {menu?.hasSubMenus && (
          <ExpandMore
            className={`
            ${selected
                ? 'text-gray-400'
                : 'text-gray-300'
              }
            ${expanded && 'rotate-180'}
            ml-auto h-5 w-5 transform group-hover:text-gray-400 transition-all duration-150`}
          />
        )}
      </div>
      {expanded && menu?.hasSubMenus
        ? (
          <div className="flex flex-col gap-1 border-l-1.5 border-softBlack30 pl-3 ml-4">
            {menu?.subMenus.map?.((_subMenu, index) => (
              <div onClick={() => {
                selectSubMenu(_subMenu.route, _subMenu.onClick)
                if (isMobile) {
                  dispatch({ type: SHOW_HIDE_SIDEBAR, payload: false })
                }
              }} key={index} className={`h-11 p-2.5 rounded cursor-pointer ${selectedItem === _subMenu.route ? 'bg-gray-2' : ''}`}>
                <span className={`font-sans text-base font-medium ${selectedItem === _subMenu.route ? 'text-softBlack' : 'text-softBlack70'}`}>{_subMenu.title}</span>
              </div>
            ))}
          </div>
        )
        : <div />}
    </div>
  )
}

MenuItem.propType = {
  menu: PropTypes.object,
  selectedItem: PropTypes.string,
  setSelectedItem: PropTypes.func
}

export default MenuItem
