/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from 'react'
import {
  // useDispatch,
  useSelector
} from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import { MENU_CATEGORY } from './constants'

import CategoryButton from './CategoryButton'
import MenuItem from './MenuItem'

import useSideBarMenu from './useSideBarMenu'
import { ADMIN_DASHBOARD, ADMIN_PROJECT_MANAGEMENT } from 'routes'
import { isMobile } from 'react-device-detect'
import CollapseSideBarButton from 'components/CollpaseSidebarButton'
// import { Switch } from 'ui'
// import { HOVER_TOGGLER } from 'store/actions/actionTypes'
// import standardStyle from 'assets/css/standardStyle'

const AdminSideBar = () => {
  const appProject = useSelector((state) => state.appReducer.appProject)
  const listOfProjects = useSelector((state) => state.appReducer.listOfProjects)
  const userType = useSelector((state) => state.authReducer.userObject.userType)
  const location = useLocation()
  const history = useHistory()
  // const dispatch = useDispatch()

  const { SIDE_BAR_MENU, getCategory } = useSideBarMenu()
  const activeCategory = getCategory(location.pathname)
  const [selectedCategory, setSelectedCategory] = useState(activeCategory?.value || MENU_CATEGORY[0].value)
  const [selectedItem, setSelectedItem] = useState(location.pathname)

  const [projectSelectedTab, setProjectSelectedTab] = useState(ADMIN_PROJECT_MANAGEMENT)
  const [selectedGeneralTab, setSelectedGeneralTab] = useState(ADMIN_DASHBOARD)

  const selectedProject = listOfProjects.find((_project) => _project._id === appProject)
  const show = useSelector((state) => state.appReducer.show)
  // const isHover = useSelector((state) => state.appReducer.isHover)

  // const handleHoverToggle = () => {
  //   dispatch({ type: HOVER_TOGGLER, payload: (isHover ? false : true) })
  // }

  useEffect(() => {
    if (selectedCategory === 'project') {
      setSelectedItem(projectSelectedTab)
      history.push({
        pathname: projectSelectedTab
      })
    } else {
      setSelectedItem(selectedGeneralTab)
      history.push({
        pathname: selectedGeneralTab
      })
    }
  }, [selectedCategory])

  // When page loads, set the last selected category to the active category
  useEffect(() => {
    if (selectedCategory === 'project') {
      setSelectedItem(selectedItem)
      history.push({
        pathname: selectedItem
      })
    } else {
      setSelectedItem(selectedItem)
      history.push({
        pathname: selectedItem
      })
    }
  }, [])

  useEffect(() => {
    if (selectedItem.indexOf('project') > -1) {
      setProjectSelectedTab(selectedItem)
    } else {
      setSelectedGeneralTab(selectedItem)
    }
  }, [selectedItem])

  return (
    <div className={`h-full overflow-y-auto bg-gray-1 ${isMobile ? 'w-full' : 'w-70.25'} ml-0 p-6 relative transition-all ease-in-out ${show && !isMobile ? 'border-r-2 border-black' : ''}`}>
      <CollapseSideBarButton />
      <h1 className='text-softBlack text-2xl font-bold font-sans mb-5'>{selectedProject?.projectName}</h1>
      {userType !== 'SalesRep' && <div className='w-full border-1.5 border-softBlack flex rounded-sm h-9.5'>
        {MENU_CATEGORY.map((category, index) => (
          <CategoryButton key={category.title} category={category} selected={selectedCategory === category?.value} setSelectedCategory={setSelectedCategory}>
            {category.title}
          </CategoryButton>
        ))}
      </div>}
      {/* hover toggler button
      <div className='mt-3 flex justify-between'>
        <h1 className='text-softBlack text-lg font-bold font-sans'>Hover toggler</h1>
        <Switch checked={isHover} onChange={() => handleHoverToggle()} style={{ background: isHover && standardStyle.colors.volt, outline: '1px solid black' }} />
      </div> */}

      <div className='mt-8'>
        {SIDE_BAR_MENU[selectedCategory].map((_section, index) => (
          <div key={index}>
            <div key={index} className="flex flex-col gap-1">
              {_section?.menu?.map?.((_menu, menuIndex) => (
                _menu?.key === 'team' && userType === 'SalesRep'
                  ? null
                  : <MenuItem menu={_menu} key={menuIndex} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
              ))}
            </div>
            <div className={`my-6 h-px w-full bg-softBlack30 ${index === SIDE_BAR_MENU?.[selectedCategory]?.length - 1 ? 'invisible' : 'visible'}`} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdminSideBar
