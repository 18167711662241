import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getFilterContacts } from 'store/actions/usersActions'
import { toast } from 'react-hot-toast'

const useGetFilterUsers = (userType, filters) => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer?.userObject)
  const userId = userObject?._id

  const getModifiedFilters = () => {
    if (userObject?.userType === 'SalesRep' && !Object.keys(filters)?.length) {
      return {
        ...filters,
        salesRepOwner: userId
      }
    } else {
      return filters
    }
  }
  const onSuccess = (searchUsers) => {
    if (searchUsers === null || !searchUsers?.data?.length) {
      toast.error('No users were found')
    }
    // } else toast.success(`Found ${searchUsers?.totalRecords} contacts`)
  }
  const { data, isLoading } = useQuery(
    ['getFilterUsers', { userType, ...getModifiedFilters() }],
    () =>
      getFilterContacts({
        ...getModifiedFilters(),
        userType
      }),
    {
      onSuccess,
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled:
        !!userId && !!(Object.keys(getModifiedFilters()).length || userType)
    }
  )
  return { filteredUsers: data, filtering: isLoading }
}

export default useGetFilterUsers
