// @ts-nocheck
import { PageHeader } from 'components'
import React, { useRef } from 'react'
import { EmailEditor } from 'react-email-editor'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const EditEmailTemplate = () => {
  const emailEditorRef = useRef(null)
  const { templateId } = useParams()

  const onLoad = () => {
    // editor instance is created
    // you can load your template here
    // const templateJson = {}
    emailEditorRef.current.editor.loadTemplate(templateId)
  }

  const onReady = () => {
    // editor is ready
    console.log('onReady')
  }

  return (
    <div className='flex flex-col relative h-full'>
      <PageHeader
        title='Edit Email Template'
      />
      <EmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        projectId='174034'
      />
    </div>
  )
}

export default EditEmailTemplate
