/* eslint-disable no-unused-vars */
// @ts-nocheck
import { OfficeBuildingIcon, PlusIcon, XCircleIcon } from '@heroicons/react/solid'
import { message } from 'antd'
import keyboardAnimation from 'assets/lottieJsons/keyboard.json'
import scanIdAnimation from 'assets/lottieJsons/scanId.json'
import sendToBuyerAnimation from 'assets/lottieJsons/sendToBuyer.json'
import {
  AddUserIcon, Camera, CameraButton, CheckIcon, CloseIcon, FadedAnimatedDiv, FileSelector, ImageUploadButtonWithThumbnail,
  ImageUploadMethodsButtons, LoadingIcon, LottieWrapper, OfferFooterButtons, UserIcon, UserIdIcon, UserRoundedImage
} from 'components'
import UserInfoCard from 'components/UserInfoCard'
import { SwitchUserIcon } from 'components/Icons'
import TableFilter from 'components/TableFilter'
import { AnimatePresence, motion } from 'framer-motion'
import { isArray, isObject } from 'lodash'
import CreateUser from 'pages/desktopMode/users/createUser'
import propTypes from 'prop-types'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { createOrUpdateCorporation, getCorporations } from 'store/actions/corporationsActions'
import { createOfferChangeBuyer } from 'store/actions/offerActions'
import { deletePrivateDocs, getCorporationPrivateDocs, uploadPrivateDocument } from 'store/actions/privateDocumentsActions'
import { changeUserImages, createAssignment, getProjectBuyers, getUsers, photoIDOCR } from 'store/actions/usersActions'
import { H2, H7, InputLabel, Modal, TSwitch } from 'ui'
import { classNames, createUploadFileList, isFile, validateRequiredInputs } from 'utils'
import {
  BuyerInfosAnimatedWrapper, BuyerInfosWrapper, BuyerMainWrapper,
  BuyerPhotoIdWrapper, BuyersListWrapper, BuyerWrapper, CameraButtonWrapper,
  CameraContainer, CancelSelectionWrapper, ConfirmSelectionWrapper, CorporateBuyerWrapper, CorporationDocumentWrapper,
  CorporationInfosWrapper, CorporationWrapper, CreateBuyerButtons,
  CustomFadedWrapper, LoadingWrapper, MethodsWrapper,
  MethodWrapper, MultiBuyerSwitchWrapper, PageWrapper, RepresentativeDocumentsWrapper,
  RepresentativeInfosWrapper, RepresentativeWrapper, TitlesWrapper
} from './styles'
import useUpdateUsers from 'hooks/useUpdateUsers'

// Form Status
const LOADING_BUYERS = 'loading_buyers'
const SHOWING_BUYERS = 'showing_buyers'
const UPLOAD_RESULT = 'upload_result'
const CREATING_BUYER_SELECT_METHOD = 'creating_buyer_select_method'
const GET_PHOTO_ID_FROM_CAMERA = 'get_photo_id_from_camera'
const GET_PHOTO_ID_FROM_LIBRARY = 'get_photo_id_from_library'
const SEND_TO_CUSTOMER = 'send_to_customer'
const TYPE_MANUALLY = 'type_manually'
const CREATING_EDITING_CORPORATE_BUYER = 'CREATING_EDITING_CORPORATE_BUYER'

const confirmationButtonsVariants = {
  initial: {
    x: 1000
  },
  confirmButtonEnter: {
    x: '20%'
  },
  cameraButtonEnter: {
    x: '50%'
  },
  cancelButtonEnter: (custom) => ({
    x: '-120%',
    transition: {
      delay: custom,
      x: { type: 'spring', stiffness: 265, damping: 30 }
    }
  }),
  libraryButtonEnter: (custom) => ({
    x: '-50%',
    transition: { delay: custom }
  }),
  exit: (custom) => ({
    x: 1000,
    transition: { delay: custom }
  })
}

const cameraButtonsAnimationValues = {
  buttonExit: (direction) => ({
    x: direction === 'left' ? -1000 : 1000
  }),
  buttonEnter: {
    x: 0
  },
  cameraExitY: {
    y: -1000
  },
  cameraEnterY: {
    y: 0
  },
  cameraExitX: {
    x: 1000
  },
  cameraEnterX: {
    x: 0
  },
  uploadError: {

  }
}

const MessageComponent = ({ children }) => (
  <H7 style={{ marginBottom: 0, color: 'white' }}>
    {children}
  </H7>
)

const UploadPhotoIdButton = ({
  uploadingPhoto, buttonText,
  buttonStyle = {},
  onClick = () => {},
  photo
}) => (
  <ImageUploadButtonWithThumbnail
    uploadingImage={uploadingPhoto}
    uploadButtonIcon={<UserIdIcon style={{ fontSize: 30 }} />}
    uploadButtonText={buttonText}
    uploadButtonStyle={buttonStyle}
    onClick={onClick}
    image={photo}
  />
)

const BuyerComponent = ({
  buyerInfos: {
    _id, firstName, lastName, email, userAvatar,
    buyerData: { salesRep, frontPhotoId, backPhotoId, assignments, allowSurveys, allowAdditionalServices }
  },
  userObject,
  selectBuyer,
  setFormStatus,
  buyerIsA,
  confirmationMessage,
  setShowLinkUserModal,
  selectedMultiBuyerIndex,
  appProject,
  isBuyer
}) => {
  const [showConfirmationButton, setShowConfirmationButton] = useState(false)

  const openConfirmationButtons = (e) => {
    setShowConfirmationButton(true)
  }

  const closeConfirmationButtons = (e) => {
    e.stopPropagation()

    setShowConfirmationButton(false)
  }

  let salesRepName = ''

  // If who is creating the offer is a Coopbroker, use itself to be the representative
  const { userType, firstName: loggedUserFirstName, lastName: loggedUserLastName } = userObject

  if (userType === 'CoopBroker') {
    salesRepName = `${loggedUserFirstName} ${loggedUserLastName}`
  } else {
    if (assignments && assignments.length) {
      const projectAssignment = assignments.filter(assignment => assignment.project === appProject)

      if (projectAssignment.length && projectAssignment[0].salesRep) {
        const { firstName, lastName } = projectAssignment[0].salesRep
        salesRepName = `${firstName} ${lastName}`
      }
    }
  }

  const userParams = {
    '-2': {
      icon: <div className='relative flex'>
        <OfficeBuildingIcon className='h-10 w-10 text-white' />
        <PlusIcon className='h-7 w-7 text-white absolute -right-4 -bottom-1' />
      </div>,
      onClick: () => setFormStatus(CREATING_EDITING_CORPORATE_BUYER)
      // onClick: () => message.info('Still in progress (:')
    },
    '-1': {
      icon: <SwitchUserIcon style={{ fontSize: 35, marginBottom: 10 }} />,
      onClick: () => setShowLinkUserModal(true)
    },
    0: {
      icon: <AddUserIcon style={{ fontSize: 35, marginBottom: 10 }} />,
      onClick: () => setFormStatus(CREATING_BUYER_SELECT_METHOD)
    },
    user: {
      icon: <UserIcon style={{ fontSize: 35, marginBottom: 10 }} />,
      onClick: () => openConfirmationButtons()
    }
  }

  const _selectBuyer = (e) => {
    e.stopPropagation()

    selectBuyer({
      _id,
      firstName,
      lastName,
      email,
      salesRepName,
      buyerData: {
        frontPhotoId,
        backPhotoId,
        assignments,
        allowSurveys,
        allowAdditionalServices
      },
      userAvatar
    })
  }

  if (isBuyer) {
    return (
      <UserInfoCard firstName={firstName} lastName={lastName} email={email} userAvatar={userAvatar} onSelect={_selectBuyer} className="w-full" />
    )
  }

  return (
    <div className={classNames(`${_id.length <= 2 && 'sticky top-0 z-50 pb-3 bg-white'}`)}>
        <BuyerWrapper
          id={_id}
          onClick={userParams[_id.length > 2 ? 'user' : _id].onClick}
          className={classNames('h-full', _id.length <= 2 ? 'bg-indigo-600 text-white' : 'bg-white')}
        >
          {
            _id.length > 2
              ? <UserRoundedImage src={userAvatar} className='h-12 w-12' />
              : userParams[_id].icon
          }

          <span>{`${firstName} ${lastName}`}</span>
          <span>{email}</span>

          <AnimatePresence initial={false}>
            {
              showConfirmationButton &&
                <ConfirmSelectionWrapper
                  key='confirmButton'
                  initial='initial'
                  animate='confirmButtonEnter'
                  exit='exit'
                  variants={confirmationButtonsVariants}
                  transition={{ duration: 0.2 }}
                  custom={0}
                  onClick={_selectBuyer}
                >
                  <CheckIcon style={{ fontSize: 30 }} />
                  <MessageComponent>
                    Confirm <strong>{firstName}</strong> as a {buyerIsA}?
                  </MessageComponent>

                  { confirmationMessage && <MessageComponent>{confirmationMessage}</MessageComponent> }
                </ConfirmSelectionWrapper>
            }

            {
              showConfirmationButton &&
                <CancelSelectionWrapper
                  key='cancelButton'
                  initial='initial'
                  animate='cancelButtonEnter'
                  exit='exit'
                  variants={confirmationButtonsVariants}
                  transition={{ duration: 0.2 }}
                  onClick={closeConfirmationButtons}
                  custom={0.01}
                >
                  <CloseIcon style={{ fontSize: 30 }} />
                  Cancel
                </CancelSelectionWrapper>
            }
          </AnimatePresence>

        </BuyerWrapper>
    </div>
  )
}

const CustomCamera = forwardRef(({
  key, exitAnimation, enterAnimation,
  setPhoto, setCameraOpened
}, ref) => (
  <CameraContainer
    key={key}
    initial={exitAnimation}
    animate={enterAnimation}
    exit={exitAnimation}
    variants={cameraButtonsAnimationValues}
    transition={{ duration: 0.2 }}
  >
    <Camera
      ref={ref}
      width='100%'
      onTakePhoto={(photo) => {
        setPhoto(photo)
      }}
      onClickCloseButton={() => setCameraOpened(false)}
    />
  </CameraContainer>
))

const MultiBuyerItem = ({
  _id, index, firstName, lastName, userAvatar,
  selectedMultiBuyerIndex, pureArray, className = '',
  onClick, onRemoveBuyer
}) => {
  const selected = selectedMultiBuyerIndex === index
  const arrayWithJustOneElement = pureArray && pureArray.length === 1

  return (
    <motion.div
      initial={{ scale: 1 }}
      animate={{ scale: selected ? 1.09 : 1 }}
      className={`bg-white hover:cursor-pointer ${className}`}
      onClick={onClick}
    >
      <div className='h-24 w-24 relative shadow-md border-gray-200 border rounded-md flex flex-col items-center justify-center text-xs overflow-visible'>
        {
          index === 'newBuyerButton'
            ? (
                <PlusIcon className='h-8 w-8 text-gray-500' />
              )
            : (
              <>
                  <XCircleIcon
                    onClick={(e) => onRemoveBuyer(e)}
                    className={`absolute text-red-500 h-6 w-6 -top-2 -right-2 ${(!selected || arrayWithJustOneElement) && 'hidden'}`}
                  />
                  <UserRoundedImage src={userAvatar} className='h-10 w-10'/>
                  <span className={`${selected && 'font-semibold'} text-center px-5`}>
                    {`${_id <= 0 ? 'Choosing buyer' : 'Buyer'} ${index + 1}`}
                  </span>
                </>
              )
        }
        {
          _id !== -1 && (
            <>
              <span>{firstName}</span>
              <span>{lastName}</span>
            </>
          )
        }
      </div>
    </motion.div>
  )
}

const Buyer = (props) => {
  // const [idType, setIdType] = useState('GovernmentID')
  const [selectedMethodOfCreation, setSelectedMethodOfCreation] = useState('')
  const [frontPhotoId, setFrontPhotoId] = useState([])
  const [backPhotoId, setBackPhotoId] = useState([])
  const [uploadingFrontPhotoId, setUploadingFrontPhotoId] = useState(false)
  const [uploadingBackPhotoId, setUploadingBackPhotoId] = useState(false)
  const [formStatus, setFormStatus] = useState(LOADING_BUYERS)
  const [originalBuyers, setOriginalBuyers] = useState([])
  const [buyers, setBuyers] = useState(originalBuyers)
  const [choosingPhotoIdMethod, setChoosingPhotoIdMethod] = useState(false)
  const [cameraOpened, setCameraOpened] = useState(false)
  const [photoToSet, setPhotoToSet] = useState('front')
  const [editingUserId, setEditingUserId] = useState(0)
  const [savingUser, setSavingUser] = useState(false)
  const [externalUserObject, setExternalUserObject] = useState('')
  const [showLinkUserModal, setShowLinkUserModal] = useState(false)
  const [buyerEmailToLink, setBuyerEmailToLink] = useState('')
  const [linkingBuyerToSalesRep, setLinkingBuyerToSalesRep] = useState(false)
  const [selectedMultiBuyerIndex, setSelectedMultiBuyerIndex] = useState(0)
  const [showRemoveMultiBuyerModal, setShowRemoveMultiBuyerModal] = useState(false)
  const [buyerNameBeingRemoved, setBuyerNameBeingRemoved] = useState('')
  const [corporationName, setCorporationName] = useState('')
  const [corporationAddress1, setCorporationAddress1] = useState('')
  const [corporationAddress2, setCorporationAddress2] = useState('')
  const [corporationCity, setCorporationCity] = useState('')
  const [corporationProvinceState, setCorporationProvinceState] = useState('')
  const [corporationCountry, setCorporationCountry] = useState('')
  const [corporationPostalCode, setCorporationPostalCode] = useState('')
  const [corporationRepresentativeFirstName, setCorporationRepresentativeFirstName] = useState('')
  const [corporationRepresentativeLastName, setCorporationRepresentativeLastName] = useState('')
  const [corporationRepresentativePhoneNumber, setCorporationRepresentativePhoneNumber] = useState('')
  const [corporationRepresentativeEmail, setCorporationRepresentativeEmail] = useState('')
  const [corporationDocument, setCorporationDocument] = useState([])
  const [uploadingCorporationDocument, setUploadingCorporationDocument] = useState(false)
  const [bindingDocument, setBindingDocument] = useState([])
  const [uploadingBindingDoc, setUploadingBindingDoc] = useState(false)
  const [frontPhotoIdFile, setFrontPhotoIdFile] = useState('')
  const [backPhotoIdFile, setBackPhotoIdFile] = useState('')
  const [corporationId, setCorporationId] = useState('')

  const webcamRef = useRef(null)
  const createUserInstance = useRef(null)
  const { createOrUpdateUser } = useUpdateUsers()

  const {
    executeOnSelectBuyer, selectedBuyers,
    createOfferChangeBuyer, userObject,
    offerId, buyerIsA, confirmationMessage,
    appProject, executeOnAddNewBuyerToOffer,
    executeOnRemoveBuyerFromOffer, offerHasMultiBuyers,
    onChangeMultiBuyers, selectedUnit
  } = props

  const getBuyer = useCallback((id) => {
    let tmpId = id
    if (!tmpId) {
      tmpId = selectedBuyers[0]._id
    }

    getUsers(tmpId)
      .then(async (userData) => {
        const { buyerData } = userData

        let tmpFrontPhotoId = []
        let tmpBackPhotoId = []

        if (buyerData.frontPhotoId) {
          tmpFrontPhotoId =
          createUploadFileList(buyerData.frontPhotoId)
        }

        if (buyerData.backPhotoId) {
          tmpBackPhotoId =
          createUploadFileList(buyerData.backPhotoId)
        }

        setEditingUserId(tmpId)
        setFrontPhotoId(tmpFrontPhotoId)
        setBackPhotoId(tmpBackPhotoId)

        // Check if it's a corporate buyer
        const { corporations } = buyerData

        if (corporations && corporations.length) {
          const corporationId = corporations[0]

          const corporationData = await getCorporations(corporationId)
          const corporationDocs = await getCorporationPrivateDocs(corporationId)

          if (corporationDocs && corporationDocs.length) {
            if (corporationDocs[0]) {
              const corporationDocument = [{
                name: corporationDocs[0].documentName,
                url: '',
                _id: corporationDocs[0]._id
              }]

              setCorporationDocument(corporationDocument)
            }

            if (corporationDocs[1]) {
              const corporationDocument = [{
                name: corporationDocs[1].documentName,
                url: '',
                _id: corporationDocs[1]._id
              }]

              setBindingDocument(corporationDocument)
            }
          }

          const {
            corporationName,
            addressLine1,
            addressLine2,
            city,
            province,
            country,
            postalCode
          } = corporationData

          const {
            firstName,
            lastName,
            email
          } = userData

          if (buyerData.phoneNumber) {
            setCorporationRepresentativePhoneNumber(buyerData.phoneNumber)
          }

          setCorporationId(corporationId)
          setCorporationName(corporationName)
          setCorporationAddress1(addressLine1)
          setCorporationAddress2(addressLine2)
          setCorporationCity(city)
          setCorporationProvinceState(province)
          setCorporationCountry(country)
          setCorporationPostalCode(postalCode)

          setCorporationRepresentativeFirstName(firstName)
          setCorporationRepresentativeLastName(lastName)
          setCorporationRepresentativeEmail(email)

          setFormStatus(CREATING_EDITING_CORPORATE_BUYER)
        } else {
          setFormStatus(UPLOAD_RESULT)
        }
      })
  }, [selectedBuyers])

  const _getBuyers = useCallback((changeFormStatus = true) => {
    if (changeFormStatus) {
      setFormStatus(LOADING_BUYERS)
    }

    getProjectBuyers(appProject)
      .then((buyers) => {
        setOriginalBuyers(buyers)
        setBuyers(buyers)

        if (changeFormStatus) {
          setFormStatus(SHOWING_BUYERS)
          // setFormStatus(CREATING_BUYER_SELECT_METHOD)
        }
      })
      .catch(() => {
        if (changeFormStatus) {
          setFormStatus(SHOWING_BUYERS)
        }
      })
  }, [appProject])

  useEffect(() => {
    const selectedBuyer = selectedBuyers[selectedMultiBuyerIndex]

    if (selectedBuyer && selectedBuyer._id !== -1) {
      getBuyer(selectedBuyer._id)
    } else {
      setEditingUserId(0)
      if (!originalBuyers.length) {
        _getBuyers()
      } else {
        setFormStatus(SHOWING_BUYERS)
      }
    }
  }, [getBuyer, originalBuyers.length, selectedBuyers, selectedMultiBuyerIndex, _getBuyers])

  useEffect(() => {
    if (appProject) {
      setExternalUserObject({
        projects: appProject
      })
    }
  }, [appProject])

  useEffect(() => {
    if (!offerHasMultiBuyers) {
      setSelectedMultiBuyerIndex(0)
    }
  }, [offerHasMultiBuyers])

  const selectBuyer = useCallback((props) => {
    executeOnSelectBuyer({
      ...props,
      indexToUpdate: selectedMultiBuyerIndex,
      offerHasMultiBuyers
    })
  }, [executeOnSelectBuyer, selectedMultiBuyerIndex, offerHasMultiBuyers])

  // const photoIDOCR = () => new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     const returnObject = {
  //       executionId: 'f33ff1da-f3a0-4847-9282-1a72ab301869',
  //       finishTime: '2021-02-04T21:48:37.074Z',
  //       startTime: '2021-02-04T21:48:36.797Z',
  //       result: {
  //         firstName: 'CARON ELIZABETH NIC',
  //         lastName: 'SAMPLE',
  //         fullName: '',
  //         issuingAuthority: '',
  //         documentNumber: 'K12-34-5678',
  //         dateOfBirth: {
  //           day: 11,
  //           month: 1,
  //           originalString: '01/11/1966',
  //           successfullyParsed: true,
  //           year: 1966
  //         },
  //         dateOfExpiry: {
  //           day: 11,
  //           month: 1,
  //           originalString: '01/11/2023',
  //           successfullyParsed: true,
  //           year: 2023
  //         },
  //         address: '123 NORTH STREET↵APT. 2↵TOPEKA, KS 66612-1234'
  //       },
  //       imageUrl: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1612475312119-0409%20Kansas_Real_ID.1.jpg'
  //     }

  //     resolve(returnObject)
  //   }, 3000)
  // })

  const _photoIDOCR = useCallback((file, updateFields, sourceSeter, loadingSeter, vim) => new Promise((resolve, reject) => {
    sourceSeter('')
    loadingSeter(true)

    photoIDOCR(file)
      .then((data) => {
        console.log('ocr data', data)
        const { imageUrl } = data

        if (updateFields) {
          const {
            result: {
              firstName,
              lastName,
              fullName,
              // issuingAuthority,
              documentNumber: idNumber,
              dob_day: dayOfBirth,
              dob_month: monthOfBirth,
              dob_year: yearOfBirth,
              documentType: idType,
              expiry_day: dayOfExpiry,
              expiry_month: monthOfExpiry,
              expiry_year: yearOfExpiry,
              address1,
              address2,
              issuerOrg_region_full: province,
              nationality_full: country,
              postcode
            }
          } = data

          let tmpFirstName = firstName
          let tmpLastName = lastName
          const tmpFullName = fullName

          const birthday = `${yearOfBirth || ''}/${monthOfBirth || ''}/${dayOfBirth || ''}`
          const idExpireDate = `${yearOfExpiry || ''}/${monthOfExpiry || ''}/${dayOfExpiry || ''}`

          // Check if the library didn't recognize the first and last name,
          // but answred with the full name
          if (!tmpFirstName && !tmpLastName && tmpFullName) {
            const splittedName = fullName.split(',')
            if (splittedName.length === 2) {
              tmpFirstName = splittedName[1]
              tmpLastName = splittedName[0]
            }
          }

          setCorporationRepresentativeFirstName(tmpFirstName)
          setCorporationRepresentativeLastName(tmpLastName)

          let userObject = {
            firstName: tmpFirstName,
            lastName: tmpLastName,
            address: `${address1} ${address2}`,
            chosenPhotoIdMethod: formStatus,
            projects: appProject,
            province,
            country,
            postalCode: postcode,
            idNumber,
            idType,
            birthday,
            idExpireDate
          }

          if (photoToSet === 'front') {
            userObject = Object.assign({}, userObject, { frontPhotoId: imageUrl })
          }

          setExternalUserObject(userObject)
        }

        const urlArray = Array.from({ length: 1 }, () => ({ url: imageUrl }))

        sourceSeter(urlArray)
        loadingSeter(false)
        resolve(imageUrl)
      })
      .catch((e) => {
        message.warn('We couldn\'t read your photo. Please, check if you have enough light or the ID is readable. We also may have some difficults to read ID\'s on screens.', 5)
        sourceSeter('')
        loadingSeter(false)
        reject(e)
      })
  }), [formStatus, photoToSet, appProject])

  const Buyers = () => (
    <div className='flex flex-col w-full h-full'>
      <CreateBuyerButtons className='grid grid-cols-3 w-full gap-3 px-3'>
        <BuyerComponent
          buyerInfos={{
            _id: '0',
            firstName: 'Create new',
            lastName: 'buyer',
            buyerData: { salesRep: {} }
          }}
          userObject={userObject}
          selectBuyer={selectBuyer}
          setFormStatus={setFormStatus}
          buyerIsA={buyerIsA}
          confirmationMessage={confirmationMessage}
          projectId={appProject}
          setShowLinkUserModal={(param) => setShowLinkUserModal(param)}
          appProject={appProject}
        />
        <BuyerComponent
          buyerInfos={{
            _id: '-2',
            firstName: 'Create new',
            lastName: 'corporation',
            buyerData: { salesRep: {} }
          }}
          userObject={userObject}
          selectBuyer={selectBuyer}
          setFormStatus={setFormStatus}
          buyerIsA={buyerIsA}
          confirmationMessage={confirmationMessage}
          projectId={appProject}
          setShowLinkUserModal={(param) => setShowLinkUserModal(param)}
          appProject={appProject}
        />
        <BuyerComponent
          buyerInfos={{
            _id: '-1',
            firstName: 'Link an existing',
            lastName: 'buyer to me',
            buyerData: { salesRep: {} }
          }}
          userObject={userObject}
          selectBuyer={selectBuyer}
          setFormStatus={setFormStatus}
          buyerIsA={buyerIsA}
          confirmationMessage={confirmationMessage}
          projectId={appProject}
          setShowLinkUserModal={(param) => setShowLinkUserModal(param)}
          selectedMultiBuyerIndex={selectedMultiBuyerIndex}
          appProject={appProject}
        />
      </CreateBuyerButtons>
      <BuyersListWrapper className='grid grid-cols-3 w-full overflow-y-auto gap-6 px-6 py-10'>
        {
          buyers.map((buyer, index) => {
            return (
              <BuyerComponent
                buyerInfos={buyer}
                key={index}
                userObject={userObject}
                selectBuyer={selectBuyer}
                setFormStatus={setFormStatus}
                buyerIsA={buyerIsA}
                confirmationMessage={confirmationMessage}
                projectId={appProject}
                setShowLinkUserModal={(param) => setShowLinkUserModal(param)}
                selectedMultiBuyerIndex={selectedMultiBuyerIndex}
                appProject={appProject}
                isBuyer
              />
            )
          }
          )
        }
      </BuyersListWrapper>
    </div>
  )

  const Method = useCallback(({ title, animation, onClick, children, selected }) => (
    <MethodWrapper
      onClick={onClick}
      animate={{ scale: selected ? 1.10 : 1 }}
      transition={{ duration: 0.15 }}
      className='flex flex-1 flex-col items-center relative overflow-hidden text-center justify-center h-full rounded-md shadow-lg hover:cursor-pointer'
    >
      <LottieWrapper
        animation={animation}
        loop={false}
        autoplay
        className='w-2/5'
      />

      <span className='text-base'>{title}</span>
      {children}
    </MethodWrapper>
  ), [])

  const setPhoto = useCallback((photo) => {
    if (photoToSet === 'front') {
      setFrontPhotoIdFile(photo)
      _photoIDOCR(photo, true, setFrontPhotoId, setUploadingFrontPhotoId, 1)
        .then(() => {
          setFormStatus(UPLOAD_RESULT)
        })
        .catch(() => {

        })
    } else {
      setBackPhotoIdFile(photo)
      _photoIDOCR(photo, false, setBackPhotoId, setUploadingBackPhotoId, 2)
        .then(() => {
          setFormStatus(UPLOAD_RESULT)
        })
        .catch(() => {

        })
    }

    setCameraOpened(false)
  }, [photoToSet, _photoIDOCR])

  const FrontSidePhotoIdCameraButton = useCallback(() => (
    <CameraButtonWrapper
      key='frontSidePhotoIdCameraButton'
      initial='buttonExit'
      animate='buttonEnter'
      exit='buttonExit'
      variants={cameraButtonsAnimationValues}
      transition={{ duration: 0.2 }}
      custom='left'
    >
      <CameraButton
        photo={frontPhotoId}
        onClick={() => {
          setPhotoToSet('front')
          setCameraOpened(true)
        }}
        onRemovePhoto={() => {
          setFrontPhotoId([])
          setFrontPhotoIdFile('')
        }}
        title='Add Front Side Photo ID'
        loading={uploadingFrontPhotoId}
      />
    </CameraButtonWrapper>

    // <div className='flex flex-col' style={style}>
    //   Front Photo ID
    //   <CameraButton
    //     key='frontSidePhotoIdCameraButton'
    //     initial={animate ? (frontPhotoIdUploadError ? 'uploadError' : 'buttonExit') : 'notAnimate'}
    //     animate={animate ? (frontPhotoIdUploadError ? 'uploadError' : 'buttonEnter') : 'notAnimate'}
    //     exit={animate ? 'buttonExit' : 'notAnimate'}
    //     variants={cameraButtonsAnimationValues}
    //     transition={{ duration: 0.2 }}
    //     custom='left'
    //     // style={style}
    //     onClick={() => {
    //       setPhotoToSet('front')
    //       setCameraOpened(true)
    //     }}
    //   >
    //     <UploadPhotoIdButton
    //       uploadingPhoto={uploadingFrontPhotoId}
    //       buttonText='Add Front Side Photo ID'
    //     />
    //   </CameraButton>
    // </div>
  ), [frontPhotoId, uploadingFrontPhotoId])

  const BackSidePhotoIdCameraButton = useCallback(({ animate, style = {} }) => (
    <CameraButtonWrapper
      key='backSidePhotoIdCameraButton'
      initial='buttonExit'
      animate='buttonEnter'
      exit='buttonExit'
      variants={cameraButtonsAnimationValues}
      transition={{ duration: 0.2 }}
      custom='right'
    >
      <CameraButton
        photo={backPhotoId}
        onClick={() => {
          setPhotoToSet('back')
          setCameraOpened(true)
        }}
        onRemovePhoto={() => {
          setBackPhotoId([])
          setFrontPhotoIdFile('')
        }}
        title='Add Back Side Photo ID'
        loading={uploadingBackPhotoId}
      />
    </CameraButtonWrapper>
  ), [backPhotoId, uploadingBackPhotoId])

  const clearUser = () => {
    if (selectedUnit) {
      const { status } = selectedUnit

      if (status === 'ReservedBuyer') {
        message.warning('You can\'t change the Buyer of a Reserved Unit')
        return
      }
    }

    if (offerHasMultiBuyers) {
      selectBuyer({ _id: -1 })
    } else {
      createOfferChangeBuyer([{ _id: -1 }])
    }

    setEditingUserId('')
    setFrontPhotoId([])
    setBackPhotoId([])
    setSelectedMethodOfCreation('')
    setChoosingPhotoIdMethod('')

    setCorporationName('')
    setCorporationAddress1('')
    setCorporationAddress2('')
    setCorporationCity('')
    setCorporationProvinceState('')
    setCorporationCountry('')
    setCorporationPostalCode('')
    setCorporationRepresentativeFirstName('')
    setCorporationRepresentativeLastName('')
    setCorporationRepresentativePhoneNumber('')
    setCorporationRepresentativeEmail('')
    setCorporationDocument([])
    setUploadingCorporationDocument(false)
    setBindingDocument([])
    setUploadingBindingDoc(false)
    setFrontPhotoId([])
    setFrontPhotoIdFile('')
    setBackPhotoIdFile('')
    setBackPhotoId([])
  }

  // const getSalesRepName = (userData) => {
  //   const {
  //     buyerData: {
  //       assignments
  //     }
  //   } = userData

  //   let spFirstName = ''
  //   let spLastName = ''

  //   if (assignments) {
  //     const { firstName, lastName } = assignments[0].salesRep
  //     spFirstName = firstName
  //     spLastName = lastName
  //   }
  // }

  const saveNewUser = useCallback((validateKey, userData) => {
    const {
      params
    } = userData

    const _frontPhotoId = frontPhotoIdFile || frontPhotoId
    const _backPhotoId = backPhotoIdFile || backPhotoId

    if (
      (
        (isArray(_frontPhotoId) && !_frontPhotoId.length) ||
        (isArray(_backPhotoId) && !_backPhotoId.length)
      ) ||
      (
        (!isArray(_frontPhotoId) && typeof _frontPhotoId.name !== 'string') ||
        (!isArray(_backPhotoId) && typeof _backPhotoId.name !== 'string')
      )
    ) {
      message.warning(`You need to attach the front and back
      images of the Buyer's ID`)
      return
    }

    validateRequiredInputs(validateKey)
      .then(async () => {
        setSavingUser(true)

        const createdUser = await createOrUpdateUser(params)
          .catch(() => {
            setSavingUser(false)
          })

        // If is creating/editing a buyer
        if (createdUser && createdUser._id && params.buyerData) {
          const {
            _id, firstName, lastName, userAvatar, email,
            buyerData: {
              assignments,
              allowSurveys,
              allowAdditionalServices
            }
          } = createdUser

          let spFirstName = ''
          let spLastName = ''

          if (assignments && assignments.length) {
            const { firstName, lastName } = assignments[0].salesRep
            spFirstName = firstName
            spLastName = lastName
          }

          // If is creating a new buyer, upload the images with the new id
          let imageParams = { userId: _id }

          if (isArray(_frontPhotoId) ||
              isObject(_frontPhotoId)
          ) {
            imageParams = Object.assign({}, imageParams,
              {
                frontPhotoId: isArray(_frontPhotoId)
                  ? _frontPhotoId[0]
                  : _frontPhotoId
              })
          }

          if (isArray(_backPhotoId) ||
              isObject(_backPhotoId)
          ) {
            imageParams = Object.assign({}, imageParams,
              {
                backPhotoId: isArray(_backPhotoId)
                  ? _backPhotoId[0]
                  : _backPhotoId
              })
          }

          // if is a new user or is editing a corporation buyer
          if ((!editingUserId || corporationId) && Object.keys(imageParams).length > 1) {
            message.loading('Uploading Photo ID...')
            await changeUserImages(imageParams, false)
          }

          setSavingUser(false)
          selectBuyer({
            _id,
            firstName,
            lastName,
            email,
            salesRepName: `${spFirstName} ${spLastName}`,
            buyerData: {
              frontPhotoId,
              backPhotoId,
              assignments,
              allowSurveys,
              allowAdditionalServices
            },
            userAvatar
          })

          if (offerHasMultiBuyers) {
            _getBuyers(false)
          }
        } else setSavingUser(false)
      })
  }, [
    _getBuyers, backPhotoId, backPhotoIdFile, editingUserId, frontPhotoId, frontPhotoIdFile,
    offerHasMultiBuyers, selectBuyer, corporationId
  ])

  const linkBuyerToSalesRep = useCallback(() => {
    validateRequiredInputs('createBuyerLink')
      .then(() => {
        setLinkingBuyerToSalesRep(true)

        const params = {
          project: appProject,
          buyer: buyerEmailToLink
        }

        createAssignment(params)
          .then((user) => {
            const {
              _id, firstName, lastName, email,
              buyerData: {
                assignments,
                frontPhotoId,
                backPhotoId,
                allowSurveys,
                allowAdditionalServices
              }
            } = user

            let spFirstName = ''
            let spLastName = ''

            if (assignments && assignments.length) {
              const currentProjectAssignment = assignments.filter((assignments) => assignments.project === appProject)

              const { firstName, lastName } = currentProjectAssignment[0].salesRep
              spFirstName = firstName
              spLastName = lastName
            }

            selectBuyer({
              _id,
              firstName,
              lastName,
              email,
              salesRepName: `${spFirstName} ${spLastName}`,
              buyerData: {
                frontPhotoId,
                backPhotoId,
                assignments,
                allowSurveys,
                allowAdditionalServices
              }
            })

            setShowLinkUserModal(false)
            setBuyerEmailToLink('')
            setLinkingBuyerToSalesRep(false)
          })
          .catch(() => setLinkingBuyerToSalesRep(false))
      })
  }, [buyerEmailToLink, appProject, selectBuyer])

  const removeMultiBuyer = useCallback((e, firstName, lastName, _id, index) => {
    e.stopPropagation()

    // If there isn't a buyer selected at the position, just remove the element
    if (_id === -1) {
      executeOnRemoveBuyerFromOffer(index)
    } else {
      // Else, ask to confirm the buyer remove
      setBuyerNameBeingRemoved(`${firstName} ${lastName}`)
      setShowRemoveMultiBuyerModal(true)
    }
  }, [executeOnRemoveBuyerFromOffer])

  const renderMultiBuyers = useCallback(() => (
    <div className='flex flex-row w-full overflow-x-auto items-center'>
      <MultiBuyerItem
        index='newBuyerButton'
        firstName='Add new buyer'
        lastName={`to the ${buyerIsA === 'Buyer' ? 'offer' : 'reservation'}`}
        className='sticky left-0 z-10 py-5 pr-4 mr-1'
        onClick={() => executeOnAddNewBuyerToOffer()}
      />
      {
        selectedBuyers.map((buyer, index, pureArray) => {
          const { _id, firstName, lastName, userAvatar } = buyer
          return (
              <MultiBuyerItem
                key={index}
                index={index}
                _id={_id}
                firstName={firstName}
                lastName={lastName}
                userAvatar={userAvatar}
                selectedMultiBuyerIndex={selectedMultiBuyerIndex}
                pureArray={pureArray}
                className='mr-5 my-5'
                onClick={() => setSelectedMultiBuyerIndex(index)}
                onRemoveBuyer={(e) => removeMultiBuyer(e, firstName, lastName, _id, index)}
              />
          )
        })
      }
    </div>
  ), [selectedBuyers, selectedMultiBuyerIndex, executeOnAddNewBuyerToOffer, removeMultiBuyer, buyerIsA])

  const renderMultiBuyerOption = useCallback(() => (
    <div className='sticky top-0 z-10 w-full'>
      <MultiBuyerSwitchWrapper className={`flex flex-row w-full bg-white justify-end ${offerHasMultiBuyers ? 'pt-4' : 'py-4'}`}>
        <TSwitch
          checked={offerHasMultiBuyers}
          onClick={() => onChangeMultiBuyers() }
          title={`This ${buyerIsA === 'Buyer' ? 'offer' : 'reservation'} has multi buyers`}
        />
      </MultiBuyerSwitchWrapper>

      <AnimatePresence exitBeforeEnter initial={false}>
        {offerHasMultiBuyers && (
          <motion.div
            key='multiBuyers'
            className='w-full flex flex-row items-center'
            initial={{
              opacity: 0,
              y: -30
            }}
            animate={{
              opacity: 1,
              y: 0
            }}
            exit={{
              opacity: 0,
              y: -30
            }}
            transition={{
              y: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.3 }
            }}
          >
            {renderMultiBuyers()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  ), [offerHasMultiBuyers, renderMultiBuyers, onChangeMultiBuyers, buyerIsA])

  const renderRemoveMultiBuyerModal = useCallback(() => (
    <Modal
      modalType='danger'
      showModal={showRemoveMultiBuyerModal}
      onCancel={() => {
        setShowRemoveMultiBuyerModal(false)
        setTimeout(() => {
          setBuyerNameBeingRemoved('')
        }, 300)
      }}
      title='Confirm removing buyer from this offer?'
      subtitle={`${buyerNameBeingRemoved} will be removed from the offer`}
      onOk={() => {
        setShowRemoveMultiBuyerModal(false)
        executeOnRemoveBuyerFromOffer(selectedMultiBuyerIndex)
      }}
    />
  ), [buyerNameBeingRemoved, showRemoveMultiBuyerModal, selectedMultiBuyerIndex, executeOnRemoveBuyerFromOffer])

  const createCorporateBuyer = useCallback(() => {
    const errors = []
    let buyerId = ''

    if (isArray(selectedBuyers)) {
      const filteredBuyers = selectedBuyers
        .filter(buyer => buyer._id !== -1)

      if (filteredBuyers.length) {
        buyerId = filteredBuyers[0]._id
      }
    }

    if (!corporationDocument.length) {
      errors.push('You must attach the Corporation Document!')
    }

    if (!bindingDocument.length) {
      errors.push('You must attach the Rep Binding Document!')
    }

    if (!frontPhotoIdFile && !frontPhotoId) {
      errors.push('You must upload the Front Photo ID!')
    }

    if (!backPhotoIdFile && !backPhotoId) {
      errors.push('You must upload the Back Photo ID!')
    }

    if (errors.length) {
      errors.map(error => message.warn(error))
      return
    }

    validateRequiredInputs('corporateBuyer')
      .then(async () => {
        setSavingUser(true)

        const corporationParams = {
          _id: corporationId,
          corporationName,
          corporationType: 'LimitedPartnership',
          addressLine1: corporationAddress1,
          addressLine2: corporationAddress2,
          city: corporationCity,
          province: corporationProvinceState,
          country: corporationCountry,
          postalCode: corporationPostalCode
        }

        try {
          const corporationData = await createOrUpdateCorporation(corporationParams)

          if (corporationData && corporationData._id) {
            setCorporationId(corporationData._id)

            // Just need to upload file if new version were attached
            if (isFile(corporationDocument[0])) {
              await uploadPrivateDocument(corporationDocument[0], '', corporationData._id)
            }

            if (isFile(bindingDocument[0])) {
              await uploadPrivateDocument(bindingDocument[0], '', corporationData._id)
            }

            let params = {
              id: buyerId,
              email: corporationRepresentativeEmail,
              firstName: corporationRepresentativeFirstName,
              lastName: corporationRepresentativeLastName,
              userType: 'Buyer',
              project: appProject,
              buyerData: {
                corporations: [corporationData._id],
                phoneNumber: corporationRepresentativePhoneNumber
              }
            }

            if (buyerId) {
              // if is editing an user, get the user data before update
              let allBuyerInfos = ''

              allBuyerInfos = await getUsers(buyerId)

              if (allBuyerInfos && isObject(allBuyerInfos) && Object.keys(allBuyerInfos).length) {
                const newUserParams = Object.assign({}, { ...allBuyerInfos }, params)

                newUserParams.buyerData = Object.assign({}, { ...allBuyerInfos.buyerData }, params.buyerData)

                params = newUserParams
              }
            }

            saveNewUser('corporateBuyer', { params })
          }

          setSavingUser(false)
        } catch (e) {
          setSavingUser(false)
        }
      })
  }, [
    corporationName, corporationAddress1, corporationAddress2, corporationCity,
    corporationCountry, corporationPostalCode, corporationProvinceState,
    corporationRepresentativeEmail, corporationRepresentativeFirstName,
    corporationRepresentativeLastName, appProject,
    corporationRepresentativePhoneNumber, corporationDocument, bindingDocument,
    selectedBuyers, frontPhotoIdFile, saveNewUser, backPhotoIdFile, corporationId,
    frontPhotoId, backPhotoId
  ])

  return (
    <PageWrapper className='h-full flex items-center justify-center w-full'>
      {renderRemoveMultiBuyerModal()}
      <Modal
        showModal={showLinkUserModal}
        onCancel={() => {
          setBuyerEmailToLink('')
          setShowLinkUserModal(false)
        }}
        modalType='custom'
        okText='Link this buyer to me'
        onOk={linkBuyerToSalesRep}
        okLoading={linkingBuyerToSalesRep}
      >
        <div className='flex flex-col'>
          <span className='text-xl mb-5'>Fill below with the Buyer's email, then we will search and link the buyer to your account</span>
          <InputLabel
            id='buyerEmailToLink'
            name='buyerEmailToLink'
            label="Buyer's email"
            value={buyerEmailToLink}
            onChange={(e) => setBuyerEmailToLink(e.target.value)}
            required
            validateKey='createBuyerLink'
          />
        </div>
      </Modal>
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          formStatus === LOADING_BUYERS &&
          (
            <CustomFadedWrapper key='loadingBuyers'>
              <LoadingWrapper className='w-full'>
                <H2 className='text-2xl text-center'>Getting Buyers...</H2>
                <LoadingIcon style={{ fontSize: 35 }} />
              </LoadingWrapper>
            </CustomFadedWrapper>
          )
        }

        {
          formStatus === SHOWING_BUYERS &&
            (
              <FadedAnimatedDiv
                key='tableFilterWrapper'
                className='w-full h-full py-11'
              >
                <div className='px-3 flex flex-col items-center justify-center'>
                  <H2 className='text-2xl text-center'>{`Select ${offerHasMultiBuyers ? 'Buyers' : 'Buyer'}`}</H2>
                  <TableFilter
                    dataToFilter={originalBuyers}
                    setData={setBuyers}
                    label=''
                    inputWrapperClassName='mx-28'
                    placeHolder='You can filter the buyers below by spacing words'
                    loading={formStatus === LOADING_BUYERS}
                  />
                  {renderMultiBuyerOption()}
                </div>
                <Buyers />
              </FadedAnimatedDiv>
            )
        }

        {
          formStatus === CREATING_BUYER_SELECT_METHOD &&
          (
            <CustomFadedWrapper key='newBuyerSelectMethod' className='mx-7'>
              <TitlesWrapper style={{ height: '15%' }}>
                <H2 className='text-2xl text-center'>{offerId ? 'Selected Buyer' : 'New Buyer'}</H2>
                <span className='text-xl'>Choose the method you want to use to create the buyer</span>
              </TitlesWrapper>

              <MethodsWrapper className='space-x-7 my-10'>
                <Method
                  title='Add Info From Photo ID Scanning'
                  animation={scanIdAnimation}
                  onClick={() => {
                    setSelectedMethodOfCreation('')
                    setChoosingPhotoIdMethod(true)
                  }}
                  selected={choosingPhotoIdMethod}
                >
                  <AnimatePresence initial={false}>
                    {
                      choosingPhotoIdMethod &&
                        <ImageUploadMethodsButtons
                          style={{ position: 'absolute' }}
                          animate
                          onCameraMethodClick={() => {
                            setSelectedMethodOfCreation(GET_PHOTO_ID_FROM_CAMERA)
                          }}
                          onLibraryMethodClick={() => {
                            setSelectedMethodOfCreation(GET_PHOTO_ID_FROM_LIBRARY)
                          }}
                          cameraSelected={selectedMethodOfCreation === GET_PHOTO_ID_FROM_CAMERA}
                          librarySelected={selectedMethodOfCreation === GET_PHOTO_ID_FROM_LIBRARY}
                          wrapperClassName='absolute'
                        />
                    }
                  </AnimatePresence>
                </Method>
                <Method
                  title='Send to Customer'
                  animation={sendToBuyerAnimation}
                  onClick={() => {
                    setChoosingPhotoIdMethod(false)
                    setSelectedMethodOfCreation(SEND_TO_CUSTOMER)
                  }}
                  selected={selectedMethodOfCreation === SEND_TO_CUSTOMER}
                  // style={{ marginTop: -40 }}
                />
                <Method
                  title='Type Manually'
                  animation={keyboardAnimation}
                  onClick={() => {
                    setChoosingPhotoIdMethod(false)
                    setSelectedMethodOfCreation(TYPE_MANUALLY)
                  }}
                  selected={selectedMethodOfCreation === TYPE_MANUALLY}
                />
              </MethodsWrapper>

              <OfferFooterButtons
                backButtonClick={() => {
                  setChoosingPhotoIdMethod(false)
                  setFormStatus(SHOWING_BUYERS)
                }}
                continueButtonClick={() => {
                  setFormStatus(selectedMethodOfCreation)
                }}
                continueButtonDisabled={!selectedMethodOfCreation}
              />
            </CustomFadedWrapper>
          )
        }

        {
          formStatus === GET_PHOTO_ID_FROM_LIBRARY &&
          (
            <CustomFadedWrapper key='newBuyerByPhotoId'>
              <TitlesWrapper>
                <H2 className='text-2xl text-center'>{offerId ? 'Selected Buyer' : 'New Buyer'}</H2>
                <span className='text-xl'>Select the buyer's Photo ID</span>
              </TitlesWrapper>

              <BuyerPhotoIdWrapper className='space-x-10'>
                <FileSelector
                  title='Front Photo ID'
                  listOfFiles={frontPhotoId}
                  loading={uploadingFrontPhotoId}
                  onRemoveFile={({ fileList }) => {
                    if (!fileList.length) {
                      setFrontPhotoIdFile('')
                      setFrontPhotoId([])
                    }
                  }}
                  onSelectFile={(fileList) => {
                    if (fileList) {
                      const file = fileList[0]

                      setFrontPhotoIdFile(file)

                      _photoIDOCR(file, true, setFrontPhotoId, setUploadingFrontPhotoId, 3)
                      // _photoIDOCR(file, true, executeOnTest, setUploadingFrontPhotoId, 3)
                        .then(() => {
                          setFormStatus(UPLOAD_RESULT)
                        })
                    }
                  }}
                />

                <FileSelector
                  title='Back Photo ID'
                  listOfFiles={backPhotoId}
                  loading={uploadingBackPhotoId}
                  onRemoveFile={({ fileList }) => {
                    if (!fileList.length) {
                      setBackPhotoIdFile('')
                      setBackPhotoId([])
                    }
                  }}
                  onSelectFile={(fileList) => {
                    if (fileList && fileList.length) {
                      setBackPhotoId(fileList)
                      setBackPhotoIdFile(fileList[0])
                    }
                  }}
                />
              </BuyerPhotoIdWrapper>

              <OfferFooterButtons
                backButtonClick={() => {
                  setFrontPhotoId('')
                  setBackPhotoId('')
                  setFormStatus(CREATING_BUYER_SELECT_METHOD)
                }}
                continueButtonClick={() => setFormStatus(UPLOAD_RESULT)}
                continueButtonDisabled={!frontPhotoId || !backPhotoId}
              />
            </CustomFadedWrapper>
          )
        }

        {
          formStatus === GET_PHOTO_ID_FROM_CAMERA &&
          (
            <CustomFadedWrapper key='getIdPhotoFromCamera'>
              <TitlesWrapper>
                <H2 className='text-2xl text-center'>{offerId ? 'Selected Buyer' : 'New Buyer'}</H2>
                <span className='text-xl'>Take photo of the buyer's Photo ID</span>
              </TitlesWrapper>

              <BuyerPhotoIdWrapper>
                <AnimatePresence exitBeforeEnter initial={false}>
                  {!cameraOpened && (
                    <div className='flex flex-row space-x-10'>
                      <FrontSidePhotoIdCameraButton />
                      <BackSidePhotoIdCameraButton />
                    </div>

                  )}

                  {
                    cameraOpened && (
                      <div className='max-h-96 flex'>
                        <CustomCamera
                          ref={webcamRef}
                          key='firstCamera'
                          exitAnimation='cameraExitY'
                          enterAnimation='cameraEnterY'
                          setPhoto={setPhoto}
                          setCameraOpened={setCameraOpened}
                        />
                      </div>
                    )
                  }
                </AnimatePresence>
              </BuyerPhotoIdWrapper>

              <OfferFooterButtons
                backButtonClick={() => {
                  setCameraOpened(false)
                  setFrontPhotoId('')
                  setBackPhotoId('')
                  setFormStatus(CREATING_BUYER_SELECT_METHOD)
                }}
                continueButtonClick={() => setFormStatus(UPLOAD_RESULT)}
                continueButtonDisabled={!frontPhotoId || !backPhotoId}
              />
            </CustomFadedWrapper>
          )
        }

        {
          (formStatus === UPLOAD_RESULT ||
          formStatus === TYPE_MANUALLY) &&
          (
            <CustomFadedWrapper key='uploadResult'>
              <TitlesWrapper style={{ height: '10%' }}>
                <H2 className='text-2xl text-center'>{offerId ? 'Selected Buyer' : 'New Buyer'}</H2>
              </TitlesWrapper>

              <BuyerMainWrapper>
                <BuyerInfosWrapper span={24}>
                  <AnimatePresence exitBeforeEnter initial={false}>
                    {
                      !cameraOpened && (
                        <BuyerInfosAnimatedWrapper
                          key='buyerInfosAnimatedWrapper'
                          initial='buttonExit'
                          animate='buttonEnter'
                          exit='buttonExit'
                          variants={cameraButtonsAnimationValues}
                          custom='right'
                          transition={{
                            x: { type: 'spring', stiffness: 300, damping: 30 }
                            // opacity: { duration: 0.2 }
                          }}
                        >
                          {renderMultiBuyerOption()}
                          <CreateUser
                            ref={createUserInstance}
                            id={editingUserId}
                            onSaveClick={() => {}}
                            onCancelClick={() => {}}
                            saving={savingUser}
                            userType='Buyer'
                            showPaymentTokens={false}
                            showFooterButtons={false}
                            loggedUserType={userObject.userType}
                            externalObject={externalUserObject}
                            onChangeFrontPhotoIdFile={(file) => setFrontPhotoIdFile(file)}
                            onChangeBackPhotoIdFile={(file) => setBackPhotoIdFile(file)}
                          />
                        </BuyerInfosAnimatedWrapper>
                      )
                    }

                    {
                      cameraOpened && (
                        <CustomCamera
                          ref={webcamRef}
                          key='secondCamera'
                          exitAnimation='cameraExitX'
                          enterAnimation='cameraEnterX'
                          setPhoto={setPhoto}
                          setCameraOpened={setCameraOpened}
                        />
                      )
                    }

                  </AnimatePresence>
                </BuyerInfosWrapper>
              </BuyerMainWrapper>

              <OfferFooterButtons
                backButtonText={Object.keys(selectedBuyers).length ? 'Clear Buyer' : 'Back'}
                backButtonClick={() => {
                  if (Object.keys(selectedBuyers).length) {
                    clearUser()
                  } else {
                    setCameraOpened(false)
                    setFormStatus(CREATING_BUYER_SELECT_METHOD)
                  }
                }}
                backButtonDisabled={offerId}
                continueButtonDisabled={offerId}
                continueButtonClick={() => saveNewUser('createUser', createUserInstance.current.getUserData())}
                continueButtonText='Save Buyer'
                continueButtonLoading={savingUser}
              />
            </CustomFadedWrapper>
          )
        }

        {
          formStatus === CREATING_EDITING_CORPORATE_BUYER &&
          (
            <CustomFadedWrapper key='creatingEditingCorporateBuyer'>
              <TitlesWrapper>
                <H2 className='text-2xl text-center'>{offerId ? 'Selected Corporate Buyer' : 'New Corporate Buyer'}</H2>
                <span className='text-xl'>Fill below the infos of the Corporation</span>
              </TitlesWrapper>

              <CorporateBuyerWrapper className='flex flex-col w-full h-13/20 px-3 overflow-y-auto'>
                <CorporationWrapper className='flex flex-row w-full'>
                  <CorporationDocumentWrapper className='flex flex-col min-w-sm justify-center px-10'>
                    <FileSelector
                      title='Add Corporation Document*'
                      fileType='File'
                      accept='.pdf'
                      listOfFiles={corporationDocument}
                      loading={uploadingCorporationDocument}
                      onRemoveFile={async ({ fileList, removedFile }) => {
                        // If is editing an user and clicked to remove an already updated file, delete this private doc
                        if (editingUserId && corporationId && removedFile._id) {
                          await deletePrivateDocs(removedFile._id)
                        }

                        if (!fileList.length) {
                          setCorporationDocument([])
                        }
                      }}
                      onSelectFile={(fileList) => {
                        if (fileList && fileList.length) {
                          setCorporationDocument(fileList)
                        }
                      }}
                    />
                    *Only PDF documents
                  </CorporationDocumentWrapper>

                  <CorporationInfosWrapper className='grid grid-cols-12 gap-3 w-full'>
                    <InputLabel
                      key='corporationName'
                      id='corporationName'
                      label='Corporation Name'
                      placeholder='Corporation Name'
                      inputWrapperClassName='col-span-12'
                      value={corporationName}
                      onChange={(e) => setCorporationName(e.target.value)}
                      required
                      validateKey='corporateBuyer'
                    />
                    <InputLabel
                      key='corporationAddress1'
                      id='corporationAddress1'
                      label='Address Line 1'
                      placeholder='Address Line 1'
                      inputWrapperClassName='col-span-6'
                      value={corporationAddress1}
                      onChange={(e) => setCorporationAddress1(e.target.value)}
                    />
                    <InputLabel
                      key='corporationAddress2'
                      id='corporationAddress2'
                      label='Address Line 2'
                      placeholder='Address Line 2'
                      inputWrapperClassName='col-span-6'
                      value={corporationAddress2}
                      onChange={(e) => setCorporationAddress2(e.target.value)}
                    />
                    <InputLabel
                      key='corporationCity'
                      id='corporationCity'
                      label='City'
                      placeholder='City'
                      inputWrapperClassName='col-span-6'
                      value={corporationCity}
                      onChange={(e) => setCorporationCity(e.target.value)}
                    />
                    <InputLabel
                      key='corporationProvinceState'
                      id='corporationProvinceState'
                      label='Province / State'
                      placeholder='Province / State'
                      inputWrapperClassName='col-span-6'
                      value={corporationProvinceState}
                      onChange={(e) => setCorporationProvinceState(e.target.value)}
                    />
                    <InputLabel
                      key='corporationCountry'
                      id='corporationCountry'
                      label='Country'
                      placeholder='Country'
                      inputWrapperClassName='col-span-6'
                      value={corporationCountry}
                      onChange={(e) => setCorporationCountry(e.target.value)}
                    />
                    <InputLabel
                      key='corporationPostalCode'
                      id='corporationPostalCode'
                      label='Postal Code'
                      placeholder='Postal Code'
                      inputWrapperClassName='col-span-6'
                      value={corporationPostalCode}
                      onChange={(e) => setCorporationPostalCode(e.target.value)}
                    />
                  </CorporationInfosWrapper>
                </CorporationWrapper>

                <span className='mb-3 mt-6 font-bold text-lg'>Representative information</span>
                <RepresentativeWrapper className='flex flex-row w-full'>
                  <RepresentativeDocumentsWrapper className='flex flex-col min-w-sm px-10 space-y-5'>
                    <FileSelector
                      title='Front Photo ID'
                      listOfFiles={frontPhotoId}
                      loading={uploadingFrontPhotoId}
                      onRemoveFile={({ fileList }) => {
                        if (!fileList.length) {
                          setFrontPhotoIdFile('')
                          setFrontPhotoId([])
                        }
                      }}
                      onSelectFile={(fileList) => {
                        if (fileList && fileList.length) {
                          const file = fileList[0]

                          setFrontPhotoIdFile(file)

                          _photoIDOCR(file, true, setFrontPhotoId, setUploadingFrontPhotoId, 5)
                            .catch((e) => console.log('photoIdOCR error', e))
                        }
                      }}
                    />

                    <FileSelector
                      title='Back Photo ID'
                      listOfFiles={backPhotoId}
                      loading={uploadingBackPhotoId}
                      onRemoveFile={({ fileList }) => {
                        if (!fileList.length) {
                          setBackPhotoIdFile('')
                          setBackPhotoId([])
                        }
                      }}
                      onSelectFile={(fileList) => {
                        if (fileList && fileList.length) {
                          setBackPhotoId(fileList)
                          setBackPhotoIdFile(fileList[0])
                        }
                      }}
                    />

                    <FileSelector
                      title='Rep Binding Doc*'
                      fileType='File'
                      accept='.pdf'
                      listOfFiles={bindingDocument}
                      loading={uploadingBindingDoc}
                      onRemoveFile={async ({ fileList, removedFile }) => {
                        // If is editing an user and clicked to remove an already updated file, delete this private doc
                        if (editingUserId && corporationId && removedFile._id) {
                          await deletePrivateDocs(removedFile._id)
                        }

                        if (!fileList.length) {
                          setBindingDocument([])
                        }
                      }}
                      onSelectFile={(fileList) => {
                        if (fileList && fileList.length) {
                          setBindingDocument(fileList)
                        }
                      }}
                    />
                    *Only PDF documents
                  </RepresentativeDocumentsWrapper>

                  <RepresentativeInfosWrapper className='grid grid-cols-12 gap-3 w-full' style={{ height: 'fit-content' }}>
                    <InputLabel
                      key='representativeFirstName'
                      id='representativeFirstName'
                      label='First Name'
                      placeholder='First Name'
                      inputWrapperClassName='col-span-6'
                      value={corporationRepresentativeFirstName}
                      onChange={(e) => setCorporationRepresentativeFirstName(e.target.value)}
                      required
                      validateKey='corporateBuyer'
                    />
                    <InputLabel
                      key='representativeLastName'
                      id='representativeLastName'
                      label='Last Name'
                      placeholder='Last Name'
                      inputWrapperClassName='col-span-6'
                      value={corporationRepresentativeLastName}
                      onChange={(e) => setCorporationRepresentativeLastName(e.target.value)}
                      required
                      validateKey='corporateBuyer'
                    />
                    <InputLabel
                      key='representativePhoneNumber'
                      id='representativePhoneNumber'
                      label='Phone Number'
                      placeholder='Phone Number'
                      inputWrapperClassName='col-span-6'
                      value={corporationRepresentativePhoneNumber}
                      onChange={(e) => setCorporationRepresentativePhoneNumber(e.target.value)}
                      required
                      validateKey='corporateBuyer'
                    />
                    <InputLabel
                      key='representativeEmail'
                      id='representativeEmail'
                      label='Email'
                      placeholder='Email'
                      inputWrapperClassName='col-span-6'
                      value={corporationRepresentativeEmail}
                      onChange={(e) => setCorporationRepresentativeEmail(e.target.value)}
                      required
                      validateKey='corporateBuyer'
                      disabled={corporationId}
                    />
                  </RepresentativeInfosWrapper>
                </RepresentativeWrapper>
              </CorporateBuyerWrapper>

              <OfferFooterButtons
                backButtonClick={() => {
                  clearUser()
                  setFormStatus(SHOWING_BUYERS)
                }}
                continueButtonClick={createCorporateBuyer}
                continueButtonText='Save'
                continueButtonLoading={savingUser}
                // continueButtonDisabled={!frontPhotoId || !backPhotoId}
              />
            </CustomFadedWrapper>
          )
        }
      </AnimatePresence>
    </PageWrapper>
  )
}

const mapStateToProps = (state) => ({
  selectedUnit: state.createOfferReducer.selectedUnit,
  selectedBuyers: state.createOfferReducer.selectedBuyers,
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {
  createOfferChangeBuyer
}

export default connect(mapStateToProps, mapDispatchToProps)(Buyer)

Buyer.defaultProps = {
  buyerIsA: 'Buyer'
}

Buyer.propTypes = {
  buyerIsA: propTypes.oneOf(['Buyer', 'Reservation Requester']),
  confirmationMessage: propTypes.string,
  executeOnSelectBuyer: propTypes.func,
  selectedBuyers: propTypes.object,
  createOfferChangeBuyer: propTypes.func,
  userObject: propTypes.object,
  offerId: propTypes.string,
  selectedUnit: propTypes.object,
  appProject: propTypes.string,
  executeOnAddNewBuyerToOffer: propTypes.func,
  executeOnRemoveBuyerFromOffer: propTypes.func,
  offerHasMultiBuyers: propTypes.bool,
  onChangeMultiBuyers: propTypes.func
}

UploadPhotoIdButton.propTypes = {
  uploadingPhoto: propTypes.bool,
  buttonText: propTypes.string,
  buttonStyle: propTypes.object,
  onClick: propTypes.func,
  photo: propTypes.string
}

MessageComponent.propTypes = {
  children: propTypes.node
}

BuyerComponent.propTypes = {
  buyerInfos: propTypes.shape({
    _id: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    userAvatar: propTypes.string,
    email: propTypes.string,
    buyerData: propTypes.shape({
      salesRep: propTypes.string,
      frontPhotoId: propTypes.string,
      backPhotoId: propTypes.string,
      assignments: propTypes.array,
      allowSurveys: propTypes.bool,
      allowAdditionalServices: propTypes.bool
    })
  }),
  userObject: propTypes.object,
  selectBuyer: propTypes.func,
  setFormStatus: propTypes.func,
  buyerIsA: propTypes.object,
  confirmationMessage: propTypes.string,
  setShowLinkUserModal: propTypes.func,
  selectedMultiBuyerIndex: propTypes.number,
  appProject: propTypes.string
}

CustomCamera.propTypes = {
  key: propTypes.string,
  exitAnimation: propTypes.string,
  enterAnimation: propTypes.string,
  setPhoto: propTypes.func,
  setCameraOpened: propTypes.func
}

MultiBuyerItem.defaultProps = {
  className: ''
}

MultiBuyerItem.propTypes = {
  _id: propTypes.string,
  index: propTypes.string,
  firstName: propTypes.string,
  lastName: propTypes.string,
  userAvatar: propTypes.string,
  selectedMultiBuyerIndex: propTypes.string,
  pureArray: propTypes.array,
  className: propTypes.string,
  onClick: propTypes.func,
  onRemoveBuyer: propTypes.func
}
