/* eslint-disable */
import { Button } from '@material-ui/core'
import React from 'react'
import useContactContext from '../hooks/useContactContext'
import SearchBox from './SearchBox/SearchBox'
import FilterToggle from './FilterToggle/FilterToggle'
import { HiOutlineDownload } from 'react-icons/hi'
import UserTable from './UserTable/UserTable'
import ContactModal from './ContactModal/ContactModal'
import FilterDrawer from '../FilterDrawer/FilterDrawer'
import { useSelector } from 'react-redux'

const tableButtonStyle = {
  backgroundColor: '#E3E3E1',
  marginRight: '20px'
}

const ContactsTable = () => {
  const { state, openAddToListDrawer } = useContactContext()
  const show = useSelector((state) => state.appReducer.show)

  const buttonsList = [
    // { title: 'Change Status' },
    // { title: 'Assignments' },
    { title: 'Manage List', onClick: () => openAddToListDrawer() }
    // { title: 'Send Email' },
    // { title: 'Archive' }
  ]

  return (
    <div className='w-full'>
      <div className='flex'>
        <FilterDrawer />
        <div className={`flex flex-col p-10 w-full max-h-full overflow-auto ${state.isFilterDrawerOpen ? 'ml-[300px]' : 'ml-0'} transition-all`}>
          <div className='flex flex-row items-center gap-2'>
            <FilterToggle />
            <SearchBox />
            <div className='w-full '>
              {!state.isContactDetailDrawerOpen && !state.isAddToListDrawerOpen && buttonsList.map(({ title, onClick }, index) => <Button style={tableButtonStyle} key={index} onClick={onClick}><HiOutlineDownload /><div className='ml-2'>{title}</div></Button>)}
              <ContactModal />
            </div>
          </div>
          <UserTable />
        </div>
      </div>
    </div>
  )
}

export default ContactsTable
