// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import InventoryUnit from 'components/InventoryUnit'
import SelectButtonWrapper from './SelectWrapper'
import { ADMIN_OFFER } from 'routes'

import LargeUnitCardModal from 'components/LargeUnitCardModal'
import { useHistory } from 'react-router-dom'
import { SelectMultiple } from 'components'

import { isMobile } from 'react-device-detect'
import AddUnitModal from 'pages/desktopMode/buildings/Forms/AddUnitModal'
import useRealtimeUnitUpdate from 'hooks/useRealtimeUnitUpdate'
import { useSelector } from 'react-redux'

const STATUS_OPTIONS = [
  { label: 'Open', value: 'Open' },
  { label: 'Reserved', value: 'reserved' },
  { label: 'Sold', value: 'sold' },
  { label: 'Sold Committed', value: 'SoldCommitted' },
  { label: 'Reserved Broker', value: 'ReservedBroker' }
]

const CardView = ({
  units,
  gettingUnits,
  buildings,
  getBuildings,
  refetchUnits
}) => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  useRealtimeUnitUpdate(projectId)
  const history = useHistory()
  const [cards, setCards] = useState([...units])
  const [availability, setAvailability] = useState([])
  const [building, setBuilding] = useState([])
  const [search, setSearch] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [tempCardToOpen, setTempCardToOpen] = useState(null)
  const [showUnitEditModal, setShowUnitEditModal] = useState(false)
  useEffect(() => {
    if (units) {
      handleCards()
    }
  }, [units, availability, building, search])

  const handleCards = () => {
    let uCards = [...units]
    if (availability?.length > 0) {
      uCards = uCards.filter((_uCard) => availability.includes(_uCard.status))
    }
    if (building.length > 0) {
      uCards = uCards.filter((_uCard) =>
        building.includes(_uCard?.building._id)
      )
    }
    if (search.length > 0) {
      const uSearch = search.toLowerCase()
      uCards = uCards.filter((_uCard) =>
        _uCard?.unitNumber.toLowerCase().includes(uSearch)
      )
    }
    setCards([...uCards])
  }

  const updateUnit = useCallback(
    (newUnitData) => {
      const index = cards.findIndex((u) => u._id === newUnitData._id)
      const newCards = [...cards]
      newCards[index] = newUnitData
      setCards(newCards)
    },
    [cards]
  )

  return (
    <>
      <div>
        <div
          className={`grid ${
            isMobile ? 'grid-cols-1' : 'grid-cols-3 h-11'
          } gap-7 justify-around content-center items-center mt-8 mb-12`}
        >
          <SelectButtonWrapper>
            <div className='flex flex-row text-softBlack_70 w-full items-center'>
              <div className='w-5 h-5'>
                <svg
                  width='22'
                  height='22'
                  viewBox='0 0 22 22'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9.96875 17.1875C13.9556 17.1875 17.1875 13.9556 17.1875 9.96875C17.1875 5.98194 13.9556 2.75 9.96875 2.75C5.98194 2.75 2.75 5.98194 2.75 9.96875C2.75 13.9556 5.98194 17.1875 9.96875 17.1875Z'
                    stroke='#2E2B2E'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M15.0742 15.0732L19.2508 19.2499'
                    stroke='#2E2B2E'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
              <input
                type='text'
                placeholder='Search Unit'
                className='border-0 ring-0 rounded-none focus:ring-0 focus:border-0 bg-transparent'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </SelectButtonWrapper>
          <SelectButtonWrapper>
            <SelectMultiple
              buttonLabel='Building'
              onChange={(newValue) => setBuilding(newValue)}
              options={buildings?.map((building) => ({
                value: building?._id,
                label: building?.name
              }))}
              multiple={false}
            />
          </SelectButtonWrapper>
          <SelectButtonWrapper>
            <SelectMultiple
              buttonLabel='Availability Status'
              onChange={(newValue) => setAvailability(newValue)}
              options={STATUS_OPTIONS}
            />
          </SelectButtonWrapper>
        </div>
        {gettingUnits && (
          <div className='grid grid-cols-3 gap-x-7 gap-y-8 items-stretch pb-8'>
            <Skeleton height={250} />
            <Skeleton height={250} />
            <Skeleton height={250} />
          </div>
        )}
        <div
          className={` ${
            isMobile
              ? 'grid grid-cols-1'
              : 'flex flex-wrap flex-row justify-between content-center'
            // : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7'
          } gap-x-7 gap-y-8 pb-8`}
        >
          {cards?.map((card, index) => (
            <div
              key={index}
              className='cursor-pointer max-w-96 w-96'
              onClick={() => {
                setTempCardToOpen(card)
                setOpenModal(true)
              }}
            >
              <InventoryUnit
                {...{
                  ...card,
                  imgSrc: card?.floorPlan?.image ?? null,
                  address: card?.building?.name ?? undefined,
                  totalValue: card?.price ?? undefined,
                  tag: card?.status,
                  name: card?.unitNumber
                }}
                onUpdate={updateUnit}
                key={index}
              />
            </div>
          ))}
        </div>
        {!gettingUnits && cards.length === 0 && (
          <div className='text-center font-bold text-2xl'>No Result</div>
        )}
      </div>
      <LargeUnitCardModal
        {...{
          openModal,
          setOpenModal,
          tempCardToOpen,
          onReserveClicked: () => {
            setOpenModal(false)
            setShowUnitEditModal(true)
          },
          onPurchaseClicked: () => {
            history.push({
              pathname: ADMIN_OFFER
            })
          }
        }}
      />
      {showUnitEditModal && (
        <AddUnitModal
          show={showUnitEditModal}
          setShow={setShowUnitEditModal}
          editDetails={{ ...tempCardToOpen, type: 'Units' }}
          setEditDetails={() => {}}
          refetch={() => {
            refetchUnits?.()
          }}
          building={tempCardToOpen?.building?._id ?? null}
        />
      )}
    </>
  )
}

export default CardView

CardView.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}
