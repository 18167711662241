import { useState, useEffect, useRef } from 'react'
import { getCalls } from 'store/actions/callsActions'

const useGetParticipantCalls = (_id) => {
  const mountedRef = useRef(true)
  const [calls, setCalls] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async () => {
    if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getCalls('', `?participants=${_id}`)
      if (!mountedRef.current) return null
      setCalls(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, calls, refetch: getData }
}

export default useGetParticipantCalls
