// @ts-nocheck
import standardStyle from 'assets/css/standardStyle'
import { PageHeader } from 'components'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Card = ({ title, value }) => (
  <div className=''>
    <div
      className='bg-white rounded'
      style={{ fontFamily: standardStyle.fonts.sfpro }}
    >
      <div className='flex flex-col space-y-2 p-6'>
        <div className='text-xs text-softBlack_70 font-medium uppercase'>
          {title}
        </div>
        <div className='text-xl text-softBlack font-bold'>{value}</div>
      </div>
    </div>
  </div>
)

const EmailCampaignBuilderPage = () => {
  const history = useHistory()
  const [templates] = useState([
    {
      name: '1881',
      unlayerProjectId: 174034,
      unlayerTemplateId: 384076,
      unlayerTemplateName: '1818 Pacifica VIP Launch',
      unlayerConfig: {

      }
    },
    {
      name: '1881',
      unlayerProjectId: 174034,
      unlayerTemplateId: 384076,
      unlayerTemplateName: '1818 Pacifica VIP Launch',
      unlayerConfig: {

      }
    },
    {
      name: '1881',
      unlayerProjectId: 174034,
      unlayerTemplateId: 384076,
      unlayerTemplateName: '1818 Pacifica VIP Launch',
      unlayerConfig: {

      }
    }
  ])
  return (
    <>
      <PageHeader
        title='Email Campaigns Builder'
      />
      <div className='px-4 sm:px-6 lg:px-8 mt-4'>
        <div className='grid grid-cols-6 gap-4 pt-4'>
          <Card title={'Templates'} value='3' />
        </div>
        <div className='mt-8 flow-root bg-white rounded px-6'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
              <table className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                    >
                      Name
                    </th>
                    <th
                      scope='col'
                      className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                    >
                    </th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                  {templates?.map((template) => (
                    <tr key={template.id}>
                      <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                        {template.unlayerTemplateName}
                      </td>
                      <td className='whitespace-nowrap py-4 text-sm flex justify-end gap-3 text-gray-900 sm:pl-0'>
                        <button
                          className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center border border-black'
                        >
                          Live preview
                        </button>
                        <button
                          className='text-softBlack text-center text-base font-medium py-2 px-6 rounded flex items-center bg-volt'
                          onClick={() => history.push(`/admin/email-builder/edit/${template.unlayerTemplateId}`)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailCampaignBuilderPage
