import React, { useEffect, useState } from 'react'

import ToggleSwitch from 'components/ToggleSwitch'
import Skeleton from 'react-loading-skeleton'
import ParkingCard from 'components/ParkingCard'
import { LoadingDetails } from 'pages/desktopMode/inventory/Allocation/AllocateInventory/Steps/Building'

import useGetProjectParkings from 'hooks/useGetProjectParkings'

const PWAParking = ({
  setButtonState,
  selectedParking,
  setSelectedParking
}) => {
  const [switchChecked, setSwitchChecked] = useState(true)
  const { loading, parkings } = useGetProjectParkings()

  const resetParkings = () => {
    const selectedParkingsClone = [...selectedParking]
    for (let i = 0; i < selectedParkingsClone.length; i++) {
      const parkingTemp = selectedParkingsClone[i]
      parkingTemp.isSelected = false
      parkingTemp.count = 0
    }
    setSelectedParking(selectedParkingsClone)
  }

  useEffect(() => {
    setButtonState('Default')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSelectedParking(parkings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parkings])

  useEffect(() => {
    if (!switchChecked) {
      resetParkings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchChecked])

  if (loading) {
    return (
      <div
        className='flex items-center justify-center w-full h-screen'
        style={{ gridColumn: '1 / -1' }}
      >
        <LoadingDetails title='Loading Parkings' />
      </div>
    )
  }

  const onSelectParking = (parking) => {
    const selectedParkingsClone = [...selectedParking]
    for (let i = 0; i < selectedParkingsClone.length; i++) {
      const parkingTemp = selectedParkingsClone[i]
      if (parkingTemp?._id === parking?._id) {
        if (parking?.isSelected) {
          parking.isSelected = false
        } else {
          parking.isSelected = true
          parking.count = 1
        }
        break
      }
    }
    setSelectedParking(selectedParkingsClone)
  }

  const onIncreaseCount = (id, value) => {
    const updatedData = selectedParking.map((parking) =>
      parking._id === id ? { ...parking, count: value } : parking
    )
    setSelectedParking(updatedData)
  }

  const onDecreaseCount = (id, value) => {
    if (value >= 1) {
      const updatedData = selectedParking.map((parking) =>
        parking._id === id ? { ...parking, count: value } : parking
      )
      setSelectedParking(updatedData)
    }
  }

  return (
    <>
      <div className='mx-20'>
        <div className='flex justify-between my-12 items-center'>
          <p className='text-softBlack font-bold font-openSans text-lg'>
            Your Unit has no Parking Spot included. Would you like to purchase a
            Parking Spot?
          </p>
          <ToggleSwitch checked={switchChecked} setChecked={setSwitchChecked} />
        </div>
        <div className='grid grid-cols-none lg:grid-cols-2 2xl:grid-cols-3 gap-6'>
          {loading &&
            Array.from(Array(3).keys()).map((a, index) => (
              <Skeleton key={index} height={280} width='100%' />
            ))}
          {switchChecked &&
            selectedParking?.map((parking, index) => (
              <ParkingCard
                key={index}
                checked={parking.isSelected}
                count={selectedParking[index].count}
                isActive={false}
                checkboxTitle={'Add Parking'}
                id={parking._id}
                description={parking.description}
                imgSrc={parking.images?.[0]}
                price={parking.value}
                title={parking.name}
                increaseCount={() => {
                  onIncreaseCount(parking._id, (parking.count ?? 0) + 1)
                }}
                decreaseCount={() => {
                  onDecreaseCount(parking._id, (parking.count ?? 1) - 1)
                }}
                setChecked={() => {
                  onSelectParking(parking)
                }}
                containerClasses={undefined}
              />
            ))}
        </div>
      </div>
    </>
  )
}

export default PWAParking
