import CancelModal from 'pages/offer/components/CancelModal'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ADMIN_CREATE_INVENTORY_ALLOCATION, ADMIN_PROJECT_ALLOCATIONS, ALLOCATIONS } from 'routes'

const AllocateInventoryHeader = () => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    let nextRoute = ''
    if (history.location.pathname === ADMIN_CREATE_INVENTORY_ALLOCATION) {
      nextRoute = ALLOCATIONS
    } else {
      nextRoute = ADMIN_PROJECT_ALLOCATIONS
    }
    history.push(nextRoute)
  }

  return (
    <>
      <div className='bg-grey-7 fixed flex justify-between items-center px-8 pt-8 pb-6 border-b-2 border-softBlack' style={{ width: 'calc(100% - 80px)' }}>
        <p className='my-0 text-4xl font-bold'>Allocate Inventory</p>
        <button className='text-base font-medium text-black' onClick={onCancel}>Cancel</button>
      </div>
      <CancelModal {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm }} />
    </>
  )
}

export default AllocateInventoryHeader
