import React from 'react'
import PropsTypes from 'prop-types'

const CategoryButton = (props) => {
  const {
    children,
    selected = false,
    setSelectedCategory,
    category,
    disabled = false
  } = props

  const onClickHandler = () => {
    setSelectedCategory(category.value)
  }

  return (
    <button
      type='button'
      disabled={disabled}
      onClick={onClickHandler}
      className={`w-1/2 h-full text-base font-medium overflow-hidden font-sans ${
        selected ? 'bg-softBlack text-white' : 'bg-transparent text-softBlack'
      }`}
    >
      {children}
    </button>
  )
}

CategoryButton.propTypes = {
  selected: PropsTypes.bool,
  setSelectedCategory: PropsTypes.func,
  category: PropsTypes.object,
  children: PropsTypes.any
}

export default CategoryButton
