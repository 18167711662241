/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  ADMIN_DASHBOARD,
  ADMIN_PROJECT_MANAGEMENT,
  SALES_PIPELINE,
  ADMIN_CONTACTS,
  ALLOCATIONS,
  ADMIN_OFFERS,
  PAGE_UNDER_CONSTRUCTION,
  EMAIL_INBOX,
  CALENDAR,
  CONTRACT_TEMPLATES,
  UNITS_INVENTORY,
  ADMIN_TEAM_SALES_REPS,
  ADMIN_TEAM_COOP_BROKER,
  ADMIN_PROJECT_IMAGES,
  ADMIN_PROJECT_PARKING,
  ADMIN_PROJECT_COLORS,
  ADMIN_PROJECT_UPGRADES,
  ADMIN_PROJECT_PAYMENTS,
  ADMIN_BUILDINGS,
  ADMIN_PROJECT_UNIT_INVENTORY,
  ADMIN_PROJECT_UNIT_INVENTORY_GRAPH,
  ADMIN_PROJECT_ALLOCATIONS,
  ADMIN_PROJECT_FILES,
  ADMIN_REPORTS,
  ADMIN_OPPORTUNITIES,
  ADMIN_PROFILE_PAGE,
  ADMIN_PROJECT_DROPDOWNS,
  ADMIN_NEW_CONTACTS,
  ADMIN_EMAIL_CAMPAIGNS,
  ADMIN_RESERVATIONS,
  ADMIN_RESERVATION_NEW,
  ADMIN_BROKER_LEADS,
  ADMIN_BROKER_LEADS_NEW,
  ADMIN_PROJECT_PARKING_INVENTORY,
  PARKINGS_INVENTORY,
  // ADMIN_SCHEDULER_MEETINGS,
  // ADMIN_SCHEDULER_SETTINGS,
  ADMIN_DEPOSIT_PAYMENT,
  ADMIN_SALES_REPORT,
  ADMIN_LEADREPORT,
  ADMIN_EMAIL_CAMPAIGNS_BUILDER,
  ADMIN_EVENT_PAGE
} from 'routes'
import {
  convertToLowerCaseString,
  convertToUpperCaseString
} from 'utils/converters'

import { MENU_CATEGORY } from './constants'
import { ReactComponent as DashboardIconSVG } from '../../assets/icons/icn_dashboard.svg'
import { ReactComponent as EmailIcon } from '../../assets/icons/icn_email.svg'
import { ReactComponent as ContactsIcon } from '../../assets/icons/icn_contacts.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/icn_calendar.svg'
import { ReactComponent as OpportunitiesIcon } from '../../assets/icons/icn_reduce_capacity.svg'
import { ReactComponent as ReservationsIcon } from '../../assets/icons/icn_dns.svg'
import { ReactComponent as BrokerIcon } from '../../assets/icons/icn_text_snippet.svg'
import { ReactComponent as ContractsIcon } from '../../assets/icons/icn_local_offer.svg'
import { ReactComponent as DepositsIcon } from '../../assets/icons/icn_local_atm.svg'
import { ReactComponent as InventoryIcon } from '../../assets/icons/icn_card_view.svg'
import { ReactComponent as EmailCampaignsIcon } from '../../assets/icons/icn_email_sent.svg'
import { ReactComponent as ContractTemplatesIcon } from '../../assets/icons/icn_sticky_note_2.svg'
import { ReactComponent as ReportsIcon } from '../../assets/icons/icn_assessment.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/icn_person.svg'
import { ReactComponent as TeamIcon } from '../../assets/icons/icn_people.svg'
import { ReactComponent as BuildingsIcon } from '../../assets/icons/icn_apartment.svg'
import { ReactComponent as ProjectManagementIcon } from '../../assets/icons/icn_ballot.svg'
import { ReactComponent as DropDownSettingsIcon } from '../../assets/icons/icn_bulleted_list.svg'
import { ReactComponent as EventsIcon } from '../../assets/icons/icn_presentation.svg'

const useSideBarMenu = () => {
  // @ts-ignore
  const user = useSelector((state) => state.authReducer.userObject)
  const history = useHistory()

  const SIDE_BAR_MENU = user.userType === 'SalesRep'
    ? {
      general: [
        {
          menu: [
            {
              key: 'dashboard',
              title: 'Dashboard',
              hasSubMenus: false,
              Icon: DashboardIconSVG,
              onClick: () => history.push(ADMIN_DASHBOARD),
              route: ADMIN_DASHBOARD
            },
            // {
            //   key: 'contacts',
            //   title: 'Contacts',
            //   hasSubMenus: false,
            //   Icon: DashboardIcon,
            //   onClick: () => history.push(ADMIN_CONTACTS),
            //   route: ADMIN_CONTACTS
            // },
            {
              key: 'newcontacts',
              title: 'New Contacts',
              hasSubMenus: false,
              Icon: ContactsIcon,
              onClick: () => history.push(ADMIN_NEW_CONTACTS),
              route: ADMIN_NEW_CONTACTS
            },
            {
              key: 'email inbox',
              title: 'Inbox',
              hasSubMenus: false,
              Icon: EmailIcon,
              onClick: () => history.push(EMAIL_INBOX),
              route: EMAIL_INBOX
            },
            {
              key: 'calendar',
              title: 'Calendar',
              hasSubMenus: false,
              Icon: CalendarIcon,
              onClick: () => history.push(CALENDAR),
              route: CALENDAR
            },
            {
              key: 'opportunities',
              title: 'Opportunities',
              hasSubMenus: false,
              Icon: OpportunitiesIcon,
              onClick: () => history.push(ADMIN_OPPORTUNITIES),
              route: ADMIN_OPPORTUNITIES
            },
            {
              key: 'reservations',
              title: 'Reservations',
              hasSubMenus: false,
              Icon: ReservationsIcon,
              onClick: () => history.push(ADMIN_RESERVATIONS),
              route: ADMIN_RESERVATIONS
            },
            {
              key: 'brokerLeads',
              title: 'Broker Landing Pages',
              hasSubMenus: false,
              Icon: BrokerIcon,
              onClick: () => history.push(ADMIN_BROKER_LEADS),
              route: ADMIN_BROKER_LEADS
            },
            {
              key: 'offers',
              title: 'Contracts',
              hasSubMenus: false,
              Icon: ContractsIcon,
              onClick: () => history.push(ADMIN_OFFERS),
              route: ADMIN_OFFERS
            },
            {
              key: ADMIN_DEPOSIT_PAYMENT,
              title: 'Deposits',
              hasSubMenus: false,
              Icon: DepositsIcon,
              onClick: () => history.push(ADMIN_DEPOSIT_PAYMENT),
              route: ADMIN_DEPOSIT_PAYMENT
            },
            {
              key: 'campaigns',
              title: 'Email Campaigns Reports',
              hasSubMenus: false,
              Icon: EmailCampaignsIcon,
              onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
              route: ADMIN_EMAIL_CAMPAIGNS
            },
            {
              key: 'inventory',
              title: 'Inventory',
              hasSubMenus: true,
              Icon: InventoryIcon,
              subMenus: [
                {
                  key: 'units inventory',
                  title: 'Unit Inventory',
                  hasSubMenus: false,
                  onClick: () => history.push(UNITS_INVENTORY),
                  route: UNITS_INVENTORY
                },
                {
                  key: 'parkings inventory',
                  title: 'Parking Inventory',
                  hasSubMenus: false,
                  onClick: () => history.push(PARKINGS_INVENTORY),
                  route: PARKINGS_INVENTORY
                },
                {
                  key: 'allocations',
                  title: 'Allocations',
                  hasSubMenus: false,
                  onClick: () => history.push(ALLOCATIONS),
                  route: ALLOCATIONS
                }
              ]
            },
            {
              key: 'reports',
              title: 'Reports',
              hasSubMenus: false,
              Icon: ReportsIcon,
              onClick: () => history.push(ADMIN_REPORTS),
              route: 'reports'
            },
            // {
            //   key: 'email_Campaigns',
            //   title: 'Email Campaigns',
            //   hasSubMenus: false,
            //   Icon: DashboardIcon,
            //   onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
            //   route: 'emailCampaigns'
            // },
            {
              key: 'profile',
              title: 'Profile',
              hasSubMenus: false,
              Icon: ProfileIcon,
              onClick: () => history.push('/admin/salesrep/profile'),
              route: '/admin/salesrep/profile'
            }
          ]
        }
      ]
    }
    : {
      general: [
        {
          menu: [
            {
              key: 'dashboard',
              title: 'Dashboard',
              hasSubMenus: false,
              Icon: DashboardIconSVG,
              onClick: () => history.push(ADMIN_DASHBOARD),
              route: ADMIN_DASHBOARD
            },
            // {
            //   key: 'contacts',
            //   title: 'Contacts',
            //   hasSubMenus: false,
            //   Icon: DashboardIcon,
            //   onClick: () => history.push(ADMIN_CONTACTS),
            //   route: ADMIN_CONTACTS
            // },
            {
              key: 'newcontacts',
              title: 'New Contacts',
              hasSubMenus: false,
              Icon: ContactsIcon,
              onClick: () => history.push(ADMIN_NEW_CONTACTS),
              route: ADMIN_NEW_CONTACTS
            },
            {
              key: 'email inbox',
              title: 'Inbox',
              hasSubMenus: false,
              Icon: EmailIcon,
              onClick: () => history.push(EMAIL_INBOX),
              route: EMAIL_INBOX
            },
            {
              key: 'calendar',
              title: 'Calendar',
              hasSubMenus: false,
              Icon: CalendarIcon,
              onClick: () => history.push(CALENDAR),
              route: CALENDAR
            },
            {
              key: ADMIN_EVENT_PAGE,
              title: 'Events & RSVPS',
              hasSubMenus: false,
              Icon: EventsIcon,
              onClick: () => history.push(ADMIN_EVENT_PAGE),
              route: ADMIN_EVENT_PAGE
            },
            {
              key: 'opportunities',
              title: 'Opportunities',
              hasSubMenus: false,
              Icon: OpportunitiesIcon,
              onClick: () => history.push(ADMIN_OPPORTUNITIES),
              route: ADMIN_OPPORTUNITIES
            },
            {
              key: 'reservations',
              title: 'Reservations',
              hasSubMenus: false,
              Icon: ReservationsIcon,
              onClick: () => history.push(ADMIN_RESERVATIONS),
              route: ADMIN_RESERVATIONS
            },
            {
              key: 'brokerLeads',
              title: 'Broker Landing Pages',
              hasSubMenus: false,
              Icon: BrokerIcon,
              onClick: () => history.push(ADMIN_BROKER_LEADS),
              route: ADMIN_BROKER_LEADS
            },
            {
              key: 'offers',
              title: 'Contracts',
              hasSubMenus: false,
              Icon: ContractsIcon,
              onClick: () => history.push(ADMIN_OFFERS),
              route: ADMIN_OFFERS
            },
            {
              key: ADMIN_DEPOSIT_PAYMENT,
              title: 'Deposits',
              hasSubMenus: false,
              Icon: DepositsIcon,
              onClick: () => history.push(ADMIN_DEPOSIT_PAYMENT),
              route: ADMIN_DEPOSIT_PAYMENT
            },
            {
              key: 'contract templates',
              title: 'Contract Templates',
              hasSubMenus: false,
              Icon: ContractTemplatesIcon,
              onClick: () => history.push(CONTRACT_TEMPLATES),
              route: CONTRACT_TEMPLATES
            },
            {
              key: 'team',
              title: 'Team',
              hasSubMenus: true,
              Icon: TeamIcon,
              subMenus: [
                {
                  key: 'sales-reps',
                  title: 'Sales Reps',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_TEAM_SALES_REPS),
                  route: ADMIN_TEAM_SALES_REPS
                },
                {
                  key: 'coop-brokers',
                  title: 'Coop Brokers',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_TEAM_COOP_BROKER),
                  route: ADMIN_TEAM_COOP_BROKER
                }
              ]
            },
            {
              key: 'campaigns',
              title: 'Email Campaigns Reports',
              hasSubMenus: false,
              Icon: EmailCampaignsIcon,
              onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
              route: ADMIN_EMAIL_CAMPAIGNS
            },
            {
              key: ADMIN_EMAIL_CAMPAIGNS_BUILDER,
              title: 'Email Campaigns Builder',
              hasSubMenus: false,
              Icon: EmailIcon,
              onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS_BUILDER),
              route: ADMIN_EMAIL_CAMPAIGNS_BUILDER
            },
            {
              key: 'inventory',
              title: 'Inventory',
              hasSubMenus: true,
              Icon: InventoryIcon,
              subMenus: [
                {
                  key: 'units inventory',
                  title: 'Unit Inventory',
                  hasSubMenus: false,
                  onClick: () => history.push(UNITS_INVENTORY),
                  route: UNITS_INVENTORY
                },
                {
                  key: 'parkings inventory',
                  title: 'Parking Inventory',
                  hasSubMenus: false,
                  onClick: () => history.push(PARKINGS_INVENTORY),
                  route: PARKINGS_INVENTORY
                },
                {
                  key: 'allocations',
                  title: 'Allocations',
                  hasSubMenus: false,
                  onClick: () => history.push(ALLOCATIONS),
                  route: ALLOCATIONS
                }
              ]
            }
          ]
        },
        {
          menu: [
            // {
            //   key: 'sales pipeline',
            //   title: 'Sales Pipeline',
            //   hasSubMenus: false,
            //   Icon: DashboardIcon,
            //   onClick: () => history.push(SALES_PIPELINE),
            //   route: SALES_PIPELINE
            // },
            // {
            //   key: 'notifications',
            //   title: 'Notifications',
            //   hasSubMenus: false,
            //   Icon: DashboardIcon,
            //   onClick: () => history.push(PAGE_UNDER_CONSTRUCTION),
            //   route: 'notifications'
            // },
            {
              key: 'reports',
              title: 'Reports',
              hasSubMenus: true,
              Icon: ReportsIcon,
              subMenus: [
                {
                  key: ADMIN_SALES_REPORT,
                  title: 'Sales Rep Activity',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_SALES_REPORT),
                  route: ADMIN_SALES_REPORT
                },
                {
                  key: ADMIN_LEADREPORT,
                  title: 'Lead Reports',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_LEADREPORT),
                  route: ADMIN_LEADREPORT
                }
              ]
            }
          ]
        },
        // {
        //   menu: [
        //     {
        //       key: 'email_Campaigns',
        //       title: 'Email Campaigns',
        //       hasSubMenus: false,
        //       Icon: DashboardIcon,
        //       onClick: () => history.push(ADMIN_EMAIL_CAMPAIGNS),
        //       route: 'emailCampaigns'
        //     }
        //   ]
        // },
        {
          menu: [
            {
              key: 'profile',
              title: 'Profile',
              hasSubMenus: false,
              Icon: ProfileIcon,
              onClick: () => history.push(ADMIN_PROFILE_PAGE),
              route: 'profile'
              // },
              // {
              //   key: 'Scheduler Meetings',
              //   title: 'Scheduler Meetings',
              //   hasSubMenus: false,
              //   Icon: DashboardIcon,
              //   onClick: () => history.push(ADMIN_SCHEDULER_MEETINGS),
              //   route: 'Scheduler Meetings'
              // },  {
              //   key: 'Scheduler Settings',
              //   title: 'Scheduler Settings',
              //   hasSubMenus: false,
              //   Icon: DashboardIcon,
              //   onClick: () => history.push(ADMIN_SCHEDULER_SETTINGS),
              //   route: 'Scheduler Settings'
            }
          ]
        }
      ],
      project: [
        {
          menu: [
            {
              key: 'project management',
              title: 'Project Management',
              hasSubMenus: true,
              Icon: ProjectManagementIcon,
              subMenus: [
                {
                  key: 'general',
                  title: 'General',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_MANAGEMENT),
                  route: ADMIN_PROJECT_MANAGEMENT
                },
                {
                  key: 'images',
                  title: 'Images',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_IMAGES),
                  route: ADMIN_PROJECT_IMAGES
                },
                {
                  key: 'parking',
                  title: 'Parking',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_PARKING),
                  route: ADMIN_PROJECT_PARKING
                },
                {
                  key: 'colors',
                  title: 'Colors',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_COLORS),
                  route: ADMIN_PROJECT_COLORS
                },
                {
                  key: 'upgrades',
                  title: 'Upgrades',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_UPGRADES),
                  route: ADMIN_PROJECT_UPGRADES
                },
                {
                  key: 'payment',
                  title: 'Payment',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_PAYMENTS),
                  route: ADMIN_PROJECT_PAYMENTS
                },
                {
                  key: ADMIN_PROJECT_FILES,
                  title: 'Files',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_FILES),
                  route: ADMIN_PROJECT_FILES
                }
              ]
            },
            {
              key: 'buildings',
              title: 'Buildings',
              hasSubMenus: false,
              Icon: BuildingsIcon,
              onClick: () => history.push(ADMIN_BUILDINGS),
              route: ADMIN_BUILDINGS
            },
            {
              key: 'inventory',
              title: 'Inventory',
              hasSubMenus: true,
              Icon: InventoryIcon,
              subMenus: [
                {
                  key: 'units inventory',
                  title: 'Unit Inventory',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_UNIT_INVENTORY),
                  route: ADMIN_PROJECT_UNIT_INVENTORY
                },
                {
                  key: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH,
                  title: 'Unit Inventory Graph',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_UNIT_INVENTORY_GRAPH),
                  route: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH
                },
                {
                  key: 'parkings inventory',
                  title: 'Parking Inventory',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_PARKING_INVENTORY),
                  route: ADMIN_PROJECT_PARKING_INVENTORY
                },
                {
                  key: 'allocations',
                  title: 'Allocations',
                  hasSubMenus: false,
                  onClick: () => history.push(ADMIN_PROJECT_ALLOCATIONS),
                  route: ADMIN_PROJECT_ALLOCATIONS
                }
              ]
            },
            {
              key: 'Drop Down Settings',
              title: 'Drop Down Settings',
              hasSubMenus: true,
              Icon: DropDownSettingsIcon,
              subMenus: [
                ...ADMIN_PROJECT_DROPDOWNS.map((dropdown) => ({
                  key: dropdown.key,
                  title: convertToUpperCaseString(dropdown.key),
                  hasSubMenus: false,
                  onClick: () => history.push(dropdown.path),
                  route: dropdown.path
                }))
              ]
            }
          ]
        }
      ]
    }

  const getCategory = (pathname) => {
    const index = MENU_CATEGORY?.map((_category) =>
      _category.routes.includes(pathname)
    ).findIndex((_item) => _item)
    return MENU_CATEGORY?.[index] || null
  }

  return { SIDE_BAR_MENU, getCategory }
}

export default useSideBarMenu
