import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { getUserConversations } from 'store/actions/usersActions'
import MessageConversation from './MessageConversation'
import { MessageModal } from './MessageModal'

const MessagesTab = ({ contact }) => {
  const [messageModal, setMessageModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [conversation, setConversation] = useState(null)

  useEffect(() => {
    getConversationData()
  }, [])

  const getConversationData = () => {
    getUserConversations({ userId: contact?.id }).then((res) => {
      setConversation(res)
    }).catch(e => {
      setError(e)
      console.error('MessagesTab => getUserConversations => e', e)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      {loading && (
        <div className='flex flex-col gap-6 mt-8'>
          <Skeleton height={100} />
        </div>
      )}
      {!loading && (
        <div className='font-openSans flex flex-col p-2 w-full bg-white rounded overflow-y-auto'>
          {conversation?.exist
            ? <MessageConversation contact={contact} />
            : <div className="font-openSans flex flex-col justify-center items-center h-96 w-full bg-white mt-8 mb-8 rounded">
              <svg
                width='22'
                height='24'
                viewBox='0 0 18 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM4 9H9V14H4V9Z'
                  fill='#2E2B2E'
                />
              </svg>
              <div className='font-semibold mb-2 mt-4'>You don't have any messages yet</div>
              <div className='text-sm text-softBlack70'>
                {`Start with your first message with ${contact?.firstName ?? '...'}`}
              </div>
              <button className='bg-yellow-200 font-semibold px-4 py-2 rounded mt-6' onClick={() => { setMessageModal(true) }}>
                Start Conversation
              </button>
            </div>
          }
          <MessageModal
            show={messageModal}
            handleClose={(isStartConversation) => {
              setMessageModal(!messageModal)
              if (isStartConversation) {
                getConversationData()
              }
            }}
            contact={contact}
          />
        </div>
      )}
      {!loading && error && (
        <div className='flex flex-col gap-6 mt-8'>
          <p>{error}</p>
        </div>
      )}
    </>
  )
}

export default MessagesTab
