import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { handleLogout } from 'store/actions/authActions'
import { Sider } from 'components'
import { Dropdown, Modal } from 'ui'
import BuyerMenus from 'components/BuyerMenus'
import { useHistory } from 'react-router-dom'
import { LOGOUT } from 'routes'
import { message } from 'antd'

const BuyerSideMenu = (props) => {
  const { showMenu, setShowMenu, userObject: { firstName, userAvatar }, handleLogout } = props
  const [selectedMenuId, setSelectedMenuId] = useState('dashboard')
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const history = useHistory()

  const profileOptions = [
    {
      title: 'Your Profile',
      onClick: () => message.info('We are working on that :)')
    },
    {
      title: 'Logout',
      onClick: () => setShowLogoutModal(true)
    }
  ]

  const UserProfile = () => (
    <Dropdown
      title={firstName}
      imgSrc={userAvatar}
      options={profileOptions}
      backgroundColor='bg-white'
      backgroundHoverColor='bg-none'
      border='border border-gray-200'
      shadow='shadow-lg'
      focus='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'
      className='lg:hover:bg-gray-200 border'
      arrowDownIconColor='text-gray-400'
    />
  )

  const logout = useCallback(() => {
    handleLogout()
    history.push(LOGOUT)
  }, [handleLogout, history])

  const confirmLogoutModal = useCallback(() => {
    return (
      <Modal
        showModal={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        modalType='alert'
        title='Are you sure?'
        subtitle='Do you want to quit?'
        onOk={() => logout()}
      />
    )
  }, [logout, showLogoutModal, setShowLogoutModal])

  return (
    <>
      {confirmLogoutModal()}
      <Sider
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        closeButtonColor='text-black'
        closeButtonClassName='left-2'
        fixed={false}
      >
        <div className='p-20'>
          <UserProfile />

          <div className='mt-10'>
            <BuyerMenus
              selectedMenuId={selectedMenuId}
              setSelectedMenuId={setSelectedMenuId}
              setShowMenu={setShowMenu}
            />
          </div>
        </div>
      </Sider>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {
  handleLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerSideMenu)

BuyerSideMenu.propTypes = {
  showMenu: propTypes.bool,
  setShowMenu: propTypes.func,
  userObject: propTypes.shape({
    firstName: propTypes.string,
    userAvatar: propTypes.string
  }),
  handleLogout: propTypes.func
}
