import React from 'react'
import propTypes from 'prop-types'

import { Image } from './styles'

const ImageLink = (props) => {
  const {
    link,
    src
  } = props
  return (
        <a href={link}>
        <Image
            src={src}
        />
        </a>

  )
}

export default ImageLink

ImageLink.propTypes = {
  link: propTypes.string,
  src: propTypes.string
}
