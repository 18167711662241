/* eslint-disable no-restricted-globals */
import React from 'react'
import { useDispatch } from 'react-redux'
import { GrClose } from 'react-icons/gr'
import {
  // HOVER_TOGGLER,
  SHOW_HIDE_SIDEBAR
} from 'store/actions/actionTypes'

const CollapseSideBarButton = () => {
  const dispatch = useDispatch()

  return (
    <div onClick={() => {
      dispatch({ type: SHOW_HIDE_SIDEBAR, payload: false })
      // dispatch({ type: HOVER_TOGGLER, payload: false })
    }} className='absolute text-black w-5 h-5 cursor-pointer right-4 top-6'>
      <GrClose fontSize="20px" />
    </div>
  )
}

export default CollapseSideBarButton
