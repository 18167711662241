import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { H1 } from 'ui'
import { TFilledEmailIcon, TFilledUserGroup, TCompanyIcon, TFilledPenIcon } from 'components/Icons'
import { getUserType } from 'utils'
import { changePinnedHeader } from 'store/actions/appActions'
import UserRoundedImage from 'components/UserRoundedImage'

const UserParam = ({ icon, name, label }) => (
  <>
    <dt className='sr-only'>{name}</dt>
    <dd className='flex items-center text-sm text-gray-500 font-medium sm:mr-6 mb-0'>
      {icon}
      {label}
    </dd>
  </>
)

const HeaderImage = ({ className, image, onImageClick, showEmptyImage }) => (
  <div
    id='imageWrapper'
    className={`${
      !image ? 'flex' : 'contents'
    } items-center justify-center relative`}
  >
    <UserRoundedImage
      src={image}
      className={className}
      applyMarginEmptyImage={false}
    />

    {onImageClick && (
      <div
        onClick={onImageClick}
        className={`${className} h-12 w-12 rounded-full flex flex-col items-center justify-center absolute
    bg-opacity-50 bg-gray-400 text-white hover:cursor-pointer`}
        id='imageEditDiv'
      >
        <TFilledPenIcon className='h-6 w-6' />
        edit
      </div>
    )}
  </div>
)

const Title = ({ children, loading }) => (
  <H1 className='mb-0 font-bold text-3xl ' loading={loading}>{children}</H1>
)

const PageHeader = (props) => {
  const {
    userObject: { developerCompany, userType, email },
    title,
    image,
    parameters: externalParameters,
    rightContent,
    showParameters,
    onImageClick,
    showImage,
    showEmptyImage,
    loading
  } = props

  const parameterIcon = (Icon) => (
    <Icon className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' />
  )

  const renderParameters = useCallback(() => {
    const localParameters = [
      {
        icon: TFilledEmailIcon,
        name: 'Account email',
        label: email
      },
      {
        icon: TFilledUserGroup,
        name: 'Account user type',
        label: getUserType(userType)
      }
    ]

    if (developerCompany) {
      localParameters.push({
        icon: TCompanyIcon,
        name: 'Company',
        label: developerCompany
      })
    }

    let parameters = []

    if (externalParameters.length) {
      parameters = [...externalParameters]
    } else {
      parameters = [...localParameters]
    }

    return parameters.map(({ icon, name, label }, index) => (
      <UserParam
        key={index}
        icon={parameterIcon(icon)}
        name={name}
        label={label}
      />
    ))
  }, [developerCompany, email, externalParameters, userType])

  const RightContent = useCallback(() => rightContent, [rightContent])

  return (
    <div
      className={`sticky h-[100px] items-center justify-center border-b-2 border-black bg-menuGray
      top-0 right-0 left-0 z-50`}
    >
      <div className='mx-auto px-4 sm:px-6 lg:px-8 h-full'>
        <div className='py-2 flex items-center justify-between my-auto h-full'>
          <div className='flex-1 min-w-0'>
            <div className='flex items-center'>
              {showImage && (
                <HeaderImage
                  className='hidden h-12 w-12 sm:flex rounded-full'
                  image={image}
                  onImageClick={onImageClick}
                  showEmptyImage={showEmptyImage}
                />
              )}
              <div>
                <div className='flex items-center'>
                  {
                    showImage && (
                      <HeaderImage
                        className='h-12 w-12 sm:hidden rounded-full'
                        image={image}
                        onImageClick={onImageClick}
                        showEmptyImage={showEmptyImage}
                      />
                    )
                  }
                  <div className='flex flex-col'>
                    <Title loading={loading}>{title}</Title>
                  </div>
                </div>
                {showParameters && !loading && (
                  <dl className='mt-6 flex flex-col mb-0 sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap'>
                    {renderParameters()}
                  </dl>
                )}
              </div>
            </div>
          </div>
          <div className='flex space-x-3 mt-0 ml-4 flex-1 justify-start justify-end'>
            {rightContent && <RightContent />}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  pinnedHeader: state.appReducer.pinnedHeader
})

const mapDispatchToProps = {
  changePinnedHeader
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)

PageHeader.defaultProps = {
  parameters: [],
  showParameters: false,
  showImage: true,
  showEmptyImage: false,
  loading: false
}

PageHeader.propTypes = {
  title: propTypes.string,
  image: propTypes.string,
  parameters: propTypes.node,
  rightContent: propTypes.node,
  userObject: propTypes.object,
  showParameters: propTypes.bool,
  onImageClick: propTypes.func,
  showImage: propTypes.bool,
  showEmptyImage: propTypes.bool,
  loading: propTypes.bool
}

UserParam.propTypes = {
  icon: propTypes.node,
  name: propTypes.string,
  label: propTypes.string
}

HeaderImage.propTypes = {
  className: propTypes.string,
  image: propTypes.string,
  onImageClick: propTypes.func,
  showEmptyImage: propTypes.bool
}

Title.propTypes = {
  children: propTypes.node,
  loading: propTypes.bool
}
