import React from 'react'

import UnitItem from './UnitItem'

const TableIndex = ({ title }) => {
  return (
    <div className='flex min-w-20 w-20 bg-[#E3E3E1] h-[59px] items-center justify-center text-softBlack70 font-medium text-xs uppercase'>
      {title}
    </div>
  )
}

const FloorItem = ({
  data,
  floorNUmber,
  availabilityFilter,
  type,
  onSelect = () => {},
  showActionButtonOnHover = true,
  isAllocateInventory = false,
  isPublicPage = false,
  refetchUnits
}) => {
  const isDisabled = (availabilityStatus) => {
    return (
      availabilityFilter.length > 0 &&
      availabilityFilter.indexOf(availabilityStatus) === -1
    )
  }

  const isOnRightSide = (index) => {
    if (data.length === 1) {
      return false
    }
    return index >= data.length / 2
  }

  return (
    <div className='flex gap-2 w-full'>
      <TableIndex title={floorNUmber} />
      <div
        className={'relative flex flex-wrap gap-2'}
        style={{ width: 'calc(100% - 80px)' }}
      >
        {data.length > 0 &&
          data.map((itemValue, index) => {
            return (
              <UnitItem
                key={index}
                floor={itemValue.floorNumber}
                bedroomCount={itemValue.numberOfBedrooms}
                bathroomCount={itemValue.numberOfBathrooms}
                price={itemValue.price}
                isDisabled={isDisabled(itemValue.status)}
                isOnRightSide={isOnRightSide(index)}
                unit={itemValue}
                type={type}
                onSelect={onSelect}
                showActionButtonOnHover={showActionButtonOnHover}
                isAllocateInventory={isAllocateInventory}
                isPublicPage={isPublicPage}
                refetchUnits={refetchUnits}
              />
            )
          })}
      </div>
    </div>
  )
}

export default FloorItem
