import React from 'react'
import propTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { BUYER_CREATE_OFFER, BUYER_DASHBOARD } from 'routes'

const itemMenuVariants = {
  hidden: {
    opacity: 0,
    x: -50
  },
  visible: (custom) => ({
    opacity: 1,
    x: 0
  })
}

const ItemMenu = ({ onClick, index, title, setSelectedMenuId, selected }) => (
  <motion.button
    variants={itemMenuVariants}
    initial='hidden'
    animate='visible'
    custom={index}
    onClick={() => {
      onClick()
    }}
    transition={{
      delay: 0.25 + (index * 0.07),
      x: { type: 'spring', stiffness: 300, damping: 30 }
    }}
    className={`text-2xl text-left mb-3 hover:bg-black hover:text-white rounded-md 
    px-3 -ml-3 focus:outline-none ${selected && 'underline'}`}
  >
    {title}
  </motion.button>
)

const BuyerMenus = (props) => {
  const { selectedMenuId, setSelectedMenuId, setShowMenu } = props

  const history = useHistory()

  const menus = [
    { key: 'availabilityBoard', title: 'Availability Board', onClick: () => history.push(BUYER_DASHBOARD), visible: false },
    {
      key: 'offers',
      title: 'Offers',
      onClick: () => history.push({
        pathname: BUYER_CREATE_OFFER,
        state: { creationType: 'offerCreated', fromDocumentsMenu: false }
      }),
      visible: false
    },
    {
      key: 'reservations',
      title: 'Reservations',
      onClick: () => history.push({
        pathname: BUYER_CREATE_OFFER,
        state: { creationType: 'reservedUnits', fromDocumentsMenu: false }
      }),
      visible: false
    },
    { key: 'transactions', title: 'Transactions', onClick: () => {}, visible: false },
    {
      key: 'documents',
      title: 'Documents',
      onClick: () => history.push({
        pathname: BUYER_CREATE_OFFER,
        state: { creationType: 'offerCreated', fromDocumentsMenu: true }
      }),
      visible: true
    }
  ]

  return (
    <div className='flex flex-col'>
      <span className='text-4xl mb-5'>Menu</span>
      {
        menus
          .filter(menu => menu.visible)
          .map((menu, index) => {
            const { key, title, onClick } = menu
            return (
            <ItemMenu
              key={key}
              title={title}
              onClick={() => {
                setSelectedMenuId(key)
                onClick()
                setShowMenu(false)
              }}
              index={index}
              setSelectedMenuId={setSelectedMenuId}
              selected={selectedMenuId === key}
            />
            )
          })
      }
    </div>
  )
}

export default BuyerMenus

BuyerMenus.propTypes = {
  selectedMenuId: propTypes.string,
  setSelectedMenuId: propTypes.func,
  setShowMenu: propTypes.func
}

ItemMenu.propTypes = {
  onClick: propTypes.func,
  index: propTypes.string,
  title: propTypes.string,
  setSelectedMenuId: propTypes.func,
  selected: propTypes.bool
}
