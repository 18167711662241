import React from 'react'
import { connect } from 'react-redux'
const ColumbiaSalesReportContainer = (props) => {
  return (
      <div className="w-full relative">
        <img
          src="/background-img.png"
          className="object-cover   w-full h-[360px]"
        />
        <div className=" w-full px-[40px] absolute top-10  py-8 xl:px-[80px] gap-x-[38px]  flex justify-between ">
          <div className="basis-1/3 z-99 flex flex-col gap-y-8">
            <div className="pt-[12px] rounded-[3px] pb-[24px] bg-[#FCFCFA] z-99">
              <div className="text-[19px] pb-[12px] font-bold px-[26px] text-[##2E2B2EB2]">
                SALES REPORT <br />
                24/04/2023 - 30/04/2023
              </div>
              <div className="bg-[#EDDF65] flex items-center justify-center py-[80px] text-[40px] font-bold">
                Pacifica 1818
              </div>
              <div className="px-[26px] pt-[26px]">
                <div>
                  <div className="italic  text-[14px] font-bold ">Sold </div>
                  <div className="text-[24px] font-bold">$153,182,000</div>
                </div>
                <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D#2E2B2E4D]"></div>
                <div>
                  <div className="italic  text-[14px] font-bold ">
                    REMAINING UNITS
                  </div>
                  <div className="text-[24px] font-bold">32</div>
                </div>
                <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
                <div>
                  <div className="italic text-[14px] font-bold ">
                    TOTAL AMOUNT remaining
                  </div>
                  <div className="text-[24px] font-bold">CA$ 37,732,000</div>
                </div>
                <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
                <div>
                  <div className="italic text-[14px] font-bold ">
                    AVE PRICE PER SQ. FT remaining
                  </div>
                  <div className="text-[24px] font-bold">CA$1,048</div>
                  <div className="flex gap-x-[23px] mt-[16px]">
                    <div className="flex gap-x-[8px]">
                      <div className="w-[12px] rounded-[4px] h-[12px] bg-[#2e2b2b]"></div>
                      <div>Sold</div>
                    </div>
                    <div className="flex gap-x-[8px]">
                      <div className="w-[12px] rounded-[4px] h-[12px] bg-[#EDDF65]"></div>
                      <div>Remaining</div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-[22px] gap-y-[10px]">
                    <div className="h-[46px] rounded-[4px] w-[75%] bg-[#2e2b2e]"></div>
                    <div className="h-[46px] rounded-[4px] w-[15%] bg-[#EDDF65]"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#FCFCFA] px-[26px] rounded-[3px] pt-[16px] pb-[30px]">
              <div className="text-[16px] font-bold">
                Top 5 Brokers - Unit Sales
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">18 </div>
                <div className="text-[16px] italic ">
                  Carlos Velasco - Sothebys
                </div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">12 </div>
                <div className="text-[16px] italic ">Judy Chang - Remax </div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">8 </div>
                <div className="text-[16px] italic ">
                  Winston Lee - Engel & Völkers
                </div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">7 </div>
                <div className="text-[16px] italic ">Aubrey Kent - EXP</div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">5</div>
                <div className="text-[16px] italic ">
                  Shannon McKenzie Sothebys
                </div>
              </div>
            </div>
            <div className="bg-[#FCFCFA] px-[26px] rounded-[3px] pt-[30px] pb-[30px]">
              <div className="text-[16px] font-bold">Sales By Unit Type</div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">7 </div>
                <div className="text-[16px] italic ">2 Bed - 2 Bath</div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">5 </div>
                <div className="text-[16px] italic ">1 Bed - 1 Bath</div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">3 </div>
                <div className="text-[16px] italic ">3 Bed - 2 Bath</div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">2 </div>
                <div className="text-[16px] italic ">Studio</div>
              </div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E4D]"></div>
              <div>
                <div className="italic text-[14px] font-bold ">0 </div>
                <div className="text-[16px] italic ">Penthouse</div>
              </div>
            </div>
            <div className="bg-[#FCFCFA] px-[26px] items flex flex-col gap-y-[16px] rounded-[3px] pt-[16px] pb-[30px]">
              <div className="text-[16px] font-bold">New Leads - 118</div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E]"></div>
              <div className=" text-[16px] p-[14px] bg-[white] items-center justify-between flex font-bold ">
                <div className="flex flex-col gap-y-[8px]">
                  <div>118</div>
                  <div className="italic text-[10px]  text-[#2E2B2EB2]">
                    CONTACTS
                  </div>
                </div>
                <div className="flex flex-col gap-y-[8px]">
                  <div>76</div>
                  <div className="italic text-[10px]  text-[#2E2B2EB2]">
                    LEADS
                  </div>
                </div>
                <div className="flex flex-col gap-y-[8px]">
                  <div>42</div>
                  <div className="italic text-[10px]  text-[#2E2B2EB2]">
                    Realtors
                  </div>
                </div>
              </div>
              <div className="text-[16px] font-bold">Lead Score</div>
              <div className="w-[full] h-[1px]  my-[8px] bg-[#2E2B2E]"></div>
              <div className="py-[12px] flex gap-x-[40px]">
                <div className="flex items-center gap-x-[8px]">
                  <div className="h-full w-[8px] bg-[#EDDF65]"></div>
                  <div className="font-bold">18</div>
                  <div className="italic text-[#2E2B2EB2]">A</div>
                </div>
                <div className="flex items-center gap-x-[8px]">
                  <div className=" h-full w-[8px] bg-[#6C6A6B]"></div>
                  <div className="font-bold">40</div>
                  <div className="italic text-[#2E2B2EB2]">B</div>
                </div>
                <div className="flex items-center gap-x-[8px]">
                  <div className=" h-full w-[8px] bg-[#BEBDBD]"></div>
                  <div className="font-bold">60</div>
                  <div className="italic text-[#2E2B2EB2]">C</div>
                </div>
              </div>
              <div>
                <div className="w-full relative  h-[35px]">
                  <div className="absolute h-full left-0 bg-[#EDDF65] w-[20%]"></div>
                  <div className="absolute h-full left-[20%] bg-[#2E2B2EB2] w-[35%]"></div>
                  <div className="absolute  h-full left-[55%] bg-[#2E2B2E4D] w-[45%]"></div>
                </div>
              </div>
              <button className="bg-[#30373E] text-white items-center flex  mx-auto py-[12px] px-[9px] rounded-[6px]">
                View All Contacts
              </button>
            </div>
          </div>
          <div className="basis-2/3 pt-8 flex flex-col gap-y-8">
            <div className="flex justify-between">
              <div className="bg-white z-99 p-6 xl:p-8 rounded-[4px]  flex flex-col top-20 justify-between  h-44 w-fit">
                <div>
                  <img src="/total-sale.svg" />
                </div>
                <div className="flex flex-col">
                  <div className="text-[16px] font-[400]">TOTAL SALES</div>
                  <div className="text-[#2E2B2E] font-bold text-[20px]">
                    CA$56,854,000
                  </div>
                </div>
              </div>
              <div className="bg-white z-99 p-6 xl:p-8 rounded-[4px]  flex flex-col top-20 justify-between  h-44 w-fit">
                <div>
                  <img src="/total-sale.svg" />
                </div>
                <div className="flex flex-col">
                  <div className="text-[16px] font-[400]">TOTAL SALES</div>
                  <div className="text-[#2E2B2E] font-bold text-[20px]">
                    CA$56,854,000
                  </div>
                </div>
              </div>
              <div className="bg-white z-99 p-6 xl:p-8 rounded-[4px]  flex flex-col top-20 justify-between  h-44 w-fit">
                <div>
                  <img src="/total-sale.svg" />
                </div>
                <div className="flex flex-col">
                  <div className="text-[16px] font-[400]">TOTAL SALES</div>
                  <div className="text-[#2E2B2E] font-bold text-[20px]">
                    CA$56,854,000
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[16px] p-6 bg-[#FCFCFA] ">
              <div className="text-[17px] font-bold">Offer</div>
              <div className="w-[full] h-[2px]  my-[8px] bg-[#2E2B2E]"></div>
              <div className="py-[12px] flex gap-x-[40px]">
                <div className="flex items-center gap-x-[8px]">
                  <div className="h-full w-[4px] bg-[#EDDF65]"></div>
                  <div>
                    <div className="font-bold">60%</div>
                    <div className="italic uppercase font-[300] text-[#2E2B2EB2]">
                      Complete OFFERS
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-x-[8px]">
                  <div className=" h-full w-[4px] bg-[#2E2B2E4D]"></div>
                  <div>
                    <div className="font-bold">15%</div>
                    <div className="italic uppercase  font-[300] text-[#2E2B2EB2]">
                      Pending payment
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-x-[8px]">
                  <div className=" h-full w-[4px] bg-[#2E2B2EB2]"></div>
                  <div>
                    <div className="font-bold">20%</div>
                    <div className="italic uppercase  font-[300] text-[#2E2B2EB2]">
                      pending Signature
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-x-[8px]">
                  <div className=" h-full w-[4px] bg-[#2E2B2E]"></div>
                  <div>
                    <div className="font-bold">60%</div>
                    <div className="italic uppercase  font-[300] text-[#2E2B2EB2]">
                      REJECTED OFFERS
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="w-full relative rounded-[3px]  h-[35px]">
                  <div className="absolute h-full left-0 bg-[#EDDF65] w-[60%]"></div>
                  <div className="absolute h-full left-[60%] bg-[#2E2B2EB2] w-[20%]"></div>
                  <div className="absolute  h-full left-[80%] bg-[#2E2B2E4D] w-[15%]"></div>
                  <div className="absolute  h-full left-[95%] bg-[#2E2B2E] w-[5%]"></div>
                </div>
              </div>
            </div>
            <div className="flex gap-x-6 justify-between">
              <div className="flex basis-1/2 w-full flex-col gap-y-[16px] p-6 bg-[#FCFCFA] ">
                <div className="text-[17px] font-bold">Opportunities</div>
                <div className="w-[full] h-[2px]  my-[8px] bg-[#2E2B2E]"></div>
                <div className="py-[12px] flex justify-between">
                  <div className="flex items-center gap-x-[8px]">
                    <div className="h-full w-[4px] bg-[#EDDF65]"></div>
                    <div>
                      <div className="font-bold">35%</div>
                      <div className="italic uppercase font-[300] text-[#2E2B2EB2]">
                        Completed
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-[8px]">
                    <div className=" h-full w-[4px] bg-[#2E2B2E4D]"></div>
                    <div>
                      <div className="font-bold">55%</div>
                      <div className="italic uppercase  font-[300] text-[#2E2B2EB2]">
                        Pending
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-[8px]">
                    <div className=" h-full w-[4px] bg-[#DF6D50]"></div>
                    <div>
                      <div className="font-bold">20%</div>
                      <div className="italic uppercase  font-[300] text-[#2E2B2EB2]">
                        lost
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-full relative rounded-[3px]  h-[35px]">
                    <div className="absolute h-full left-0 bg-[#EDDF65] w-[35%]"></div>
                    <div className="absolute h-full left-[35%] bg-[#2E2B2EB2] w-[45%]"></div>
                    <div className="absolute  h-full left-[80%] bg-[#DF6D50] w-[20%]"></div>
                  </div>
                </div>
              </div>
              <div className="flex basis-1/2 w-full flex-col gap-y-[16px] p-6 bg-[#FCFCFA] ">
                <div className="text-[17px] font-bold">Recision & Closing</div>
                <div className="w-[full] h-[2px]  my-[8px] bg-[#2E2B2E]"></div>
                <div className="py-[12px] flex justify-between">
                  <div className="flex items-center gap-x-[8px]">
                    <div className="h-full w-[4px] bg-[#EDDF65]"></div>
                    <div>
                      <div className="font-bold">60%</div>
                      <div className="italic uppercase font-[300] text-[#2E2B2EB2]">
                        Complete OFFERS
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-[8px]">
                    <div className=" h-full w-[4px] bg-[#2E2B2E4D]"></div>
                    <div>
                      <div className="font-bold">30%</div>
                      <div className="italic uppercase  font-[300] text-[#2E2B2EB2]">
                        CLOSED SALES
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-x-[8px]">
                    <div className=" h-full w-[4px] bg-[#DF6D50]"></div>
                    <div>
                      <div className="font-bold">10%</div>
                      <div className="italic uppercase  font-[300] text-[#2E2B2EB2]">
                        rescinded
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="w-full relative rounded-[3px]  h-[35px]">
                    <div className="absolute h-full left-0 bg-[#EDDF65] w-[60%]"></div>
                    <div className="absolute h-full left-[60%] bg-[#2E2B2EB2] w-[30%]"></div>
                    <div className="absolute  h-full left-[90%] bg-[#DF6D50] w-[10%]"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[12px] p-6 bg-white ">
              <div className="text-[17px] font-bold">Recent Sales</div>
              <div className="w-[full] h-[1px]   bg-[#2E2B2E]"></div>
              <div className="w-full">
                <table className="w-full ">
                  <tr className="text-[#777777] relative uppercase w-full">
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        UNIT
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        BUILDING
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        AMOUNT
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        Buyer
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        BROKER
                      </div>
                    </th>
                    <div className="w-full absolute h-[1px] -bottom-1  left-0 bg-[#2E2B2E4D]"></div>
                  </tr>

                  <tr className="italic font-[300] pt-6 text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #1108
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$1,145,000</td>
                    <td>Omar Bator</td>
                    <td>Carlos Velasco</td>
                  </tr>
                  <tr className="italic font-[300] pt-6 text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #203
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$828,000</td>
                    <td>Ali Aljuwaji </td>
                    <td>Judy Chang</td>
                  </tr>
                  <tr className="italic font-[300] mt-[24px] text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #2701
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$2,552,000</td>
                    <td>Charlie Wuu</td>
                    <td>Winston Lee</td>
                  </tr>
                  <tr className="italic font-[300] mt-[24px] text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #306
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$994,334,000</td>
                    <td>Madelyn Arcand</td>
                    <td>Aubrey Kent</td>
                  </tr>
                  <tr className="italic font-[300] mt-[24px] text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #1507
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$12,452,000</td>
                    <td>Tabitha Hunter</td>
                    <td>Dustin Jones</td>
                  </tr>
                </table>
                <div className="flex mx-auto mt-[40px]">
                  <div className="flex gap-x-2 items-center mx-auto">
                    <div className="italic text-[#2E2B2E] font-[300]">
                      Show more
                    </div>
                    <div>
                      <img src="/down-arrow.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[12px] p-6 bg-white ">
              <div className="text-[17px] font-bold">Recent Assignments</div>
              <div className="w-[full] h-[2px]  my-[8px] bg-[#2E2B2E]"></div>
              <div className="w-full">
                <table className="w-full flex-nowrap">
                  <tr className="text-[#777777] relative uppercase w-full">
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        UNIT
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        BUILDING
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        AMOUNT
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        Assignor
                      </div>
                    </th>
                    <th>
                      <div className="flex items-center  text-[12px] text-[#2E2B2EB2] italic">
                        BROKER
                      </div>
                    </th>
                    <div className="w-full absolute h-[1px] -bottom-1  left-0 bg-[#2E2B2E4D]"></div>
                  </tr>

                  <tr className="italic font-[300] pt-6 text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #1108
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$1,145,000</td>
                    <td>Stefan Dupuis</td>
                    <td>Katie Wong</td>
                  </tr>
                  <tr className="italic font-[300] pt-6 text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #504
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$828,000</td>
                    <td>Charlie Press</td>
                    <td>Max James</td>
                  </tr>
                  <tr className="italic font-[300] pt-6 text-[#2E2B2E]  text-[16px]">
                    <td className="text-[#2E2B2E] not-italic font-[400] ">
                      Unit #2306
                    </td>
                    <td>1818 Pacifica</td>
                    <td>CA$2,552,000</td>
                    <td>Barney Tong</td>
                    <td>Amber Vuu</td>
                  </tr>
                </table>
                <div className="flex mx-auto mt-[40px]">
                  <div className="flex gap-x-2 items-center mx-auto">
                    <div className="italic text-[#2E2B2E] font-[300]">
                      Show more
                    </div>
                    <div>
                      <img src="/down-arrow.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col  justify-between gap-y-[23px]">
              <div className="flex justify-between gap-x-8">
                <div className=" max-w-[500px] w-full text-center bg-[#FCFCFA] px-8 pt-[64px] pb-[41px] rounded-[16px] font-[700] text-[28px] text-[#2E2B2E]">
                  <div>
                    Lead Generation <br /> Report
                  </div>
                </div>
                <div className="   max-w-[500px] w-full text-center bg-[#FCFCFA] px-8 pt-[64px] pb-[41px] rounded-[16px] font-[700] text-[28px] text-[#2E2B2E]">
                  <div>
                    Sales Team <br />
                    Activity Report
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-x-8">
                <div className=" max-w-[500px] w-full text-center bg-[#FCFCFA] px-8 pt-[64px] pb-[41px] rounded-[16px] font-[700] text-[28px] text-[#2E2B2E]">
                  <div>
                    Email Campaign <br />
                    Report
                  </div>
                </div>
                <div className="  max-w-[500px] w-full text-center bg-[#FCFCFA] px-8 pt-[64px] pb-[41px] rounded-[16px] font-[700] text-[28px] text-[#2E2B2E]">
                  <div>Custom Reports</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColumbiaSalesReportContainer)
