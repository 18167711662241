import React from 'react'
import propTypes from 'prop-types'
import { LottieWrapper } from 'components'
import searching from 'assets/lottieJsons/searching.json'

const Searching = ({ title }) => (
  <>
    <LottieWrapper
      animation={searching}
      loop
      autoplay
      className='h-52 w-52'
    />
    <span className='mt-10 text-2xl'>{title}</span>
  </>
)

export default Searching

Searching.propTypes = {
  title: propTypes.string
}
