/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader, ProjectReservations } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
import { ADMIN_RESERVATION_NEW } from 'routes'
import { connect } from 'react-redux'

import { isMobile } from 'react-device-detect'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 flex items-center'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const Reservations = (props) => {
  const { appProject } = props
  const history = useHistory()

  const createReservation = useCallback(() => {
    history.push({
      pathname: ADMIN_RESERVATION_NEW,
      state: {
        projectId: appProject
      }
    })
  }, [appProject, history])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <Button disabled={!appProject} onClick={() => createReservation()}>
        <TFilledPlusIcon className='h-6 w-3' />
        {isMobile ? 'Create' : 'Create Reservation'}
      </Button>
    </div>
  )

  return (
    <>
      <PageHeader title='Reservations' rightContent={<RightContent />} />
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <ProjectReservations />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Reservations)

Reservations.propTypes = {
  appProject: propTypes.string
}

Button.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func,
  children: propTypes.node
}
