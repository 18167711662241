import React from 'react'
import AddToListButton from './MobileCardComponents/AddToListButton'
import BrokerSelect from './MobileCardComponents/BrokerSelect'
import BuyerLeadApproachSelect from './MobileCardComponents/BuyerLeadApproachSelect'
import DeleteButton from './MobileCardComponents/DeleteButton'
import LeadBrokerSelect from './MobileCardComponents/LeadBrokerSelect'
import LeadSourceSelect from './MobileCardComponents/LeadSourceSelect'
import LeadStatusSelect from './MobileCardComponents/LeadStatusSelect'
import RatingSelect from './MobileCardComponents/RatingSelect'
import ReasonForBuyerLostSelect from './MobileCardComponents/ReasonForBuyerLostSelect'
import ReferralAgentSelect from './MobileCardComponents/ReferralAgentSelect'
import SalesRepOwner from './MobileCardComponents/SalesRepOwner'
import SalesRepSelect from './MobileCardComponents/SalesRepSelect'
import UserImage from './MobileCardComponents/UserImage'
import UserName from './MobileCardComponents/UserName'
import UserType from './MobileCardComponents/UserType'

const MobileCard = ({
  firstName,
  lastName,
  imgsrc,
  email,
  salesRepOwner,
  userType,
  leadStatuses,
  scores,
  handleLeadSourceChange,
  handleReasonForBuyerLostChange,
  handleBuyerLeadApproachChange,
  handleLeadStatusChange,
  leadStatus,
  rating,
  handleRatingChange,
  broker,
  setBroker,
  brokers,
  referralAgent,
  setReferralAgent,
  referralAgents,
  leadSources,
  reasonsForBuyerLost,
  leadApproachesForBuyer,
  selectedLeadSource,
  setSelectedLeadSource,
  selectedReasonForBuyerLost,
  setSelectedReasonForBuyerLost,
  selectedBuyerLeadApproach,
  setSelectedBuyerLeadApproach,
  onClickDelete,
  buyerData,
  buyerEmail,
  developerAdminId,
  handleSalesRepChange,
  projectId,
  salesRep,
  salesrep,
  setSalesRep,
  setOpenAddToList,
  forceMobile = false,
  showDeleteButton = true
}) => {
  return (
    <div className='flex flex-col justify-center items-center w-full overflow-hidden'>
      <UserImage firstName={firstName} lastName={lastName} imgsrc={imgsrc} />
      <UserName firstName={firstName} lastName={lastName} />
      <div className='text-xs text-gray-600 mt-[6px]'>{email}</div>
      <SalesRepOwner salesRepOwner={salesRepOwner} />
      <UserType userType={userType} />
      <SalesRepSelect
        buyerData={buyerData}
        buyerEmail={buyerEmail}
        developerAdminId={developerAdminId}
        handleSalesRepChange={handleSalesRepChange}
        projectId={projectId}
        salesRep={salesRep}
        salesrep={salesrep}
        setSalesRep={setSalesRep}
        userType={userType}
        forceMobile
      />
      <LeadStatusSelect
        userType={userType}
        leadStatuses={leadStatuses}
        leadStatus={leadStatus}
        handleLeadStatusChange={handleLeadStatusChange}
        marginTop='30px'
        forceMobile
      />
      <RatingSelect
        rating={rating}
        ratings={scores}
        handleRatingChange={handleRatingChange}
        marginTop='30px'
        forceMobile
      />
      <BrokerSelect
        broker={broker}
        setBroker={setBroker}
        brokers={brokers}
        userType={userType}
        marginTop='30px'
        forceMobile
      />
      <ReferralAgentSelect
        referralAgent={referralAgent}
        setReferralAgent={setReferralAgent}
        referralAgents={referralAgents}
        userType={userType}
        marginTop='30px'
        forceMobile
      />
      <LeadSourceSelect
        handleLeadSourceChange={handleLeadSourceChange}
        leadSources={leadSources}
        selectedLeadSource={selectedLeadSource}
        setSelectedLeadSource={setSelectedLeadSource}
        userType={userType}
        marginTop='30px'
        forceMobile
      />
      <LeadBrokerSelect
        broker={broker}
        brokers={brokers}
        setBroker={setBroker}
        userType={userType}
        marginTop='30px'
        forceMobile
      />
      <ReasonForBuyerLostSelect
        handleReasonForBuyerLostChange={handleReasonForBuyerLostChange}
        reasonsForBuyerLost={reasonsForBuyerLost}
        selectedReasonForBuyerLost={selectedReasonForBuyerLost}
        setSelectedReasonForBuyerLost={setSelectedReasonForBuyerLost}
        userType={userType}
        marginTop='30px'
        forceMobile
      />
      <BuyerLeadApproachSelect
        handleBuyerLeadApproachChange={handleBuyerLeadApproachChange}
        leadApproachesForBuyer={leadApproachesForBuyer}
        selectedBuyerLeadApproach={selectedBuyerLeadApproach}
        setSelectedBuyerLeadApproach={setSelectedBuyerLeadApproach}
        userType={userType}
        marginTop='30px'
        forceMobile
      />
      {!forceMobile && <AddToListButton setOpenAddToList={setOpenAddToList} />}
      {showDeleteButton && (
        <DeleteButton onClickDelete={onClickDelete} marginTop='30px' />
      )}
    </div>
  )
}

export default MobileCard
