import React from 'react'

const UserImage = ({ firstName = '', lastName = '', imgsrc = '' }) => {
  return (
    imgsrc === ''
      ? (
      <div className='h-18 w-18 border rounded-full flex items-center justify-center bg-gray-200 text-text-gray-500 font-bold'>
        {firstName[0]}
        {lastName[0]}
      </div>
        )
      : (
      <img
        src={imgsrc}
        alt='Avatar'
        className='h-18 w-18 border rounded-full'
      />
        )
  )
}

export default UserImage
