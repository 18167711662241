// @ts-nocheck
import styled from 'styled-components'
import standardStyle from 'assets/css/standardStyle'

export const LeftPanelContainer = styled.div`
  width: 80px;
  height: 100%;
  background: ${standardStyle.colors.secondaryGray};
  z-index: 999;
  border-right: 2px solid black;
`
export const BottomLine = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px solid rgba(46, 43, 46, 0.3);
`
export const BottomLineContainer = styled.div`
    margin-top: 16px;
`
export const LogoContainer = styled.div`
    padding: 16px 16px 8px;
`
export const CreateProjectContainer = styled.div`
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    align-items: center;
    margin: auto;
`
export const FooterMenuContainer = styled.div`
    height: 48px;
    align-items: center;
    margin: auto;
    position: fixed;
    bottom: 30px;
    text-align: center;
    padding: 16px;
`
export const LeftPanelMenuContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 16px;
    max-height: 570px;
`
export const ProjectMenu = styled.button`
    border: ${props => props.isActive ? `2px solid ${standardStyle.colors.softBlack} !important` : 'unset'};
    width: 48px;
    height: 48px;
    margin: 8px auto;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.87px;
    color: ${standardStyle.colors.softBlack};
    font-family: ${standardStyle.fonts.sfpro};
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    background: ${standardStyle.colors.volt};
    cursor: pointer;
    &:hover {
        border: 2px solid ${standardStyle.colors.softBlack} !important;
    }
`
export const CreateProjectButton = styled.button`
    border: unset;
    width: 48px;
    height: 48px;
    margin: 8px auto;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.87px;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    background: unset;
    cursor: pointer;
    color: rgba(46, 43, 46, 0.3);
`
export const LogoutButton = styled.button`
    width: 48px;
    height: 48px;
    margin: 8px auto 0px;
    border-radius: 4px;
    text-align: center;
    letter-spacing: 0.87px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    padding: 8px;
    color: rgba(46, 43, 46, 0.3);
    background: unset;
    border: unset;
    cursor: pointer;
    color: rgba(46, 43, 46, 0.3);
`

export const LeftPanelParent = styled.div`
    min-height: 100vh;
    display: flex;
`
