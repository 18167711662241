/* eslint-disable */
// @ts-nocheck
import { Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useContactContext from '../hooks/useContactContext'
import { IoMdClose } from 'react-icons/io'
import BuyerBrokerFilter from './BuyerBrokerFilter/BuyerBrokerFilter'
import BuyerFilterList from './BuyerFilterList'
import BrokerFilterList from './BrokerFilterList'

const buttonStyles = {
  backgroundColor: '#EDDF65'
}

const FilterDrawer = () => {
  const { state, closeFilterDrawer, setFilters, resetFilters } =
    useContactContext()
  const [selectedState, setSelectedState] = useState({})

  const handleResetFilters = () => {
    resetFilters(true)
    setSelectedState({})
  }

  useEffect(() => {
    if (state.search) {
      setSelectedState({})
    }
  }, [state.search])

  return (
    <div className={`fixed flex flex-col h-screen overflow-y-auto overflow-x-hidden p-4 pt-0 gap-4 w-[300px] bg-white border-r-2 border-softBlack ${state.isFilterDrawerOpen ? 'translate-x-0' : '-translate-x-[600px]'} transition-all duration-300 ease-in-out`} >
      <div className='flex flex-col w-full max-h-full overflow-y-auto overflow-x-hidden p-4 pt-10 gap-4'>
        <div className='flex flex-row w-full justify-between items-center mb-4'>
          <div className='text-xl font-bold'>Contacts</div>
          <button onClick={() => closeFilterDrawer()}>
            <IoMdClose fontSize='22px' />
          </button>
        </div>
        <div>
          <BuyerBrokerFilter />
        </div>
        {state.contactUserType === 'Buyer' && (
          <BuyerFilterList
            setSelectedState={setSelectedState}
            selectedState={selectedState}
          />
        )}
        {state.contactUserType === 'LeadBroker' && (
          <BrokerFilterList
            setSelectedState={setSelectedState}
            selectedState={selectedState}
          />
        )}
        <Button
          variant='solid'
          style={buttonStyles}
          onClick={() => setFilters(selectedState)}
        >
          Filter List
        </Button>
        <Button
          variant='info'
          class='border-softBlack border-2 p-2'
          onClick={handleResetFilters}
        >
          Reset Filters
        </Button>
      </div>
    </div>
  )
}

export default FilterDrawer
