/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  memo,
  useMemo,
  useCallback,
  useRef
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import {
  getProjectDeposits,
  getProjectDepositStatistics
} from 'store/actions/depositActions'
import { Table } from 'ui'
import { formatDate, formatMoney } from 'utils'
import FadedAnimatedDiv from 'components/FadedAnimatedDiv'
import { message } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import UserRoundedImage from 'components/UserRoundedImage'
import Dropdown from './Dropdown'
import { EyeIcon } from '@heroicons/react/outline'
import { CloseIcon, EditIcon } from 'components/Icons'
import { ReactComponent as UserCheck } from 'assets/icons/user-check.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'
import DeleteModal from 'components/DeleteModal'
import { Modal } from '@material-ui/core'

import { isMobile } from 'react-device-detect'

const LoadingContext = createContext()

export const depositStatus = {
  SoldFirm: { text: 'Sold - Firm', backgroundColor: 'bg-green-700' },
}

const UserProfile = ({ name }) => (
  <div className='flex'>
    {/* <UserRoundedImage className='h-16 w-16' /> */}
    <span className='font-medium text-base text-softBlack capitalize'>
      {name}
    </span>
  </div>
)

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const ColoredStatus = ({ status }) => {
  if (depositStatus?.[status]) {
    return (
      <div
        className={`font-medium py-1 text-base text-white uppercase
        ${depositStatus?.[status]?.backgroundColor || 'bg-black'} text-center
      `}
      >
        {depositStatus?.[status]?.text}
      </div>
    )
  }
  return null
}

const CardMoneyTextComponent = memo(({ children }) => (
  <div className='text-lg font-medium text-gray-900'>{children}</div>
))

const StatisticValue = memo(({ title, value, loadingObject }) => {
  const { gettingProjectDeposits, gettingstProjectStatistics } =
    useContext(LoadingContext)

  return (
    <AnimatePresence exitBeforeEnter initial>
      {gettingProjectDeposits || gettingstProjectStatistics ? (
        <FadedAnimatedDiv key={`statisticValueLoading${title}`}>
          {loadingObject}
        </FadedAnimatedDiv>
      ) : (
        <FadedAnimatedDiv key={`statisticValue${title}`}>
          {value}
        </FadedAnimatedDiv>
      )}
    </AnimatePresence>
  )
})

const CardComponent2 = ({ iconSrc, title, amount, noOfUnits }) => (
  <div
    className='bg-white rounded'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='p-4'>
      <div className='w-8 h-4'>{iconSrc}</div>
    </div>
    <div className='flex flex-col space-y-2 p-6'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>
        <StatisticValue
          title={title}
          value={formatMoney(amount || 0)}
          loadingObject={
            <CardMoneyTextComponent>loading...</CardMoneyTextComponent>
          }
        />
      </div>
      <div className='text-sm font-bold text-softBlack pt-4'>
        <StatisticValue title={title} value={`${noOfUnits ?? '0'} Counts`} />
      </div>
    </div>
  </div>
)

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <Table
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      isFromContracts
    />
  )
}

const ActionButton = ({ text, onClick, value }) => {
  return (
    <div
      className='font-medium py-1 text-base text-black uppercase text-center border border-black w-32 cursor-pointer'
      onClick={onClick}
    >
      View/Edit
    </div>
  )
}

const ProjectDeposits = ({ appProject, userObject }) => {
  const history = useHistory()
  const [gettingProjectDeposits, setGettingProjectDeposits] = useState(false)
  const [gettingstProjectStatistics, setGettingProjectStatistics] =
    useState(false)
  const [projectDeposits, setProjectDeposits] = useState([])
  const [filterProjectDeposits, setFilterProjectDeposits] = useState([])
  const [projectStatistics, setProjectStatistics] = useState('')
  const [depositIdShowingDocs, setDepositIdShowingDocs] = useState('')
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [idBeingDeleted, setIdBeingDeleted] = useState('')
  const [showCancelDepositModal, setShowCancelDepositModal] = useState(false)
  const [cancelingDeposit, setCancelingDeposit] = useState(false)
  const [sortType, setSortType] = useState('asc')
  const [sortByField, setSortByField] = useState('createdAt')

  const openDocumentsModal = ({ id: depositId }) => {
    setDepositIdShowingDocs(depositId)
    setShowDocumentsModal(true)
  }

  const onEditClick = ({ id: depositId }) => {
    // history.push({
    //   pathname: ,
    //   state: {
    //     appProject,
    //     depositId
    //   }
    // })
  }

  const onSortClicked = useCallback(
    (field) => {
      let newSortType = 'asc'
      if (field === sortByField) {
        newSortType = sortType === 'asc' ? 'desc' : 'asc'
      }
      const sortedData = projectDeposits.sort((a, b) => {
        if (newSortType === 'asc') {
          return a[field]?.localeCompare(b[field])
        } else if (newSortType === 'desc') {
          return b[field]?.localeCompare(a[field])
        } else return 0
      })
      setFilterProjectDeposits([...sortedData])
      setSortByField(field)
      setSortType(newSortType)
    },
    [projectDeposits, sortByField, sortType]
  )

  const confirmCancelDeposit = ({ id: depositId, status }) => {
    if (status !== 'DepositRejected') {
      setIdBeingDeleted(depositId)
      setShowCancelDepositModal(true)
    } else {
      message.warning('Deposit already canceled')
    }
  }

  const options = [
    {
      title: 'View Documents',
      icon: <EyeIcon className='w-6 h-6' />,
      onClick: (data) => {
        openDocumentsModal(data)
      }
    },
    {
      title: 'Edit',
      icon: <EditIcon className='w-6 h-6' />,
      onClick: (data) => {
        onEditClick(data)
      }
    },
    {
      title: 'Cancel Deposit',
      icon: <CloseIcon className='w-6 h-6' />,
      onClick: (data) => {
        confirmCancelDeposit(data)
      }
    }
  ]

  const EditButton = (deposit) => {
    return (
      <Dropdown
        options={options.map((option) => {
          return { ...option, ...deposit }
        })}
      />
    )
  }

  const cards = useMemo(
    () => [
      {
        IconSrc: <></>,
        title: 'Total deposits collected',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total deposit 1',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total deposit 1 collected',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total 1st deposit  pending',
        amount: 0,
        totalCount: 0
      },
      {
        IconSrc: <></>,
        title: 'Total deposit pending ',
        amount: 0,
        totalCount: 0
      }
    ],
    []
  )

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Date Created</span>
            <button onClick={() => onSortClicked('createdAt')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'createdAt',
        dataIndex: 'createdAt',
        Cell: (props) => <TextField value={formatDate(props.cell.value)} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Buyer</span>
            <button onClick={() => onSortClicked('buyer')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'buyer',
        dataIndex: 'buyer',
        Cell: (props) => <UserProfile name={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Deposit Number</span>
            <button onClick={() => onSortClicked('depositNumber')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'depositNumber',
        dataIndex: 'depositNumber',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Deposit Amount</span>
            <button onClick={() => onSortClicked('depositAmount')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'depositAmount',
        dataIndex: 'depositAmount',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Payment method</span>
            <button onClick={() => onSortClicked('paymentMethod')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'paymentMethod',
        dataIndex: 'paymentMethod',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Date Due</span>
            <button onClick={() => onSortClicked('dateDue')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'dateDue',
        dataIndex: 'dateDue',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Deposit Status</span>
            <button onClick={() => onSortClicked('depositStatus')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'depositStatus',
        dataIndex: 'depositStatus',
        Cell: (props) => <ColoredStatus status={props.cell.value} />
      },
      {
        Title: '',
        accessor: '_id',
        Cell: (props) => (
          <div className='flex flex-row gap-2 text-center items-center justify-between'>
            <ActionButton
              text={props.cell.value}
              value={props.cell}
              onClick={() => {
                // const depositId = props.cell.value
                // history.push(`/new/admin/deposit_preview/${depositId}`)
              }}
            />
            {/* <EditButton
              id={props.row.original._id}
              status={props.row.original.status}
            /> */}
          </div>
        )
      }
    ],
    [onSortClicked]
  )

  const getData = useCallback(() => {
    if (appProject) {
      setProjectDeposits([])
      setGettingProjectDeposits(true)
      setGettingProjectStatistics(true)

      getProjectDepositStatistics(appProject)
        .then((statistics) => {
          const { counters } = statistics
          setProjectStatistics(counters)
          setGettingProjectStatistics(false)
        })
        .catch(() => {
          setGettingProjectStatistics(false)
        })

      getProjectDeposits(appProject)
        .then((Deposits) => {
          const tmpProjectDeposits = Deposits.map((deposit, index) => {
            const {
              buyer: { _id: buyerId, firstName, lastName, email },
              unit: { unitNumber, price: unitPrice },
              project: { totalDollarValueCurrencyType },
              totalCost,
              status,
              _id,
              createdAt
            } = deposit

            const tmpDeposit = {
              _id,
              createdAt,
              buyerId,
              buyer: `${firstName} ${lastName}`.toLowerCase(),
              // buyer: {
              //   name: `${firstName} ${lastName}`.toLowerCase(),
              //   image: null
              // },
              buyerEmail: email,
              unitNumber,
              saleAmount: formatMoney(unitPrice),
              amountOptionsCost: formatMoney(totalCost),
              totalDollarValueCurrencyType,
              status
            }

            return tmpDeposit
          })

          setProjectDeposits(tmpProjectDeposits)
          setGettingProjectDeposits(false)
        })
        .catch(() => {
          setGettingProjectDeposits(false)
        })
    }
  }, [appProject])

  useEffect(() => {
    getData()
  }, [getData])

  const values = useMemo(
    () => ({
      gettingProjectDeposits,
      gettingstProjectStatistics
    }),
    [gettingProjectDeposits, gettingstProjectStatistics]
  )

  const tabeleDataSource = useMemo(() => {
    const values =
      filterProjectDeposits?.length > 0 ? filterProjectDeposits : projectDeposits
    return values.filter((x) => !x.deleted)
  }, [filterProjectDeposits, projectDeposits])

  const cancelDeposit = () => {
    setShowCancelDepositModal(false)
    setCancelingDeposit(true)
    cancelDepositAPI(idBeingDeleted)
      .then(() => {
        setCancelingDeposit(false)
        setIdBeingDeleted('')
        getData()
      })
      .catch(() => setCancelingDeposit(false))
  }

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col mt-6 items-center'>
        <Modal
          open={showDocumentsModal}
          onClose={() => setShowDocumentsModal(false)}
          aria-labelledby='Deposit Documents'
          aria-describedby='Deposit documents list'
        >
          <div
            className='font-openSans relative bg-grey-8 y-0 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
            style={{
              width: isMobile ? '100%' : 1376,
              height: '760px',
              overflowY: 'auto',
              overflowX: 'auto'
            }}
          >
            <button
              className='absolute right-9 top-9'
              style={{ right: '2rem', top: '2rem' }}
              onClick={() => setShowDocumentsModal(false)}
            >
              <CrossIcon />
            </button>
          </div>
        </Modal>
        <DeleteModal
          title='Cancel Deposit'
          description='Are you sure you want to cancel this deposit? It will be deleted permanently'
          isOpen={showCancelDepositModal}
          cancelButtonTitle='Close'
          setIsOpen={() => {
            setShowCancelDepositModal(false)
            setIdBeingDeleted('')
          }}
          confirmButtonTitle='Yes, cancel it'
          onConfirm={cancelDeposit}
        />
        <div className='grid grid-cols1 md:grid-cols-5 gap-4 w-full'>
          {cards.map((card, index) => {
            return (
              <CardComponent2
                IconSrc={card.iconSrc}
                title={card.title}
                amount={card.amount}
                noOfUnits={card.total}
              />
            )
          })}
        </div>

        {isMobile && filterProjectDeposits?.length && (
          <div className='flex flex-col gap-4 mt-5 w-full'>
            {filterProjectDeposits.map((deposit) => (
              <div className='flex flex-col relative bg-white rounded p-4 gap-4'>
                <div className='text-lg'>
                  <strong>Name:</strong> {deposit?.buyer?.name}
                </div>
                <div className='text-lg'>
                  <strong>Email:</strong> {deposit?.buyerEmail}
                </div>
                <div className='text-lg'>
                  <strong>Unit:</strong> {deposit?.unitNumber}
                </div>
                <div className='text-lg'>
                  <strong>Sales Amount:</strong>{' '}
                  {deposit?.totalDollarValueCurrencyType}{' '}
                  {deposit?.saleAmount?.props?.value}
                </div>
                <div className='text-lg'>
                  <strong>Status:</strong> {deposit?.status}
                </div>
                <div className='absolute top-2 right-2'>
                  <EditButton id={deposit._id} status={deposit?.status} />
                </div>
              </div>
            ))}
          </div>
        )}

        {!isMobile && (
          <div className='mt-5 w-full'>
            <MemoTable
              dataSource={tabeleDataSource}
              columns={tableColumns}
              loading={gettingProjectDeposits}
              idBeingDeleted={idBeingDeleted}
              deleting={cancelingDeposit}
            />
          </div>
        )}
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDeposits)

ProjectDeposits.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}

StatisticValue.propTypes = {
  title: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.string]),
  loadingObject: propTypes.object
}

CardComponent2.propTypes = {
  IconSrc: propTypes.node,
  title: propTypes.string,
  amount: propTypes.number,
  noOfUnits: propTypes.number
}
