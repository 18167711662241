/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-spacing */
// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react'

import { TPlusPrimaryIcon, TFilledPenIcon } from 'components/Icons'
import { ReactComponent as Done } from '../../../../assets/icons/done.svg'
import { Oval } from 'react-loader-spinner'

import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import { isArray } from 'lodash-es'

import Option from './Option'
import { useSelector } from 'react-redux'

import {
  createOrUpdateProject,
  getProjects,
  createOrUpdateProjectOption,
  createOrUpdateProjectOptionContractDetail
} from 'store/actions/projectActions'
import CreateProjectContext from '../context/CreateProjectProvider'
import { getProjectContractTemplates } from 'store/actions/contractTemplateActions'

const LoadingProjectpOptions = () => (
  <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
    <Searching title='Loading Project Details...' />
  </FadedAnimatedDiv>
)

const ProjectOptions = ({ viewMode, type, setStep, prev, next }) => {
  const mountedRef = useRef(true)
  const projectcontext = useContext(CreateProjectContext)
  const newProjectId = projectcontext.newProjectId
  const appProject = useSelector((state) => state.appReducer.appProject)

  const projectId = newProjectId || appProject

  const [editMode, setEditMode] = useState(true)
  const [loading, setLoading] = useState(false)
  const [latestContractTemplateId, setLatestContractTemplateId] = useState([])

  const [projectData, setProjectData] = useState({})
  const [deleteHandler, setDeleteHandler] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [allContractTemplates, setAllContractTemplates] = useState([])

  const optionValue = {
    name: '',
    description: '',
    value: '',
    maxAmountPerUnit: '',
    images: [],
    contractDetail: [],
    displayImages: []
  }
  const [pOptions, setPOptions] = useState([
    type === 'edit' && { ...optionValue }
  ])

  const [errorValue, setErrorValue] = useState({
    name: false,
    description: false,
    value: false,
    maxAmountPerUnit: false,
    images: false
  })
  const [errors, setErrors] = useState([type === 'edit' && { ...errorValue }])
  const [contractParams, setContractParams] = useState([])

  // Getting all latest contracts
  const getTemplates = () => {
    getProjectContractTemplates(appProject)
      .then((contractTemplates) => {
        if (!mountedRef.current) return null
        setAllContractTemplates(contractTemplates)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    getTemplates()
  }, [])

  const validate = () => {
    let isValid = true
    const _errors = [...errors]

    for (let index = 0; index < pOptions.length; index++) {
      Object.entries(pOptions[index]).forEach(function ([key, val]) {
        if (key in _errors[index]) {
          if (val.length === 0 || val === '' || val === undefined) {
            _errors[index][key] = true
          } else {
            _errors[index][key] = false
          }
        }
      })
      // eslint-disable-next-line no-loop-func, no-unused-expressions
      Object.entries(errors[index]).forEach(([, val]) => { if (val) isValid = false })
    }
    setErrors([...errors])

    return isValid
  }

  const handleSubmit = async () => {
    const isValid = validate()
    if (!isValid) {
      return
    }
    if (pOptions.length <= 0) {
      setStep(next)
      return
    }

    const oldpOptions = pOptions.filter((pOption) => pOption?._id)
    const newpOptions = pOptions.filter(
      (pOption) => pOption?.displayImages.length > 0 && !pOption?._id
    )
    setLoading(true)
    if (oldpOptions.length > 0) {
      let { options } = projectData
      const results = options.filter(
        ({ _id: id1 }) => !oldpOptions.some(({ _id: id2 }) => id2 === id1)
      )
      const tmpoption = results.concat(oldpOptions)
      options = [...tmpoption]
      const params = {
        ...projectData,
        options
      }
      // Update
      try {
        await createOrUpdateProject({ params })
      } catch (err) {
        setLoading(false)
      }
      setLoading(false)
      if (viewMode === 'create') {
        setStep(next)
      }

      const _contractParams = [...contractParams]
      const oldContractParams = _contractParams.splice(0, oldpOptions.length)

      oldContractParams.forEach(async (contractParam, index) => {
        if (Object.keys(contractParam).length > 0) {
          try {
            const data = await createOrUpdateProjectOptionContractDetail(contractParam)
            let { contractDetail } = data.options.filter(
              (option) =>
                option.type === type &&
                      option._id === pOptions[index]._id
            )
            contractDetail = contractDetail.pop()
          } catch (err) {
            console.log(err)
          }
        }
      })
    }
    if (newpOptions.length) {
      newpOptions.forEach(async (pOption, index) => {
        const params = {
          ...pOption,
          ...{ type, projectId }
        }
        const optionCreated = await createOrUpdateProjectOption(params)
        setLoading(false)
        if (viewMode === 'create') {
          setStep(next)
        }
        const { optionId } = optionCreated
        const contractIndex = oldpOptions.length + index
        const temp = {
          ...contractParams[contractIndex],
          optionId
        }
        if (typeof contractParams[contractIndex] !== 'undefined' && Object.keys(contractParams[contractIndex]).length > 0) {
          try {
            const data = await createOrUpdateProjectOptionContractDetail(temp)
            let { contractDetail } = data.options.filter(
              (option) =>
                option.type === type && option._id === optionId
            ).pop()
            contractDetail = contractDetail.pop()
            if (contractDetail) {
              const temps = [
                ...latestContractTemplateId,
                contractDetail.pop()._id
              ]
              setLatestContractTemplateId(temps)
            }
          } catch (error) {
            console.log(error)
          }
        }
        getProjectDetail(projectId)
      })
    }
  }

  const addOptionHandler = () => {
    setErrors([...errors, { ...errorValue }])
    setPOptions([...pOptions, { ...optionValue }])
  }

  const getProjectDetail = (projectId) => {
    getProjects(projectId).then((project) => {
      if (!mountedRef.current) return null
      const { options } = project
      if (isArray(options)) {
        let tmppOptions = options.filter((option) => option.type === type)

        tmppOptions = tmppOptions.map((pOption) => ({
          ...pOption,
          displayImages: []
        }))
        setPOptions(tmppOptions)
        const tmpErrors = []
        tmppOptions.forEach(function () {
          tmpErrors.push(errorValue)
        })
        setErrors(tmpErrors)
      }
      setProjectData({
        ...project,
        developer: project.developer._id,
        id: project._id,
        createdBy: project.createdBy._id
      })
      setIsLoading(false)
    })
  }
  useEffect(() => {
    getProjectDetail(projectId)
  }, [projectId])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const { options } = projectData
    if (isArray(options)) {
      let tmppOptions = options.filter((option) => option.type === type)

      tmppOptions = tmppOptions.map((pOption) => ({
        ...pOption,
        displayImages: []
      }))
      setPOptions(tmppOptions)
    }
  }, [projectData])
  // console.log(pOptions)
  return (
    <>
      {isLoading && <LoadingProjectpOptions />}
      {!isLoading &&
        pOptions.map((option, index) => (
          <Option
            key={index}
            index={index}
            pOptions={pOptions}
            setPOptions={setPOptions}
            lengthOfOptions={pOptions.length}
            values={option}
            editMode={editMode}
            setProjectData={setProjectData}
            projectData={projectData}
            setDeleteHandler={setDeleteHandler}
            type={type}
            viewMode={viewMode}
            allContractTemplates={allContractTemplates || null}
            errors={errors}
            errorValue={errors[index]}
            setErrors={setErrors}
            params={contractParams}
            setParams={setContractParams}
            latestContractTemplateId={latestContractTemplateId}
            setLatestContractTemplateId={setLatestContractTemplateId}
          />
        ))}
      <div className='border-b border-softBlack_30 w-full'></div>
      <div className='my-10'>
        <button
          className='flex items-center font-openSans italic font-light capitalize'
          onClick={addOptionHandler}
        >
          <TPlusPrimaryIcon className='w-4 h-4 mr-1' />
          Add {type}
        </button>
      </div>

      <div>
        {viewMode !== 'create' && (
          <>
            {!editMode
              ? (
              <button
                onClick={() => setEditMode(true)}
                className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'
              >
                <TFilledPenIcon className='h-4 w-4 mr-2' />
                Edit
              </button>
                )
              : (
              <button
                onClick={handleSubmit}
                className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'
              >
                {loading && <Oval height='18' width='18' color='black' />}
                {!loading && <Done className='h-4 w-4 mr-2' />}
                Save
              </button>
                )}
          </>
        )}
      </div>
      {viewMode === 'create' && (
        <div className='flex justify-end'>
          <button
            onClick={() => setStep(prev)}
            className='px-6 py-3 mt-6 rounded flex items-center border border-softBlack_70 font-openSans italic font-light'
          >
            Back
          </button>
          <button
            onClick={handleSubmit}
            className='ml-auto bg-volt px-6 py-3 mt-6 rounded flex items-center font-openSans italic font-light'
          >
            {loading && <Oval height='18' width='18' color='black' />}
            Next
          </button>
        </div>
      )}
    </>
  )
}

export default ProjectOptions
