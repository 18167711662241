import React, { useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { BuyerSideMenu, TMenuIcon } from 'components'

const BuyerContainer = (props) => {
  const { children } = props
  const [showMenu, setShowMenu] = useState(false)

  const OpenMenuButton = () => (
    <button
      onClick={() => {
        setShowMenu(true)
      }}
      className={`fixed px-5 py-5 text-black focus:outline-none
      focus:ring-2 focus:ring-inset focus:ring-cyan-500 z-10 ${showMenu && 'hidden'}`}
    >
      <span className='sr-only'>Open sidebar</span>
      <TMenuIcon className='h-6 w-6' />
    </button>
  )

  return (
    <div className='h-screen w-screen' id='buyerWrapper'>
      <BuyerSideMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
      <OpenMenuButton />

      {children}
    </div>
  )
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerContainer)

BuyerContainer.propTypes = {
  children: propTypes.node
}
