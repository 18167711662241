import React from 'react'
import ContactDetailDrawer from './ContactDetailDrawer/ContactDetailDrawer'
import ContactsTable from './ContactsTable/ContactsTable'
import MainContactProvider from './context/MainContactContext'
// import FilterDrawer from './FilterDrawer/FilterDrawer'
import { ContactProvider } from 'pages/contacts/context/Contacts'
import AddToListDrawer from './AddToListDrawer/AddToListDrawer'

const NewContacts = () => {
  return (
    <MainContactProvider>
      <div className='flex'>
        {/* <FilterDrawer /> */}
        <ContactsTable />
        <ContactProvider>
          <ContactDetailDrawer />
        </ContactProvider>
        <AddToListDrawer />
      </div>
    </MainContactProvider>
  )
}

export default NewContacts
