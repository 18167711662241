import { ContactContext } from 'pages/contacts/context/Contacts'
import React, { useContext } from 'react'

const AssignSalesrep = ({ setOpen }) => {
  const { saveList } = useContext(ContactContext)
  return (
    saveList?.length
      ? <div>
            <div style={{ marginLeft: '11px' }}>
            <button
              onClick={() => setOpen(true)}
              type='button'
              style={{ fontStyle: 'italic', padding: '15px' }}
              className='inline-flex items-center justify-left rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
            >
              <span
                style={{
                  marginLeft: '10px',
                  fontSize: '16px',
                  marginRight: '10px'
                }}
              >
                + Assign to SalesRep
              </span>
            </button>
          </div>
      </div>
      : null
  )
}

export default AssignSalesrep
