import React, { useState } from 'react'
import {
  AdminPageWrapper,
  PageHeader,
  ViewSwitcher
} from 'components'
import { connect, useSelector } from 'react-redux'
import ProjectParkingsInventory from 'components/ProjectParkingsInventory'

const ParkingsInventory = () => {
  const [viewType, setViewType] = useState('cardView')
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const RightContent = () => (
    <>
      {!window?.location?.href?.includes('public/inventory') && (
        <div className='flex justify-center items-center font-bold w-36 border border-black rounded cursor-pointer'>
          <a
            href={`${
              window.location.origin
            }/public/parking_inventory?projectId=${btoa(projectId)}`}
            target='_blank'
          >
            Open Public URL
          </a>
        </div>
      )}
      <ViewSwitcher
        view={viewType}
        onChange={(_viewType) => {
          setViewType(_viewType)
        }}
      />
    </>
  )

  return (
    <>
      <PageHeader title='Parkings Inventory' rightContent={<RightContent />} />
      <AdminPageWrapper fullscreen>
        <ProjectParkingsInventory viewType={viewType} />
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  // appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ParkingsInventory)

ParkingsInventory.propTypes = {
  // appProject: propTypes.string
}
