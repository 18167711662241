/* eslint-disable */
import { BedIcon, EditIcon } from 'components';
import { ReactComponent as TFilledBasketIcon } from 'assets/icons/deleteIcon.svg'
import { ReactComponent as TFilledPencilIcon } from 'assets/icons/editIcon.svg'
import Tag from 'components/Tags';
import React from 'react';
const Button = ({ onClick, children, className = '', disabled = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'px-4 py-2 border border-gray-700 text-sm rounded flex items-center justify-center hover:bg-gray-100 ' +
        className
      }
    >
      {children}
    </button>
  );
};

const CustomParkingDetail = ({
  unit = {
    image: '',
    name: 'Unit 1-5',
    price: '',
    description: 'lorem ipsum',
    unitsAdded: '',
    status: '',
    bedrooms: '',
    bathrooms: '',
    balcony: '',
  },
  handleEdit,
  handleDelete,
}) => {
  return (
    <div className='p-4 flex space-x-4 text-softBlack bg-white rounded  border border-dashed border-softBlack_30 relative'>
      {unit.image && (
        <div className='w-48 h-32 overflow-hidden flex items-center'>
          <img src={unit.image} alt='Uploaded Image' className='object-cover' />
        </div>
      )}
      <div>
        {unit.name && (
          <h1 className='font-bold' style={{ fontSize: 22 }}>
            {unit.name}
          </h1>
        )}
        {unit.status && (
          <Tag
            label={unit.status}
            classes='italic'
            containerClasses='absolute top-4 right-4'
            type='Reserved'
          />
        )}

        {unit.price && (
          <h1
            className='font-light text-softBlack italic'
            style={{ fontSize: 14, marginTop: '-10px' }}
          >
            {unit.price}
          </h1>
        )}

        <div className='flex items-center gap-4'>
          {unit.bedrooms && (
            <div className='flex items-center gap-4'>
              <BedIcon />
              <h1 className='m-0'>{unit.bedrooms}</h1>
            </div>
          )}
          {unit.bathrooms && (
            <div className='flex items-center gap-4'>
              <BedIcon />
              <h1 className='m-0'>{unit.bathrooms}</h1>
            </div>
          )}
          {unit.balcony && (
            <div className='flex items-center gap-4'>
              <BedIcon />
              <h1 className='m-0'>Balcony</h1>
            </div>
          )}
        </div>

        {unit.description && (
          <div className='text-xs space-x-2 mt-3 font-openSans flex items-center gap-1 text-black'>
            {unit.description}
          </div>
        )}
        {unit.unitsAdded && (
          <div className='text-xs space-x-2 mt-3 font-openSans flex items-center gap-1 text-black'>
            {unit.unitsAdded} units added
          </div>
        )}
        <div className='mt-6 space-x-4 flex'>
          <Button className='font-openSans italic' onClick={handleEdit}>
            <TFilledPencilIcon />
            Edit
          </Button>
          <Button className='font-openSans italic' onClick={handleDelete}>
            <TFilledBasketIcon />
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomParkingDetail;
