import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import CancelModal from 'components/Modal/Cancel'

const NoHeader = ({ title, redirect }) => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    history.push(redirect)
  }
  const cancelMessage = 'Are you sure you want to cancel?'
  return (
    <>
      <div className='bg-menuGray fixed flex justify-between items-center px-8 pt-6 pb-6 border-b-2 border-softBlack z-10' style={{ width: 'calc(100% - 88px)' }}>
        <p className='my-0 text-4xl font-bold font-openSans'>{ title }</p>
        <button className='text-base font-thin italic font-openSans' onClick={onCancel}>Cancel</button>
      </div>
      <CancelModal {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm, cancelMessage }} />
    </>
  )
}

export default NoHeader
