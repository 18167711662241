import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getSearchContacts } from 'store/actions/usersActions'
import { toast } from 'react-hot-toast'

const useGetSearchUsers = (search, userType) => {
  // @ts-ignore
  const userId = useSelector((state) => state.authReducer?.userObject?._id)

  const onSuccess = (searchUsers) => {
    if (searchUsers === null || !searchUsers?.data?.length) return toast.error('No users were found')
    toast.success(`Found ${searchUsers?.totalRecords} contacts`)
  }

  const { data, isLoading, isFetching } = useQuery(
    ['getSearchUsers', search, userType],
    ({ queryKey }) => getSearchContacts(queryKey[1], queryKey[2]),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId && !!search, onSuccess })
  return { searchUsers: data, searching: isLoading, isFetching }
}

export default useGetSearchUsers
