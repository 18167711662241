import { message } from 'antd'
import { api, ApiErrors } from 'services/api'

export const getContractTemplates = (id = '') => new Promise((resolve, reject) => {
  api.get(`/contracttemplates/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getProjectContractTemplates = (id = '') => new Promise((resolve, reject) => {
  api.get(`/contracttemplates/projects/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateContractTemplate = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `contracttemplates/${_id}` : 'contracttemplates'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`Contract was ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteContractTemplate = (id) => new Promise((resolve, reject) => {
  api.delete(`/contracttemplates/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Contract deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
