/* eslint-disable no-unused-vars */

import React, { useState } from 'react'
import { PhoneIcon } from '@heroicons/react/solid'
import useGetParticipantCalls from 'hooks/useGetParticipantCalls'
import Skeleton from 'react-loading-skeleton'

import CallCard from './CallCard'

import { CallModal } from './CallModal'

export const CallTab = ({ contact }) => {
  const { calls, loading, refetch } = useGetParticipantCalls(contact._id)

  const [open, setOpen] = useState(false)

  const onSuccess = () => {
    refetch()
  }

  return (
    <>
      {loading && (
        <div className='flex flex-col gap-6 mt-8'>
          <Skeleton height={100} />
        </div>
      )}
      {!loading && calls.length === 0 && (
        <div className="font-openSans flex flex-col justify-center items-center h-96 w-full bg-white mt-8 mb-8 rounded">
          <PhoneIcon className="h-10 w-10" />
          <div className="font-semibold mb-2 mt-4">
            You don't have any calls yet
          </div>
          <div className='text-sm text-softBlack70'>Start a call register by clicking the button below</div>
          <button
            onClick={() => {
              setOpen(true)
            }}
            className="bg-yellow-200 font-semibold px-4 py-2 rounded mt-4"
          >
            New Call
          </button>
        </div>
      )}
      {calls.length > 0 && (
        <div>
          <div className='font-openSans flex flex-col gap-6 my-8'>
            {calls?.map((_call, index) => <CallCard call={_call} key={`${_call._id}_${index}`} onSuccess={onSuccess} contact={contact} />)}
          </div>
          <div className='text-center'>
            <button
              onClick={() => {
                setOpen(true)
              }}
              className="bg-yellow-200 font-semibold px-4 py-2 rounded"
            >
              New Call
            </button>
          </div>
        </div>
      )}
      <CallModal show={open} setClose={setOpen} contact={contact} onSuccess={onSuccess} call={null} />
    </>
  )
}
