/* eslint-disable */
import React, { useCallback, useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import ProjectFiles from 'components/ProjectFiles'
import { EyeIcon } from '@heroicons/react/outline'
import { TButton } from 'ui'

const ProjectFileSelector = ({ onSuccess, title }) => {
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleClose = useCallback(async () => {
    setShow(false)
    const copiedValue = (await navigator.clipboard.readText()) ?? ''
    onSuccess?.(copiedValue)
    setSelectedItem(JSON.parse(copiedValue))
  }, [onSuccess])

  return (
    <div className='flex flex-col'>
      <span className='pb-2'>Select {title ?? 'File'}:</span>
      <div className='flex flex-row w-full gap-2 items-center justify-center content-center'>
        <TButton
          className='w-full'
          onClick={() => {
            setShow(true)
          }}
        >
          Open {title ?? 'File'} Selector
        </TButton>
        {Object.keys(selectedItem ?? {})?.length > 0 && (
          <a
            className='p-[6px] bg-white leading-[0] rounded border shadow-sm h-full flex justify-center items-center text-center w-1/2 gap-2 py-3'
            // @ts-ignore
            href={selectedItem?.url}
            target='_blank'
            style={{
              textDecoration: 'none'
            }}
          >
            Preview {title ?? 'File'}
          </a>
        )}
      </div>
      <Modal
        open={show}
        aria-labelledby='Select OR Upload File Modal'
        aria-describedby='Select OR Upload File Modal Description'
      >
        <div
          className='relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
        >
          <button className='absolute right-10' onClick={handleClose}>
            <CrossIcon />
          </button>
          <h1 className='font-bold text-xl text-center'>
            Upload OR Select {title ?? 'File'} From
          </h1>
          <div className='relative w-full h-full'>
            <ProjectFiles />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ProjectFileSelector
