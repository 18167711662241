import React from 'react'

import { LogoContainer } from './styles'
import ImageLink from 'components/ImageLink'
import logo from '../../assets/img/royalLogo.png'

const AdminLogo = () => {
  return (
        <LogoContainer>
            <ImageLink
                src={logo}
                link='/admin#/admin'
            />
        </LogoContainer>
  )
}

export default AdminLogo
