/* eslint-disable no-unused-vars */
// @ts-nocheck
/* eslint-disable indent */
import React, { useState } from 'react'
import useGetLists from 'hooks/useGetLists'
import { MdDelete } from 'react-icons/md'
import { deleteList } from 'store/actions/usersActions'
import AddToListModal from 'components/TailwindTable/components/AddToListModal'
import ContactListCreateModal from 'components/TailwindTable/components/ContactListCreateModal'
import useCreateContactList from 'hooks/useCreateContactList'
import ListDeleteModal from 'pages/contacts/popups/ListDelete'
import { useQueryClient } from '@tanstack/react-query'

const ContactList = ({ contact }) => {
    const client = useQueryClient()
    const { lists } = useGetLists()
    const [showDeleteContact, setShowDeleteContact] = useState(false)
    const [listId, setListId] = useState()
    const [listName, setListName] = useState()
    const [openAddToList, setOpenAddToList] = useState(false)
    const [openCreateList, setOpenCreateList] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const { createSaveList: createContactList, loading } =
      useCreateContactList()
    const id = contact?.id
    return (
        <>
        <div className='h-56 overflow-y-scroll'>
        {lists?.filter?.(list => list?.users?.includes?.(id)).map((list, index) => {
                return (
                    <div className='flex align-center h-auto mt-1'>
                        <div className='flex gap-x-2.5' style={{ width: '40%' }}>
                            <input
                                type='checkbox'
                                className='h-4  w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500  hover:cursor-pointer'
                            />
                            <h1 className='ml-3 text-softBlack70 text-sm font-medium'>{list.title}</h1>
                        </div>
                        <MdDelete className='ml-7 mt-1 cursor-pointer' onClick={() => {
                            setListId(list?._id)
                            setListName(list?.title)
                            setShowDeleteContact(true)
                        }} />
                    </div>
                )
            })}
        </div>
            <ListDeleteModal
                isOpen={showDeleteContact}
                listName={listName}
                loading={setDeleteLoading}
                onClose={() => setShowDeleteContact(false)}
                onDelete={async () => {
                    await deleteList(listId, contact?.id, setDeleteLoading)
                    setShowDeleteContact(false)
                    setDeleteLoading(true)
                    client.invalidateQueries(['getLists'])
                }}
            />
            <div className=' flex flex-1 gap-4' style={{ marginTop: '15px', height: '40px' }}>
                <button
                    onClick={() => setOpenAddToList(true)}
                    type='button'
                    style={{ fontStyle: 'italic', padding: '5px', height: '40px' }}
                    className='inline-flex items-center justify-left rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
                >
                    <span
                        style={{
                            marginLeft: '10px',
                            fontSize: '14px',
                            marginRight: '10px'
                        }}
                    >
                        + Add To List
                    </span>
                </button>
            </div>

            <ContactListCreateModal
                open={openCreateList}
                setOpen={setOpenCreateList}
                createContactList={createContactList}
                savingContacts={loading}
                showBackButtonToAddList
                setAddToListOpen={setOpenAddToList}
                refetchLists={() => client.invalidateQueries(['getLists'])}
            />
            <AddToListModal
                open={openAddToList}
                setOpen={setOpenAddToList}
                selectedContactId={id}
                showCreateListButton
                setOpenCreateListModal={setOpenCreateList}
                refetchLists={() => client.invalidateQueries(['getLists'])}
            />
        </>
    )
}
export default ContactList
