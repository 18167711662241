import React from 'react'
import { Select } from 'antd'
import { isMobile } from 'react-device-detect'

const LeadSourceSelect = ({ userType, selectedLeadSource, leadSources, setSelectedLeadSource, handleLeadSourceChange, marginTop, forceMobile = false }) => (
  userType === 'Buyer'
    ? <div
        style={{
          position: 'relative',
          overflow: 'visible',
          marginLeft: isMobile || forceMobile ? '' : '40px',
          marginTop
        }}
    >
        <div
        className='text-softBlack70'
        style={{
          position: 'absolute',
          width: '100%',
          fontSize: '18px',
          fontWeight: '300',
          top: isMobile || forceMobile ? '-25px' : '-20px',
          left: '10px'
        }}
        >
        Lead Source
        </div>
        <Select
        showSearch={true}
        filterOption={(input, option) =>
          (option?.label ?? '')
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        value={selectedLeadSource}
        onChange={(value) => {
          setSelectedLeadSource(value)
          handleLeadSourceChange(value)
        }}
        className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
        >
        {leadSources?.map((_item, index) => (
            <Select.Option value={_item._id} key={index} label={_item.label}>
            <div className='flex gap-2 items-center'>
                <span>{_item.label}</span>
            </div>
            </Select.Option>
        ))}
        </Select>
    </div>
    : null
)

export default LeadSourceSelect
