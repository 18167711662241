/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import moment from 'moment'

import DeleteModal from 'components/DeleteModal'

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'

import NewMeeting from 'components/NewMeeting'
import { deleteCalendarEvent } from 'store/actions/usersActions'
import { getCalenderDateMomentUsingObjectType } from 'utils'

const MeetingCard = ({ meeting, contact, onSuccess, buyers }) => {
  const [loading, setLoading] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)

  const onConfirmDelete = async () => {
    setLoading(true)
    try {
      await deleteCalendarEvent(meeting.id)
      setIsDeleteModal(false)
      onSuccess()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  if (meeting?.when?.object !== 'timespan') {
    return null
  }

  const startDate = moment(getCalenderDateMomentUsingObjectType(meeting.when.object, meeting.when.start_time))

  return (
    <>
      <div className='group border border-softBlack_10 bg-white p-6 flex gap-4 rounded'>
        <div className='bg-volt text-softBlack h-11.75 w-14 flex justify-center items-center flex-col text-center'>
          <div className='text-sm font-semibold'>{startDate.format('DD')}</div>
          <div className='text-xm'>{startDate.format('MMM YY')}</div>
        </div>
        <div className='w-full'>
          <div className='flex justify-between w-full'>
            <div className='text-base font-medium mb-1'>
              {meeting?.title}
            </div>
            <div className='flex gap-4 invisible group-hover:visible'>
              <button onClick={() => setIsEditModal(true)}><EditIcon /></button>
              <button onClick={() => setIsDeleteModal(true)}><TrashIcon /></button>
            </div>
          </div>
          <div className='text-base text-softBlack70'>
            {moment(getCalenderDateMomentUsingObjectType(meeting.when.object, meeting.when.start_time)).format('YYYY/MM/DD hh:mm A')} - {moment(getCalenderDateMomentUsingObjectType(meeting.when.object, meeting.when.end_time)).format('YYYY/MM/DD hh:mm A')}
            </div>
        </div>
      </div>
      <DeleteModal
        title='Confirm deletion?'
        description='This meeting will be deleted permanently!'
        confirmButtonTitle='Delete Meeting'
        cancelButtonTitle='Cancel'
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={onConfirmDelete}
        loading={loading}
      />
      {isEditModal ? <NewMeeting isOpen={isEditModal} setOpen={setIsEditModal} contact={contact} meeting={meeting} onSuccess={onSuccess} buyers={buyers}/> : null}
    </>
  )
}

export default MeetingCard
