import React, { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { IntercomProvider } from 'react-use-intercom'

import ROUTES, { RenderRoutes } from 'routes'
import { updateLoggedUserObject } from 'utils'

import TagManager from 'react-gtm-module'
import HistoryProvider from 'global/HistoryContext'

import { Toaster } from 'react-hot-toast'
import useProjectChange from 'hooks/useProjectChange'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs)

// Load stripe promise
const promise = loadStripe(
  'pk_test_51HEZN8GN9TL1P8ikv0MqANAe3n82XSECpOdokzDLBdOC8UPZS8XQyXWu1dFqyTCbrhoGeGYwDXk93w5MMACf0WGv00rsUJVztP'
)

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID

const App = () => {
  useProjectChange()
  useEffect(() => {
    updateLoggedUserObject()
  }, [])

  return (
    <div>
      <Toaster />
      <Elements stripe={promise}>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <HistoryProvider>
            <RenderRoutes routes={ROUTES} />
          </HistoryProvider>
        </IntercomProvider>
      </Elements>
    </div>
  )
}

export default App
