import React, { useState } from 'react'
import Logo from './Logo'
import background from '../../assets/img/bg.png'
import { forgotPassword, setResetPassword } from 'store/actions/usersActions'

import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { LOGIN } from 'routes'

const Input = ({ placeholder, label, value, onChange, defaultValue = '', type = 'text' }) => {
  return (
    <label className='text-xs w-full'>
      <div className='text-xs'>{label}</div>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        className='border p-2 px-3 leading-none mt-2 w-full placeholder:text-gray-400'
      />
    </label>
  )
}

export const SignUpPage = ({
  firstName = { onChange: () => {}, value: '' },
  lastName = { onChange: () => {}, value: '' },
  developmentCompany = { onChange: () => {}, value: '' },
  email = { onChange: () => {}, value: '' },
  password = { onChange: () => {}, value: '' },
  onCreateAccount = () => {},
  onSignInInstead = () => {}
}) => {
  return (
    <div className='min-h-screen w-100 flex'>
      <div className='p-8 bg-stone-100 w-full md:w-1/2'>
        <div className='relative flex flex-col justify-center items-center h-full'>
          <Logo />
          <div className='max-w-md mt-auto'>
            <h1 className='text-2xl font-bold'>Welcome to Aires</h1>
            <p className='text-sm mt-3'>Please enter your details</p>
            <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
              <Input placeholder='Enter first name' label='FIRST NAME *' {...firstName} />
              <Input placeholder='Enter last name' label='LAST NAME *' {...lastName} />
              <Input
                placeholder='Enter development company'
                label='DEVELOPMENT COMPANY *'
                {...developmentCompany}
              />
              <Input type='email' placeholder='Enter email' label='EMAIL *' {...email} />
              <Input
                type='password'
                placeholder='Enter password'
                label='ENTER PASSWORD *'
                {...password}
              />
              <p className='text-[0.7rem] relative bottom-2'>
                * Password must contain at least 6 characters A-Z, including lower and upper case
                and digits.
              </p>
              <button
                className='w-full bg-yellow-200 mt-4 p-3 focus:border-[3px] hover:bg-white hover:text-yellow-400 hover:border-yellow-100 transition-all focus:border-blue-300 text-gray-600 font-sm'
                onClick={onCreateAccount}>
                Create Account
              </button>
            </div>
          </div>
          <div className='mt-auto pt-2'>
            Already have an account? <strong onClick={onSignInInstead}>Sign In</strong>
          </div>
        </div>
      </div>
      <div className='w-0 hidden md:w-1/2 md:flex h-full'>
        <img alt='background' className='object-cover h-full w-full' src={background} />
      </div>
    </div>
  )
}

export const LoginPage = ({
  email = { onChange: () => {}, value: '' },
  password = { onChange: () => {}, value: '' },
  onLoggingIn = () => {},
  loginProgress = false,
  onSignUpInstead = () => {},
  onForgotPassword = () => {},
  isResetPasswordPage = false,
  resetToken = '',
  ...props
}) => {
  const [resetPassword, setResetPasswords] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const history = useHistory()

  const updatePassword = () => {
    // eslint-disable-next-line no-useless-return
    if (resetPassword !== confirmPassword) {
      message.error('Passwords do not match')
      return
    } else if (resetPassword.length === 0) {
      message.error('Please fill all required fields')
      return
    }

    setResetPassword(resetToken, resetPassword).then((response) => {
      if (response?.success) {
        message.success('Password reset successfull')
        setTimeout(() => {
          history.push(LOGIN)
        }, 1000)
      }
    })
  }

  return (
    <div className='h-screen w-100 flex'>
      <div className='p-8 bg-stone-100 w-full md:w-1/2'>
        <div className='relative flex flex-col justify-center items-center h-full'>
          <Logo />
            <div className='max-w-md w-full' style={{ margin: 'auto' }}>
            {!isResetPasswordPage
              ? <><h1 className='text-2xl font-bold'>Welcome Back</h1>
            <p className='text-sm mt-3'>Please enter your details</p>
            <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
              <Input type='email' placeholder='Enter email' label='EMAIL *' {...email} />
              <Input
                type='password'
                placeholder='Enter password'
                label='ENTER PASSWORD *'
                {...password}
              />
              <button
                className='text-[0.7rem] font-bold relative bottom-2 self-end'
                onClick={onForgotPassword}>
                Forgot Password?
              </button>
              <button
                className='w-full bg-yellow-200 mt-4 p-3 focus:border-[3px] hover:bg-white hover:text-yellow-400 hover:border-yellow-100 transition-all focus:border-blue-300 text-gray-600 font-sm'
                onClick={onLoggingIn}>
                {loginProgress ? 'Loading...' : 'Sign In'}
              </button>
            </div>
            </>
              : <><h1 className='text-2xl font-bold'>Reset Password</h1>
            <p className='text-sm mt-3'>Please enter your new password</p>
            <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
              <Input
                type='password'
                placeholder='Enter password'
                label='PASSWORD *'
                value={resetPassword}
                onChange={(e) => setResetPasswords(e?.target?.value)}
                />
              <Input
                type='password'
                placeholder='Confirm password'
                label='CONFIRM PASSWORD *'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e?.target?.value)}
              />
              <button
                className='w-full bg-yellow-200 mt-4 p-3 focus:border-[3px] hover:bg-white hover:text-yellow-400 hover:border-yellow-100 transition-all focus:border-blue-300 text-gray-600 font-sm'
                onClick={updatePassword}
                style={{ marginTop: 30 }}
                >
                Update Password
              </button>
            </div>
            </>}
          </div>
        </div>
      </div>
      <div className='w-0 md:w-1/2 md:flex h-full'>
        <img className='object-cover h-full w-full' alt='background' src={background} />
      </div>
    </div>
  )
}

export const ResetPasswordPage = ({
  onLoggingIn: onSendLink = () => {
    console.log('sending link')
  },
  userEmail = '',
  setRequestingRecoveryPassword,
  onSignInInstead = () => {},
  ...props
}) => {
  const [email, setEmail] = useState(userEmail)
  const [linkSent, setLinkSent] = useState(false)

  const onForgotPassword = () => {
    forgotPassword(email)
      .then(() => {
        setLinkSent((prev) => !prev)
      })
  }
  return (
    <div className='h-screen w-100 flex'>
      <div className='p-8 bg-stone-100 w-full md:w-1/2'>
        <div className='relative flex flex-col justify-center items-center h-full'>
          <Logo />
          <div className='max-w-md w-full' style={{ margin: 'auto' }}>
            {!linkSent
              ? (<>
                <button className='flex items-center space-x-2 text-xs font-bold'
                  onClick={() => { setRequestingRecoveryPassword(false) }}
                >
                  <svg
                    width='15'
                    height='10.5'
                    viewBox='0 0 20 14'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M7.25065 13.4163L8.54315 12.1238L4.34482 7.91634H19.1673V6.08301H4.34482L8.55232 1.87551L7.25065 0.583008L0.833984 6.99967L7.25065 13.4163Z'
                      fill='#2E2B2E'
                    />
                  </svg>
                  <span>Sign In</span>
                </button>
                <h1 className='text-2xl font-bold pt-4'>Reset Password</h1>
                <p className='text-sm mt-3'>
                  Please enter your email and we'll send you a link for password resetting
                </p>
                <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
                  <Input type='email' placeholder='Enter email' label='EMAIL *' defaultValue={userEmail} onChange={(e) => setEmail(e.target.value)} />
                  <div>
                    <button
                      className='w-full bg-yellow-200 mt-3 p-3 focus:border-[3px] hover:bg-white hover:text-yellow-400 hover:border-yellow-100 transition-all focus:border-blue-300 text-gray-600 font-sm'
                      onClick={() => {
                        onForgotPassword()
                      }}>
                        Send Link
                    </button>
                  </div>
                </div>
              </>)
              : (
              <>
                <h1 className='text-2xl font-bold pt-4'>Link Sent</h1>
                <p className='text-sm mt-3'>
                  Link was sent to your email <strong>{email}</strong>
                </p>
                <div className='flex flex-col space-y-4 mt-6 text-gray-500'>
                  <div>
                    <button
                      className='w-full bg-yellow-200 mt-2 p-3 focus:border-[3px] hover:bg-white hover:text-yellow-400 hover:border-yellow-100 transition-all focus:border-blue-300 text-gray-600 font-sm'
                      onClick={() => { setRequestingRecoveryPassword(false) }}
                    >
                      Go to Signin
                    </button>
                  </div>
                </div>
              </>
                )}
          </div>
        </div>
      </div>
      <div className='w-0 md:w-1/2 md:flex h-full'>
        <img className='object-cover h-full w-full' alt='background' src={background} />
      </div>
    </div>
  )
}
