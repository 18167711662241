import { OfferDocuments } from 'components'
import React from 'react'

const Contract = ({ contractData, onClose }) => {
  const offerId = contractData?.offer?._id ?? null
  return (
    <OfferDocuments
      offerId={offerId}
      isPublicPath={true}
      contracts={contractData?.offer?.contracts}
      setCloseDocument={onClose}
    />
  )
}

export default Contract
