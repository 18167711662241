import React from 'react'
import ProjectManagement from '../components/ProjectManagement'

const EditGeneralInfo = () => {
  return (
    <div className='bg-menuGray min-h-screen relative'>
      <div className='p-6 flex justify-between items-center'>
        <span className='font-bold text-3xl font-openSans'>Project Management</span>
      </div>
      <div className='border-b-2 border-black' />
          <div className='m-6'>
            <ProjectManagement type={'edit'} />
          </div>
    </div>
  )
}

export default EditGeneralInfo
