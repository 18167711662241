// @ts-nocheck
/* eslint-disable no-unused-vars */
import { api, ApiErrors } from 'services/api'

export const getReservations = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`reservation/project/${id}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReservationDetail = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`reservation/${id}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getDocusignDocumentPreview = (reservationId = '') =>
  new Promise((resolve, reject) => {
    api
      .get(`reservation/contracts/reservation/${reservationId}/preview`)
      .then((response) => {
        if (response.ok) {
          resolve(response?.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const setReservationDetails = (params) =>
  new Promise((resolve, reject) => {
    const _id = params._id
    const verb = _id ? 'put' : 'post'
    const url = _id ? `reservation/${_id}` : 'reservation'
    api[verb](url, params).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteReservationDocument = (reservationId, documentId) =>
  new Promise((resolve, reject) => {
    api
      .delete(`reservation/${reservationId}/document/${documentId}`)
      .then((response) => {
        if (response.ok) {
          resolve(response?.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const deleteReservation = (reservationId) =>
  new Promise((resolve, reject) => {
    api.delete(`reservation/${reservationId}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const cancelReservation = (reservationId) =>
  new Promise((resolve, reject) => {
    api.put(`reservation/cancel/${reservationId}`, {}).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
