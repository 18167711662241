import React from 'react'
import { buyerSteps, steps } from '../utils'

const Receiver = ({
  options,
  onSelect,
  selected,
  setStep,
  stepsState,
  setSteps
}) => {
  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    if (selected === 'Buyers') {
      setSteps(buyerSteps)
    } else {
      setSteps(steps)
    }
    setStep((prev) => prev + 1)
  }

  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Inventory Receiver</p>
      </div>
      <p className='my-0 mt-4 mb-5 text-softBlack text-base'>
        Select who will receive the Inventory
      </p>
      <div className='grid grid-cols-2 gap-6'>
        {options.map((option, index) => (
          <div
            onClick={() => onSelect(option)}
            key={index}
            className={`flex items-center justify-center bg-[#F2F2F1] rounded-sm border-2 ${
              selected === option ? 'border-softBlack' : 'border-transparent'
            }`}
            style={{ height: 120 }}
          >
            <div className='flex items-center justify-center flex-col gap-4'>
              <svg
                width='25'
                height='24'
                viewBox='0 0 25 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M0.75 2.66667V21.3333C0.75 22.8 1.93667 24 3.41667 24H22.0833C23.55 24 24.75 22.8 24.75 21.3333V2.66667C24.75 1.2 23.55 0 22.0833 0H3.41667C1.93667 0 0.75 1.2 0.75 2.66667ZM16.75 8C16.75 10.2133 14.9633 12 12.75 12C10.5367 12 8.75 10.2133 8.75 8C8.75 5.78667 10.5367 4 12.75 4C14.9633 4 16.75 5.78667 16.75 8ZM4.75 18.6667C4.75 16 10.0833 14.5333 12.75 14.5333C15.4167 14.5333 20.75 16 20.75 18.6667V20H4.75V18.6667Z'
                  fill='#2E2B2E'
                  fillOpacity='0.3'
                />
              </svg>
              <h1 className='font-bold text-lg m-0'>{option}</h1>
            </div>
          </div>
        ))}
      </div>
      <div className='flex justify-between mt-32'>
        <button
          className='py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium'
          onClick={onBack}
        >
          Back
        </button>
        <button
          className='py-3 px-6 text-base bg-volt rounded'
          onClick={onNext}
          disabled={selected === undefined || selected?.length === 0}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default Receiver
