import { create } from 'apisauce'
import { message } from 'antd'

import { isAuthenticated } from 'services/auth'

const timeout = 60000

export const getTokenRequestHeader = () => {
  const token = isAuthenticated()

  if (token !== false) return `Bearer ${token}`

  return false
}

export const api = create({
  baseURL: process.env.REACT_APP_BE_URL,
  timeout,
  headers: {
    'Content-Type': 'application/json',
    Authorization: getTokenRequestHeader()
  }
})

export const ApiErrors = (response, warningDuration = 3) => {
  let localReturn = ''

  if (response.problem) {
    if (response.problem === 'NETWORK_ERROR') {
      localReturn +=
        'Your device does not appear to be connected to the Internet. \n'
      localReturn += 'Please, check your connection and try again.'
    }

    if (response.problem === 'CONNECTION_ERROR') {
      localReturn += 'Could not connect to the server. \n'
      localReturn += 'Please, check your connection and try again.'
    }

    if (response.problem === 'TIMEOUT_ERROR') {
      localReturn += 'The server did not respond. \n'
      localReturn += 'Please, check your connection and try again.'
    }

    // if (response.problem === 'SERVER_ERROR') {
    //   localReturn += 'A server error has occurred. \n'
    //   localReturn += 'Please, try again.'
    // }
  }

  // Show the error from API
  if (localReturn === '') {
    response?.data?.errors?.forEach((element) => {
      if (element?.msg?.length > 0) {
        message.warning(element.msg, warningDuration)
        // message.warning(element.error, warningDuration)
      }
    })
  } else {
    message.warning(localReturn, warningDuration)
  }

  return localReturn
}
