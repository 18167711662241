import React, { createContext, useState } from 'react'

import { RISK_TYPES } from '../components/constants'

export const CreateOfferContext = createContext({})

export const CreateOfferProvider = ({ children }) => {
  const [selectedUnit, setSelectedUnit] = useState({})
  const [selectedBuyers, setSelectedBuyers] = useState([])
  const [selectedBroker, setSelectedBroker] = useState(null)
  const [selectedParkings, setSelectedParkings] = useState([])
  const [selectedUpgrades, setSelectedUpgrades] = useState([])
  const [additionalBuyers, setAdditionalBuyers] = useState([])
  const [privacy, setPrivacy] = useState({
    allowAdditionalServices: false,
    allowSurveys: false
  })

  const [isThirdParty, setIsThirdParty] = useState(true)
  const [thirdPartDescription, setThirdPartDescription] = useState('')
  const [riskType, setRiskType] = useState(RISK_TYPES[0].value)
  const [selectedOption, setSelectedOption] = useState('')
  const [riskExplanation, setRiskExplanation] = useState('')
  const [depositOptions, setDepositOptions] = useState(null)
  const [checkedProperties, setCheckedProperties] = useState([])
  const [businessRelationshipExplanation, setBusinessRelationshipExplanation] =
    useState('')
  const [thirdPartRecord, setThirdPartRecord] = useState({
    address: '',
    birthday: '',
    incorporationCountry: '',
    incorporationJurisdiction: '',
    incorporationNumber: '',
    name: '',
    occupation: '',
    phoneNumber: '',
    relationship: ''
  })

  return (
    <CreateOfferContext.Provider
      value={{
        selectedUnit,
        setSelectedUnit,
        selectedBuyers,
        setSelectedBuyers,
        selectedBroker,
        setSelectedBroker,
        selectedParkings,
        setSelectedParkings,
        selectedUpgrades,
        setSelectedUpgrades,
        privacy,
        setPrivacy,
        isThirdParty,
        setIsThirdParty,
        riskType,
        setRiskType,
        selectedOption,
        setSelectedOption,
        checkedProperties,
        setCheckedProperties,
        thirdPartRecord,
        setThirdPartRecord,
        thirdPartDescription,
        setThirdPartDescription,
        riskExplanation,
        setRiskExplanation,
        businessRelationshipExplanation,
        setBusinessRelationshipExplanation,
        depositOptions,
        setDepositOptions,
        additionalBuyers,
        setAdditionalBuyers
      }}
    >
      {children}
    </CreateOfferContext.Provider>
  )
}
