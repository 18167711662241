import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CustomInput from 'components/CustomInput'
import { Oval } from 'react-loader-spinner'
import CustomSelect from 'components/CustomSelect'
import { useSelector, connect } from 'react-redux'
import { getDevelopers } from 'store/actions/developersActions'
import { getContractTemplates } from 'store/actions/contractTemplateActions'
import CustomTextArea from 'components/CustomTextArea'
import { FadedAnimatedDiv, TFilledPenIcon } from 'components'
import Searching from 'pages/contacts/Searching'
import { ReactComponent as Done } from '../../../../assets/icons/done.svg'
import {
  createOrUpdateProject,
  getProjects
} from 'store/actions/projectActions'
import { CREATE_PROJECT_IMAGES_STEP } from '../constant'
import { changeAppProject } from 'store/actions/appActions'
import CreateProjectContext from '../context/CreateProjectProvider'
import useProjectChange from 'hooks/useProjectChange'
import useCurrencyList from 'hooks/useCurrencyList'

const ProjectManagement = ({
  type = 'create',
  setStep = () => {},
  changeAppProject
}) => {
  const { onCurrencyChange } = useProjectChange()
  const { formattedCurrencyList: currencyData } = useCurrencyList()
  const mountedRef = useRef(true)
  const countryData = [
    { id: 'Canada', value: 'Canada' },
    { id: 'United States', value: 'United States' },
    { id: 'Mexico', value: 'Mexico' }
  ]

  const initialReducerState = useSelector((state) => state.appReducer)

  const localProjectId = initialReducerState?.newProject
    ? ''
    : initialReducerState.appProject
  const [developersData, setDevelopersData] = useState([])
  const [contractsData, setContractsData] = useState([])
  const [editMode, setEditMode] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const projectcontext = useContext(CreateProjectContext)
  const setNewProjectId = projectcontext.setNewProjectId
  const newProjectId = projectcontext.newProjectId

  const initialStateOfValues = {
    localProjectId: '',
    projectName: '',
    description: '',
    websiteUrl: '',
    city: '',
    province: '',
    country: '',
    totalDollarValue: 0,
    totalDollarValueCurrencyType: '',
    numberOfUnits: '',
    developer: '',
    reservationMaxDays: '',
    brokerMaxReservationDays: '',
    projectBackgroundColor: '',
    originalProjectBackgroundColor: '',
    paymentRounds: [],
    contracts: [],
    signer: {
      name: '',
      email: ''
    },
    images: [],
    backgroundImage: '',
    logoImage: '',
    signerName: '',
    signerEmail: ''
  }
  const [values, setValues] = useState(initialStateOfValues)
  const [errors, setErrors] = useState({
    projectName: false,
    description: false,
    websiteUrl: false,
    city: false,
    country: false,
    totalDollarValue: false,
    totalDollarValueCurrencyType: false,
    numberOfUnits: false,
    developer: false,
    reservationMaxDays: false,
    brokerMaxReservationDays: false
  })

  useEffect(() => {
    if (!mountedRef.current) return null
    if (initialReducerState.newProject === true) {
      setValues(initialStateOfValues)
    }
  }, [initialReducerState])

  const onChangeAppProject = (selectedproject) => {
    const projectId = selectedproject
    const developerId = values.developer
    changeAppProject({ projectId, developerId })
  }

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }
  // When mounted
  async function fetchData () {
    if (type !== 'create') {
      setEditMode(false)
    } else {
      setEditMode(true)
      setIsLoading(false)
    }
    const tmpContracts = await getContractTemplates(localProjectId)
    const tmpDevelopers = await getDevelopers('')
    // Get developers and map it to fill the select components for the user
    const tmpDevelopersData = tmpDevelopers.map((developer) => ({
      id: developer._id,
      value: developer.companyName
    }))
    if (!mountedRef.current) return null
    setDevelopersData(tmpDevelopersData)
    // Get contracts and map it to fill the select components for the user
    const tmpContractsData = tmpContracts.map((contract) => ({
      id: contract._id,
      value: contract.name
    }))
    setContractsData(tmpContractsData)
  }
  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const onValidate = () => {
    const requiredFields = [
      'projectName',
      'description',
      'website',
      'city',
      'country',
      'totalDollarValue',
      'totalDollarValueCurrencyType',
      'numberOfUnits',
      'developer',
      'reservationMaxDays',
      'brokerMaxReservationDays'
    ]
    for (let i = 0; i < requiredFields.length; i++) {
      if (values[requiredFields[i]] === '') {
        return false
      }
      if (
        (requiredFields[i] === 'reservationMaxDays' ||
          requiredFields[i] === 'brokerMaxReservationDays') &&
        (parseInt(values[requiredFields[i]]) <= 0 ||
          parseInt(values[requiredFields[i]]) > 14)
      ) {
        return false
      }
    }
    return true
  }

  const getProjectData = useCallback(() => {
    setIsLoading(true)
    if (values.localProjectId) {
      // Get project data from id
      getProjects(values.localProjectId).then((project) => {
        if (!mountedRef.current) return null
        const {
          _id,
          projectName,
          description,
          websiteUrl,
          city,
          province,
          country,
          totalDollarValue,
          totalDollarValueCurrencyType,
          numberOfUnits,
          paymentRounds,
          options,
          logoImage,
          backgroundImage,
          images,
          developer,
          contracts,
          reservationMaxDays,
          projectBackgroundColor,
          brokerMaxReservationDays,
          signer
        } = project
        setValues({
          localProjectId: _id,
          projectName,
          websiteUrl,
          description: description ?? '',
          city,
          province,
          country,
          totalDollarValue,
          totalDollarValueCurrencyType,
          numberOfUnits,
          paymentRounds,
          options,
          logoImage,
          backgroundImage,
          images,
          developer: developer._id,
          contracts,
          reservationMaxDays,
          projectBackgroundColor,
          brokerMaxReservationDays,
          signer,
          signerEmail: signer?.email,
          signerName: signer?.name
        })
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [values.localProjectId])

  useEffect(() => {
    if (type === 'edit') {
      setValues({ localProjectId })
    }
    if (newProjectId) {
      setValues({ localProjectId: newProjectId })
    }
    if (localProjectId) {
      setValues({ localProjectId })
    }
  }, [type, newProjectId, localProjectId])

  useEffect(() => {
    if (localProjectId) {
      getProjectData()
    }
  }, [values.localProjectId])
  const validate = () => {
    let isValid = true
    const _errors = { ...errors }
    Object.entries(values).forEach(function ([key, val]) {
      if (key in _errors) {
        if (val === '' || val === undefined) {
          _errors[key] = true
        } else {
          _errors[key] = false
          if (
            (key === 'reservationMaxDays' ||
              key === 'brokerMaxReservationDays') &&
            (parseInt(val) <= 0 || parseInt(val) > 14)
          ) {
            _errors[key] = true
          }
        }
        setErrors(_errors)
      }
    })
    Object.entries(_errors).forEach(function ([key, val]) {
      if (val === true) {
        isValid = false
      }
    })
    return isValid
  }

  const _onSaveClick = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const isValid = validate()

        if (isValid) {
          setLoading(true)
          const params = {
            _id: values.localProjectId,
            projectName: values.projectName,
            websiteUrl: values.websiteUrl,
            city: values.city,
            description: values.description,
            province: values.province,
            country: values.country,
            totalDollarValue: values.totalDollarValue,
            totalDollarValueCurrencyType: values.totalDollarValueCurrencyType,
            numberOfUnits: values.numberOfUnits,
            developer: values.developer,
            paymentRounds: values.paymentRounds,
            contracts: values.contracts,
            reservationMaxDays: values.reservationMaxDays,
            brokerMaxReservationDays: values.brokerMaxReservationDays,
            projectBackgroundColor: values.projectBackgroundColor,
            signer: {
              name: values.signerName,
              email: values.signerEmail
            },
            logoImage: values.logoImage,
            backgroundImage: values.backgroundImage,
            images: values.images
          }
          onCurrencyChange(values.totalDollarValueCurrencyType)
          createOrUpdateProject({ params })
            .then((projectData) => {
              setLoading(false)
              onChangeAppProject(projectData._id)
              if (type === 'new') {
                setNewProjectId(projectData._id)
              }
              if (type === 'edit') {
                setEditMode(false)
              }
              setStep(CREATE_PROJECT_IMAGES_STEP)
            })
            .catch(() => {
              setLoading(false)
              getProjectData()
              reject()
            })
        }
      }),
    [values, getProjectData]
  )

  const LoadingProjectDetails = () => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title='Loading Project Information...' />
    </FadedAnimatedDiv>
  )

  // useEffect(() => console.log(errors), [errors]);
  return (
    <div className='p-6 bg-grey-8 rounded'>
      <h1 className='font-black text-lg pt-3 font-openSans'>
        General Information
      </h1>
      <div className='border-b-2 border-black my-2' />
      <h2 className='font-openSans'>
        Please enter the details about the projectS
      </h2>
      {isLoading && <LoadingProjectDetails />}
      {!isLoading && (
        <>
          <div className='grid grid-cols-2  gap-6 border-0 p-0 mt-10 rounded-none shadow-none'>
            <div>
              <CustomInput
                label='Project Name *'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter project name'
                value={values.projectName}
                onChange={(e) => onChange('projectName', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
                required='required'
                error={errors.projectName}
              />
              <div className='mt-6'>
                <CustomInput
                  label='Website *'
                  labelClassName='text-xs italic font-openSans font-light'
                  placeholder='http://'
                  value={values.websiteUrl}
                  onChange={(e) => onChange('websiteUrl', e.target.value)}
                  classes='font-openSans text-base'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  required='required'
                  error={errors.websiteUrl}
                />
              </div>
            </div>
            <div>
              <CustomTextArea
                label='Project Description *'
                placeholder='Enter description'
                labelClasses='text-xs italic font-openSans font-light'
                value={values.description}
                onChange={(e) => onChange('description', e.target.value)}
                style={{ height: '144px' }}
                textareaClasses='font-openSans'
                disabled={!editMode}
                required='required'
                error={errors.description}
              />
            </div>
            <CustomInput
              label='City *'
              labelClassName='italic font-openSans font-light'
              placeholder='Enter city'
              value={values.city}
              onChange={(e) => onChange('city', e.target.value)}
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={!editMode}
              required='required'
              error={errors.city}
            />
            <CustomSelect
              label='Country *'
              labelClasses='text-left italic font-openSans font-light'
              inputClasses='h-10 bg-white font-openSans'
              selectedOption={values?.country}
              options={countryData}
              setSelectedOption={(e) => onChange('country', e)}
              fonts={'font-openSans'}
              fieldName={'country'}
              disabled={!editMode}
              required='required'
              error={errors.country}
            />
            <CustomInput
              label='Total Value *'
              labelClassName='text-xs italic font-openSans font-light'
              placeholder='Enter value'
              value={values?.totalDollarValue}
              onChange={(e) => onChange('totalDollarValue', e.target.value)}
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={!editMode}
              required='required'
              error={errors.totalDollarValue}
            />
            <div className='grid grid-cols-2 gap-6'>
              <CustomSelect
                label='Currency *'
                labelClasses='text-xs italic font-openSans font-light'
                inputClasses='h-10 bg-white font-openSans'
                selectedOption={values?.totalDollarValueCurrencyType}
                options={currencyData}
                setSelectedOption={(e) =>
                  onChange('totalDollarValueCurrencyType', e)
                }
                fonts={'font-openSans'}
                fieldName={'currency'}
                disabled={!editMode}
                required='required'
                error={errors.totalDollarValueCurrencyType}
              />
              <CustomInput
                label='Number of Units *'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter units'
                value={values.numberOfUnits}
                onChange={(e) => onChange('numberOfUnits', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
                required='required'
                type='number'
                min={1}
                error={errors.numberOfUnits}
              />
            </div>
            <CustomSelect
              label='Developer *'
              labelClasses='text-xs italic font-openSans font-light'
              inputClasses='h-10 bg-white font-openSans'
              selectedOption={values?.developer}
              options={developersData}
              setSelectedOption={(e) => onChange('developer', e)}
              fonts={'font-openSans'}
              fieldName={'developer'}
              disabled={!editMode}
              required='required'
              error={errors.developer}
            />
            <div className='grid grid-cols-2 gap-6'>
              <CustomInput
                label='Days to keep units reserved *'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter days'
                min={1}
                max={14}
                type='number'
                value={values.reservationMaxDays}
                onChange={(e) => onChange('reservationMaxDays', e.target.value)}
                classes='font-openSans text-base'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
                required='required'
                error={errors.reservationMaxDays}
              />
              <CustomInput
                label='Inventory allocation days *'
                labelClassName='text-xs italic font-openSans font-light'
                placeholder='Enter days'
                value={values.brokerMaxReservationDays}
                onChange={(e) =>
                  onChange('brokerMaxReservationDays', e.target.value)
                }
                classes='font-openSans text-base'
                min={1}
                max={14}
                type='number'
                style={{ maxHeight: '48px' }}
                disabled={!editMode}
                required='required'
                error={errors.brokerMaxReservationDays}
              />
            </div>
            <CustomInput
              label='Project background color'
              labelClassName='text-xs italic font-openSans font-light'
              placeholder='HEX #'
              value={values.projectBackgroundColor}
              onChange={(e) =>
                onChange('projectBackgroundColor', e.target.value)
              }
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={!editMode}
            />
            <CustomSelect
              label='Contracts'
              labelClasses='text-left text-xs italic font-openSans font-light'
              inputClasses='h-10 bg-white font-openSans'
              selectedOption={values.contracts}
              options={contractsData}
              setSelectedOption={(e) => onChange('contracts', e)}
              fonts={'font-openSans'}
              fieldName={'contracts'}
              multiple={true}
              disabled={!editMode}
            />
            <CustomInput
              label='Signer Name'
              labelClassName='text-xs italic font-openSans font-light'
              placeholder='Enter full name'
              value={values.signerName}
              onChange={(e) => onChange('signerName', e.target.value)}
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={!editMode}
            />
            <CustomInput
              label='Signer Email'
              labelClassName='text-xs italic font-openSans font-light'
              placeholder='Enter email'
              value={values.signerEmail}
              onChange={(e) => onChange('signerEmail', e.target.value)}
              classes='font-openSans text-base'
              style={{ maxHeight: '48px' }}
              disabled={!editMode}
            />
          </div>
          {type !== 'create' &&
            (!editMode
              ? (
              <button
                onClick={() => setEditMode(true)}
                className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'
              >
                <TFilledPenIcon className='h-4 w-4 mr-2' />
                Edit
              </button>
                )
              : (
              <div className='flex flex-between items-center'>
                <button
                  onClick={() => {
                    getProjectData()
                    setEditMode(false)
                  }}
                  className='border border-softBlack px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'
                >
                  Cancel
                </button>
                <button
                  onClick={_onSaveClick}
                  className='ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'
                >
                  {loading && <Oval height='18' width='18' color='black' />}
                  {!loading && <Done className='h-4 w-4 mr-2' />}
                  Save
                </button>
              </div>
                ))}
          {type === 'create' && (
            <div className='flex justify-end'>
              <button
                onClick={_onSaveClick}
                disabled={!onValidate()}
                className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'
              >
                {loading && <Oval height='18' width='18' color='black' />}
                Next
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject,
  listOfProjects: state.appReducer.listOfProjects
})

const mapDispatchToProps = {
  changeAppProject
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectManagement)
