import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import standardStyle from 'assets/css/standardStyle'
import Skeleton from 'react-loading-skeleton'

export const H1 = ({ children, className, loading }) =>
  <h1 className={`block text-2xl ${className}`}>
    {
      loading
        ? <Skeleton height={32} width={200} />
        : children
    }
  </h1>

export const H2 = ({ children, className }) =>
  <h2 className={`block text-xl ${className}`}>{children}</h2>

export const H3 = ({ children, className }) =>
  <h3 className={`block text-base ${className}`}>{children}</h3>

export const H4 = ({ children, className }) =>
  <h4 className={`block text-base  ${className}`}>{children}</h4>

export const H5 = ({ children, className }) =>
  <h5 className={`block text-base ${className}`}>{children}</h5>

export const H6 = ({ children, className }) =>
  <h6 className={`block text-base ${className}`}>{children}</h6>

export const AdminPageTitle = ({ title, className, buttons }) => (
  <div className='md:flex md:items-center md:justify-between'>
    <div className='flex-1 min-w-0'>
      <h2 className='text-2xl leading-7 text-gray-900 sm:text-3xl sm:truncate'>
        {title}
      </h2>
    </div>
    <div className='mt-4 flex md:mt-0 md:ml-4'>
      {buttons}
    </div>
  </div>
)

export const H7 = styled.h6`
  color: ${props => props.loading ? standardStyle.colors.skeletonGray : standardStyle.colors.primary};
  background: ${props => props.loading && standardStyle.colors.skeletonGray};
  display:block;
  font-family: ${standardStyle.fonts.title};
  font-size: ${standardStyle.headings.h7};
  line-height:1.15;
  font-weight: ${props => props.fontWeight || 'inherit'};
`

H1.propTypes = {
  children: propTypes.node,
  className: propTypes.string,
  loading: propTypes.bool
}

H2.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

H3.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

H4.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

H5.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

H6.propTypes = {
  children: propTypes.node,
  className: propTypes.string
}

AdminPageTitle.propTypes = {
  title: propTypes.string,
  className: propTypes.string,
  buttons: propTypes.node
}
