import React from 'react'

const ProgressSteps = ({ steps = [], currentStepIndex = 0, className = '' }) => {
  return (
    <div className={`bg-white flex flex-col rounded-lg px-8 py-2 overflow-y-scroll ${className}`}>
      {steps.map((step, i) => {
        return (
          <div key={i}>
            {i !== 0 && <div className='border-l-2 h-6 ml-5 rounded-md w-0' />}
            <div
              className={`flex space-x-5 items-center text-gray-700 ${currentStepIndex === i && 'opacity-80'}
              }`}>
              <div className='rounded-full bg-zinc-100 h-10 w-10 min-w-10 min-h-10 flex justify-center items-center p-1'>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M12.1667 7.16667V2.16667C12.1667 1.25 11.4167 0.5 10.5 0.5H5.5C4.58333 0.5 3.83333 1.25 3.83333 2.16667V3.83333H2.16667C1.25 3.83333 0.5 4.58333 0.5 5.5V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H6.33333C6.79167 15.5 7.16667 15.125 7.16667 14.6667V12.1667H8.83333V14.6667C8.83333 15.125 9.20833 15.5 9.66667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V8.83333C15.5 7.91667 14.75 7.16667 13.8333 7.16667H12.1667ZM3.83333 13.8333H2.16667V12.1667H3.83333V13.8333ZM3.83333 10.5H2.16667V8.83333H3.83333V10.5ZM3.83333 7.16667H2.16667V5.5H3.83333V7.16667ZM7.16667 10.5H5.5V8.83333H7.16667V10.5ZM7.16667 7.16667H5.5V5.5H7.16667V7.16667ZM7.16667 3.83333H5.5V2.16667H7.16667V3.83333ZM10.5 10.5H8.83333V8.83333H10.5V10.5ZM10.5 7.16667H8.83333V5.5H10.5V7.16667ZM10.5 3.83333H8.83333V2.16667H10.5V3.83333ZM13.8333 13.8333H12.1667V12.1667H13.8333V13.8333ZM13.8333 10.5H12.1667V8.83333H13.8333V10.5Z'
                    fill='#2E2B2E'
                  />
                </svg>
              </div>
              <div className='flex flex-row min-w-[6rem] flex-grow'>
                <div className='flex-1'>{step?.text}
                </div>
                {step?.createdAt?.length > 0
                  ? <div>{new Date(step?.createdAt).toLocaleString()}</div>
                  : null
                }
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ProgressSteps
