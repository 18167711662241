/* eslint-disable */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdminSideBar from '../AdminSideBar/index'

import { isMobile } from 'react-device-detect'
import { HOVER_TOGGLER, SHOW_HIDE_SIDEBAR } from 'store/actions/actionTypes'
import OfferSideBar from 'components/Offer/OfferSideBar'

const MobileAdminMenuBar = (props) => {
  // @ts-ignore
  const show = useSelector((state) => state.appReducer)
  const dispatch = useDispatch()

  const handleCloseBar = () => {
    dispatch({ type: HOVER_TOGGLER, payload: false })
    dispatch({ type: SHOW_HIDE_SIDEBAR, payload: false })
  }

  return (
    <>
      <div className='flex'>
        {!isMobile &&
          <OfferSideBar {...props} />
        }
        <div className={`fixed z-[99] flex flex-row transition-all duration-300 ease-in-out delay-0 h-screen ${isMobile ? 'w-full' : ''} ${show.show ? isMobile ? 'translate-x-0 opacity-100' : 'translate-x-20 opacity-100' : '-translate-x-full opacity-0'}`} >
          {isMobile &&
            <OfferSideBar {...props} />
          }
          <AdminSideBar />
        </div>
      </div>
      {/* {(!isMobile && show.show && show.isHover) && (
        <div onClick={handleCloseBar} className={`absolute z-4999 h-full w-full transition-all duration-300 ease-in-out bg-black ${show.isHover ? 'opacity-40' : 'opacity-0'}`} />
      )} */}
    </>
  )
}

export default MobileAdminMenuBar
