import React, { useEffect, useState } from 'react'
import { getNylasSchedulerPages } from 'store/actions/usersActions'

const SchedulerMeetings = () => {
  const [meetings, setMeetings] = useState([])

  useEffect(() => {
    getNylasSchedulerPages().then((data) => {
      console.log('getNylasSchedulerPages', data)
      setMeetings([...data])
    }).catch((error) => { console.error('getNylasSchedulerPages', error) })
  }, [])

  return (
    <div className='p-4'>
      <p>Scheduled Meetings</p>
      <ul>
        {meetings?.map((data) => {
          console.log(data)
          return <li>
            <a
              href={`https://schedule.nylas.com/${data.slug}`}
              target='_blank'
              rel='noopener noreferrer'
              title='Preview file'
            >{`${data.name} -> ${data.slug}`}
            </a>
          </li>
        })}
      </ul>
    </div>
  )
}

export default SchedulerMeetings
