import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import Skeleton from 'react-loading-skeleton'
import { classNames, sanitizeCode } from 'utils'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ReplyIcon,
  PencilAltIcon
} from '@heroicons/react/solid'
import EmailAttachments from './EmailAttachments'

const EmailNavigationButton = ({
  onClick,
  title,
  icon: Icon,
  className,
  disabled
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600',
        className
      )}
      disabled={disabled}
    >
      <span className='sr-only'>{title}</span>
      <Icon className='h-5 w-5' aria-hidden='true' />
    </button>
  )
}

const SelectedMailSection = ({
  gettingThreadContent,
  selectedEmailIndex,
  setSelectedEmailIndex,
  showEmailBox,
  setShowMessageList,
  setShowEmailBox,
  selectedThreadContent,
  setComposingEmailRecipient,
  setComposingEmailBody,
  setComposingEmailSubject,
  listOfEmails
}) => {
  const renderSelectedEmailThread = useCallback(() => {
    if (gettingThreadContent) {
      return (
        <div className='flex flex-col gap-6 p-8'>
          <Skeleton height={100} />
        </div>
      )
    }

    if (!selectedThreadContent || !Object.keys(selectedThreadContent).length) return null

    return (
      <div className='min-h-0 flex-1 overflow-y-auto'>
        <div className='bg-white pt-5 pb-6 shadow'>
          <div className='px-4 sm:flex sm:justify-between sm:items-baseline sm:px-6 lg:px-8'>
            <div className='sm:w-0 sm:flex-1'>
              <h1
                id='message-heading'
                className='text-lg font-medium text-gray-900'
              >
                {selectedThreadContent.subject}
              </h1>
              <p className='mt-1 text-sm text-gray-500 truncate'>{`${selectedThreadContent.senderName} - ${selectedThreadContent.senderEmail}`}</p>
            </div>
          </div>
        </div>
        {/* Thread section */}
        <ul className='py-4 space-y-2 sm:px-6 sm:space-y-4 lg:px-8'>
          {selectedThreadContent.items.map((item) => (
            <li
              key={item.id}
              className='bg-white px-4 py-6 shadow-lg sm:rounded-lg sm:px-6'
            >
              <div className='sm:flex sm:justify-between sm:items-baseline'>
                <h3 className='text-base font-medium'>
                  <span className='text-gray-900'>{item.author}</span>{' '}
                  <span className='text-gray-600'>wrote</span>
                </h3>
                <p className='mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3'>
                  <time dateTime={item.datetime}>{item.date}</time>
                </p>
              </div>
              <div
                className='mt-4 space-y-6 text-sm text-gray-800'
                dangerouslySetInnerHTML={{ __html: item.body }}
              />
              <EmailAttachments files={item.files} />
            </li>
          ))}
        </ul>
      </div>
    )
  }, [selectedThreadContent, gettingThreadContent])

  const onReplyForwardClick = useCallback(
    (action) => {
      // Get most recent email of the threa
      if (selectedThreadContent.items && selectedThreadContent.items.length) {
        const latestEmailBody = selectedThreadContent.items[0].body

        setComposingEmailBody(sanitizeCode(latestEmailBody))
        if (action === 'reply') {
          setComposingEmailRecipient([
            {
              name: selectedThreadContent.senderName,
              email: selectedThreadContent.senderEmail
            }
          ])
        }
        setComposingEmailSubject(
          `${action === 'reply' ? 'Re' : 'Fwd'}: ${
            selectedThreadContent.subject
          }`
        )
        setShowEmailBox(true)
      }
    },
    [selectedThreadContent]
  )

  return (
    <section
      aria-labelledby='message-heading'
      className='min-w-0 flex-1 h-screen flex flex-col overflow-hidden'
    >
      {/* Top section */}
      <div className='flex-shrink-0 bg-white border-b border-gray-200'>
        {/* Toolbar */}
        <div className='h-16 flex flex-col justify-center'>
          <div className='px-4 sm:px-6 lg:px-8'>
            <div
              className={`py-3 flex ${
                isMobile ? 'justify-evenly' : 'justify-between'
              }`}
            >
              {isMobile && (
                <div className='flex justify-center items-center bg-black rounded px-1 mr-2'>
                  <span className='relative z-0 inline-flex shadow-sm rounded-md sm:shadow-none sm:space-x-3'>
                    <button
                      type='button'
                      onClick={() => setShowMessageList(true)}
                      className='flex w-20 justify-center items-center text-white rounded'
                    >
                      Show List
                    </button>
                  </span>
                </div>
              )}
              {/* Left buttons */}
              <div>
                <span className='relative z-0 inline-flex shadow-sm rounded-md sm:shadow-none sm:space-x-3'>
                  <button
                    type='button'
                    onClick={() => setShowEmailBox(true)}
                    className={classNames(
                      'sm:inline-flex -ml-px relative items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none ',
                      showEmailBox && 'ring-1 ring-blue-600 border-blue-600'
                    )}
                  >
                    <PencilAltIcon
                      className={`${
                        isMobile ? '' : ' mr-2.5'
                      } h-5 w-5 text-gray-400`}
                      aria-hidden='true'
                    />
                    {!isMobile && <span>New</span>}
                  </button>

                  <span
                    className={`inline-flex ${
                      isMobile ? 'ml-2' : ''
                    } sm:shadow-sm`}
                  >
                    <button
                      onClick={() => onReplyForwardClick('reply')}
                      type='button'
                      className={`relative inline-flex ${
                        isMobile ? 'pl-4' : 'px-4 py-2'
                      } items-center rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none`}
                    >
                      <ReplyIcon
                        className='mr-2.5 h-5 w-5 text-gray-400'
                        aria-hidden='true'
                      />
                      {!isMobile && <span>Reply</span>}
                    </button>

                    <button
                      onClick={() => onReplyForwardClick('forward')}
                      type='button'
                      className={`sm:inline-flex -ml-px relative items-center ${
                        isMobile ? 'pr-2 pl-3' : 'px-4 py-2'
                      } rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:outline-none`}
                    >
                      <ReplyIcon
                        className='mr-2.5 h-5 w-5 text-gray-400'
                        aria-hidden='true'
                        style={{ transform: 'rotateY(180deg)' }}
                      />
                      {!isMobile && <span>Forward</span>}
                    </button>
                  </span>
                </span>
              </div>

              {/* Right buttons */}
              <nav className='' aria-label='Pagination'>
                <span className='relative z-0 inline-flex shadow-sm rounded-md'>
                  <EmailNavigationButton
                    onClick={() => {
                      if (selectedEmailIndex > 0) {
                        setSelectedEmailIndex(selectedEmailIndex - 1)
                      }
                    }}
                    title='Next'
                    icon={ChevronUpIcon}
                    className='rounded-l-md'
                    disabled={selectedEmailIndex <= 0}
                  />
                  <EmailNavigationButton
                    onClick={() => {
                      if (selectedEmailIndex < listOfEmails?.length - 1) {
                        setSelectedEmailIndex(selectedEmailIndex + 1)
                      }
                    }}
                    title='Previous'
                    icon={ChevronDownIcon}
                    className='rounded-r-md'
                    disabled={selectedEmailIndex >= listOfEmails?.length - 1}
                  />
                </span>
              </nav>
            </div>
          </div>
        </div>
        {/* Message header */}
      </div>
      {renderSelectedEmailThread()}
    </section>
  )
}

export default SelectedMailSection
