/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import propTypes from 'prop-types'
import standardStyle from 'assets/css/standardStyle'
import { FadedAnimatedDiv, GridFormWrapper, ReportLine } from 'components'
import { InputLabel, H2, H3, Modal, TButton } from 'ui'
import { addDays, capitalizeWords, dateDiffInDays, formatMoney, pluralWords, removeLastCommaFromStrings } from 'utils'
import {
  PageWrapper,
  ReviewWrapper,
  ReviewSectionWrapper,
  ReviewSectionDescription,
  ReviewColumns,
  CreateOfferButtonWrapper,
  TitlesWrapper,
  ReviewMainWrapper
} from './styles'
import { message } from 'antd'
import { createOrUpdateOffer } from 'store/actions/offerActions'
import CreateOfferSuccessPage from './successPage'
import { AnimatePresence } from 'framer-motion'
import { TSaveButton } from 'components/DefaultButtons'
import UserRoundedImage from 'components/UserRoundedImage'

const SelectedBuyer = ({ data: { userAvatar, firstName, lastName, email } }) => (
  <div className='flex flex-row w-full'>
    <UserRoundedImage src={userAvatar} className={`${userAvatar ? 'h-14 w-14' : 'h-16 w-16'}`}/>

    <div className={'flex flex-col ml-5'}>
      <span className={'text-lg'}>{`${firstName} ${lastName}`}</span>
      <span className={'text-base'}>{email}</span>
    </div>
  </div>
)

const Review = (props) => {
  const {
    selectedUnit: {
      unitNumber,
      price: unitPrice,
      numberOfBedrooms,
      numberOfBathrooms,
      _id: unitId,
      floorPlan
    },
    selectedBuyers,
    selectedBroker,
    selectedParking,
    selectedColorScheme,
    selectedAdditionalOptions,
    selectedFintracOptions,
    paymentRounds,
    onClickShowDocs,
    offerId: externalOfferId,
    modalInnerContainer,
    executeWhenOfferCreated,
    alreadyShownCreateOfferAnimation,
    showSecondaryButton,
    saveButtonClassName,
    customTitle,
    showButtonsWrapper,
    allowDepositsEdition,
    projectData,
    showPrimaryButton,
    brokerCommissionFirstRange,
    brokerCommissionSecondRange
  } = props

  let unitSlots = 0

  if (floorPlan) {
    unitSlots = floorPlan.parkingQty
  }

  const [optionsTotalPrice, setOptionsTotalPrice] = useState(0)
  const [totalParkingValue, setTotalParkingValue] = useState(0)
  const [totalColorSchemeValue, setTotalColorSchemeValue] = useState(0)
  const [offerTotalPrice, setOfferTotalPrice] = useState(0)
  const [offerTotalPriceStringFormated, setOfferTotalPriceFormated] = useState('')
  const [localPaymentRounds, setLocalPaymentRounds] = useState([])
  const [creatingOffer, setCreatingOffer] = useState(false)
  const [offerId, setOfferId] = useState('')
  const [showModalUpdateOfferConfirm, setShowModalUpdateOfferConfirm] = useState(false)
  const [currencyType, setCurrencyType] = useState('')

  const refLocalPaymentRounds = useRef(localPaymentRounds)

  const [projectHasColorSchemeOptions, setProjectHasColorSchemeOptions] = useState(false)
  const [projectHasParkingOptions, setProjectHasParkingOptions] = useState(false)
  const [projectHasOptionsAndUpgrades, setProjectHasOptionsAndUpgrades] = useState(false)

  const updateLocalPaymentRounds = (newState) => {
    refLocalPaymentRounds.current = newState
    setLocalPaymentRounds(newState)
  }

  useEffect(() => {
    const hasOptions = projectData.options && projectData.options.length

    let hasColorSchemeOptions = false
    let hasParkingOptions = false
    let hasOptionsAndUpgrades = false

    if (hasOptions) {
      hasColorSchemeOptions = Boolean(projectData.options.filter(option => option.type === 'color').length)
      hasParkingOptions = Boolean(projectData.options.filter(option => option.type === 'parking').length)
      hasOptionsAndUpgrades = Boolean(projectData.options.filter(option => option.type === 'additionalOptions').length)
    }

    setProjectHasColorSchemeOptions(hasColorSchemeOptions)
    setProjectHasParkingOptions(hasParkingOptions)
    setProjectHasOptionsAndUpgrades(hasOptionsAndUpgrades)
  }, [projectData])

  useEffect(() => {
    if (Object.keys(projectData).length) {
      const { totalDollarValueCurrencyType } = projectData

      setCurrencyType(totalDollarValueCurrencyType)
    }
  }, [projectData])

  useEffect(() => {
    if (externalOfferId) {
      setOfferId(externalOfferId)
    }
  }, [externalOfferId])

  useEffect(() => {
    if (selectedParking && Object.keys(selectedParking).length) {
      const totalParkingValue = selectedParking.value
      setTotalParkingValue(totalParkingValue)
    }
  }, [selectedParking])

  useEffect(() => {
    if (selectedColorScheme && Object.keys(selectedColorScheme).length) {
      setTotalColorSchemeValue(selectedColorScheme.value)
    }
  }, [selectedColorScheme])

  useEffect(() => {
    if (selectedAdditionalOptions.length) {
      const tmpOptionsTotalPrice = selectedAdditionalOptions.reduce((acumulator, option) => {
        return acumulator + option.value
      }, 0)

      setOptionsTotalPrice(tmpOptionsTotalPrice)
    }
  }, [selectedAdditionalOptions])

  useEffect(() => {
    const total =
    Number(unitPrice || 0) +
    Number(optionsTotalPrice || 0) +
    Number(totalParkingValue || 0) +
    Number(totalColorSchemeValue || 0)

    const totalString = formatMoney(total, currencyType)

    setOfferTotalPrice(total)
    setOfferTotalPriceFormated(totalString)
  }, [optionsTotalPrice, totalColorSchemeValue, totalParkingValue, unitPrice, currencyType])

  useEffect(() => {
    if (paymentRounds) {
      updateLocalPaymentRounds(paymentRounds.map((payment) => {
        const { value } = payment

        const newElement = { ...payment }

        if (!value) {
          newElement.value = offerTotalPrice * (payment.perc / 100)
        }

        newElement.type = 'Card'
        return newElement
      }))
    }
  }, [offerTotalPrice, paymentRounds])

  const managePaymentRound = (props) => {
    const { field, value, _id } = props

    const tmpPaymentRounds = refLocalPaymentRounds.current.map((paymentRound) => {
      const tmpElement = { ...paymentRound }
      if (paymentRound._id === _id) {
        if (field === 'date' && value) {
          const today = new Date()
          tmpElement.days = dateDiffInDays(today, value)
        }

        if (field === 'perc') {
          tmpElement.perc = value
        }

        if (field === 'value') {
          tmpElement.value = parseInt(value)
        }
      }

      return tmpElement
    })

    updateLocalPaymentRounds(tmpPaymentRounds)
  }

  const renderDepositLine = ({
    _id,
    placeHolder,
    daysToDeposit,
    depositPercent,
    depositValue
  }) => {
    const today = new Date()

    return (
      <>
        <InputLabel
          typeInput='datepicker'
          xs={12}
          sm={12}
          md={8}
          lg={8}
          label={`${daysToDeposit} ${daysToDeposit > 1 ? 'days' : 'day'} from today`}
          value={addDays(today, daysToDeposit)}
          onChange={(e) => managePaymentRound({ field: 'date', value: e || '', _id: _id })}
          placeholder={placeHolder}
          disabled={!allowDepositsEdition}
        />
        <InputLabel
          xs={12}
          sm={12}
          md={8}
          lg={8}
          label={`${placeHolder} percent`}
          value={depositPercent}
          onChange={(e) => managePaymentRound({ field: 'perc', value: e.target.value, _id: _id })}
          type='tel'
          name={`depositPercentValue${placeHolder}`}
          id={`depositPercentValue${placeHolder}`}
          disabled={!allowDepositsEdition}
        />
        <InputLabel
          xs={12}
          sm={12}
          md={8}
          lg={8}
          label={`${placeHolder} value`}
          id={`depositValue${placeHolder}`}
          name={`depositValue${placeHolder}`}
          type='tel'
          prefix={currencyType}
          typeInput='numberinput'
          value={depositValue}
          onChange={(e) => managePaymentRound({ field: 'value', value: e.value, _id: _id })}
          disabled={!allowDepositsEdition}
        />
      </>
    )
  }

  const _createUpdateOffer = () => {
    const errors = []

    if (!unitId) {
      errors.push({ message: 'Please, select the Unit' })
    }

    const tmpBuyers = selectedBuyers.filter(buyer => buyer._id !== -1)

    if (!tmpBuyers.length) {
      errors.push({ message: 'Please, select the Buyer' })
    } else {
      tmpBuyers.forEach(buyer => {
        const { firstName, lastName, buyerData: { frontPhotoId, backPhotoId } } = buyer

        if ((!frontPhotoId || !backPhotoId) || (!frontPhotoId.length || !backPhotoId.length)) {
          errors.push({
            message: `${firstName} ${lastName} must have attached the
            front and back images of the Buyer's ID`
          })
        }
      })
    }

    if (selectedBroker && !Object.keys(selectedBroker).length) {
      errors.push({ message: 'Please, choose the Broker option' })
    }

    if (selectedFintracOptions && !Object.keys(selectedFintracOptions).length) {
      errors.push({ message: 'Please, choose the Fintrac options' })
    }

    if (errors.length) {
      errors.forEach((error) => message.warning(error.message))
      return
    }

    setCreatingOffer(true)

    let selectedOptions = []

    // Create the buyer list for the offer
    const offerBuyers = tmpBuyers.map((buyer) => buyer._id)

    // Get the selected parking options
    selectedOptions = selectedOptions.concat(selectedParking || [])

    // Get the selected color scheme
    selectedOptions = selectedOptions.concat(
      Object.keys(selectedColorScheme).length
        ? selectedColorScheme
        : []
    )

    // Get the selected additional options
    selectedOptions = selectedOptions.concat(
      Object.keys(selectedAdditionalOptions).length
        ? selectedAdditionalOptions
        : []
    )

    const createOfferParams = {
      id: offerId,
      unit: unitId,
      // status: 'pending',
      buyer: offerBuyers,
      totalCost: offerTotalPrice,
      paymentRounds: refLocalPaymentRounds.current,
      options: selectedOptions,
      fintrac: selectedFintracOptions,
      brokerCommissionFirstRange: brokerCommissionFirstRange || 0,
      brokerCommissionSecondRange: brokerCommissionSecondRange || 0
    }

    createOrUpdateOffer(createOfferParams, !!offerId)
      .then((response) => {
        executeWhenOfferCreated({ offerData: response })
        setCreatingOffer(false)
      })
      .catch(() => {
        setOfferId('')
        setCreatingOffer(false)
      })
  }

  const buttonLabel = {
    showing_docs: 'Contracts Review',
    choosing_payment_method: 'Back to Payment'

  }

  const confirmUpdateOffer = () => {
    setShowModalUpdateOfferConfirm(true)
  }

  const renderOfferBuyers = useCallback(() => {
    const tmpSelectedBuyers = selectedBuyers.filter(buyer => buyer._id !== -1)

    if (!tmpSelectedBuyers.length) return 'Buyer was not selected yet'

    return (
      <div className='flex flex-col space-y-3'>
        {
          tmpSelectedBuyers.map((buyer) => (
            <SelectedBuyer data={buyer} />
          ))
        }
      </div>
    )
  }, [selectedBuyers])

  return (
    <>
      <Modal
        showModal={showModalUpdateOfferConfirm}
        title='Confirm the offer update?'
        subtitle='The offer will be updated with the new selections you made'
        modalType='alert'
        onCancel={() => setShowModalUpdateOfferConfirm(false)}
        onOk={() => {
          setShowModalUpdateOfferConfirm(false)
          _createUpdateOffer()
        }}
      />
      <PageWrapper>
        <AnimatePresence exitBeforeEnter initial={false}>
          {
            (externalOfferId && !alreadyShownCreateOfferAnimation)
              ? (
                  <FadedAnimatedDiv
                    key='successWrapper'
                    className='h-full flex flex-col justify-center'
                  >
                    <CreateOfferSuccessPage
                      nextButtonClassName='h-9 md:h-16 text-base md:text-2xl px-10'
                      executeWhenDone={() => {
                        onClickShowDocs({
                          paymentRounds: refLocalPaymentRounds.current
                        })
                      }}
                    />
                  </FadedAnimatedDiv>
                )
              : (
                <FadedAnimatedDiv
                  key='reviewPage'
                  style={{ height: '100%' }}
                >
                  <TitlesWrapper style={{ height: '8%' }}>
                    {
                      customTitle || (
                        <H2 className='text-2xl text-center'>Offer Summary</H2>
                      )
                    }

                  </TitlesWrapper>

                  <ReviewMainWrapper
                    className='py-0 px-0 md:px-5'
                    style={{ height: !showButtonsWrapper && '92%' }}
                  >
                    <ReviewWrapper>
                      <ReviewColumns span={!projectHasColorSchemeOptions && !projectHasParkingOptions ? 24 : 12}>
                        <ReviewSectionWrapper>
                          <H3 className='text-2xl'>Unit</H3>
                          {
                            unitNumber
                              ? (
                                  <>
                                    <ReviewSectionDescription>{unitNumber}</ReviewSectionDescription>
                                    <ReviewSectionDescription>{`${numberOfBedrooms} bedrooms`}</ReviewSectionDescription>
                                    <ReviewSectionDescription>{`${numberOfBathrooms} bathrooms`}</ReviewSectionDescription>

                                    <ReportLine
                                      leftText='Total'
                                      rightText={formatMoney(unitPrice, currencyType)}
                                      size='large'
                                      leftTextBold
                                      rightTextBold
                                      color={standardStyle.colors.primary}
                                    />
                                  </>
                                )
                              : (
                                  <ReviewSectionDescription>Unit was not selected yet</ReviewSectionDescription>
                                )
                          }
                        </ReviewSectionWrapper>
                        <ReviewSectionWrapper>
                          <H3 className='text-2xl'>{pluralWords(selectedBuyers.length, 'Buyer')}</H3>
                          <ReviewSectionDescription>
                            {
                              renderOfferBuyers()

                              // getSelectedElementsFromArray(selectedBuyers, 'Buyer').fullDescription || 'Buyer was not selected yet'
                            }
                          </ReviewSectionDescription>
                        </ReviewSectionWrapper>
                        <ReviewSectionWrapper>
                          <H3 className='text-2xl'>{`${pluralWords(selectedBuyers.length, 'Buyer')} representative`}</H3>
                          <ReviewSectionDescription>
                            {
                              !selectedBuyers.filter(buyer => buyer._id !== -1).length
                                ? 'Buyer was not selected yet'
                                : selectedBroker && Object.keys(selectedBroker).length
                                  ? `${selectedBroker.firstName} ${selectedBroker.lastName}`
                                  : `${pluralWords(selectedBuyers.length, 'Buyer')} will be represented by Sales Rep`
                            }
                          </ReviewSectionDescription>

                          <ReviewSectionDescription>
                            {
                              selectedBroker && Object.keys(selectedBroker).length &&
                              brokerCommissionFirstRange !== '' && brokerCommissionSecondRange !== '' &&
                              brokerCommissionFirstRange && brokerCommissionSecondRange
                                ? `Broker will receive ${brokerCommissionFirstRange}% on the first $100,000 and ${brokerCommissionSecondRange}% on balance.`
                                : null
                            }
                          </ReviewSectionDescription>
                        </ReviewSectionWrapper>
                      </ReviewColumns>
                      <ReviewColumns span={!projectHasColorSchemeOptions && !projectHasParkingOptions ? 0 : 12}>
                        {
                          projectHasColorSchemeOptions && (
                            <ReviewSectionWrapper>
                              <H3 className='text-2xl'>Color Scheme</H3>
                              {
                                Object.keys(selectedColorScheme).length
                                  ? <ReviewSectionDescription>{selectedColorScheme.name} Color Scheme Selected</ReviewSectionDescription>
                                  : <ReviewSectionDescription>No Color Scheme Selected</ReviewSectionDescription>
                              }
                              <ReportLine
                                leftText='Total'
                                rightText={formatMoney(totalColorSchemeValue, currencyType)}
                                size='large'
                                leftTextBold
                                rightTextBold
                                color={standardStyle.colors.primary}
                              />
                            </ReviewSectionWrapper>
                          )
                        }

                        {
                          projectHasParkingOptions && (
                            <ReviewSectionWrapper>
                              <H3 className='text-2xl'>Parking</H3>

                              {
                                <>
                                  <ReviewSectionDescription>{`${unitSlots} unit's ${pluralWords(unitSlots, 'slot')}`}</ReviewSectionDescription>
                                  {
                                    Object.keys(selectedParking).length
                                      ? <ReviewSectionDescription>{selectedParking.name} Parking Option Selected</ReviewSectionDescription>
                                      : <ReviewSectionDescription>No Parking Option Selected</ReviewSectionDescription>
                                  }
                                </>
                              }

                              <ReportLine
                                leftText='Total'
                                rightText={formatMoney(totalParkingValue, currencyType)}
                                size='large'
                                leftTextBold
                                rightTextBold
                                color={standardStyle.colors.primary}
                              />
                            </ReviewSectionWrapper>
                          )
                        }
                      </ReviewColumns>
                    </ReviewWrapper>
                    {
                      projectHasOptionsAndUpgrades && (
                        <ReviewSectionWrapper style={{ width: '-webkit-fill-available' }}>
                          <H3 className='text-2xl'>Options</H3>
                          {
                            selectedAdditionalOptions.length
                              ? selectedAdditionalOptions.map((option, index) =>
                                <ReportLine
                                  key={index}
                                  leftText={option.name}
                                  rightText={formatMoney(option.value, currencyType)}
                                  size='large'
                                  leftTextBold={false}
                                  rightTextBold={false}
                                  color={standardStyle.colors.primary}
                                />
                              )
                              : (
                                <ReviewSectionDescription>No Additional Options Selected</ReviewSectionDescription>
                                )
                          }
                          <ReportLine
                            leftText='Total'
                            rightText={formatMoney(optionsTotalPrice, currencyType)}
                            size='large'
                            leftTextBold
                            rightTextBold
                            color={standardStyle.colors.primary}
                          />
                        </ReviewSectionWrapper>
                      )
                    }

                    {
                      Boolean(selectedFintracOptions && Object.keys(selectedFintracOptions).length) && (
                        <ReviewSectionWrapper style={{ width: '-webkit-fill-available' }}>
                          <H3 className='text-2xl'>Fintrac</H3>
                          <ReportLine
                            leftText='Third party?'
                            rightText={selectedFintracOptions.thirdPart}
                            size='large'
                            leftTextBold
                            rightTextBold
                            color={standardStyle.colors.primary}
                          />
                          <ReportLine
                            leftText='Client risk'
                            rightText={selectedFintracOptions.clientRisk}
                            size='large'
                            leftTextBold
                            rightTextBold
                            color={standardStyle.colors.primary}
                          />
                          <ReportLine
                            leftText='Business Relationship'
                            rightText={removeLastCommaFromStrings(
                              selectedFintracOptions.businessRelationship.reduce((ac, option) => `${ac} ${capitalizeWords(option.replaceAll('_', ' '))}, `, '')
                            )}
                            size='large'
                            leftTextBold
                            rightTextBold
                            color={standardStyle.colors.primary}
                          />
                        </ReviewSectionWrapper>
                      )
                    }

                    <ReviewSectionWrapper style={{ width: '-webkit-fill-available' }}>
                      <H3 className='text-2xl mb-0'>Deposits</H3>
                      {
                        allowDepositsEdition && (
                          <span>
                            If you want a specific value of your deposit, please remove the percent value and inform the
                            price you want to pay.
                          </span>
                        )
                      }

                      <GridFormWrapper className='mt-4 w-full'>
                        {refLocalPaymentRounds.current.map((paymentRound, index) => {
                          return renderDepositLine({
                            _id: paymentRound._id,
                            placeHolder: `${++index}º deposit`,
                            daysToDeposit: paymentRound.days,
                            depositPercent: paymentRound.perc,
                            depositValue: paymentRound.value
                          })
                        })}
                      </GridFormWrapper>
                      <span className='text-3xl mt-8 text-center'>Total: {offerTotalPriceStringFormated}</span>
                    </ReviewSectionWrapper>
                  </ReviewMainWrapper>

                  {
                    showButtonsWrapper && (
                      <CreateOfferButtonWrapper
                        className={`fixed pb-3 md:pb-0 md:relative bottom-0 left-0 right-0
                        md:h-2/20 w-full flex items-center justify-center bg-white`}
                      >
                        {
                          (offerId && showSecondaryButton) && (
                            <TButton
                              onClick={() => onClickShowDocs({
                                paymentRounds: refLocalPaymentRounds.current
                              })}
                              className={`mr-3 ${saveButtonClassName}`}
                            >
                              {buttonLabel[modalInnerContainer]}
                            </TButton>
                          )
                        }
                        {
                          showPrimaryButton && (
                            <TSaveButton
                              onClick={() => offerId ? confirmUpdateOffer() : _createUpdateOffer()}
                              loading={creatingOffer}
                              className={saveButtonClassName}
                            >
                              {offerId ? 'Update Offer' : 'Create Offer'}
                            </TSaveButton>
                          )
                        }
                      </CreateOfferButtonWrapper>
                    )
                  }
                </FadedAnimatedDiv>
                )
          }
        </AnimatePresence>
      </PageWrapper>
    </>
  )
}

export default Review

Review.defaultProps = {
  showSecondaryButton: true,
  saveButtonClassName: '',
  showButtonsWrapper: true,
  allowDepositsEdition: true,
  showPrimaryButton: true
}

Review.propTypes = {
  selectedUnit: propTypes.shape({
    unitNumber: propTypes.string,
    price: propTypes.string,
    numberOfBedrooms: propTypes.string,
    numberOfBathrooms: propTypes.string,
    _id: propTypes.string,
    floorPlan: propTypes.shape({
      parkingQty: propTypes.string
    })
  }),
  selectedBuyers: propTypes.array,
  selectedBroker: propTypes.object,
  // selectedBuyers: propTypes.shape({
  //   firstName: propTypes.string,
  //   lastName: propTypes.string,
  //   salesRepName: propTypes.string,
  //   _id: propTypes.string,
  //   frontPhotoId: propTypes.string,
  //   backPhotoId: propTypes.string
  // }),
  selectedParking: propTypes.array,
  selectedColorScheme: propTypes.string,
  selectedAdditionalOptions: propTypes.array,
  selectedFintracOptions: propTypes.object,
  paymentRounds: propTypes.array,
  onClickShowDocs: propTypes.func,
  offerId: propTypes.string,
  modalInnerContainer: propTypes.node,
  executeWhenOfferCreated: propTypes.func,
  alreadyShownCreateOfferAnimation: propTypes.bool,
  field: propTypes.string,
  value: propTypes.string,
  _id: propTypes.string,
  showSecondaryButton: propTypes.bool,
  saveButtonClassName: propTypes.string,
  customTitle: propTypes.node,
  showButtonsWrapper: propTypes.bool,
  allowDepositsEdition: propTypes.bool,
  projectData: propTypes.object,
  showPrimaryButton: propTypes.bool,
  brokerCommissionFirstRange: propTypes.oneOfType([
    propTypes.string,
    propTypes.number
  ]),
  brokerCommissionSecondRange: propTypes.oneOfType([
    propTypes.string,
    propTypes.number
  ])
}

SelectedBuyer.propTypes = {
  data: propTypes.shape({
    userAvatar: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    email: propTypes.string
  })
}
