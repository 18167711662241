/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

import useGetParticipantsMeetings from 'hooks/useGetParticipantsMeetings'

import NewMeeting from 'components/NewMeeting'
import MeetingCard from 'components/MeetingCard'
import useBuyers from 'hooks/useGetBuyers'

const MeetingTab = ({ contact }) => {
  const { buyers, getBuyersData } = useBuyers()
  const { loading, meetings, refetch } = useGetParticipantsMeetings(contact._id)
  const [createMeeting, setCreateMeeting] = useState(false)

  const filteredMeetings = useMemo(() => {
    if (!meetings?.length || loading) { return [] }
    return meetings.filter((_meeting) => _meeting?.participants?.filter(_part => _part?.email === contact?.email).length)
  }, [meetings, loading])

  useEffect(() => {
    getBuyersData()
  }, [])

  const openCreateModal = () => {
    setCreateMeeting(true)
  }

  const onSuccess = () => {
    refetch()
  }

  return (
    <>
      {loading && (
        <div className='flex flex-col gap-6 mt-8'>
          <Skeleton height={100} />
        </div>
      )}
      {!loading && !filteredMeetings?.length && (
        <div className='font-openSans flex flex-col justify-center items-center h-96 w-full bg-white mt-8 mb-8 rounded'>
          <svg
            width='22'
            height='24'
            viewBox='0 0 18 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM4 9H9V14H4V9Z'
              fill='#2E2B2E'
            />
          </svg>
          <div className='font-semibold mb-2 mt-4'>You don't have any meetings yet</div>
          <div className='text-sm text-softBlack70'>
            Schedule your first meeting with Aaron
          </div>
          <button className='bg-yellow-200 font-semibold px-4 py-2 rounded mt-6' onClick={openCreateModal}>
            Schedule Meeting
          </button>
        </div>
      )}
      {!loading && filteredMeetings.length > 0 && (
        <div>
          <div className='text-center'>
            <button
              onClick={() => {
                setCreateMeeting(true)
              }}
              className='bg-yellow-200 font-semibold px-4 py-2 rounded'
            >
              Schedule Meeting
            </button>
          </div>
          <div className='font-openSans flex flex-col gap-6 my-8'>
            {filteredMeetings?.map((_meeting, index) => <MeetingCard meeting={_meeting} key={`${_meeting._id}_${index}`} onSuccess={onSuccess} contact={contact} buyers={buyers} />)}
          </div>
        </div>
      )}
      {createMeeting ? <NewMeeting isOpen={createMeeting} setOpen={setCreateMeeting} contact={contact} onSuccess={onSuccess} buyers={buyers} /> : null}
    </>
  )
}

export default MeetingTab
