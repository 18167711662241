import React, { useEffect, useState } from 'react'
import { getAllCampaigns } from 'store/actions/emailCampaignActions'
import standardStyle from 'assets/css/standardStyle'
import Button from 'components/Buttons'

const Card = ({ title, value }) => (
  <div className=''>
    <div
        className='bg-white rounded'
        style={{ fontFamily: standardStyle.fonts.sfpro }}
    >
        <div className='flex flex-col space-y-2 p-6'>
            <div className='text-xs text-softBlack_70 font-medium uppercase'>
                {title}
            </div>
            <div className='text-xl text-softBlack font-bold'>{value}</div>
        </div>
    </div>
  </div>
)

export default function EmailCampaignPage () {
  const [campaigns, setCampaigns] = useState([])
  const [analytics, setAnalytics] = useState({
    total_campaign: 0,
    total_email_sent: 0,
    total_opened: 0,
    total_clicked: 0,
    total_bounced: 0,
    total_unsubscribed: 0
  })
  useEffect(() => {
    getAllCampaigns()
      .then((campaigns) => {
        campaigns = campaigns.filter(e => e.report)
        setCampaigns(campaigns)
        const analytics = campaigns.reduce(
          (out, campaign) => {
            if (!campaign.report) {
              return out
            }
            return {
              total_campaign: out.total_campaign + 1,
              total_email_sent: out.total_email_sent + campaign.emails_sent,
              total_opened: out.total_opened + campaign.report.opens.opens_total,
              total_clicked: out.total_clicked + campaign.report.clicks.clicks_total,
              total_bounced: out.total_bounced + campaign.report.bounces.hard_bounces + campaign.report.bounces.soft_bounces,
              total_unsubscribed: out.total_unsubscribed + campaign.report.unsubscribed
            }
          },
          {
            total_campaign: 0,
            total_email_sent: 0,
            total_opened: 0,
            total_clicked: 0,
            total_bounced: 0,
            total_unsubscribed: 0
          }
        )
        setAnalytics(analytics)
      })
      .catch()
  }, [])

  return (
        <div className='px-4 sm:px-6 lg:px-8 mt-4'>
            <div className='grid grid-cols-6 gap-4 pt-4'>
                <Card title={'Campaign Sent'} value={analytics.total_campaign} />
                <Card title={'Total Email Sent'} value={analytics.total_email_sent} />
                <Card title={'Total opened'} value={analytics.total_opened} />
                <Card title={'Total clicked'} value={analytics.total_clicked} />
                <Card title={'Total Bounces'} value={analytics.total_bounced} />
                <Card title={'Total Unsubscribed'} value={analytics.total_unsubscribed} />
            </div>
            <div className='mt-8 flow-root bg-white rounded px-6'>
                <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead>
                        <tr>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Name
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Date Sent
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Emails Count
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Opens
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Clicks
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Bounced
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                            Unsubscribed
                          </th>
                          <th
                            scope='col'
                            className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                          >
                          </th>
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200'>
                        {campaigns?.map((campaign) => (
                          <tr key={campaign.id}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {campaign.settings.subject_line}
                            </td>
                             <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {new Date(campaign.send_time).toLocaleDateString()}
                            </td>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {campaign.report.emails_sent}
                            </td>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {campaign.report.opens.opens_total}
                            </td>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {campaign.report.clicks.clicks_total}
                            </td>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {campaign.report.bounces.hard_bounces + campaign.report.bounces.soft_bounces}
                            </td>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0'>
                              {campaign.report.unsubscribed}
                            </td>
                            <td className='whitespace-nowrap py-4 text-sm text-gray-900 sm:pl-0'>
                              <div className='flex gap-3 w-full items-center content-center justify-center'>
                                <a target={'_blank'} href={campaign.long_archive_url} rel="noreferrer"><Button type='Secondary' size='Long' label='Live Preview'/></a>
                                <a href={'campaigns/' + campaign.id}><Button type='Primary' label='Report'/></a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
  )
}
