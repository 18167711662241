/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  memo,
  useMemo,
  useCallback,
  useRef
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import {
  getProjectOffers,
  getProjectStatistics,
  cancelOffer as cancelOfferAPI
} from 'store/actions/offerActions'
import { Table } from 'ui'
import { formatDate, formatMoney } from 'utils'
import FadedAnimatedDiv from 'components/FadedAnimatedDiv'
import { ADMIN_OFFER } from 'routes'
import { OfferDocuments } from 'components'
import { message } from 'antd'
import standardStyle from 'assets/css/standardStyle'
import UserRoundedImage from 'components/UserRoundedImage'
import Dropdown from './Dropdown'
import { EyeIcon } from '@heroicons/react/outline'
import { CloseIcon, EditIcon } from 'components/Icons'
import { ReactComponent as UserCheck } from 'assets/icons/user-check.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'
import DeleteModal from 'components/DeleteModal'
import { Modal } from '@material-ui/core'

import { isMobile } from 'react-device-detect'
import PaymentTypeModal from 'pages/offer/components/PaymentTypeModal'
import PaymentMethodModal from 'pages/offer/components/PaymentMethodModal'

const LoadingContext = createContext()

export const offerStatus = {
  SoldFirm: { text: 'Sold - Firm', backgroundColor: 'bg-green-700' },
  Signed: { text: 'Signed', backgroundColor: 'bg-blue-500' },
  OfferSent: { text: 'Offer Sent', backgroundColor: 'bg-blue-300' },
  OfferCreated: { text: 'Offer Created', backgroundColor: 'bg-gray-600' },
  OfferAccepted: { text: 'Offer Accepted', backgroundColor: 'bg-blue-300' },
  Paid: { text: 'Paid', backgroundColor: 'bg-green-900' },
  SevenDaysRescission: {
    text: 'Seven Days Rescission',
    backgroundColor: 'bg-yellow-600'
  },
  OfferRejected: { text: 'Offer Rejected', backgroundColor: 'bg-red-600' },
  ContractCancelled: { text: 'Offer Cancelled', backgroundColor: 'bg-red-600' }
}

const UserProfile = ({ name }) => (
  <div className='flex'>
    {/* <UserRoundedImage className='h-16 w-16' /> */}
    <span className='font-medium text-base text-softBlack capitalize'>
      {name}
    </span>
  </div>
)

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const ColoredStatus = ({ status }) => {
  if (offerStatus?.[status]) {
    return (
      <div
        className={`font-medium py-1 text-base text-white uppercase
        ${offerStatus?.[status]?.backgroundColor || 'bg-black'} text-center
      `}
      >
        {offerStatus?.[status]?.text}
      </div>
    )
  }
  return null
}

const CardMoneyTextComponent = memo(({ children }) => (
  <div className='text-lg font-medium text-gray-900'>{children}</div>
))

const StatisticValue = memo(({ title, value, loadingObject }) => {
  const { gettingProjectOffers, gettingstProjectStatistics } =
    useContext(LoadingContext)

  return (
    <AnimatePresence exitBeforeEnter initial>
      {gettingProjectOffers || gettingstProjectStatistics ? (
        <FadedAnimatedDiv key={`statisticValueLoading${title}`}>
          {loadingObject}
        </FadedAnimatedDiv>
      ) : (
        <FadedAnimatedDiv key={`statisticValue${title}`}>
          {value}
        </FadedAnimatedDiv>
      )}
    </AnimatePresence>
  )
})

const CardComponent2 = ({ IconSrc, title, amount, noOfUnits }) => (
  <div
    className='bg-white rounded'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='p-6'>
      <div className='w-8 h-6'>{IconSrc}</div>
    </div>
    <div className='flex flex-col space-y-2 p-6'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>
        <StatisticValue
          title={title}
          value={formatMoney(amount || 0)}
          loadingObject={
            <CardMoneyTextComponent>loading...</CardMoneyTextComponent>
          }
        />
      </div>
      <div className='text-sm font-bold text-softBlack pt-4'>
        <StatisticValue title={title} value={`${noOfUnits ?? '0'} UNITS`} />
      </div>
    </div>
  </div>
)

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <Table
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
      isFromContracts
    />
  )
}

const ActionButton = ({ text, onClick, value }) => {
  return (
    <div
      className='font-medium py-1 text-base text-black uppercase text-center border border-black w-32 cursor-pointer'
      onClick={onClick}
    >
      View/Edit
    </div>
  )
}

const ProjectOffers = ({ appProject, userObject }) => {
  const history = useHistory()
  const [gettingProjectOffers, setGettingProjectOffers] = useState(false)
  const [gettingstProjectStatistics, setGettingProjectStatistics] =
    useState(false)
  const [projectOffers, setProjectOffers] = useState([])
  const [filterProjectOffers, setFilterProjectOffers] = useState([])
  const [projectStatistics, setProjectStatistics] = useState('')
  const [offerIdShowingDocs, setOfferIdShowingDocs] = useState('')
  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [idBeingDeleted, setIdBeingDeleted] = useState('')
  const [showCancelOfferModal, setShowCancelOfferModal] = useState(false)
  const [cancelingOffer, setCancelingOffer] = useState(false)
  const [sortType, setSortType] = useState('asc')
  const [sortByField, setSortByField] = useState('createdAt')
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false)
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const [successfulPayment, setSuccessfulPayment] = useState(false)

  const openDocumentsModal = ({ id: offerId }) => {
    setOfferIdShowingDocs(offerId)
    setShowDocumentsModal(true)
  }

  const onEditClick = ({ id: offerId }) => {
    history.push({
      pathname: ADMIN_OFFER,
      state: {
        appProject,
        offerId
      }
    })
  }

  const onSortClicked = useCallback(
    (field) => {
      let newSortType = 'asc'
      if (field === sortByField) {
        newSortType = sortType === 'asc' ? 'desc' : 'asc'
      }
      const sortedData = projectOffers.sort((a, b) => {
        if (newSortType === 'asc') {
          return a[field]?.localeCompare(b[field])
        } else if (newSortType === 'desc') {
          return b[field]?.localeCompare(a[field])
        } else return 0
      })
      setFilterProjectOffers([...sortedData])
      setSortByField(field)
      setSortType(newSortType)
    },
    [projectOffers, sortByField, sortType]
  )

  const confirmCancelOffer = ({ id: offerId, status }) => {
    if (status !== 'OfferRejected') {
      setIdBeingDeleted(offerId)
      setShowCancelOfferModal(true)
    } else {
      message.warning('Offer already canceled')
    }
  }

  const options = [
    {
      title: 'View Documents',
      icon: <EyeIcon className='w-6 h-6' />,
      onClick: (data) => {
        openDocumentsModal(data)
      }
    },
    {
      title: 'Edit',
      icon: <EditIcon className='w-6 h-6' />,
      onClick: (data) => {
        onEditClick(data)
      }
    },
    {
      title: 'Cancel Offer',
      icon: <CloseIcon className='w-6 h-6' />,
      onClick: (data) => {
        confirmCancelOffer(data)
      }
    }
  ]

  const EditButton = (offer) => {
    return (
      <Dropdown
        options={options.map((option) => {
          return { ...option, ...offer }
        })}
      />
    )
  }

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Date Created</span>
            <button onClick={() => onSortClicked('createdAt')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'createdAt',
        dataIndex: 'createdAt',
        Cell: (props) => <TextField value={formatDate(props.cell.value)} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Buyer</span>
            <button onClick={() => onSortClicked('buyer')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'buyer',
        dataIndex: 'buyer',
        Cell: (props) => <UserProfile name={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Unit</span>
            <button onClick={() => onSortClicked('unitNumber')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'unitNumber',
        dataIndex: 'unitNumber',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Sale Amount</span>
            <button onClick={() => onSortClicked('saleAmount')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'saleAmount',
        dataIndex: 'saleAmount',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Amount + Options</span>
            <button onClick={() => onSortClicked('amountOptionsCost')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'amountOptionsCost',
        dataIndex: 'amountOptionsCost',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Status</span>
            <button onClick={() => onSortClicked('status')}>
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'status',
        dataIndex: 'status',
        Cell: (props) => <ColoredStatus status={props.cell.value} />
      },
      {
        Title: '',
        accessor: '_id',
        Cell: (props) => (
          <div className='flex flex-row gap-2 text-center items-center justify-between'>
            <ActionButton
              text={props.cell.value}
              value={props.cell}
              onClick={() => {
                const offerId = props.cell.value
                history.push(`/new/admin/offer_preview/${offerId}`)
              }}
            />
            <EditButton
              id={props.row.original._id}
              status={props.row.original.status}
            />
          </div>
        )
      }
    ],
    [onSortClicked]
  )

  const getData = useCallback(() => {
    if (appProject) {
      setProjectOffers([])
      setGettingProjectOffers(true)
      setGettingProjectStatistics(true)

      getProjectStatistics(appProject)
        .then((statistics) => {
          const { counters } = statistics
          setProjectStatistics(counters)

          setGettingProjectStatistics(false)
        })
        .catch(() => {
          setGettingProjectStatistics(false)
        })

      getProjectOffers(appProject)
        .then((offers) => {
          const tmpProjectOffers = offers.map((offer, index) => {
            const {
              buyer: { _id: buyerId, firstName, lastName, email },
              unit: { unitNumber, price: unitPrice },
              project: { totalDollarValueCurrencyType },
              totalCost,
              status,
              _id,
              createdAt
            } = offer

            const tmpOffer = {
              _id,
              createdAt,
              buyerId,
              buyer: `${firstName} ${lastName}`.toLowerCase(),
              // buyer: {
              //   name: `${firstName} ${lastName}`.toLowerCase(),
              //   image: null
              // },
              buyerEmail: email,
              unitNumber,
              saleAmount: formatMoney(unitPrice),
              amountOptionsCost: formatMoney(totalCost),
              totalDollarValueCurrencyType,
              status
            }

            return tmpOffer
          })

          setProjectOffers(tmpProjectOffers)
          setGettingProjectOffers(false)
        })
        .catch(() => {
          setGettingProjectOffers(false)
        })
    }
  }, [appProject])

  useEffect(() => {
    getData()
  }, [getData])

  const values = useMemo(
    () => ({
      gettingProjectOffers,
      gettingstProjectStatistics
    }),
    [gettingProjectOffers, gettingstProjectStatistics]
  )

  const tabeleDataSource = useMemo(() => {
    const values =
      filterProjectOffers?.length > 0 ? filterProjectOffers : projectOffers
    return values.filter((x) => !x.deleted)
  }, [filterProjectOffers, projectOffers])

  const cancelOffer = () => {
    setShowCancelOfferModal(false)
    setCancelingOffer(true)
    cancelOfferAPI(idBeingDeleted)
      .then(() => {
        setCancelingOffer(false)
        setIdBeingDeleted('')
        getData()
      })
      .catch(() => setCancelingOffer(false))
  }

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col mt-6 items-center'>
        <Modal
          open={showDocumentsModal}
          onClose={() => setShowDocumentsModal(false)}
          aria-labelledby='Offer Documents'
          aria-describedby='Offer documents list'
        >
          <div
            className='font-openSans relative bg-grey-8 y-0 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
            style={{
              width: isMobile ? '100%' : 1376,
              height: '760px',
              overflowY: 'auto',
              overflowX: 'auto'
            }}
          >
            <button
              className='absolute right-9 top-9'
              style={{ right: '2rem', top: '2rem' }}
              onClick={() => setShowDocumentsModal(false)}
            >
              <CrossIcon />
            </button>
            <OfferDocuments
              offerId={offerIdShowingDocs}
              setCloseDocument={() => setShowDocumentsModal(false)}
              setPrimaryBtnAction={(val)=>setShowPaymentTypeModal(val)}
            />
          </div>
        </Modal>
        <PaymentTypeModal
          {...{
            showPaymentTypeModal,
            setShowPaymentTypeModal,
            setShowPaymentMethodModal,
            setPaymentMethodId,
            setSuccessfulPayment
          }}
        />
        <PaymentMethodModal
          {...{
            showPaymentMethodModal,
            setShowPaymentMethodModal,
            paymentMethodId,
            successfulPayment,
            setSuccessfulPayment
            // setShowPaymentModal
          }}
        />
        <DeleteModal
          title='Cancel Offer'
          description='Are you sure you want to cancel this offer? It will be deleted permanently'
          isOpen={showCancelOfferModal}
          cancelButtonTitle='Close'
          setIsOpen={() => {
            setShowCancelOfferModal(false)
            setIdBeingDeleted('')
          }}
          confirmButtonTitle='Yes, cancel it'
          onConfirm={cancelOffer}
        />
        <div className='grid grid-cols1 md:grid-cols-4 gap-4 w-full'>
          <CardComponent2
            IconSrc={
              <svg
                width='32'
                height='32'
                viewBox='0 0 32 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M22.6654 5.33301H9.33203V25.333H22.6654V5.33301Z'
                  fill='#2E2B2E'
                />
                <path d='M6.66667 8H4V22.6667H6.66667V8Z' fill='#2E2B2E' />
                <path d='M27.9987 8H25.332V22.6667H27.9987V8Z' fill='#2E2B2E' />
              </svg>
            }
            title='Total Available'
            amount={projectStatistics.sumUnitAvailable}
            noOfUnits={projectStatistics.counterUnitAvailable}
          />

          <CardComponent2
            IconSrc={<UserCheck />}
            title='Total Reserved'
            noOfUnits={projectStatistics.counterUnitReserved}
            amount={projectStatistics.sumUnitReserved}
          />

          <CardComponent2
            IconSrc={
              <svg
                width='33'
                height='32'
                viewBox='0 0 33 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M24.5 17.3334H23.5933L20.9267 20.0001H23.4733L25.8333 22.6667H7.16667L9.54 20.0001H12.2733L9.60667 17.3334H8.5L4.5 21.3334V26.6667C4.5 28.1334 5.68667 29.3334 7.15333 29.3334H25.8333C27.3 29.3334 28.5 28.1467 28.5 26.6667V21.3334L24.5 17.3334ZM23.1667 10.6001L16.5667 17.2001L11.8467 12.4801L18.4467 5.88005L23.1667 10.6001ZM17.5133 3.05339L9.02 11.5467C8.5 12.0667 8.5 12.9067 9.02 13.4267L15.62 20.0267C16.14 20.5467 16.98 20.5467 17.5 20.0267L25.98 11.5467C26.5 11.0267 26.5 10.1867 25.98 9.66672L19.38 3.06672C18.8733 2.53339 18.0333 2.53339 17.5133 3.05339Z'
                  fill='#2E2B2E'
                />
              </svg>
            }
            title='Total Offer Created'
            noOfUnits={projectStatistics.counterOfferCreated}
            amount={projectStatistics.sumOfferCreated}
          />

          <CardComponent2
            IconSrc={
              <svg
                width='33'
                height='32'
                viewBox='0 0 33 32'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.918 22.6663H17.5846V21.333H18.918C19.6513 21.333 20.2513 20.733 20.2513 19.9997V15.9997C20.2513 15.2663 19.6513 14.6663 18.918 14.6663H14.918V13.333H20.2513V10.6663H17.5846V9.33301H14.918V10.6663H13.5846C12.8513 10.6663 12.2513 11.2663 12.2513 11.9997V15.9997C12.2513 16.733 12.8513 17.333 13.5846 17.333H17.5846V18.6663H12.2513V21.333H14.918V22.6663ZM26.918 5.33301H5.58464C4.10464 5.33301 2.9313 6.51967 2.9313 7.99967L2.91797 23.9997C2.91797 25.4797 4.10464 26.6663 5.58464 26.6663H26.918C28.398 26.6663 29.5846 25.4797 29.5846 23.9997V7.99967C29.5846 6.51967 28.398 5.33301 26.918 5.33301ZM26.918 23.9997H5.58464V7.99967H26.918V23.9997Z'
                  fill='#2E2B2E'
                />
              </svg>
            }
            title='Total Sold Firm'
            noOfUnits={projectStatistics.counterSoldFirm}
            amount={projectStatistics.sumSoldFirm}
          />
        </div>

        {isMobile && filterProjectOffers?.length && (
          <div className='flex flex-col gap-4 mt-5 w-full'>
            {filterProjectOffers.map((offer) => (
              <div className='flex flex-col relative bg-white rounded p-4 gap-4'>
                <div className='text-lg'>
                  <strong>Name:</strong> {offer?.buyer?.name}
                </div>
                <div className='text-lg'>
                  <strong>Email:</strong> {offer?.buyerEmail}
                </div>
                <div className='text-lg'>
                  <strong>Unit:</strong> {offer?.unitNumber}
                </div>
                <div className='text-lg'>
                  <strong>Sales Amount:</strong>{' '}
                  {offer?.totalDollarValueCurrencyType}{' '}
                  {offer?.saleAmount?.props?.value}
                </div>
                <div className='text-lg'>
                  <strong>Status:</strong> {offer?.status}
                </div>
                <div className='absolute top-2 right-2'>
                  <EditButton id={offer._id} status={offer?.status} />
                </div>
              </div>
            ))}
          </div>
        )}

        {!isMobile && (
          <div className='mt-5 w-full'>
            <MemoTable
              dataSource={tabeleDataSource}
              columns={tableColumns}
              loading={gettingProjectOffers}
              idBeingDeleted={idBeingDeleted}
              deleting={cancelingOffer}
            />
          </div>
        )}
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOffers)

ProjectOffers.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}

StatisticValue.propTypes = {
  title: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.string]),
  loadingObject: propTypes.object
}

CardComponent2.propTypes = {
  IconSrc: propTypes.node,
  title: propTypes.string,
  amount: propTypes.number,
  noOfUnits: propTypes.number
}

// MemoTable.propTypes = {
//   dataSource: propTypes.array,
//   columns: propTypes.array,
//   loading: propTypes.bool,
//   idBeingDeleted: propTypes.string,
//   deleting: propTypes.bool
// }
