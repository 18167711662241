/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { getNylasSchedulerAccessToken } from 'store/actions/usersActions'

const SchedulerSettings = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getNylasSchedulerAccessToken().then((data) => {
      console.log('getNylasSche1dulerAccessToken', data.accessToken)
      // @ts-ignore
      nylas.scheduler.show({
        auth: {
          // Account <ACCESS_TOKEN> with active calendar scope
          accessToken: data.accessToken
        },
        style: {
          // Style the schdule editor
          tintColor: '#32325d',
          backgroundColor: 'white'
        }
        // defaults: {
        //   event: {
        //     title: '30-min Coffee Meeting',
        //     duration: 30,
        //   },
        // },
      })
    }).catch((error) => { console.error('getNylasSchedulerAccessToken', error) })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <div className='p-2'>
      <p>Schedule Settings</p>
      {loading && <p>Loading...</p>}
    </div>
  )
}

export default SchedulerSettings
