/* eslint-disable no-unused-vars */
import CustomModal from 'components/Modal'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { buttonStyle } from './style'
import useGetSalesReps from 'hooks/useGetSalesReps'
import { Select } from 'antd'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { ContactContext } from 'pages/contacts/context/Contacts'
import { getUsers, updateSalesRep } from 'store/actions/usersActions'

const AssignSalesrepModal = ({ open, setOpen }) => {
  const {
    saveList,
    setSaveList,
    setContacts,
    setIsLoading,
    setFilteredContacts
  } = useContext(ContactContext)
  const { salesReps, refetch, loading } = useGetSalesReps()
  const [selected, setSelected] = useState('')

  useEffect(() => {
    if (!salesReps?.length) refetch()
  }, [salesReps, refetch])

  const handleClick = () => {
    const salesRepFound = salesReps?.find?.(
      (salesreps) => salesreps?._id === selected
    )
    saveList?.forEach?.(async (contact) => {
      await updateSalesRep({ salesRep: salesRepFound, contactId: contact?._id })
    })
    setSaveList([])
    setIsLoading(true)
    const filters = '?userType=Buyer&userType=LeadBroker'
    getUsers('', filters).then((users) => {
      setContacts(users)
      // @ts-ignore
      setFilteredContacts(users)
      setIsLoading(false)
    })
  }

  const handleSalesRepChange = (salesRepId) => setSelected(salesRepId)

  return (
    <CustomModal isOpen={open} closeModal={() => setOpen(false)}>
      <div
        style={{ width: '100%', maxWidth: '600px', height: '400px' }}
        className='flex flex-col justify-between items-center gap-4 p-4 w-full'
      >
        <div className='text-bold'>Assign SalesRep to selected contacts</div>
        <Select
          loading={loading}
          suffixIcon={<DownIcon />}
          showArrow
          onChange={(salesRepId) => {
            handleSalesRepChange(salesRepId)
          }}
          getPopupContainer={(node) => node.parentNode}
          className='ant-picker-input1 w-full'
        >
          {salesReps?.map?.(
            (_item, index) =>
              _item?.firstName && (
                <Select.Option value={_item?._id} key={index}>
                  <div className='flex gap-2 items-center'>
                    {_item?.firstName} {_item?.lastName}{' '}
                  </div>
                </Select.Option>
              )
          )}
        </Select>
        <div className='flex-1 flex justify-end content-end items-end'>
          <Button onClick={handleClick} style={buttonStyle} variant='contained'>
            + Assign to Salesrep
          </Button>
        </div>
      </div>
    </CustomModal>
  )
}

export default AssignSalesrepModal
