import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import LargeUnitCard from 'components/LargeUnitCard'
import UserInfoCard from 'components/UserInfoCard'
import React, { useEffect, useState } from 'react'
import { formatMoney } from 'utils/formatters'
import AllocationConfirmModal from '../modals/AllocationConfirmModal'
import AllocationEnterNameModal from '../modals/AllocationEnterNameModal'
import AllocationNameModal from '../modals/AllocationNameModal'

const Review = ({
  setStep,
  value,
  setValue,
  onCreateOffer,
  handleSubmit,
  loading,
  receiver
}) => {
  const [allocationData, setAllocationData] = useState([
    { id: 1, value: 'North Penthouse' },
    { id: 2, value: 'West Penthouse' }
  ])
  const [durationState, setDurationState] = useState({ to: '', from: '' })
  const [open, setOpen] = useState(false)
  const [allocationConfirmModal, setAllocationConfirmModal] = useState(false)
  const [openCreationModal, setOpenCreationModal] = useState(false)
  const [allocationName, setAllocationName] = useState('')
  const [isMultiSequenced] = useState(
    value?.receiver === 'Brokers' && value?.method === 'Multi Broker Sequenced'
  )

  const totalPrice = () => {
    let totalPrice = 0
    for (let index = 0; index < value.inventory.length; index++) {
      totalPrice = value.inventory[index].price + totalPrice
    }
    return totalPrice
  }
  const allDayOptions = () => {
    const options = []
    const totalDays = 31
    for (let i = 1; i <= totalDays; i++) {
      options.push({
        _id: i,
        value: `${i} ${i === 1 ? 'day' : 'days'} `
      })
    }
    return options
  }

  const openAllocationNameModal = () => {
    setOpen(true)
  }

  const [filteredBrokers, setFilteredBrokers] = useState(
    value.broker.filter((broker) => broker.isActive === true)
  )

  useEffect(() => {
    if (allocationName?.length > 0) {
      const newId = allocationData[allocationData.length - 1].id + 1
      const data = { id: newId, value: allocationName }
      setAllocationData((prevState) => [...prevState, data])
    }
  }, [openCreationModal])

  return (
    <div>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Review</p>
      </div>
      <div
        className='my-10 border-b border-softBlack'
        style={{ paddingBottom: 40 }}
      >
        {' '}
        {isMultiSequenced && (
          <div className='mb-6'>
            <p
              className='text-softBlack font-bold my-4'
              style={{ fontSize: 22 }}
            >
              Reservation Calendar
            </p>
            <div className='flex justify-start' style={{ columnGap: '30px' }}>
              <CustomInput
                type='date'
                label={'From'}
                value={durationState.to}
                onChange={(e) =>
                  setDurationState((prevState) => ({
                    from: prevState.from,
                    to: e.target.value
                  }))
                }
                placeholder={undefined}
                containerClasses={'w-52'}
                classes={undefined}
              />
              <CustomInput
                type='date'
                label={'To'}
                value={durationState.from}
                onChange={(e) =>
                  setDurationState((prevState) => ({
                    to: prevState.to,
                    from: e.target.value
                  }))
                }
                placeholder={undefined}
                containerClasses={'w-52'}
                classes={undefined}
              />
            </div>
          </div>
        )}
        <p className='text-softBlack font-bold my-4' style={{ fontSize: 22 }}>
          {receiver}
          {isMultiSequenced && 's'}
        </p>
        <div className='flex gap-4 flex-wrap'>
          {filteredBrokers.map((broker, key) => {
            return (
              <UserInfoCard
                firstName={broker?.firstName}
                lastName={broker?.lastName}
                email={broker?.email}
                userAvatar={broker?.userAvatar}
                className={'w-72'}
                onSelect={undefined}
                location={undefined}
                isActive={undefined}
                key={key}
                selectDuration={isMultiSequenced}
                durationValue={broker.duration}
                onDurationChange={(e) => {
                  const filterdBrokersClone = [...filteredBrokers]
                  for (let i = 0; i < filterdBrokersClone.length; i++) {
                    const broker2 = filterdBrokersClone[i]
                    if (broker.id === broker2.id) {
                      broker.duration = e.target.value
                      break
                    }
                  }
                  setFilteredBrokers(() => [...filterdBrokersClone])
                }}
              />
            )
          })}
        </div>
      </div>
      <div style={{ paddingBottom: 40 }} className='border-b border-softBlack'>
        <p className='text-softBlack font-bold mb-8' style={{ fontSize: 22 }}>
          Units
        </p>
        <div className={`${isMultiSequenced && 'mt-8 grid grid-cols-3 gap-4'}`}>
          {value?.inventory.map((unit, key) => (
            <div
              className='pt-6'
              style={{
                borderBottom: `${
                  key === value?.inventory.length - 1 ? '0' : '0.5'
                }px solid rgba(46, 43, 46, 0.3)`
              }}
              key={key}
            >
              <LargeUnitCard
                imgsrc={unit.floorPlan?.image}
                unitName={`#${unit?.unitNumber}`}
                noOfBedrooms={unit?.numberOfBedrooms}
                noOfBathrooms={unit?.numberOfBathrooms}
                balcony={unit?.balcony}
                noOfParkingSlot={unit?.floorPlan?.parkingQty}
                building={unit?.building?.name}
                price={unit?.price}
                sizeInSquareFeet={undefined}
                address={unit?.fullAddress}
                pricePerSq={undefined}
                tag={undefined}
                type={isMultiSequenced ? 'grid' : 'flex'}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='py-4'>
        <p className='my-0 text-xl font-bold'>Reservation</p>
      </div>
      <p className='my-0 mt-4 mb-5 text-softBlack text-base'>
        Select duration of reservation
      </p>
      <CustomSelect
        options={allDayOptions()}
        label='Select duration'
        multiple={false}
        selectedOption={value?.duration}
        setSelectedOption={(option) => {
          setValue({
            ...value,
            duration: option
          })
        }}
        labelClasses={undefined}
        inputClasses={undefined}
        defaultValue={undefined}
      />
      <p className='py-4 border-b border-softBlack'>
        You can change your default Allocation Period by aceesing the Project
        Management {'>'} General {'>'} Inventory allocation days
      </p>
      <p className='font-bold mt-10 text-softBlack' style={{ fontSize: 22 }}>
        Total Value
      </p>

      <div className='flex justify-between items-center gap-4 m-0 p-0'>
        <p className='text-2xl p-0 m-0 font-bold text-softBlack'>
          {formatMoney(totalPrice())}
        </p>
        <button
          className='flex items-center justify-center rounded-md font-medium italic'
          style={{ backgroundColor: '#EDDF65', width: 143, height: 48 }}
          onClick={openAllocationNameModal}
        >
          Confirm Allocation
        </button>
      </div>
      <AllocationNameModal
        open={open}
        setOpen={setOpen}
        setAllocationConfirmModal={setAllocationConfirmModal}
        setOpenCreationModal={setOpenCreationModal}
        allocationData={allocationData}
        handleSubmit={handleSubmit}
        loading={loading}
      />
      <AllocationConfirmModal
        duration={value.duration}
        open={allocationConfirmModal}
        setOpen={setAllocationConfirmModal}
      />
      <AllocationEnterNameModal
        open={openCreationModal}
        setOpen={setOpenCreationModal}
        setAllocationName={setAllocationName}
      />
    </div>
  )
}

export default Review
