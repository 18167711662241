import React, { useState, useCallback, useEffect, memo } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { getOffers } from 'store/actions/offerActions'
import { getProjects } from 'store/actions/projectActions'
import menuStyles from './menu.module.scss'
import {
  CreateOfferAdditionalOptionsStep, CreateOfferColorSchemeStep,
  CreateOfferParkingStep, GlassedWrapper, TFilledChevronDownIcon
} from 'components'
import { useLocation } from 'react-router-dom'
import { getReservedUnits } from 'store/actions/unitActions'

import BuyerUnits from './buyerUnits'
import BuyerOfferSummary from './buyerOfferSummary'
import BuyerOfferContracts from './buyerOfferContracts'
import BuyerOfferPayment from './buyerOfferPayment'

import { formatMoney } from 'utils'
import { AnimatePresence } from 'framer-motion'
import { AnimatedStepWrapper } from './styles'

const scrollVariants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 400 : -400,
      opacity: 0
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 400 : -400,
      opacity: 0
    }
  }
}

const AnimatedCarouselWrapper = memo(({ key, direction, children }) => (
  <AnimatedStepWrapper
    key={key}
    className='h-full w-full'
    custom={direction}
    initial='enter'
    animate='center'
    exit='exit'
    transition={{
      opacity: { duration: 0.2 }
    }}
    variants={scrollVariants}
  >
    {children}
  </AnimatedStepWrapper>
))

const BuyerCreateOffer = (props) => {
  const location = useLocation()
  const { userObject } = props
  const { _id: buyerId, firstName } = userObject

  const [gettingUnits, setGettingUnits] = useState(false)
  const [projects, setProjects] = useState([])
  const [buyerUnits, setBuyerUnits] = useState([])
  const [selectedProject, setSelectedProject] = useState('')
  const [gettingProjects, setGettingProjects] = useState(false)
  const [projectData, setProjectData] = useState('')
  const [menuOpened, setMenuOpened] = useState(false)
  const [localCreationType, setLocalCreationType] = useState('')
  const [isOnCreationMode, setIsOnCreationMode] = useState(false)
  const [offerId, setOfferId] = useState('')
  const [offerData, setOfferData] = useState('')
  const [alreadyShownCreateOfferAnimation, setAlreadyShownCreateOfferAnimation] = useState()
  const [allDocsSigned, setAllDocsSigned] = useState(false)
  const [[page, direction], setPage] = useState([0, 0])
  const [alreadyRedirected, setAlreadyRedirected] = useState(false)
  const [fromDocumentsMenu, setFromDocumentsMenu] = useState(false)

  const [selectedUnit, setSelectedUnit] = useState('')
  const [selectedColorScheme, setSelectedColorScheme] = useState('')
  const [selectedParkingOption, setSelectedParkingOption] = useState('')
  const [selectedOptionsUpgrade, setSelectedOptionsUpgrade] = useState([])
  const [selectedOptionsUpgradeDescription, setSelectedOptionsUpgradeDescription] = useState('')
  const [offerCost, setOfferCost] = useState('')

  const [projectHasColorSchemeOptions, setProjectHasColorSchemeOptions] = useState(false)
  const [projectHasParkingOptions, setProjectHasParkingOptions] = useState(false)
  const [projectHasOptionsAndUpgrades, setProjectHasOptionsAndUpgrades] = useState(false)

  useEffect(() => {
    const hasOptions = projectData.options && projectData.options.length

    let hasColorSchemeOptions = false
    let hasParkingOptions = false
    let hasOptionsAndUpgrades = false

    if (hasOptions) {
      hasColorSchemeOptions = Boolean(projectData.options.filter(option => option.type === 'color').length)
      hasParkingOptions = Boolean(projectData.options.filter(option => option.type === 'parking').length)
      hasOptionsAndUpgrades = Boolean(projectData.options.filter(option => option.type === 'additionalOptions').length)
    }

    setProjectHasColorSchemeOptions(hasColorSchemeOptions)
    setProjectHasParkingOptions(hasParkingOptions)
    setProjectHasOptionsAndUpgrades(hasOptionsAndUpgrades)
  }, [projectData])

  const paginate = useCallback((newDirection) => {
    setPage([page + newDirection, newDirection])
  }, [setPage, page])

  const changePage = useCallback((current) => {
    paginate(current - page)
  }, [page, paginate])

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    scrollToTop()
  }, [page])

  useEffect(() => {
    setIsOnCreationMode(false)
    setMenuOpened(false)
    setOfferId('')
    setOfferData('')
    setAlreadyShownCreateOfferAnimation('')
    setAllDocsSigned('')
    setAlreadyRedirected(false)
    setSelectedUnit('')
    setSelectedColorScheme('')
    setSelectedParkingOption('')
    setSelectedOptionsUpgrade([])
    setSelectedOptionsUpgradeDescription('')
    setOfferCost('')
    setPage([0, 0])
  }, [localCreationType])

  useEffect(() => {
    const { state } = location
    if (state) {
      const { creationType, fromDocumentsMenu } = state

      if (creationType) {
        setLocalCreationType(creationType)
      }

      if (fromDocumentsMenu) {
        setFromDocumentsMenu(fromDocumentsMenu)
      }
    }
  }, [location])

  const invertMenuScrollDirection = () => {
    const list = document.getElementById('stepsList')
    if (list) {
      list.addEventListener('wheel', (ev) => {
        ev.preventDefault()
        list.scrollLeft += (ev.deltaY + ev.deltaX)
      })
    }
  }

  useEffect(() => {
    if (isOnCreationMode) {
      invertMenuScrollDirection()

      setMenuOpened(true)
    }
  }, [isOnCreationMode])

  function removeDuplicateElements (data, key) {
    return [
      ...new Map(
        data.map(x => [key(x), x])
      ).values()
    ]
  }

  useEffect(() => {
    async function getUnits () {
      setGettingUnits(true)

      let data = []

      if (localCreationType === 'offerCreated') {
        data = await getOffers('', `?buyer=${buyerId}&sortBy=createdAt&orderBy=desc`)

        if (data && data.length) {
          data = data.map(element => {
            const tmpElement = { ...element }

            if (tmpElement.unit && !tmpElement.unit.price) {
              tmpElement.unit.price = element.totalCost
            }

            return tmpElement
          })
        }
      }

      if (localCreationType === 'reservedUnits') {
        const tmpData = await getReservedUnits(buyerId)
        data = tmpData.filter((unit) => unit.status === 'ReservedBuyer')
      }

      if (data.length) {
        const projects = data
          .filter((element) => ((element.unit && element.unit.building) || element.building))
          .map((element) => {
            const baseSource = element.unit || element

            const object = {
              id: baseSource.building.project._id,
              value: baseSource.building.project.projectName
            }

            return object
          })

        const filteredArray = removeDuplicateElements(projects, project => project.id)

        if (filteredArray.length) {
          setProjects(filteredArray)
          setSelectedProject(filteredArray[0].id)
        }
      }

      setGettingUnits(false)
      setBuyerUnits(data)
    }

    if (buyerId && localCreationType) {
      getUnits()
    }
  }, [buyerId, localCreationType])

  useEffect(() => {
    if (selectedProject) {
      setGettingProjects(true)

      getProjects(selectedProject)
        .then((projectData) => {
          setProjectData(projectData)
          setGettingProjects(false)
        })
        .catch(() => {
          setGettingProjects(false)
        })
    }
  }, [selectedProject])

  const selectColorScheme = useCallback((colorScheme) => {
    setSelectedColorScheme(colorScheme)
    setMenuOpened(false)
    paginate(1)
  }, [paginate])

  const selectParkingOption = useCallback((parkingOption) => {
    setSelectedParkingOption(parkingOption)
    paginate(1)
  }, [paginate])

  const selectOptionsUpgrade = useCallback((optionsUpgrade) => {
    setSelectedOptionsUpgrade(optionsUpgrade)
    paginate(1)
  }, [paginate])

  const startCreationMode = useCallback((baseSource) => {
    if (localCreationType === 'offerCreated') {
      const { _id: offerId } = baseSource
      setOfferId(offerId)
      // getOfferData(offerId)
    } else {
      setSelectedUnit(baseSource)
      paginate(1)
    }

    if (!fromDocumentsMenu) {
      setIsOnCreationMode(true)
    }

    invertMenuScrollDirection()
  }, [paginate, localCreationType, fromDocumentsMenu])

  const getPages = useCallback(() => {
    const pages = [

      <AnimatedCarouselWrapper
        key='buyerUnits'
        direction={direction}
      >
        <BuyerUnits
          projects={projects}
          selectedProject={selectedProject}
          onChangeProject={(project) => setSelectedProject(project)}
          buyerUnits={buyerUnits}
          projectData={projectData}
          buyerName={firstName}
          creationType={localCreationType}
          onSelectUnit={(unit) => startCreationMode(unit)}
          selectedUnit={selectedUnit}
          offerId={offerId}
          gettingUnits={gettingUnits}
          gettingProjects={gettingProjects}
        />
      </AnimatedCarouselWrapper>,
      <AnimatedCarouselWrapper
        key='buyerSummary'
        direction={direction}
      >
        <BuyerOfferSummary
          projectData={projectData}
          paymentRounds={offerId ? offerData.paymentRounds : projectData.paymentRounds}
          selectedUnit={selectedUnit}
          selectedBuyers={[userObject]}
          selectedParking={selectedParkingOption}
          selectedColorScheme={selectedColorScheme}
          selectedAdditionalOptions={selectedOptionsUpgrade}
          offerId={offerId}
          onClickShowDocs={() => {
            changePage(getPages().findIndex((element) => element.key === 'buyerContracts'))
            setAlreadyShownCreateOfferAnimation(true)
          }}
          showSecondaryButton={fromDocumentsMenu}
          modalInnerContainer={fromDocumentsMenu && 'showing_docs'}
          executeWhenOfferCreated={({ offerData }) => {
            const { _id: offerId, totalCost } = offerData

            setOfferId(offerId)
            setOfferData(offerData)
            setOfferCost(totalCost)
          }}
          showPrimaryButton={!fromDocumentsMenu}
          alreadyShownCreateOfferAnimation={alreadyShownCreateOfferAnimation}
          saveButtonClassName='h-9 md:h-16 text-base md:text-2xl px-10 px-6 mt-2'
          customTitle={<span className='text-4xl'>Purchase Summary</span>}
          showButtonsWrapper={!offerId || fromDocumentsMenu}
          allowDepositsEdition={false}
        />
      </AnimatedCarouselWrapper>,
      <AnimatedCarouselWrapper
        key='buyerContracts'
        direction={direction}
      >
        <BuyerOfferContracts
          projectData={projectData}
          offerId={offerId}
          onReturnToOffersClick={() => {
            setOfferId('')
            // setSelectedUnit('')
            setAlreadyRedirected(false)
            changePage(getPages().findIndex((element) => element.key === 'buyerUnits'))
          }}
        />
      </AnimatedCarouselWrapper>,
      <AnimatedCarouselWrapper
        key='buyerPayment'
        direction={direction}
      >
        <BuyerOfferPayment
          offerData={offerData}
          projectData={projectData}
        />
      </AnimatedCarouselWrapper>
    ]

    if (projectHasOptionsAndUpgrades) {
      pages.splice(1, 0,
        <AnimatedCarouselWrapper
          key='buyerOptionsUpgrade'
          direction={direction}
        >
          <CreateOfferAdditionalOptionsStep
              projectData={projectData}
              onSelectOptionUpgrade={(optionUpgrade) => selectOptionsUpgrade(optionUpgrade)}
              selectedOptionsUpgrade={selectedOptionsUpgrade}
              offerId={offerId}
            />
        </AnimatedCarouselWrapper>
      )
    }

    if (projectHasParkingOptions) {
      pages.splice(1, 0,
        <AnimatedCarouselWrapper
          key='buyerParkingOptions'
          direction={direction}
        >
          <CreateOfferParkingStep
            projectData={projectData}
            onSelectParkingOption={(parkingOption) => selectParkingOption(parkingOption)}
            selectedUnit={selectedUnit}
            selectedParkingOption={selectedParkingOption}
            offerId={offerId}
          />
        </AnimatedCarouselWrapper>
      )
    }

    if (projectHasColorSchemeOptions) {
      pages.splice(1, 0,
        <AnimatedCarouselWrapper
          key='buyerColorSchemes'
          direction={direction}
        >
          <CreateOfferColorSchemeStep
            projectData={projectData}
            onSelectColorScheme={(colorScheme) => selectColorScheme(colorScheme)}
            selectedColorScheme={selectedColorScheme}
            offerId={offerId}
          />
        </AnimatedCarouselWrapper>
      )
    }

    return pages
  }, [
    alreadyShownCreateOfferAnimation, buyerUnits, changePage, direction, firstName, fromDocumentsMenu, gettingProjects,
    gettingUnits, localCreationType, offerData, offerId, projectData, projectHasColorSchemeOptions, projectHasOptionsAndUpgrades,
    projectHasParkingOptions, projects, selectColorScheme, selectOptionsUpgrade, selectParkingOption, selectedColorScheme,
    selectedOptionsUpgrade, selectedParkingOption, selectedProject, selectedUnit, startCreationMode, userObject
  ])

  useEffect(() => {
    if (offerId && !alreadyRedirected && !gettingUnits) {
      setGettingUnits(true)
      getOffers(offerId)
        .then((offer) => {
          const { totalCost, options, unit, status } = offer

          const selectedColor = options.filter(({ type }) => type === 'color')
          const selectedParking = options.filter(({ type }) => type === 'parking')
          const selectedOptionsUpgrade = options
            .filter(({ type }) => type === 'additionalOptions')
            .map((item) => ({ ...item, selected: true }))

          if (selectedColor.length) {
            setSelectedColorScheme(selectedColor[0])
          }

          if (selectedParking.length) {
            setSelectedParkingOption(selectedParking[0])
          }

          if (selectedOptionsUpgrade.length) {
            setSelectedOptionsUpgrade(selectedOptionsUpgrade)
          }

          setSelectedUnit(unit)
          setOfferData(offer)
          setOfferCost(totalCost)

          if (localCreationType === 'offerCreated') {
            setAlreadyShownCreateOfferAnimation(true)

            // If SoldFirm or came from the Documents Menu, hide the contracts and payment and go to the Summary Page
            if (status === 'SoldFirm' || status === 'Paid' || fromDocumentsMenu) {
              changePage(getPages().findIndex((element) => element.key === 'buyerSummary'))
            } else

            // If Already Signed the contracts, go to the Payment Page
            if (status === 'Signed') {
              setAllDocsSigned(true)
              changePage(getPages().findIndex((element) => element.key === 'buyerPayment'))
            } else {
              changePage(getPages().findIndex((element) => element.key === 'buyerContracts'))
            }
          }

          setAlreadyRedirected(true)
          setGettingUnits(false)
        })
    }
  }, [fromDocumentsMenu, localCreationType, changePage, getPages, alreadyRedirected, offerId, gettingUnits])

  // const executeWhenAllDocsSigned = useCallback(() => {
  //   setAllDocsSigned(true)
  //   paginate(1)
  // }, [paginate])

  useEffect(() => {
    if (selectedOptionsUpgrade) {
      const tmpSelectedOptionDescription = selectedOptionsUpgrade.reduce((acumulator, option, index) => {
        let tmpAcumulator = ''
        if (index < 1) {
          tmpAcumulator = acumulator + option.name
        }

        if (index === 1) {
          tmpAcumulator = acumulator + ' and more...'
        }

        if (index > 1) {
          tmpAcumulator = acumulator
        }

        return tmpAcumulator
      }, '')

      setSelectedOptionsUpgradeDescription(tmpSelectedOptionDescription)
    }
  }, [selectedOptionsUpgrade])

  useEffect(() => {
    const selectedPage = getPages()[page]

    // If is going to the summary page, hide the menu
    if (selectedPage && selectedPage.key === 'buyerSummary') {
      setMenuOpened(false)
    }
  }, [page, getPages])

  const getMenus = useCallback(() => {
    const { unitNumber } = selectedUnit
    const { name: colorSchemeName } = selectedColorScheme
    const { name: parkingOptionName } = selectedParkingOption
    const { totalDollarValueCurrencyType } = projectData
    const { status } = offerData

    const menus = [
      { id: 'units', title: 'Units', subtitle: unitNumber || '', menuEnabled: true },
      { id: 'summary', title: 'Purchase Summary', subtitle: offerCost ? formatMoney(offerCost, totalDollarValueCurrencyType) : '', menuEnabled: true },
      {
        id: 'contracts',
        title: 'Contracts',
        subtitle: allDocsSigned ? 'All Docs Signed' : '',
        menuEnabled: Boolean(offerId && (status !== 'SoldFirm' && status !== 'Paid'))
      },
      {
        id: 'payment',
        title: 'Payment',
        menuEnabled: Boolean(offerId && (status !== 'SoldFirm' && status !== 'Paid') && allDocsSigned)
      }
    ]

    if (projectHasOptionsAndUpgrades) {
      menus.splice(1, 0,
        {
          id: 'additionalOptions',
          title: 'Upgrade and Options',
          subtitle: selectedOptionsUpgradeDescription,
          menuEnabled: true
        }
      )
    }

    if (projectHasParkingOptions) {
      menus.splice(1, 0,
        {
          id: 'parking',
          title: 'Parking',
          subtitle: parkingOptionName || '',
          menuEnabled: true
        }
      )
    }

    if (projectHasColorSchemeOptions) {
      menus.splice(1, 0,
        {
          id: 'colorScheme',
          title: 'Color Scheme',
          subtitle: colorSchemeName || '',
          menuEnabled: true
        }
      )
    }

    return menus
  }, [
    selectedUnit, selectedColorScheme, selectedParkingOption,
    selectedOptionsUpgradeDescription, allDocsSigned, offerCost,
    projectData, offerId, offerData, projectHasColorSchemeOptions,
    projectHasOptionsAndUpgrades, projectHasParkingOptions
  ])

  const renderMenuItem = useCallback(({ title, subtitle, menuEnabled, menuIndex }) => {
    const active = menuEnabled

    return (
      <li
        onClick={() => active && changePage(menuIndex)}
        className={`${menuStyles.menuItem} px-8 ${active ? 'hover:cursor-pointer' : 'hover:cursor-default'}
                  flex flex-col ${page === menuIndex && 'font-bold'}`}
      >
        <span className={`${!active ? 'opacity-20 text-black font-bold' : ''} text-lg`}>{title}</span>
        {subtitle}
      </li>
    )
  }, [changePage, page])

  const menu = useCallback(() => (
    <div
      id='menuWrapper'
      className={`fixed md:absolute overflow-hidden left-0 right-0 top-0
       z-20 lg:left-32 lg:right-32 xl:left-56 xl:right-56`}
    >
      <GlassedWrapper className={`h-14 ${menuOpened ? 'mt-0' : '-mt-14'}`}>
        <ul className={menuStyles.menuList} id='stepsList'>
          {getMenus().map((menu, index) => renderMenuItem({
            key: menu.id,
            title: menu.title,
            subtitle: menu.subtitle,
            menuEnabled: menu.menuEnabled,
            menuIndex: index
          }))}
        </ul>
      </GlassedWrapper>
      <button
        onClick={() => setMenuOpened(!menuOpened)}
        className='relative flex items-center justify-center h-6 bg-indigo-600 rounded-b-lg w-14
        left-1/2 hover:cursor-pointer focus:outline-none'
        style={{ marginRight: '-50%', transform: 'translate(-50%)' }}
      >
        <TFilledChevronDownIcon
          className={`h-8 w-8 text-white transform ease-in-out duration-150
          ${menuOpened ? '-rotate-180' : 'rotate-0'}
          `}
        />
      </button>
    </div>
  ), [menuOpened, getMenus, renderMenuItem])

  return (
    <>
      {isOnCreationMode && menu()}
      <AnimatePresence exitBeforeEnter initial={false} custom={direction}>
        {getPages()[page]}
      </AnimatePresence>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerCreateOffer)

BuyerCreateOffer.propTypes = {
  userObject: propTypes.shape({
    _id: propTypes.string,
    firstName: propTypes.string
  })

}

AnimatedCarouselWrapper.propTypes = {
  key: propTypes.string,
  direction: propTypes.number,
  children: propTypes.node
}
