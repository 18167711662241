/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import CustomInput from 'components/CustomInput'
import LargeUnitCard from 'components/LargeUnitCard'
import UserInfoCard from 'components/UserInfoCard'
import CreateOfferSuccessModal from './CreateOfferSuccessModal'

import { CreateOfferContext } from '../context/CreateOfferContext'
import { lowRiskOptions } from './constants'
import { getCurrenyType, formatMoney, formatValue } from 'utils/formatters'
import standardStyle from 'assets/css/standardStyle'

import {
  createOrUpdateOffer,
  getOfferContracts
} from 'store/actions/offerActions'
import { message } from 'antd'
import { MessageModal } from 'components/MessageModal'
import { Modal } from '@material-ui/core'
import { OfferDocuments } from 'components'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import AdditionalBuyerItem from './AdditionalBuyerItem'

const white = '#FCFCFA'
const SelectReview = ({
  setStep,
  contextUsed = true,
  createOfferObject = {}
}) => {
  const {
    selectedUnit,
    selectedBuyers,
    selectedBroker,
    selectedParkings,
    selectedUpgrades,
    isThirdParty,
    riskType,
    selectedOption,
    checkedProperties,
    businessRelationshipExplanation,
    thirdPartDescription,
    thirdPartRecord,
    riskExplanation,
    depositOptions,
    additionalBuyers
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = contextUsed ? useContext(CreateOfferContext) : createOfferObject
  const [loading, setLoading] = useState(false)
  const [isSuccessModal, setIsSuccessModal] = useState(false)
  const [offerRes, serOfferRes] = useState({})
  const [showOfferCreateMessageModal, setShowOfferCreateMessageModal] =
    useState(false)
  const [showDocSignMessageModal, setShowDocSignMessageModal] = useState(false)

  const [showDocumentsModal, setShowDocumentsModal] = useState(false)
  const [showDeposit, setShowDeposit] = useState(false)
  const [offerId, setOfferId] = useState('')

  // const [offerIdShowingDocs, setOfferIdShowingDocs] = useState('624c3bb48b1296001271588a')
  const findSelectedRiskOptionValue = () => {
    const { value } = lowRiskOptions.find(
      (option) => option.id === selectedOption
    )
    return value
  }
  const selectedRiskOptionValue = selectedOption
    ? findSelectedRiskOptionValue(selectedOption)
    : 'NO OPTION SELECTED'

  const selectedAgentOptionValue =
    checkedProperties.length > 0
      ? checkedProperties.map((_prop) => _prop.label).join(', ')
      : 'NO OPTION SELECTED'

  const getFormattedDepositOptions = () => {
    return {
      optionName: depositOptions.optionName,
      discount: {
        percentage: `${depositOptions.discount.percentage}%`,
        value: `$${Number(depositOptions.discount.value).toFixed(2)}`
      },
      installments: depositOptions.installments.map((item) => {
        return {
          name: item.name,
          percentage: `${item.percentage}%`,
          value: `$${Number(item.value).toFixed(2)}`
        }
      })
    }
  }

  const onCreateOffer = async () => {
    const offerObj = {
      id: '',
      unit: selectedUnit._id,
      buyer: selectedBuyers?.map((_buyer) => _buyer.id),
      totalCost: totalPrice(),
      paymentRounds: [
        {
          days: 7,
          perc: 10,
          value: totalPrice() * 0.1,
          type: 'Card'
        }
      ],
      deposit: getFormattedDepositOptions(),
      depositOption: depositOptions.id,
      additionalBuyers,
      options: [...parkingOptions(), ...upgradeOptions()],
      brokerCommissionFirstRange: 0,
      brokerCommissionSecondRange: 0,
      fintrac: {
        thirdPart: isThirdParty ? 'Yes' : 'No',
        thirdPartDescription,
        thirdPartRecord: {
          name: thirdPartRecord.name,
          address: thirdPartRecord.address,
          phoneNumber: thirdPartRecord.phoneNumber,
          birthday: thirdPartRecord.birthday,
          occupation: thirdPartRecord.occupation,
          incorporation: {
            number: thirdPartRecord.incorporationNumber,
            jurisdiction: thirdPartRecord.incorporationJurisdiction,
            country: thirdPartRecord.incorporationCountry
          },
          relationship: thirdPartRecord.relationship,
          ...riskInfo()
        },
        businessRelationship: checkedProperties?.map((_prop) => _prop.label),
        businessRelationshipExplanation
      }
    }
    try {
      setLoading(true)
      const response = await createOrUpdateOffer(offerObj, false)
      serOfferRes(response)
      setOfferId(response._id)
      setIsSuccessModal(true)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('===>> err', err)
      message.error('there is some issue to create offer')
    }
  }

  const totalPrice = () => {
    // const priceArr = selectedParkings
    //   .filter((_parking) => _parking.isSelected)
    //   ?.map((_parking1) => _parking1.value)
    // const parkingPrice = priceArr.reduce((prev, curr) => prev + curr, 0)
    return (
      selectedUnit?.price *
        (1 - (depositOptions?.discount?.percentage / 100 ?? 0)) || 0
    )
  }

  const riskInfo = () => {
    const obj = {}
    if (riskType === 'Low') {
      obj.lowOption = selectedOption
      obj.lowOptionExplanation =
        selectedOption === 'explain' ? riskExplanation : ''
    } else if (riskType === 'Medium') {
      obj.mediumOption = selectedOption
      obj.mediumRiskExplanation =
        selectedOption === 'explain' ? riskExplanation : ''
    } else {
      obj.highOption = selectedOption
      obj.highRiskExplanation =
        selectedOption === 'explain' ? riskExplanation : ''
    }
    return obj
  }

  const parkingOptions = () => {
    const parkings = selectedParkings
      .filter((_parking) => _parking.isSelected)
      .map((_parking1) => {
        const { isSelected, count, ...remValues } = _parking1
        return remValues
      })
    return parkings
  }

  const upgradeOptions = () => {
    const upgrades = selectedUpgrades
      .filter((_upgrade) => _upgrade.isSelected)
      .map((_upgrade1) => {
        const { isSelected, count, ...remValues } = _upgrade1
        return remValues
      })
    return upgrades
  }

  const onGoToContract = () => {
    // getOfferContracts(offerRes._id)
    setIsSuccessModal(false)
    setShowDocumentsModal(true)
  }

  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    setStep((prev) => prev + 1)
  }

  useEffect(() => {
    if (showDeposit) {
      onNext()
    }
  }, [showDeposit])
  return (
    <div>
      <CreateOfferSuccessModal
        {...{
          open: isSuccessModal,
          setOpen: setIsSuccessModal,
          onConfirm: onGoToContract
        }}
      />
      {contextUsed && (
        <div className='pb-4 border-b-2 border-softBlack'>
          <p className='my-0 text-xl font-bold'>Review</p>
        </div>
      )}

      {/* Unit Details */}
      <div className='my-10'>
        <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
          Unit Details
        </p>
        <LargeUnitCard
          imgsrc={selectedUnit?.floorPlan?.image}
          unitName={`#${selectedUnit?.unitNumber}`}
          noOfBedrooms={selectedUnit?.numberOfBedrooms}
          noOfBathrooms={selectedUnit?.numberOfBathrooms}
          balcony={selectedUnit?.balcony}
          noOfParkingSlot={selectedUnit?.floorPlan?.parkingQty}
          building={selectedUnit?.building?.name}
          price={selectedUnit?.price}
        />
      </div>

      {selectedParkings.filter((_parking) => _parking.isSelected).length >
        0 && (
        <div>
          <div className='w-full border-b-2 pb-0'></div>

          {/* Parking Details */}
          <div className='my-10'>
            <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
              Parking
            </p>
            {selectedParkings
              .filter((_parking) => _parking.isSelected)
              .map((parking, index) => (
                <div
                  className={
                    selectedParkings.length === index ? 'pb-0' : 'pb-8'
                  }
                >
                  <LargeUnitCard
                    key={parking._id}
                    imgsrc={parking.images[0]}
                    unitName={parking.name}
                    description={parking.description}
                    price={parking?.value}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
      {selectedUpgrades.filter((_upgrade) => _upgrade.isSelected).length >
        0 && (
        <div>
          <div className='w-full border-b-2 pb-0'></div>

          {/* Upgrade Options Details */}
          <div className='my-10'>
            <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
              Upgrades
            </p>
            {selectedUpgrades
              .filter((_upgrade) => _upgrade.isSelected)
              .map((_pgrade, index) => (
                <div
                  className={
                    selectedUpgrades.length === index ? 'pb-0' : 'pb-8'
                  }
                >
                  <LargeUnitCard
                    key={_pgrade._id}
                    imgsrc={_pgrade.images[0]}
                    unitName={_pgrade.name}
                    description={_pgrade.description}
                    price={_pgrade?.value}
                  />
                </div>
              ))}
          </div>
        </div>
      )}

      <div className='w-full border-b-2 pb-0'></div>

      {/* Buyer Details */}
      <div className='my-10'>
        <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
          Buyer
        </p>
        <div className='flex gap-4 flex-wrap'>
          {selectedBuyers?.map((buyer) => (
            <UserInfoCard
              key={buyer?._id}
              firstName={buyer?.firstName}
              lastName={buyer?.lastName}
              email={buyer?.email}
              selected={false}
              userAvatar={buyer?.userAvatar}
              showSelectedRing={false}
              className={'w-72'}
              backgroundColor={contextUsed === false && white}
            />
          ))}
        </div>
        {Object.keys(additionalBuyers?.[0] ?? {})?.length > 0 && (
          <AdditionalBuyerItem
            onChange={() => {}}
            editMode={false}
            buyerTag={'Buyer 2 '}
            defaultData={additionalBuyers?.[0]}
          />
        )}
        {Object.keys(additionalBuyers?.[1] ?? {})?.length > 0 && (
          <AdditionalBuyerItem
            onChange={() => {}}
            editMode={false}
            buyerTag={'Buyer 3 '}
            defaultData={additionalBuyers[1]}
          />
        )}
      </div>
      <div className='w-full border-b-2 pb-0'></div>
      {/* Broker Details */}
      {/* <div className="my-10">
        <p className="text-softBlack font-bold my-4" style={{ fontSize: 22 }}>
          Broker
        </p>
        <div className="flex gap-4 flex-wrap">
          <UserInfoCard
            firstName={selectedBroker?.firstName}
            lastName={selectedBroker?.lastName}
            email={selectedBroker?.email}
            selected={false}
            userAvatar={selectedBroker?.userAvatar}
            showSelectedRing={false}
            className={'w-72'}
            backgroundColor={contextUsed === false && white}
          />
        </div>
        <p className="text-softBlack_70 pt-6 m-0">
          Broker will receive 10% on the first $100,000 and 5%{' '}
        </p>
      </div> */}

      {/* <div className="w-full border-b-2" style={{ margin: 0 }}></div> */}

      {/* <div className="flex flex-col gap-4 my-10">
        <p className="text-softBlack font-bold" style={{ fontSize: 22 }}>
          Fintrac
        </p>

        <div>
          <p className="text-softBlack font-bold m-0">Third Party</p>
          <p className="text-softBlack_70">{isThirdParty ? 'Yes' : 'No'}</p>
        </div>
        <div>
          <p className="text-softBlack font-bold m-0">Client Risk</p>
          <p className="text-softBlack_70">
            <span className="capitalize"> {riskType}</span>.{' '}
            {selectedRiskOptionValue}
          </p>
        </div>
        <div>
          <p className="text-softBlack font-bold m-0">Business Relationship</p>
          <p className="text-softBlack_70">
            {selectedAgentOptionValue}
          </p>
        </div>
      </div> */}
      {/* <div className="w-full border-b-2 p-0"></div> */}

      {Object.keys(depositOptions ?? {})?.length > 0 && (
        <div className=' my-10'>
          <p className='text-softBlack font-bold' style={{ fontSize: 22 }}>
            Deposits
          </p>
          <div className='grid grid-cols-5 gap-4 mt-3'>
            {depositOptions.installments?.map((item) => {
              return (
                <CustomInput
                  label={item.name}
                  defaultValue={`${item.percentage}%`}
                  disabled
                />
              )
            })}
            <CustomInput
              label={'DISCOUNT'}
              defaultValue={`${depositOptions.discount.percentage}%`}
              disabled
            />
          </div>
          <div className='grid grid-cols-5 gap-4 mt-3'>
            {depositOptions.installments?.map((item) => {
              return (
                <CustomInput
                  label={item.name}
                  defaultValue={`${getCurrenyType()}${formatValue(item.value)}`}
                  disabled
                />
              )
            })}

            <CustomInput
              label={'DISCOUNT'}
              defaultValue={`${getCurrenyType()}${formatValue(
                depositOptions.discount.value
              )}`}
              disabled
            />
          </div>
        </div>
      )}

      <div className='w-full border-b-2 p-0'></div>

      <p className='font-bold mt-10 text-softBlack' style={{ fontSize: 22 }}>
        Total Value
      </p>

      <div className='flex justify-between items-center gap-4 m-0 p-0'>
        <p className='text-2xl p-0 m-0 font-bold text-softBlack'>
          {formatMoney(totalPrice())}
        </p>
        <div className='flex gap-6'>
          <button
            className='flex items-center justify-center rounded-md font-medium'
            style={{
              backgroundColor: standardStyle.colors.softBlack_10,
              width: 113,
              height: 48,
              fontStyle: 'italic'
            }}
            disabled={loading}
            onClick={onBack}
          >
            Go Back
          </button>
          <button
            className='flex items-center justify-center rounded-md font-medium'
            style={{ backgroundColor: '#EDDF65', width: 143, height: 48 }}
            onClick={onCreateOffer}
            disabled={loading}
          >
            Create Offer
          </button>
        </div>
      </div>
      <MessageModal
        show={showOfferCreateMessageModal}
        setShow={setShowOfferCreateMessageModal}
        title='Success'
        message={'Your offer is created. Now you can send it to the Buyer'}
        primaryBtn={true}
        primaryBtnText={'Go to Contract'}
        setPrimaryBtnAction={setShowDocumentsModal}
      />
      <Modal
        open={showDocumentsModal}
        onClose={() => setShowDocumentsModal(false)}
        aria-labelledby='Offer Documents'
        aria-describedby='Offer documents list'
      >
        <div
          className='font-openSans relative bg-grey-8 absolute py-0 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          style={{ width: 1376, height: '760px', overflowY: 'auto' }}
        >
          <button
            className='absolute right-9 top-9'
            style={{ right: '2rem', top: '2rem' }}
            onClick={() => setShowDocumentsModal(false)}
          >
            <CrossIcon />
          </button>
          <OfferDocuments
            contracts={offerRes?.contracts}
            btnsShow={true}
            setCloseDocument={setShowDocumentsModal}
            setPrimaryBtnAction={setShowDocSignMessageModal}
            offerId={offerId}
          />
        </div>
      </Modal>
      <MessageModal
        show={showDocSignMessageModal}
        setShow={setShowDocSignMessageModal}
        title='Success'
        message={'All Documents signed'}
        primaryBtn={true}
        primaryBtnText={'Proceed to payment'}
        setPrimaryBtnAction={setShowDeposit}
      />
    </div>
  )
}

export default SelectReview
