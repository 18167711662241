import React from 'react'
import { useHistory } from 'react-router-dom'
import { ADMIN_OPPORTUNITIES } from 'routes'

const Header = () => {
  const history = useHistory()

  return (
    <div className='flex flex-row justify-between w-full p-5 border-b-2 border-black'>
        <div style={{ fontSize: '40px' }} className='font-bold'>Create Opportunity</div>
        <div onClick={() => history.replace(ADMIN_OPPORTUNITIES)} className='text-md italic cursor-pointer text-gray h-max flex justify-center items-center'>cancel</div>
    </div>
  )
}

export default Header
