import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  PageWrapper,
  TitleWrapper,
  Option,
  OptionTitleContainer,
  OptionTitle,
  ProjectThumbnailWrapper,
  ProjectImageWrapper,
  ProjectName,
  ProjectImage,
  ProjectLogo,
  ListOfProjectsWrapper
} from './styles'

import desktopAnimation from 'assets/lottieJsons/desktop.json'
import presentationCentre from 'assets/lottieJsons/presentationCentre.json'
import { ADMIN_DASHBOARD, AVAILABILITY_BOARD, PWA_PROJECTS } from 'routes'
import { useHistory } from 'react-router-dom'
import { AnimatedList, FadedAnimatedDiv, LottieWrapper } from 'components'
import { AnimatePresence, motion } from 'framer-motion'
import { classNames } from 'utils'
import { TButton } from 'ui'
import { changeAppProject } from 'store/actions/appActions'

const AnimatedOption = ({ children, onClick, selected }) => (
  <Option
    className={
      classNames(
        `h-full bg-indigo-600 rounded flex flex-1 flex-col items-center transition ease-in duration-75 
        justify-around shadow-2xl transform hover:scale-115 hover:cursor-pointer md:max-w-lg`,
        selected && 'outline-none ring-2 ring-offset-2 ring-indigo-500 transform scale-115'
      )}
    onClick={onClick}
  >
    {children}
  </Option>
)

const ProjectThumbnail = ({
  projectData: { backgroundImage, logoImage, projectName, _id, developerId },
  executeOnSelectProject,
  selectedProject
}) => (
  <ProjectThumbnailWrapper
    className={
      classNames(
        'flex flex-col items-center justify-center transform duration-75 ease-in-out hover:scale-115 hover:cursor-pointer',
        selectedProject === _id && 'transform scale-115'
      )}
    onClick={() => executeOnSelectProject({ projectId: _id, developerId })}
  >
    <ProjectImageWrapper className='relative'>
      <ProjectLogo src={logoImage} className='h-12 w-22 absolute mt-2 object-contain' />
      <ProjectImage
        src={backgroundImage}
        className={classNames('h-36 w-48 rounded-md object-cover', selectedProject === _id && 'ring-4 ring-indigo-500')}
      />
    </ProjectImageWrapper>
    <ProjectName className='text-base'>{projectName}</ProjectName>
  </ProjectThumbnailWrapper>
)

const LayoutModeSelector = (props) => {
  const history = useHistory()

  const [animationStopped, setAnimationStopped] = useState(true)
  const [selectedLayoutMode, setSelectedLayoutMode] = useState('')
  const [showListOfProjectsWrapper, setShowListOfProjectsWrapper] = useState(false)
  const [showListOfProjects, setShowListOfProjects] = useState(false)
  const [selectedProject, setSelectedProject] = useState('')
  const [selectedDeveloper, setSelectedDeveloper] = useState('')

  const { userObject: { userType }, changeAppProject, listOfProjects } = props

  useEffect(() => {
    setTimeout(() => {
      setAnimationStopped(false)
    }, 600)
  }, [])

  useEffect(() => {
    if ((listOfProjects && listOfProjects.length) && (listOfProjects.length === 1 || userType === 'SuperAdmin')) {
      setSelectedProject(listOfProjects[0]._id)

      if (listOfProjects[0].developerId) {
        setSelectedDeveloper(listOfProjects[0].developerId)
      }
    }
  }, [userType, listOfProjects])

  useEffect(() => {
    if (selectedLayoutMode && !showListOfProjectsWrapper && listOfProjects.length > 1) {
      setShowListOfProjectsWrapper(true)
    }
  }, [selectedLayoutMode, showListOfProjectsWrapper, listOfProjects])

  const confirmSelection = useCallback(() => {
    changeAppProject({ projectId: selectedProject, developerId: selectedDeveloper })

    if (selectedLayoutMode === 'pwa') {
      history.push(PWA_PROJECTS)
    } else {
      if (userType === 'CoopBroker') {
        history.push({
          pathname: AVAILABILITY_BOARD,
          state: { showTabsFilter: true }
        })
      } else {
        return history.push(ADMIN_DASHBOARD)
      }
    }
  }, [changeAppProject, selectedLayoutMode, history, userType, selectedProject, selectedDeveloper])

  useEffect(() => {
    if (selectedLayoutMode && (listOfProjects.length <= 1 || userType === 'SuperAdmin')) {
      confirmSelection()
    }
  }, [selectedLayoutMode, listOfProjects, userType, confirmSelection])

  const selectProject = useCallback(({ projectId, developerId }) => {
    setSelectedProject(projectId)
    setSelectedDeveloper(developerId)
  }, [])

  return (
    <PageWrapper className='mt-14'>
      <TitleWrapper className='flex w-full items-center justify-center'>
        <span className='text-center text-lg md:text-3xl'>Please select the mode you want to execute</span>
      </TitleWrapper>
      <AnimatedList
        wrapperClassName='px-8 my-18 w-full flex flex-col md:flex-row space-x-8 items-center justify-around'
        itemClassName='w-full flex items-center justify-center'
      >
        <AnimatedOption
          selected={selectedLayoutMode === 'desktop'}
          onClick={() => setSelectedLayoutMode('desktop')}
          >
          <LottieWrapper
            animation={desktopAnimation}
            className='h-36 w-36'
            isStopped={animationStopped}
            autoPlay={false}
          />
          <OptionTitleContainer>
            <OptionTitle>Full Desktop APP</OptionTitle>
          </OptionTitleContainer>
        </AnimatedOption>

        <AnimatedOption
          selected={selectedLayoutMode === 'pwa'}
          onClick={() => setSelectedLayoutMode('pwa')}
        >
          <LottieWrapper
            animation={presentationCentre}
            className='h-36 w-36'
            isStopped={animationStopped}
            autoPlay={false}
          />

          <OptionTitleContainer>
            <OptionTitle>Presentation Centre APP</OptionTitle>
          </OptionTitleContainer>
        </AnimatedOption>
      </AnimatedList>
      <AnimatePresence initial={false}>
        {
          showListOfProjectsWrapper && (
            <ListOfProjectsWrapper className='flex flex-col w-full items-center justify-center'>
              <FadedAnimatedDiv
                className='text-2xl'
                key='projectListTitle'
                onAnimationComplete={() => setShowListOfProjects(true)}
              >
                Now, select the project you want to use
              </FadedAnimatedDiv>
              <AnimatePresence>
              {
                showListOfProjects &&
                <AnimatedList
                  wrapperClassName='flex flex-row items-center justify-center space-x-10 mt-5 overflow-x-auto p-5'
                >
                  {listOfProjects?.map((project, index) =>
                    <ProjectThumbnail
                      key={index}
                      executeOnSelectProject={selectProject}
                      projectData={project}
                      selectedProject={selectedProject}
                    />
                  )}
                </AnimatedList>
              }
              </AnimatePresence>
            </ListOfProjectsWrapper>
          )
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          selectedLayoutMode && selectedProject && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className='w-full flex items-center justify-center mt-5'
            >
              <TButton onClick={confirmSelection} className='w-20 h-10'>Ok</TButton>
            </motion.div>
          )
        }
      </AnimatePresence>
    </PageWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject,
  listOfProjects: state.appReducer.listOfProjects
})

const mapDispatchToProps = {
  changeAppProject
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModeSelector)

AnimatedOption.propTypes = {
  children: propTypes.node,
  onClick: propTypes.func,
  selected: propTypes.bool
}

LayoutModeSelector.propTypes = {
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  changeAppProject: propTypes.func,
  listOfProjects: propTypes.array
}

ProjectThumbnail.propTypes = {
  projectData: propTypes.shape({
    backgroundImage: propTypes.string,
    logoImage: propTypes.string,
    projectName: propTypes.string,
    _id: propTypes.string,
    developerId: propTypes.string
  }),
  executeOnSelectProject: propTypes.func,
  selectedProject: propTypes.string
}
