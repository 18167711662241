import React, { useState } from 'react'

import AddOrEditSingleContact from 'components/AddOrEditContact'
import AddOrEditBulkContact from 'components/AddBulkContact'

import ContactEditMethodModal from 'components/ContactEditMethodModal'

import { useHistory } from 'react-router-dom'

const Header = () => {
  const history = useHistory()

  const [isOpenContactEditMethodModal, setIsOpenContactEditMethodModal] =
    useState(false)
  const [isOpenCreateSingleContactModal, setIsOpenCreateSingleContactModal] =
    useState(false)
  const [singleContactModalType, setSingleContactModalType] = useState(null)
  const [isOpenCreateBulkContactModal, setIsOpenCreateBulkContactModal] =
    useState(false)

  const openContactEditMethodModal = () => {
    setIsOpenContactEditMethodModal(true)
  }

  const openCreateSingleContactModal = (type) => {
    setSingleContactModalType(type)
    setIsOpenCreateSingleContactModal(true)
  }

  const openCreateBulkContactModal = () => {
    setIsOpenCreateBulkContactModal(true)
  }

  const onCreateSingleContact = (_id) => {
    if (history) {
      history.push({
        pathname: '/admin/newcontacts',
        search: `?id=${_id}&userType=${singleContactModalType}`
      })
    }
  }

  const onCreateBulkContact = (data) => {}

  const onOpenBulkContactModal = () => {
    openCreateBulkContactModal()
  }

  return (
    <>
      {isOpenContactEditMethodModal && (
        <ContactEditMethodModal
          show={isOpenContactEditMethodModal}
          setShow={setIsOpenContactEditMethodModal}
          onMethodSelected={(selectedMethod) => {
            if (selectedMethod === 'bulk') {
              onOpenBulkContactModal()
            } else if (selectedMethod === 'LeadBroker') {
              openCreateSingleContactModal('LeadBroker')
            } else if (selectedMethod === 'Buyer') {
              openCreateSingleContactModal('Buyer')
            }
          }}
        />
      )}
      {isOpenCreateBulkContactModal && (
        <AddOrEditBulkContact
          show={isOpenCreateBulkContactModal}
          setShow={setIsOpenCreateBulkContactModal}
          onSuccess={onCreateBulkContact}
        />
      )}
      {isOpenCreateSingleContactModal && (
        <AddOrEditSingleContact
          show={isOpenCreateSingleContactModal}
          setShow={setIsOpenCreateSingleContactModal}
          userType={singleContactModalType}
          contact={null}
          onSuccess={onCreateSingleContact}
          invalideCacheOnUpdate
        />
      )}
      <div className='bg-gray-100 p-6 flex justify-between items-center'>
        <span className='font-bold text-3xl'>Contacts</span>
        <span
          className='cursor-pointer hover:text-yellow-400'
          onClick={openContactEditMethodModal}
        >
          <span className='text-2xl mr-2'>+</span>Add Contact
        </span>
      </div>
      <div className='border-b-2 border-black' />
    </>
  )
}

export default Header
