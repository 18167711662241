import { Drawer, makeStyles } from '@material-ui/core'
import ContactItem from 'pages/contacts/ContactItem'
import { ContactContext } from 'pages/contacts/context/Contacts'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IoMdClose } from 'react-icons/io'
import { useSelector } from 'react-redux'
import useContactContext from '../hooks/useContactContext'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import { deleteUser } from 'store/actions/usersActions'
// import LeadBrokerContactItem from '../LeadBrokerContactItem/LeadBrokerContactItem'

const ContactDetailDrawer = () => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { state, closeContactDetailDrawer } = useContactContext()
  const { selectedContact, isLoading, selectedContactObject, setSelectedContact } = useContext(ContactContext)
  const [width80, setWidth80] = useState(window.innerWidth)

  useEffect(() => {
    setWidth80(window.innerWidth < 1400 ? window.innerWidth * 0.55 : window.innerWidth * 0.75)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: width80,
      flexShrink: 0
    },
    drawerPaper: {
      width: width80
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  useEffect(() => {
    if (state.selectedContact?._id) {
      setSelectedContact(state.selectedContact?._id)
    }
  }, [state.selectedContact?._id, setSelectedContact])

  const LoadingContacts = useCallback(() => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title={`Loading ${state.selectedContact?.firstName}'s info...`} />
    </FadedAnimatedDiv>
  ), [state.selectedContact?.firstName])

  return (
    <Drawer
      open={state.isContactDetailDrawerOpen}
      variant="persistent"
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className='mt-20 relative'>
        <button onClick={() => closeContactDetailDrawer()} className='absolute top-0 right-5'><IoMdClose fontSize="22px" /></button>
        {isLoading && <LoadingContacts />}
        {/* {state.selectedContact?.userType !== 'LeadBroker' && selectedContact && !isLoading && selectedContactObject?._id && <ContactItem */}
        {selectedContact && !isLoading && selectedContactObject?._id && (
          <ContactItem
            handleContactDelete={() => {
              deleteUser(selectedContactObject?._id)
              closeContactDetailDrawer()
            }}
            isSalesRep={userObject?.userType === 'SalesRep'}
            showBackButton={false}
          />
        )}
        {/* {state.selectedContact?.userType === 'LeadBroker' && !isLoading && <LeadBrokerContactItem />} */}
      </div>
    </Drawer>
  )
}

export default ContactDetailDrawer
