import React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { BUYER_DASHBOARD, LOGIN, SET_PASSWORD } from 'routes'

// URL ACTIONS
const BUYER = 'buyer'
const SET_PASSWORD_ACTION = 'set_password'

const RouteDecisor = (props) => {
  const history = useHistory()
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const action = query.get('action')
  const value = query.get('value')

  // If is needing to show the buyer pages
  if (action === BUYER) {
    history.push(BUYER_DASHBOARD)
    return (
      <Redirect to={{
        pathname: BUYER_DASHBOARD,
        search: `?action=${action}&value=${value}`
      }}
      />
    )
  } else
  if (action === SET_PASSWORD_ACTION) {
    return (
      <Redirect to={{
        pathname: SET_PASSWORD,
        search: `?action=${action}&value=${value}`
      }}
      />
    )
  } else {
    return <Redirect to={LOGIN} />
  }
}

export default RouteDecisor
