import React from 'react'

const BottomActions = ({ onBack, onNext }) => {
  return (
    <div className="flex justify-between mt-10">
      <button
        className="py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium"
        onClick={onBack}
      >
        Back
      </button>
      <button
        className="py-3 px-6 text-base bg-volt rounded font-medium"
        onClick={onNext}
      >
        Next
      </button>
    </div>
  )
}

export default BottomActions
