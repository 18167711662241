import React, { useState } from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrokerSideMenu, TMenuIcon } from 'components'
import background from 'assets/img/broker-bg.png'

const BrokerContainer = (props) => {
  const { children } = props
  const [showMenu, setShowMenu] = useState(false)

  const OpenMenuButton = () => (
    <button
      onClick={() => {
        setShowMenu(true)
      }}
      className={`fixed px-5 py-5 text-black focus:outline-none
      focus:ring-2 focus:ring-inset focus:ring-cyan-500 z-10 ${
        showMenu && 'hidden'
      }`}
    >
      <span className='sr-only'>Open sidebar</span>
      <TMenuIcon className='h-6 w-6' />
    </button>
  )

  return (
    <div
      className='h-screen w-screen bg-cover bg-center bg-no-repeat overflow-x-hidden'
      id='buyerWrapper'
      style={{
        backgroundImage: `url(${background})`
      }}
    >
      <BrokerSideMenu showMenu={showMenu} setShowMenu={setShowMenu} />
      <OpenMenuButton />
      {children}
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BrokerContainer)

BrokerContainer.propTypes = {
  children: propTypes.node
}
