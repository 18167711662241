import React from 'react'
import CustomModal from 'components/Modal'

const CancelModal = ({ isOpenCancelModal, setIsOpenCancelModal, onConfirm, cancelMessage }) => {
  const closeModal = () => {
    setIsOpenCancelModal(false)
  }
  return (
    <CustomModal isOpen={isOpenCancelModal} closeModal={closeModal} contentLabel="Cancel Modal">
      <div className='w-136 max-w-full p-10 text-center rounded bg-grey-8'>
        <h1 className='text-softBlack font-bold text-xl mb-0'>Cancel</h1>
        <p className='mb-0 mt-6 text-softBlack text-base'>{cancelMessage}</p>
        <div className='flex justify-center items-center gap-6 mt-8'>
          <button className='border rounded border-softBlack70 w-37.5 text-base font-medium h-12' onClick={closeModal}>No</button>
          <button className='rounded w-37.5 bg-volt text-base font-medium h-12' onClick={onConfirm}>Yes, cancel it</button>
        </div>
      </div>
    </CustomModal>
  )
}

export default CancelModal
