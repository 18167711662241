import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getProjects } from 'store/actions/projectActions'

const useGetProjectUpgradeAndOptions = () => {
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)

  const [upgrades, setUpgrades] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [appProject])

  const getData = async () => {
    setLoading(true)
    try {
      const data = await getProjects(appProject)
      const { options } = data
      const upgradeOptions = options.filter(
        (option) => ['upgrade', 'colors'].indexOf(option.type) !== -1
      )
      setUpgrades(upgradeOptions)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, upgrades }
}

export default useGetProjectUpgradeAndOptions
