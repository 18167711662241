// @ts-nocheck
import React, { useState, useEffect, useCallback, useContext, useRef } from 'react'
import { TFilledPenIcon, FadedAnimatedDiv } from 'components'
import { ImageUploaderPrimary } from 'components/ImageUploader'
import { Oval } from 'react-loader-spinner'
import { ReactComponent as Done } from '../../../../assets/icons/done.svg'
import { getProjects, changeProjectImages, deleteProjectImage, deleteProjectLogoImage, deleteProjectBackgroundImage } from 'store/actions/projectActions'
import { useSelector } from 'react-redux'
import Searching from 'pages/contacts/Searching'
import { CREATE_PROJECT_GENERAL_INFO_STEP, CREATE_PROJECT_PARKING_STEP } from '../constant'
import CreateProjectContext from '../context/CreateProjectProvider'

const ProjectImage = ({ index, imgsrc, onDeleteProjectImage, disabled }) => {
  return (
    <div className="mb-4">
      <ImageUploaderPrimary
        imgsrc={imgsrc}
        imgTitle={imgsrc.substring(imgsrc.lastIndexOf('/') + 1)}
        imgFileType={imgsrc?.substring(imgsrc?.lastIndexOf('.') + 1)?.toUpperCase()}
        onDelete={() => onDeleteProjectImage(index)}
        disabled={disabled}
      />
    </div>
  )
}
const ProjectImages = ({ type = 'create', setStep = () => {} }) => {
  const mountedRef = useRef(true)
  const appProject = useSelector((state) => state.appReducer.appProject)
  const [values, setValues] = useState({
    projectId: appProject,
    logoImage: '',
    backgroundImage: '',
    images: []
  })
  const [editMode, setEditMode] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [images, setImages] = useState([])
  const [logoImage, setLogoImage] = useState('')
  const [backgroundImage, setBackgroundImage] = useState('')
  const [backgroundImageDeleted, setBackgrounImageDeleted] = useState(false)
  const [logoImageDeleted, setLogoImageDeleted] = useState(false)
  const projectcontext = useContext(CreateProjectContext)
  const newProjectId = projectcontext.newProjectId
  const [errors, setErrors] = useState({
    logoImage: false,
    backgroundImage: false,
    images: false
  })
  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  const getProjectData = useCallback(() => {
    if (appProject) {
      getProjects(appProject).then((project) => {
        if (!mountedRef.current) return null
        const {
          logoImage,
          backgroundImage,
          images,
          _id
        } = project

        setImages(images)
        setLogoImage(logoImage)
        setBackgroundImage(backgroundImage)
        setValues({ logoImage, backgroundImage, images, projectId: _id })
        setIsLoading(false)
      })
    }
  })

  const isValidated = () => {
    let isValid = true
    if (values.logoImage === '') {
      errors.logoImage = true
      isValid = false
    } else {
      errors.logoImage = false
    }
    if (values.backgroundImage === '') {
      isValid = false
      errors.backgroundImage = true
    } else {
      errors.backgroundImage = false
    }
    if (values.images.length < 7) {
      errors.images = true
      isValid = false
    } else {
      errors.images = false
    }
    setErrors(...[errors])
    setBackgroundImage(backgroundImage)
    setLogoImage(logoImage)
    setImages([...images])

    return isValid
  }
  const onSubmit = () => {
    if (isValidated()) {
      setLoading(true)
      changeProjectImages({
        projectId: values.projectId,
        logoImage: values.logoImage,
        backgroundImage: values.backgroundImage,
        images: values.images
      }).then((response) => {
        setLoading(false)
        getProjectData()
        if (type === 'create') {
          setStep(CREATE_PROJECT_PARKING_STEP)
        } else {
          setEditMode(false)
        }
      })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (type !== 'create') {
      getProjectData()
    } else {
      setIsLoading(false)
    }
    if (appProject) {
      setValues({ ...values, projectId: appProject })
    }
    if (newProjectId) {
      setValues({ ...values, projectId: newProjectId })
    }
  }, [appProject, newProjectId])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const LoadingProjectImages = () => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
    <Searching title='Loading Project Information...' />
    </FadedAnimatedDiv>
  )
  const onChangeUploadImage = async (field, onSet, e) => {
    const file = e.target.files[0]
    onSet(field, file)
    const _errors = { ...errors }
    if (field === 'logoImage') {
      _errors.logoImage = false
      setLogoImageDeleted(false)
    }
    if (field === 'backgroundImage') {
      _errors.backgroundImage = false
      setBackgrounImageDeleted(false)
    }
    setErrors({ ..._errors })
  }
  const onDeleteLogoImage = () => {
    onChange('logoImage', '')
    setLogoImage('')
    setLogoImageDeleted(true)
    deleteProjectLogoImage({ projectId: values.projectId })
  }
  const onDeleteProjectImage = (index) => {
    const tmpImages = [...images]
    const updatedDisplayImages = tmpImages.filter(function (item, i) {
      return i !== index
    })
    const tmpimagefile = values.images.filter(function (item, i) {
      return i !== index
    })
    onChange('images', [...values.images, tmpimagefile])
    setImages(updatedDisplayImages)
    deleteProjectImage({ url: values.images[index], projectId: values.projectId })
    setValues({ ...values, images: tmpimagefile })
    const _errors = { ...errors }
    if (tmpimagefile.length >= 7) {
      _errors.images = false
    }
    setErrors({ ..._errors })
  }
  const onDeleteBackgroundImage = () => {
    onChange('backgroundImage', '')
    setBackgroundImage('')
    setBackgrounImageDeleted(true)
    deleteProjectBackgroundImage({ projectId: values.projectId })
  }
  const AddProjectFile = async ({ e }) => {
    const tmpImages = [...images]
    const _errors = { ...errors }
    const file = e.target.files[0]
    const url = URL.createObjectURL(e.target.files[0])
    tmpImages.push(url)
    onChange('images', [...values.images, file])
    setImages(tmpImages)
    if (tmpImages.length >= 7) {
      _errors.images = false
    }
    setErrors({ ..._errors })
  }
  return (
    <>
      <div className='p-6 bg-grey-8 rounded font-openSans'>
        <h1 className='font-black text-lg pt-3 font-openSans'>Images</h1>
        <div className='border-b-2 border-black my-2'></div>
          <h2 className="font-openSans">You can set the images of the project here</h2>
          {isLoading && <LoadingProjectImages />}
          {!isLoading &&
            <>
            <div className='mt-4 col-span-2'>
              <div className='font-light pb-4 italic font-openSans'>Upload Project's Logo *</div>
              <div className=''>
                <ImageUploaderPrimary
                    imgsrc={logoImage}
                    imgTitle={logoImage?.substring(logoImage?.lastIndexOf('/') + 1)}
                    imgFileType={logoImage?.substring(logoImage?.lastIndexOf('.') + 1)?.toUpperCase()}
                    onDelete={() => onDeleteLogoImage()}
                    isDeleted={logoImageDeleted}
                    disabled={!editMode}
                    onFileChange={(e) => onChangeUploadImage('logoImage', onChange, e)}
                />
                {errors.logoImage && (
                  <p className='text-red-500'>Logo image is required</p>
                )}
              </div>
            </div>
            <div className='mt-4 col-span-2'>
              <div className='font-light pb-4 italic font-openSans'>Upload Project's Background *</div>
              <div className=''>
                <ImageUploaderPrimary
                    imgsrc={backgroundImage}
                    imgTitle={backgroundImage?.substring(backgroundImage?.lastIndexOf('/') + 1)}
                    imgFileType={backgroundImage?.substring(backgroundImage?.lastIndexOf('.') + 1)?.toUpperCase()}
                    imgSize={'fullWidth'}
                    onDelete={() => onDeleteBackgroundImage()}
                    isDeleted={backgroundImageDeleted}
                    disabled={!editMode}
                    onFileChange={(e) => onChangeUploadImage('backgroundImage', onChange, e)}
                />
                {errors.backgroundImage && (
                  <p className='text-red-500'>Background image is required</p>
                )}
              </div>
            </div>
            <div className='mt-4 col-span-2'>
              <div className='font-light pb-4 italic font-openSans'>Upload Project's Images <span className="font-normal">(at least 7 images)</span> *</div>
              <div className=''>
                {images?.map((image, index) => {
                  return (
                  <ProjectImage key={index} index={index} imgsrc={image} disabled={!editMode} onDeleteProjectImage={() => onDeleteProjectImage(index) } />
                  )
                })}
              </div>
              <div className="mb-4">
                <ImageUploaderPrimary disabled={!editMode} onFileChange={(e) => AddProjectFile({ e })} uploader={true} />
                {errors.images && (
                  <p className='text-red-500'>Project Images should be at least 7</p>
                )}
              </div>
            </div>
            {
              type === 'edit' && (
                !editMode
                  ? (
                    <button
                    onClick={() => setEditMode(true)}
                      className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'>
                        <TFilledPenIcon className='h-4 w-4 mr-2' />
                      Edit
                    </button>
                    )
                  : (
                  <button
                    onClick={onSubmit}
                    className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'>
                      {loading && <Oval height="18" width="18" color="black" />}
                      {!loading && (
                        <Done className='h-4 w-4 mr-2' />
                      )}
                    Save
                  </button>
                    )
              )
            }
            {type === 'create' && (
              <div className='flex justify-end'>
                <button
                  onClick={() => setStep(CREATE_PROJECT_GENERAL_INFO_STEP)}
                  className='px-6 py-3 mt-6 rounded font-semibold flex items-center border border-softBlack_70 font-openSans italic font-light'>
                  Back
                </button>
                <button
                  onClick = {onSubmit}
                  className='ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'>
                  {loading && <Oval height="18" width="18" color="black" />}
                  Next
                </button>
              </div>
            )}
          </>
          }
        </div>
    </>
  )
}

export default ProjectImages
