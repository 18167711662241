// @ts-nocheck
import React, { useState } from 'react'
import moment from 'moment'
import { Oval } from 'react-loader-spinner'
import CustomModal from 'components/Modal'

import CustomInput from 'components/CustomInput'
import CustomTextArea from 'components/CustomTextArea'

import { createOrUpdateTask } from 'store/actions/taskActions'
import { SelectTaskStatus } from '../../pages/contacts/SelectTaskStatus'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

import { isMobile } from 'react-device-detect'

const emptyStates = {
  title: '',
  description: '',
  status: '',
  notifyDate: '',
  dueDate: ''
}

const TaskModal = ({ show, setClose, contact, task, refetch }) => {
  const initialSates = {
    title: task?.title || emptyStates.title,
    description: task?.description || emptyStates.description,
    status: task?.status || emptyStates.status,
    notifyDate: task?.notifyDate ? moment(task?.notifyDate).format('YYYY-MM-DDThh:mm') : emptyStates.notifyDate,
    dueDate: task?.dueDate ? moment(task?.dueDate).format('YYYY-MM-DDThh:mm') : emptyStates.dueDate
  }

  const showTimeInitialValue = !!task?.notifyDate

  const [taskDetails, setTaskDetails] = useState({
    ...initialSates
  })
  const [loading, setLoading] = useState(false)
  const [showTime, setShowTime] = useState(showTimeInitialValue)

  const onChange = (fieldName, value) => {
    setTaskDetails({ ...taskDetails, [fieldName]: value })
  }

  const onValidate = () => {
    for (const key in taskDetails) {
      if (taskDetails[key] === '') {
        if (key === 'notifyDate' && !showTime) {
          return true
        }
        return false
      }
    }
    return true
  }

  const onSave = async () => {
    let date = null
    if (!taskDetails.dueDate) {
      const temp = new Date()
      temp.setHours(11)
      temp.setMinutes(59)
      temp.setMilliseconds(0)
      date = temp
    }
    const params = {
      _id: task?._id,
      buyer: contact?._id,
      ...taskDetails,
      notifyDate: showTime ? new Date(taskDetails.notifyDate) : null
    }
    if (!taskDetails.dueDate) params.dueDate = date
    setLoading(true)
    try {
      await createOrUpdateTask(params)
      setLoading(false)
      refetch?.()
      if (!task?._id) {
        setTaskDetails({ ...initialSates })
      }
      setClose(true)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setShowTime(showTimeInitialValue)
    setTaskDetails({ ...initialSates })
    setClose(false)
  }

  const tempTaskTitles = [
    'WhatsApp Message',
    'Not Started',
    'In Progress',
    'Completed',
    'Waiting on someone else',
    'Deferred',
    'Preview Boook Review & Reservation',
    'Call',
    'Send Letter',
    'Other',
    'Send CMA',
    'Follow-up with Comps/ Listings',
    'Follow-up',
    'Schedule Showing',
    'Incomiing Call',
    'Outgoing Call',
    'Appointment',
    'Email',
    'Appointment - New Customer',
    'Appointment - New Realtor',
    'Visit',
    'Introduction Call',
    'Follow Up Call',
    'Meeting',
    'Introduction Meeting',
    'Follow Up Meeting',
    'Task',
    'Follow Up Task',
    'Contract Update',
    'Financing Update',
    'Deposit Update',
    'Closing Update',
    'First Contact Attempt',
    'Second Contact Attempt',
    'Third Contact Attempt',
    'Fourth Contact Attempt',
    'Owner Visit'
  ]

  return (
    <CustomModal
      isOpen={show}
      closeModal={handleClose}
      contentLabel="New Task Modal"
    >
      <div
        className="bg-grey-8 p-10 rounded shadow-lg"
        style={{ width: isMobile ? '100%' : 544 }}
      >
        <button className='absolute right-10' onClick={handleClose}><CrossIcon /></button>
        <h1 className="font-bold text-xl text-center p-0 m-0">{task?._id ? 'Update Task' : 'New Task'}</h1>
        <div className={`flex flex-col ${isMobile ? 'gap-4' : 'py-8 gap-6'}`}>
          <div className={'flex flex-col gap-2'}>
            <CustomInput placeholder="Enter title" label="Title" value={taskDetails.title} classes="text-base" onChange={(e) => onChange('title', e.target.value)} />
            <p className="text-softBlack70 uppercase font-bold text-sm text-center mb-0">OR</p>
            <div>
              <p
                className="mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase"
                style={{ letterSpacing: '0.16px' }}
              >
                Title Dropdown
              </p>
              <select
                placeholder="Select title"
                value={taskDetails.title}
                onChange={e => onChange('title', e.target.value)}
                className="w-full text-base bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70"
              >
                <option value="" className="text-softBlack70">{`${!taskDetails?.title?.length ? 'Select title' : tempTaskTitles.indexOf(taskDetails.title) !== -1 ? 'Select title' : taskDetails.title}`}</option>
                {tempTaskTitles?.map((_option, index) => <option key={index} value={_option}>{_option}</option>)}
              </select>
            </div>
          </div>
          <CustomTextArea
            label="description *"
            placeholder="Enter description"
            value={taskDetails.description}
            onChange={(e) => {
              onChange('description', e.target.value)
            }}
            classes="w-full text-base"
            style={{ color: '#2E2B2E' }}
          />
          <SelectTaskStatus
            handleChange={(value) => {
              onChange('status', value)
            }}
            value={taskDetails.status}
          />
          <CustomInput
            label="Due Date"
            value={taskDetails.dueDate}
            onChange={(e) => {
              onChange('dueDate', e.target.value)
            }}
            type="datetime-local"
            classes="w-full uppercase text-base"
            min={null}
          />
          {showTime && (
            <CustomInput
              label="Enter date & time *"
              value={taskDetails.notifyDate}
              onChange={(e) => {
                onChange('notifyDate', e.target.value)
              }}
              type="datetime-local"
              classes="w-full uppercase text-base"
              min={moment().format('YYYY-MM-DDThh:mm')}
            />
          )}
          <button
            className="py-3 px-6 text-base bg-volt rounded font-medium m-auto flex gap-2 items-center"
            onClick={onSave}
            disabled={!onValidate()}
          >
            {loading && <Oval height="18" width="18" color="black" />}
            {task?._id ? 'Update' : 'Save'}
          </button>
        </div>
      </div>
    </CustomModal>
  )
}

export default TaskModal
