import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import AddOrEditSingleContact from 'components/AddOrEditContact'
import AddOrEditBulkContact from 'components/AddBulkContact'
import ContactEditMethodModal from 'components/ContactEditMethodModal'
import { useHistory } from 'react-router-dom'
import AddReferralAgent from 'components/AddReferralAgent/AddReferralAgent'

const ContactModal = () => {
  const tableButtonStyle = {
    backgroundColor: '#E3E3E1',
    marginRight: '20px',
    gap: '10px'
  }
  const history = useHistory()

  const [isOpenContactEditMethodModal, setIsOpenContactEditMethodModal] =
    useState(false)
  const [isOpenCreateSingleContactModal, setIsOpenCreateSingleContactModal] =
    useState(false)
  const [singleContactModalType, setSingleContactModalType] = useState(null)
  const [isOpenCreateBulkContactModal, setIsOpenCreateBulkContactModal] =
    useState(false)
  const [isOpenReferralAgentModal, setIsOpenReferralAgentModal] =
    useState(false)

  const openContactEditMethodModal = () => {
    setIsOpenContactEditMethodModal(true)
  }

  const openCreateSingleContactModal = (type) => {
    setSingleContactModalType(type)
    setIsOpenCreateSingleContactModal(true)
  }

  const onCreateSingleContact = (_id) => {
    if (history) {
      history.push({
        pathname: '/admin/newcontacts',
        search: `?id=${_id}&userType=${singleContactModalType}`
      })
    }
  }

  const onCreateBulkContact = () => {
    setIsOpenCreateBulkContactModal(true)
  }

  return (
    <>
      {isOpenContactEditMethodModal && (
        <ContactEditMethodModal
          show={isOpenContactEditMethodModal}
          setShow={setIsOpenContactEditMethodModal}
          onMethodSelected={(selectedMethod) => {
            if (selectedMethod === 'referral') {
              setIsOpenReferralAgentModal(true)
            } else if (selectedMethod === 'LeadBroker') {
              openCreateSingleContactModal('LeadBroker')
            } else if (selectedMethod === 'Buyer') {
              openCreateSingleContactModal('Buyer')
            } else if (selectedMethod === 'bulk') {
              onCreateBulkContact()
            }
          }}
        />
      )}
      {isOpenCreateBulkContactModal && (
        <AddOrEditBulkContact
          show={isOpenCreateBulkContactModal}
          setShow={setIsOpenCreateBulkContactModal}
          onSuccess={onCreateBulkContact}
        />
      )}
      {isOpenReferralAgentModal && (
        <AddReferralAgent
          show={isOpenReferralAgentModal}
          setShow={setIsOpenReferralAgentModal}
          contact={null}
          onSuccess={''}
        />
      )}
      {isOpenCreateSingleContactModal && (
        <AddOrEditSingleContact
          show={isOpenCreateSingleContactModal}
          setShow={setIsOpenCreateSingleContactModal}
          userType={singleContactModalType}
          contact={null}
          onSuccess={onCreateSingleContact}
          invalideCacheOnUpdate
        />
      )}
      <Button style={tableButtonStyle} onClick={openContactEditMethodModal}>
        <div className='text-xl'>+</div> <div className='ml-2'>Add Contact</div>
      </Button>
    </>
  )
}

export default ContactModal
