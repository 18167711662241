import { useFormikContext } from 'formik'
import React from 'react'

const FormInput = ({ title = '', placeholder = '', fieldName = '' }) => {
  const { handleChange } = useFormikContext()
  return (
    <div className='flex flex-1 flex-col justify-left'>
        <div className='font-bold text-softBlack_70 italic'>{title}</div>
        <input onChange={handleChange(fieldName)} className='' placeholder={placeholder} />
    </div>
  )
}

export default FormInput
