// @ts-nocheck

/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  // useRef,
  useState,
  useCallback,
  useContext
} from 'react'

import { ReactComponent as DownIcon } from 'assets/icons/down.svg'

import EditIcon from 'assets/icons/edit.svg'
import DoneIcon from 'assets/icons/done.svg'
import CrossIcon from 'assets/icons/cross.svg'

import { Oval } from 'react-loader-spinner'

import { Select, message } from 'antd'
import CustomSelect from 'components/CustomSelect'
import CustomInput from 'components/CustomInput'
import { FadedAnimatedDiv } from 'components'
import Searching from './Searching'

import { getDevelopers } from 'store/actions/developersActions'
import { changeUserImages, updateUserPhotos } from 'store/actions/usersActions'
import { isArray } from 'lodash'

import { ImageUploaderBrowse } from 'components/ImageUploader'
import { createUploadFileList } from 'utils/createUploadFileList'
import { ContactContext } from './context/Contacts'

import { isMobile } from 'react-device-detect'
import { NotesTab } from './NotesTab'
import ContactList from 'components/ContactList/ContactList'
import { getUserDataByType } from 'utils/getUserDataByType'
import useUpdateUsers from 'hooks/useUpdateUsers'

export const InfoTab = ({ contact, user }) => {
  // COLORS
  const gray = '#767674'

  const { projectsLoading, dropdownData, projects, selectedContactObject } =
    useContext(ContactContext)
  const { createOrUpdateUser } = useUpdateUsers()
  const DROPDOWN_TITLES = [
    'Unit Interests',
    'Budget',
    'Timeline to Purchase',
    'Project Interests',
    'Floor Interests',
    'Needs Financing'
  ]

  const {
    createdAt,
    lastName,
    firstName,
    userType,
    message: emailMessage,
    email
  } = contact ?? {}
  const userDataObject = getUserDataByType(userType, contact)

  const getSelectedUnitInterests = () => {
    const unitInterestsIndex = []
    const unitInterestsApi = userDataObject.unitInterests
    const dropdownValues = []
    for (let i = 0; i < dropdownData.unitInterests.length; i++) {
      dropdownValues.push(dropdownData.unitInterests[i].value)
    }
    for (let i = 0; i < unitInterestsApi.length; i++) {
      const unitInterest = unitInterestsApi[i]
      const index = dropdownValues.indexOf(unitInterest)
      if (index > -1) {
        unitInterestsIndex.push(index)
      }
    }
    return unitInterestsIndex
  }

  const getSelectedProjectInterests = () => {
    const projectInterests = []
    const projectInterestsApi = userDataObject.projectInterests
    for (let i = 0; i < projectInterestsApi.length; i++) {
      const id = projectInterestsApi[i]._id
      projectInterests.push(id)
    }
    return projectInterests
  }

  const getSelectedFloorInterests = () => {
    const floorInterestsIndex = []
    const floorInterestsApi = userDataObject.floorInterests
    const dropdownValues = []
    for (let i = 0; i < dropdownData.floorInterests.length; i++) {
      dropdownValues.push(dropdownData.floorInterests[i].value)
    }
    for (let i = 0; i < floorInterestsApi.length; i++) {
      const floorInterest = floorInterestsApi[i]
      const index = dropdownValues.indexOf(floorInterest)
      if (index > -1) {
        floorInterestsIndex.push(index)
      }
    }
    return floorInterestsIndex
  }

  const [selectedUnitInterests, setSelectedUnitInterests] = useState(
    userDataObject?.unitInterests?.length > 0 ? getSelectedUnitInterests() : []
  )
  const [selectedBudgetOption, setSelectedBudgetOption] = useState(
    userDataObject?.budget?.length > 0 ? userDataObject.budget : 'Select Option'
  )
  const [selectedtimelineToPurchase, setSelectedtimelineToPurchase] = useState(
    userDataObject?.timelineToPurchase?.length > 0
      ? userDataObject.timelineToPurchase
      : 'Select Option'
  )
  const [selectedProjectInterests, setSelectedProjectInterests] = useState(
    userDataObject?.projectInterests?.length > 0
      ? getSelectedProjectInterests()
      : []
  )
  const [selectedFloorInterests, setSelectedFloorInterests] = useState(
    userDataObject?.floorInterests?.length > 0
      ? getSelectedFloorInterests()
      : []
  )
  const [selectedNeedsFinancing, setSelectedNeedsFinancing] = useState(
    userDataObject?.needsFinancing?.length > 0
      ? userDataObject.needsFinancing
      : 'Select Option'
  )
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const residentData = [
    { id: false, value: 'No' },
    { id: true, value: 'Yes' }
  ]

  const prefixData = [
    { id: 'mr', value: 'Mr.' },
    { id: 'miss', value: 'Miss.' },
    { id: 'ms', value: 'Ms.' },
    { id: 'mrs', value: 'Mrs.' }
  ]

  const maritalData = [
    { id: 'Single', value: 'Single' },
    { id: 'Married', value: 'Married' },
    { id: 'Divorced', value: 'Divorced' },
    { id: 'Separated', value: 'Separated' }
  ]

  const { updateContact, getUsersData } = useContext(ContactContext)

  // CONTACT EDIT INFO STATES
  const [values, setValues] = useState({
    id: contact?.id,
    email: contact?.email,
    userType: contact?.userType,
    prefix: userDataObject?.prefix ?? '',
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    legalName: userDataObject?.legalName ?? '',
    address: userDataObject?.address,
    city: userDataObject?.city,
    province: userDataObject?.province,
    country: userDataObject?.country,
    postalCode: userDataObject?.postalCode,
    resident: userDataObject?.resident,
    stripeCustomerId: userDataObject?.stripeCustomerId,
    iQ11Token: userDataObject?.iQ11Token,
    phoneNumber: userDataObject?.phoneNumber,
    mobileNumber: userDataObject?.mobileNumber,
    workNumber: userDataObject?.workNumber,
    bus: userDataObject?.bus,
    sin: userDataObject?.sin,
    fax: userDataObject?.fax,
    idNumber: userDataObject?.idNumber ?? '',
    idType: userDataObject?.idType ?? '',
    idExpireDate:
      userDataObject?.idExpireDate?.slice(
        0,
        userDataObject?.idExpireDate.indexOf('T')
      ) ?? '',
    birthday:
      userDataObject?.birthday?.slice(
        0,
        userDataObject?.birthday.indexOf('T')
      ) ?? '',
    employer: userDataObject?.employer,
    occupation: userDataObject?.occupation,
    frontPhotoIdFile: userDataObject?.frontPhotoIdFile,
    frontPhotoIdUrl: userDataObject?.frontPhotoIdUrl,
    backPhotoId: userDataObject?.backPhotoId,
    backPhotoIdFile: userDataObject?.backPhotoIdFile,
    backPhotoIdUrl: userDataObject?.backPhotoIdUrl,
    selectedPhotoIdSource: userDataObject?.selectedPhotoIdSource,
    payorId: userDataObject?.payorId,
    frontPhotoId: userDataObject?.frontPhotoId,
    typeformData: userDataObject?.typeformData,
    rating: userDataObject?.rating,
    preferredContact: userDataObject?.preferredContact,
    socialMedia: userDataObject?.socialMedia,
    assignments: userDataObject?.assignments,
    additionalFields: userDataObject?.additionalFields,
    allowAdditionalServices: userDataObject?.allowAdditionalServices,
    allowSurveys: userDataObject?.allowSurveys,
    twitter: userDataObject?.socialMedia?.twitter ?? '',
    webSite: userDataObject?.socialMedia?.webSite ?? '',
    licenseNumber: contact?.brokerData?.licenseNumber,
    licenseBrokerage: contact?.brokerData?.licenseBrokerage,
    licenseExpiration: contact?.brokerData?.licenseExpiration,
    developerCompany: '',
    project: contact?.brokerData?.project,
    projects: {},
    developersData: [],
    buyerData: {
      socialModia: {
        twitter: userDataObject?.twitter,
        webSite: userDataObject?.webSite
      }
    },
    rfc: userDataObject?.rfc,
    curp: userDataObject?.curp,
    placeOfBirth: userDataObject?.placeOfBirth,
    maritalStatus: userDataObject?.maritalStatus,
    nationality: userDataObject?.nationality,
    notificationAddress: userDataObject?.notificationAddress
  })

  const uploadImage = (imageType, file) => {
    const params = { userId: values.id }

    if (imageType === 'frontPhotoId') {
      params.frontPhotoId = isArray(file) ? file[0] : file
    } else {
      params.backPhotoId = isArray(file) ? file[0] : file
    }
    changeUserImages(params)
  }

  useEffect(() => {
    if (values.frontPhotoIdFile !== userDataObject?.frontPhotoIdFile) {
      uploadImage('frontPhotoId', values.frontPhotoId)
    }
  }, [values.frontPhotoIdFile])

  useEffect(() => {
    if (values.backPhotoIdFile !== userDataObject?.backPhotoIdFile) {
      uploadImage('backPhotoId', values.backPhotoId)
    }
  }, [values.backPhotoIdFile])

  // When mounted, get the list of developers to fill Developers Select
  useEffect(() => {
    if (!projects) return
    if (
      values.userType === 'DeveloperAdmin' ||
      values.userType === 'SalesRep' ||
      values.userType === 'CoopBroker'
    ) {
      getDevelopers()
        .then((developers) => {
          const newDevelopersData = developers.map((developer) => ({
            id: developer._id,
            value: developer.companyName
          }))
          setValues({ ...values, developersData: newDevelopersData })
        })
        .catch(() => {})
    }
    const tmpProjects = projects?.map?.((project) => ({
      id: project._id,
      value: project.projectName
    }))
    setValues({ ...values, projects: tmpProjects })
  }, [values.userType, projects])

  const getUserData = useCallback(() => {
    if (values.id) {
      // Get user data from id
      const user = selectedContactObject
      const {
        buyerData,
        brokerData,
        developerAdminData,
        salesRepData,
        project
      } = user
      if (buyerData) {
        const { frontPhotoId, backPhotoId } = buyerData
        // Buyer
        if (buyerData) {
          if (project) {
            setValues({ ...values, project })
          }

          if (frontPhotoId) {
            const tmpFrontPhotoId = createUploadFileList(frontPhotoId)
            setValues({ ...values, frontPhotoId: tmpFrontPhotoId })
            setValues({ ...values, frontPhotoIdUrl: frontPhotoId })
          }

          if (backPhotoId) {
            const tmpBackPhotoId = createUploadFileList(backPhotoId)
            setValues({ ...values, backPhotoId: tmpBackPhotoId })
            setValues({ ...values, backPhotoIdUrl: backPhotoId })
          }

          // if (frontPhotoId || backPhotoId) {
          // // Just to show the imaes, and not the upload method selector
          //   setChosenPhotoIdMethod(GET_PHOTO_ID_FROM_LIBRARY)
          // }
        }

        // Coop Broker
        if (brokerData) {
          const { city, province, country } = brokerData
          setValues({ ...values, city })
          setValues({ ...values, province })
          setValues({ ...values, country })

          // const tmpDeveloperCompanies = []
          // if (developerCompany.length) {
          //   developerCompany.forEach((element) =>
          //     tmpDeveloperCompanies.push(element._id)
          //   )
          // }
          setValues({ ...values, developerCompany: values.developerCompany })
        }

        // Developer Admin
        if (developerAdminData) {
          const { developerCompany } = developerAdminData

          if (developerCompany) {
            setValues({ ...values, developerCompany: developerCompany._id })
          }
        }

        // Sales Rep
        if (salesRepData) {
          const { developerCompany, projects } = salesRepData

          if (developerCompany) {
            setValues({ ...values, developerCompany: developerCompany._id })
          }
          setValues({ ...values, projects })
        }
      }
    } else {
      // cleanValues()
    }
  }, [values.id])

  useEffect(() => {
    getUserData()
  }, [getUserData])

  const onChange = (field, value) => {
    setValues({ ...values, [field]: value })
  }

  const onSave = async () => {
    const isValid = true
    if (isValid) {
      setEditMode(false)
      if (values.frontPhotoId || values.backPhotoId) {
        const imageData = new FormData()
        imageData.append('frontPhotoId', values.frontPhotoId)
        imageData.append('backPhotoId', values.backPhotoId)
        await updateUserPhotos({ formData: imageData, userId: contact?._id })
      }
      let baseObject = {
        id: values.id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        userType: values.userType
      }

      let params = {}

      if (
        values.userType === 'Buyer' ||
        values.userType === 'Lead' ||
        values.userType === 'LeadBroker'
      ) {
        baseObject = Object.assign({}, baseObject, {
          project: values.projects.length ? values.projects : []
        })
        const unitInterestsTemp = []
        for (let i = 0; i < selectedUnitInterests.length; i++) {
          const unitID = selectedUnitInterests[i]
          const selectedUnitInterest = dropdownData.unitInterests[unitID].value
          unitInterestsTemp.push(selectedUnitInterest)
        }
        const floorInterestsTemp = []
        for (let i = 0; i < selectedFloorInterests.length; i++) {
          const floorId = selectedFloorInterests[i]
          const selectedUnitInterest =
            dropdownData.floorInterests[floorId].value
          floorInterestsTemp.push(selectedUnitInterest)
        }
        const projectInterestsTemp = []
        for (let i = 0; i < selectedProjectInterests.length; i++) {
          const id = selectedProjectInterests[i]
          const project = dropdownData.projectInterests.find(function (
            project,
            index
          ) {
            if (project.id === id) {
              return true
            }
            return false
          })
          if (project) {
            projectInterestsTemp.push({ _id: project.id })
          }
        }
        params = Object.assign({}, baseObject, {
          [values.userType === 'Buyer' ? 'buyerData' : 'leadBrokerData']: {
            city: values.city,
            province: values.province,
            country: values.country,
            phoneNumber: values.phoneNumber,
            prefix: values.prefix,
            occupation: values.occupation,
            address: values.address,
            postalCode: values.postalCode,
            bus: values.bus,
            fax: values.fax,
            sin: values.sin,
            resident: values.resident,
            iQ11Token: values.iQ11Token,
            stripeCustomerId: values.stripeCustomerId,
            payorId: values.payorId,
            legalName: values.legalName,
            mobileNumber: values.mobileNumber,
            workNumber: values.workNumber,
            employer: values.employer,
            socialMedia: {
              twitter: values?.buyerData?.socialMedia?.twitter,
              website: values?.buyerData?.socialMedia?.webSite
            },
            typeformData: values.typeformData,
            rating: values.rating,
            preferredContact: values.preferredContact,
            assignments: values.assignments,
            additionalFields: values.additionalFields,
            frontPhotoId: values.frontPhotoIdUrl,
            backPhotoId: values.backPhotoIdUrl,
            birthday: values.birthday,
            idType: values.idType,
            idNumber: values.idNumber,
            idExpireDate: values.idExpireDate,
            allowAdditionalServices: values.allowAdditionalServices,
            allowSurveys: values.allowSurveys,
            unitInterests: values.userType === 'Buyer' ? unitInterestsTemp : '',
            budget:
              selectedBudgetOption?.length > 0 &&
              selectedBudgetOption !== 'Select Option'
                ? selectedBudgetOption
                : dropdownData.budgetOptions[selectedBudgetOption]?.label ?? '',
            timelineToPurchase:
              selectedtimelineToPurchase?.length > 0 &&
              selectedtimelineToPurchase !== 'Select Option'
                ? selectedtimelineToPurchase
                : dropdownData.timelineToPurchase[selectedtimelineToPurchase]
                    ?.label ?? '',
            floorInterests: floorInterestsTemp,
            needsFinancing:
              selectedNeedsFinancing?.length > 0 &&
              selectedNeedsFinancing !== 'Select Option'
                ? selectedNeedsFinancing
                : dropdownData.needFinancing[selectedNeedsFinancing]?.label ??
                  '',
            projectInterests: projectInterestsTemp,
            rfc: values.userType === 'Buyer' ? values?.rfc : null,
            curp: values.userType === 'Buyer' ? values?.curp : null,
            placeOfBirth:
              values.userType === 'Buyer' ? values?.placeOfBirth : null,
            maritalStatus:
              values.userType === 'Buyer' ? values?.maritalStatus : null,
            nationality:
              values.userType === 'Buyer' ? values?.nationality : null,
            notificationAddress:
              values.userType === 'Buyer' ? values?.notificationAddress : null
          }
        })
      }

      if (values.userType === 'DeveloperAdmin') {
        params = Object.assign({}, baseObject, {
          developerAdminData: {
            developerCompany: values.developerCompany
          }
        })
      }

      if (values.userType === 'SalesRep') {
        const salesRepProjects = []
        for (let i = 0; i < values.projects?.length; i++) {
          const projectTemp = values.projects[i]
          salesRepProjects.push(projectTemp.id)
        }
        params = Object.assign({}, baseObject, {
          salesRepData: {
            developerCompany: values.developerCompany,
            projects: salesRepProjects,
            buyerData: {
              ...values.buyerData,
              unitInterests: [dropdownData.unitInterests[0].value],
              budget: dropdownData.budgetOptions[selectedBudgetOption].label,
              timelineToPurchase:
                dropdownData.timelineToPurchase[selectedtimelineToPurchase]
                  .label,
              floorInterests: selectedFloorInterests,
              needsFinancing:
                dropdownData.needFinancing[selectedNeedsFinancing].label
            }
          }
        })
      }

      if (values.userType === 'CoopBroker') {
        params = Object.assign({}, baseObject, {
          brokerData: {
            licenseNumber: values.licenseNumber,
            licenseBrokerage: values.licenseBrokerage,
            licenseExpiration: values.licenseExpiration,
            developerCompany: values.developerCompany,
            city: values.city,
            province: values.province,
            country: values.country
          }
        })
      }

      if (values.userType === 'SuperAdmin') {
        params = Object.assign({}, baseObject, {})
      }
      setLoading(true)
      try {
        if (!params?.buyerData?.birthday) {
          delete params?.buyerData?.birthday
        }
        if (!params?.buyerData?.idExpireDate) {
          delete params?.buyerData?.idExpireDate
        }
        const data = await createOrUpdateUser(params, false, false)
        updateContact(data)
      } catch (err) {
        setLoading(false)
      }
      setLoading(false)
      getUsersData()
    } else {
      message.error('Required fields cannot be empty')
    }
  }

  const LoadingProjects = () => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title='Loading projects...' />
    </FadedAnimatedDiv>
  )

  const renderContactInformation = () => (
    <div className='flex flex-col md:flex-row'>
      <div className='text-softBlack flex-1'>
        <div className='flex items-center'>
          <p className='font-bold w-37.5'>Registration Date:</p>
          <p className='col-span-5'>{createdAt}</p>
        </div>
        <div className='flex items-center'>
          <p className='font-bold w-37.5'>Name:</p>
          <p className='col-span-5'>{firstName + ' ' + lastName}</p>
        </div>
        <div className='flex items-center'>
          <p className='font-bold w-37.5'>Email:</p>
          <p className='col-span-5'>{email}</p>
        </div>
        <div className='flex items-center'>
          <p className='font-bold w-37.5'>Phone Number</p>
          <p className='col-span-5'>{userDataObject?.phoneNumber || '-'}</p>
        </div>
        <div className='flex items-center'>
          <p className='font-bold w-37.5'>Is Broker</p>
          <p className='col-span-5'>
            {userType === 'LeadBroker' ? 'Yes' : 'No'}
          </p>
        </div>
        <div className='flex items-center'>
          <p className='font-bold w-37.5'>Message</p>
          <p className='col-span-5'>
            {userDataObject?.notes || emailMessage || '-'}
          </p>
        </div>
      </div>
      <div className='flex-1 flex-col justify-center content-center items-center h-64 overflow-y-scroll'>
        <NotesTab contact={contact} user={user} />
      </div>
    </div>
  )

  const renderDemandNotes = () => {
    // Rendering Single Select
    const renderSingleSelect = (
      title,
      dropdownValues,
      handleOnChange,
      selectedOption
    ) => {
      return (
        <div
          style={{
            position: 'relative',
            overflow: 'visible'
          }}
        >
          <div
            className='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase text-xs italic font-openSans font-light'
            style={{
              color: 'soft-black',
              width: '100%',
              fontStyle: 'italic',
              fontWeight: '300',
              textTransform: 'uppercase'
            }}
          >
            {title}
          </div>
          <Select
            suffixIcon={<DownIcon />}
            showArrow
            onChange={handleOnChange}
            value={selectedOption}
            style={{
              fontSize: 16,
              color: gray,
              height: '43px',
              paddingTop: 6.5,
              width: isMobile ? '100%' : '90%'
            }}
            className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
            disabled={!editMode}
            getPopupContainer={(node) => node.parentNode}
          >
            {dropdownValues?.map((_item, index) => (
              <Select.Option value={_item.value} key={index}>
                <div className='flex gap-2 items-center'>
                  <span style={{ fontStyle: 'italic' }}>{_item.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
      )
    }

    const renderMultiSelectDropdown = (
      title,
      dropdownValues,
      handleOnChange,
      selectedOptions
    ) => {
      return (
        <div
          style={{
            position: 'relative',
            overflow: 'visible'
          }}
        >
          <div
            className='mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase text-xs italic font-openSans font-light'
            style={{
              color: 'soft-black',
              width: '100%',
              fontStyle: 'italic',
              fontWeight: '300',
              textTransform: 'uppercase'
            }}
          >
            {title}
          </div>
          <div style={isMobile ? { width: '100%' } : { width: '90%' }}>
            <CustomSelect
              label={undefined}
              options={dropdownValues}
              selectedOption={selectedOptions}
              setSelectedOption={(e) => handleOnChange(e)}
              labelClasses={'text-left'}
              inputClasses={'bg-white'}
              defaultValue={undefined}
              multiple={true}
              fieldName={'Options'}
              disabled={!editMode}
            />
          </div>
        </div>
      )
    }

    const renderDropdowns = () => (
      <>
        {renderMultiSelectDropdown(
          DROPDOWN_TITLES[0],
          dropdownData.unitInterests,
          (e) => setSelectedUnitInterests(e),
          selectedUnitInterests
        )}
        {renderSingleSelect(
          DROPDOWN_TITLES[1],
          dropdownData.budgetOptions,
          (e) => setSelectedBudgetOption(e),
          selectedBudgetOption
        )}
        {renderSingleSelect(
          DROPDOWN_TITLES[2],
          dropdownData.timelineToPurchase,
          (e) => setSelectedtimelineToPurchase(e),
          selectedtimelineToPurchase
        )}
        {renderMultiSelectDropdown(
          DROPDOWN_TITLES[3],
          dropdownData.projectInterests,
          (e) => setSelectedProjectInterests(e),
          selectedProjectInterests
        )}
        {renderMultiSelectDropdown(
          DROPDOWN_TITLES[4],
          dropdownData.floorInterests,
          (e) => setSelectedFloorInterests(e),
          selectedFloorInterests
        )}
        {renderSingleSelect(
          DROPDOWN_TITLES[5],
          dropdownData.needFinancing,
          (e) => setSelectedNeedsFinancing(e),
          selectedNeedsFinancing
        )}
      </>
    )

    const renderButtons = () => (
      <div
        style={{ marginRight: '5%' }}
        className={`flex ${
          isMobile ? 'flex-col justify-center items-center' : ''
        } gap-4`}
      >
        {!editMode && (
          <button
            onClick={() => setEditMode(true)}
            type='button'
            style={{ fontStyle: 'italic', padding: '10px' }}
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
          >
            <span style={{ marginLeft: '10px' }}></span>
            <img src={EditIcon} alt='edit-icon' />
            <span
              style={{
                marginLeft: '10px',
                fontSize: '16px',
                marginRight: '10px'
              }}
            >
              Edit
            </span>
          </button>
        )}
        {editMode && (
          <button
            onClick={() => setEditMode(false)}
            type='button'
            style={{
              fontStyle: 'italic',
              padding: '10px',
              minWidth: isMobile ? '120px' : undefined
            }}
            className='inline-flex items-center justify-center rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
          >
            <span style={{ marginLeft: '10px' }}></span>
            <img src={CrossIcon} alt='edit-icon' />
            <span
              style={{
                marginLeft: '10px',
                fontSize: '16px',
                marginRight: '10px'
              }}
            >
              Cancel
            </span>
          </button>
        )}

        <button
          onClick={onSave}
          type='button'
          style={{
            fontStyle: 'italic',
            padding: '15px 10px 15px 10px',
            display: `${!editMode && !loading ? 'none' : 'inherit'}`,
            minWidth: isMobile ? '120px' : undefined
          }}
          className='inline-flex items-center justify-center rounded-md border border-transparent bg-volt px-4 py-2 text-sm font-medium text-black shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto mt-1'
          disabled={loading}
        >
          <span style={{ marginLeft: '10px' }}></span>
          {!loading && <img src={DoneIcon} alt='edit-icon' />}
          {loading && <Oval height='18' width='18' color='black' />}
          <span
            style={{
              marginLeft: '10px',
              fontSize: '16px',
              marginRight: '10px'
            }}
          >
            Save
          </span>
        </button>
      </div>
    )

    return (
      <div className='mt-2'>
        <div className={`flex ${isMobile ? 'flex-col' : ''} justify-between`}>
          <h2 className='mb-2 font-bold text-softBlack text-lg '>
            Demand notes
          </h2>
          {renderButtons()}
        </div>

        <div className='mt-6 flex jusify-between'>
          <div
            className={`grid ${
              isMobile ? 'grid-cols-1' : 'grid-cols-1'
            }  justify-between gap-8`}
            style={{ width: '50%' }}
          >
            {renderDropdowns()}
          </div>
          <div style={{ width: '50%' }}>
            <div className='mt-4'>
              <p className='text-lg text-left font-bold mt-0 mb-4'>Lists</p>
              <div className=' align-center flex flex-col p-5 '>
                <ContactList contact={contact} user={user} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderContactEditInformation = () => {
    return (
      <div
        className={`max-w-full ${isMobile ? 'pt-10' : 'p-10'
          } text-center rounded bg-grey-8 relative`}
      >
        <div>
          <span className='border-red'></span>
          <div
            className={`grid ${
              isMobile ? 'grid-cols-1' : 'grid-cols-4'
            } gap-6 pb-6`}
          >
            <CustomSelect
              label='Prefix'
              labelClasses='text-left'
              inputClasses='h-10 bg-white'
              selectedOption={values.prefix}
              options={prefixData}
              setSelectedOption={(e) => onChange('prefix', e)}
              defaultValue={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='First Name'
              placeholder='Enter first name'
              onChange={(e) => onChange('firstName', e.target.value)}
              value={values.firstName || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Last Name'
              placeholder='Enter last name'
              onChange={(e) => onChange('lastName', e.target.value)}
              value={values.lastName || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Legal Name'
              placeholder='Enter legal name'
              onChange={(e) => onChange('legalName', e.target.value)}
              value={values.legalName || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
          </div>
          <div
            className={`grid ${
              isMobile ? 'grid-cols-1' : 'grid-cols-4'
            } gap-6 pb-6`}
          >
            <CustomInput
              classes='max-h-14'
              label='Birthday'
              placeholder='DD/MM/YYYY'
              type='date'
              onChange={(e) => onChange('birthday', e.target.value)}
              value={values.birthday || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Id Number'
              placeholder='Enter id'
              onChange={(e) => onChange('idNumber', e.target.value)}
              value={values.idNumber || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Id Type'
              placeholder='Enter type'
              onChange={(e) => onChange('idType', e.target.value)}
              value={values.idType || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Id Expiry Date'
              placeholder='DD/MM/YYYY'
              type='date'
              onChange={(e) => onChange('idExpireDate', e.target.value)}
              value={values.idExpireDate || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            {values.userType === 'Buyer' && (
              <>
                <CustomInput
                  classes='max-h-14'
                  label='rfc'
                  value={values.rfc}
                  onChange={(e) => onChange('rfc', e.target.value)}
                  placeholder='rfc'
                  containerClasses={undefined}
                  disabled={!editMode}
                />
                <CustomInput
                  classes='max-h-14'
                  label='curp'
                  value={values.curp}
                  onChange={(e) => onChange('curp', e.target.value)}
                  placeholder='curp'
                  containerClasses={undefined}
                  disabled={!editMode}
                />
                <CustomInput
                  classes='max-h-14'
                  label='Place Of Birth'
                  value={values.placeOfBirth}
                  onChange={(e) => onChange('placeOfBirth', e.target.value)}
                  placeholder='Place Of Birth'
                  containerClasses={undefined}
                  disabled={!editMode}
                />
                <CustomSelect
                  label='Marital Status'
                  labelClasses='text-left'
                  inputClasses='h-10 bg-white'
                  selectedOption={values.maritalStatus}
                  options={maritalData}
                  setSelectedOption={(e) => onChange('maritalStatus', e)}
                  defaultValue={undefined}
                  disabled={!editMode}
                />
                <CustomInput
                  classes='max-h-14'
                  label='Nationality'
                  value={values.nationality}
                  onChange={(e) => onChange('nationality', e.target.value)}
                  placeholder='Nationality'
                  containerClasses={undefined}
                  disabled={!editMode}
                />
                <CustomInput
                  classes='max-h-14'
                  label='Notification Address'
                  value={values.notificationAddress}
                  onChange={(e) =>
                    onChange('notificationAddress', e.target.value)
                  }
                  placeholder='Notification Address'
                  containerClasses='col-span-3'
                  disabled={!editMode}
                />
              </>
            )}
          </div>
          <p className='text-lg text-left font-bold mt-0 mb-6'>
            Representative Information
          </p>
          <div
            className={`grid ${
              isMobile ? 'grid-cols-1' : 'grid-cols-2'
            } gap-6 pb-8`}
          >
            <CustomInput
              classes='max-h-14'
              label='Address'
              placeholder='Enter address'
              onChange={(e) => onChange('address', e.target.value)}
              value={values.address || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='City'
              placeholder='Enter city'
              onChange={(e) => onChange('city', e.target.value)}
              value={values.city || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Province'
              placeholder='Enter province'
              onChange={(e) => onChange('province', e.target.value)}
              value={values.province || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Country'
              placeholder='Enter country'
              onChange={(e) => onChange('country', e.target.value)}
              value={values.country || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />

            <CustomInput
              classes='max-h-14'
              label='Postal Code'
              placeholder='Enter code'
              onChange={(e) => onChange('postalCode', e.target.value)}
              value={values.postalCode || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomSelect
              label='Resident'
              labelClasses='text-left'
              inputClasses='h-10 bg-white'
              selectedOption={values.resident}
              options={residentData}
              setSelectedOption={(e) => onChange('resident', e)}
              defaultValue={undefined}
              disabled={!editMode}
            />
          </div>

          <p className='text-lg text-left font-bold mt-0 mb-6'>Contacts</p>
          <div
            className={`grid ${
              isMobile ? 'grid-cols-1' : 'grid-cols-2'
            } gap-6 pb-8`}
          >
            <CustomInput
              type='phone'
              classes='max-h-14'
              label='Phone Number'
              placeholder='Enter phone number'
              onChange={(e) => onChange('phoneNumber', e.target.value)}
              value={values.phoneNumber || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              type='phone'
              classes='max-h-14'
              label='Mobile Number'
              placeholder='Enter mobile number'
              onChange={(e) => onChange('mobileNumber', e.target.value)}
              value={values.mobileNumber || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Work Number'
              placeholder='Enter work number'
              onChange={(e) => onChange('workNumber', e.target.value)}
              value={values.workNumber || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Fax'
              placeholder='Enter fax'
              onChange={(e) => onChange('fax', e.target.value)}
              value={values.fax || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Business Number'
              placeholder='Enter business number'
              onChange={(e) => onChange('bus', e.target.value)}
              value={values.bus || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Social Insurance Number'
              placeholder='Enter number'
              onChange={(e) => onChange('sin', e.target.value)}
              value={values.sin || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              type='email'
              classes='max-h-14'
              label='Email'
              placeholder='Enter email'
              onChange={(e) => onChange('email', e.target.value)}
              value={values.email || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Occupation'
              placeholder='Enter occupation'
              onChange={(e) => onChange('occupation', e.target.value)}
              value={values.occupation || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomInput
              classes='max-h-14'
              label='Employer'
              placeholder='Enter employer'
              onChange={(e) => onChange('employer', e.target.value)}
              value={values.employer || ''}
              containerClasses={undefined}
              disabled={!editMode}
            />
            <CustomSelect
              label='Gender'
              labelClasses='text-left'
              inputClasses='h-10 bg-white'
              selectedOption={undefined}
              options={[
                { id: 1, value: 'Male' },
                { id: 2, value: 'Female' }
              ]}
              setSelectedOption={(e) => {}}
              defaultValue={undefined}
              disabled={!editMode}
            />
          </div>
          <p className='text-lg text-left font-bold mt-0 mb-4'>
            Upload Front Photo ID *
          </p>
          <div className='mb-6 col-span-2'>
            <div className='border'>
              <ImageUploaderBrowse
                value={values.frontPhotoIdFile || ''}
                onFileChange={(e) =>
                  setValues((prev) => {
                    return {
                      ...prev,
                      frontPhotoIdFile: e?.target?.value || '',
                      frontPhotoId: e?.target?.files[0]
                    }
                  })
                }
              />
            </div>
          </div>
          <p className='text-lg text-left font-bold mt-0 mb-4'>
            Upload Back Photo ID *
          </p>
          <div className='mb-6 col-span-2'>
            <div className='border'>
              <ImageUploaderBrowse
                value={values.backPhotoIdFile || ''}
                onFileChange={(e) =>
                  setValues((prev) => {
                    return {
                      ...prev,
                      backPhotoIdFile: e?.target?.value || '',
                      backPhotoId: e?.target?.files[0]
                    }
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (projectsLoading) {
    return <LoadingProjects />
  }

  return (
    <div>
      <div className={isMobile ? 'p-0' : 'p-4'}>
        <h1 className='mb-2 font-bold text-softBlack70 text-xl'>
          LEAD REGISTRATION FORM
        </h1>
        {renderContactInformation()}
        {renderDemandNotes()}
        {renderContactEditInformation()}
      </div>
    </div>
  )
}
