import React from 'react'
import CustomModal from 'components/Modal'
import { Oval } from 'react-loader-spinner'

const DeleteModal = ({ isOpen, setIsOpen, onConfirm, title, description, confirmButtonTitle, cancelButtonTitle, loading }) => {
  const closeModal = () => {
    setIsOpen(false)
  }
  return (
    <CustomModal isOpen={isOpen} closeModal={closeModal} contentLabel="Cancel Modal">
      {loading && (
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Oval height="50" width="50" color="black" />
        </div>
      )}
      <div className='w-136 max-w-full p-10 text-center rounded bg-grey-8'>
        <h1 className='text-softBlack font-bold text-xl mb-0'>{title}</h1>
        <p className='mb-0 mt-6 text-softBlack text-base'>{description}</p>
        <div className='flex justify-center items-center gap-6 mt-8'>
          <button className='border rounded border-softBlack70 w-37.5 text-base font-medium h-12' onClick={closeModal}>{cancelButtonTitle}</button>
          <button className='rounded w-37.5 bg-volt text-base font-medium h-12' onClick={onConfirm}>{confirmButtonTitle}</button>
        </div>
      </div>
    </CustomModal>
  )
}

export default DeleteModal
