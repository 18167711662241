/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useState, useEffect, useContext, useRef } from 'react'
import { FadedAnimatedDiv, ViewSwitcher } from 'components'
import ContactCard from 'components/ContactCard'
import ContactItem from './ContactItem'
import FilterBySearch from './FilterBySearch'
import FilterModal from './FilterModal'
import Searching from './Searching'
import Header from './ContactHeader'

import { ContactProvider, ContactContext } from './context/Contacts'
import ContactsTailwindTable from 'components/TailwindTable'
import useCreateContactList from 'hooks/useCreateContactList'
import { useSelector } from 'react-redux'

import { isMobile } from 'react-device-detect'
import { deleteUser } from 'store/actions/usersActions'

import { useHistory, useParams } from 'react-router-dom'
import { classNames } from 'utils'
import FilterBySalesType from 'components/FilterBySalesType'
import {
  filterNullAssignments,
  filterAssignmentsBySaleRepId,
  sortContacts
} from 'pages/newContacts/context/MainContactContext'

// ===================== CONSTANTS ===================== //
const letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

const Filters = ({
  searchFilter,
  setSearchFilter,
  setIsFilterModalOpen,
  isFilterModalOpen,
  contacts,
  setFilteredContacts,
  viewType,
  setViewType,
  loading,
  setSelectedWord,
  selectedSalesType,
  setSelectedSalesType,
  filteredContacts
}) => {
  const refFilterBySearch = useRef(null)
  return (
    <div
      className={`flex ${
        isMobile ? 'flex-col gap-4' : ''
      } justify-between px-6 py-3`}
    >
      <div className='flex flex-col w-136'>
        <FilterBySearch
          searchFilter={searchFilter}
          setSearchFilter={setSearchFilter}
          loading={loading}
          ref={refFilterBySearch}
        />
        <FilterBySalesType
          selected={selectedSalesType}
          onSelectChange={setSelectedSalesType}
          loading={loading}
          length={filteredContacts?.length ?? 0}
        />
      </div>
      <div style={{ alignSelf: 'center' }} className='flex'>
        <div style={{ marginRight: 20 }}>
          <ViewSwitcher
            view={viewType}
            onChange={(_viewType) => {
              setViewType(_viewType)
            }}
          />
        </div>
        <button
          className='flex space-x-2 items-center border w-36 mr-5 justify-center rounded border-black font-semibold hover:bg-gray-300'
          onClick={() => {
            refFilterBySearch.current?.clearQuery?.()
            setSearchFilter('')
            setSelectedWord('')
            setFilteredContacts(contacts.sort(sortContacts))
          }}
        >
          <span>Show All Contacts</span>
        </button>
        <button
          className='flex space-x-2 items-center border w-28 justify-center rounded border-black font-semibold hover:bg-gray-300'
          onClick={() => setIsFilterModalOpen((prev) => !prev)}
        >
          <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.541571 1.67492C2.2249 3.83325 5.33324 7.83325 5.33324 7.83325V12.8333C5.33324 13.2916 5.70824 13.6666 6.16657 13.6666H7.83324C8.29157 13.6666 8.66657 13.2916 8.66657 12.8333V7.83325C8.66657 7.83325 11.7666 3.83325 13.4499 1.67492C13.8749 1.12492 13.4832 0.333252 12.7916 0.333252H1.1999C0.508238 0.333252 0.116571 1.12492 0.541571 1.67492Z'
              fill='#323232'
            />
          </svg>
          <span>Filters</span>
        </button>
      </div>
      {isFilterModalOpen && (
        <FilterModal
          contacts={contacts}
          setFilteredContacts={(data) => {
            setFilteredContacts(data.sort(sortContacts))
          }}
          close={() => {
            setIsFilterModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

const Letter = ({
  children,
  onClick,
  isDisabled = false,
  isSelected = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={classNames(
        'flex-grow text-sm w-8.25 h-8.25 font-medium flex justify-center items-center rounded leading-none opacity-100',
        isSelected ? 'bg-yellow-200' : 'bg-grey-8',
        isDisabled ? 'grey-8 opacity-50' : 'hover:bg-yellow-200'
      )}
    >
      {children}
    </button>
  )
}

const Letters = ({ selectedWord, setSelectedWord, contacts }) => {
  return (
    <div className='p-6 gap-2 flex flex-wrap'>
      {letters.map((letter) => (
        <Letter
          key={letter}
          onClick={() =>
            selectedWord === letter
              ? setSelectedWord('')
              : setSelectedWord(letter)
          }
          isSelected={selectedWord === letter}
          isDisabled={
            contacts.filter((contact) => contact?.firstName?.[0] === letter)
              .length === 0
          }
        >
          {letter}
        </Letter>
      ))}
    </div>
  )
}

const LoadingContacts = () => (
  <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
    <Searching title='Loading contacts...' />
  </FadedAnimatedDiv>
)

// ===================== COMPONENT PARTS ===================== //

const ContactsList = React.memo((props) => {
  const history = useHistory()
  const ContactsListView = () => {
    const { createSaveList: createContactList, loading } =
      useCreateContactList()
    return (
      <div
        style={{
          paddingRight: '1rem',
          paddingLeft: '12px',
          paddingBottom: '100px'
        }}
        className='w-full overflow-hidden pl-4'
      >
        <ContactsTailwindTable
          createContactList={createContactList}
          savingContacts={loading}
          {...props}
        />
      </div>
    )
  }

  return (
    <>
      {props?.isLoading && (
        <div className='p-6 flex items-center h-full'>
          <LoadingContacts />
        </div>
      )}
      {!props?.isLoading && props?.viewType === 'cardView' && (
        <div className='p-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 justify-center'>
          {props.contacts.map((contact, index) => (
            <ContactCard
              {...contact}
              onClick={() => history.push(`/admin/contacts/${contact?._id}`)}
              key={index}
            />
          ))}
        </div>
      )}
      {!props?.isLoading && props?.viewType === 'listView' && <ContactsListView />}
    </>
  )
}
)

const Contacts = React.memo(() => {
  const {
    loading,
    buyers,
    contacts,
    setContacts,
    isLoading,
    contactRatingFilters,
    setContactRatingFilters,
    resetFilters,
    showFilters,
    setShowFilters,
    filteredContacts,
    setFilteredContacts,
    selectedWord,
    setSelectedWord,
    searchFilter,
    setSearchFilter,
    getUsersData,
    updateContact,
    viewType,
    setViewType,
    saveList,
    setSaveList,
    limit,
    selected,
    setSelected,
    tableContact,
    setTableContact,
    contactsLists,
    contactsListsChecked,
    setContactsListsChecked,
    listsChecked,
    setListsChecked,
    allListsChecked,
    setAllListsChecked,
    getContacts,
    selectedContact,
    setSelectedContact,
    setIsLoading,
    userType,
    setUserType,
    selectedContactObject,
    setSelectedContactObject,
    fetchProjectsForInfoTab,
    dropdownData,
    projectsLoading,
    projects,
    setProjects,
    updateContactObject,
    searchedContacts,
    getSearchContacts,
    isSearchingContacts,
    selectedSalesType,
    setSelectedSalesType
  } = useContext(ContactContext)
  const viewProps = {
    loading,
    buyers,
    contacts,
    setContacts,
    isLoading,
    contactRatingFilters,
    setContactRatingFilters,
    resetFilters,
    showFilters,
    setShowFilters,
    filteredContacts,
    setFilteredContacts,
    selectedWord,
    setSelectedWord,
    searchFilter,
    setSearchFilter,
    getUsersData,
    updateContact,
    viewType,
    setViewType,
    saveList,
    setSaveList,
    limit,
    selected,
    setSelected,
    tableContact,
    setTableContact,
    contactsLists,
    contactsListsChecked,
    setContactsListsChecked,
    listsChecked,
    setListsChecked,
    allListsChecked,
    setAllListsChecked,
    getContacts,
    selectedContact,
    setSelectedContact,
    setIsLoading,
    userType,
    setUserType,
    selectedContactObject,
    setSelectedContactObject,
    fetchProjectsForInfoTab,
    dropdownData,
    projectsLoading,
    projects,
    setProjects,
    updateContactObject,
    searchedContacts,
    getSearchContacts,
    isSearchingContacts,
    selectedSalesType,
    setSelectedSalesType
  }
  const userObject = useSelector(state => state.authReducer.userObject)
  const { id } = useParams()
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)

  useEffect(() => {
    const contactsToFilter = searchFilter?.length ? searchedContacts : contacts
    const _filterContacts = contactsToFilter
      ?.filter((contact) => {
        const firstWord = contact?.firstName?.[0]?.toLowerCase()
        if (!selectedWord?.length) return true
        return firstWord === selectedWord?.toLowerCase()
      })
      ?.filter((contact) => {
        const salesType = contact?.userType?.toLowerCase?.()
        if (selectedSalesType === 'unassigned') {
          return (
            !filterNullAssignments(contact?.buyerData?.assignments) &&
            !filterNullAssignments(contact?.leadBrokerData?.assignments)
          )
        } else if (selectedSalesType === 'broker') {
          return (
            salesType?.indexOf(selectedSalesType) !== -1 &&
            filterAssignmentsBySaleRepId(
              contact?.leadBrokerData?.assignments,
              userObject
            )
          )
        } else if (selectedSalesType === 'buyer') {
          return (
            salesType?.indexOf(selectedSalesType) !== -1 &&
            filterAssignmentsBySaleRepId(
              contact?.buyerData?.assignments,
              userObject
            )
          )
        } else {
          return false
        }
      })
    setFilteredContacts(_filterContacts.sort(sortContacts))
  }, [
    searchFilter,
    selectedWord,
    searchedContacts,
    contacts,
    selectedSalesType
  ])

  useEffect(() => {
    if (searchFilter?.length) {
      getSearchContacts(searchFilter)
    }
    setSelectedWord('')
  }, [searchFilter])

  // useEffect(() => {
  //   const contactsToFilter = searchFilter?.length ? searchedContacts : contacts
  //   if (filteredContacts.length === 0) {
  //     if (userObject.userType === 'SalesRep') {
  //       const onlyAssignedContacts = contactsToFilter?.filter?.(contact =>
  //         contact?.buyerData?.assignments?.some?.(assignment =>
  //           assignment?.salesRep?._id === userObject._id))
  //       setFilteredContacts(onlyAssignedContacts.sort(sortContacts))
  //     } else {
  //       setFilteredContacts(contactsToFilter.sort(sortContacts))
  //     }
  //   }
  // }, [searchedContacts, contacts])

  useEffect(() => {
    if (!id) return
    // @ts-ignore
    setSelectedContact(id)
  }, [id])

  const handleContactDelete = (id) => {
    deleteUser(id)
    setSelectedContact(null)
    const filtered = filteredContacts.filter(contact => contact._id !== id)
    setFilteredContacts(filtered.sort(sortContacts))
  }

  // ===================== MAIN COMPONENT ===================== //
  return (
    <>
      <Header/>
      {!selectedContact && (
        <div className='bg-gray-100 min-h-screen'>
          <Letters {...{ selectedWord, setSelectedWord, contacts: searchFilter?.length ? searchedContacts : contacts }} />
          <Filters
            {...{
              searchFilter,
              setSearchFilter,
              setIsFilterModalOpen,
              isFilterModalOpen,
              contacts: searchFilter?.length ? searchedContacts : contacts,
              setFilteredContacts,
              viewType,
              setViewType,
              loading: loading || isSearchingContacts,
              setSelectedWord,
              selectedSalesType,
              setSelectedSalesType,
              filteredContacts
            }}
          />
          <ContactsList
            {...viewProps}
            contacts={filteredContacts}
            viewType={viewType}
            isLoading={isSearchingContacts || isLoading}
            filteredContacts={filteredContacts}
            setSelectedContact={setSelectedContact}
          />
        </div>
      )}
      {selectedContact && isLoading &&
        <div className='p-6 flex items-center h-full'>
          <LoadingContacts />
        </div>}
      {selectedContact && !isLoading && selectedContactObject?._id && (
        <div className='p-6 bg-gray-100'>
          <ContactItem
            contactId={selectedContact}
            cancel={() => setSelectedContact(null)}
            handleContactDelete={handleContactDelete}
            isSalesRep={userObject?.userType === 'SalesRep'}
          />
        </div>
      )}
    </>
  )
})

const ContactsWrapper = () => {
  return (
    <ContactProvider>
      <Contacts />
    </ContactProvider>
  )
}

export default ContactsWrapper
