import React from 'react'
import EmailAttachmentItem from './EmailAttachmentItem'

const EmailAttachments = ({ files }) => {
  if (!files?.length) {
    return null
  }
  return <ul className='flex flex-wrap py-4 gap-2'>
    {files.map((file) => (
      <EmailAttachmentItem file={file} key={file.id} />
    ))}
  </ul>
}

export default EmailAttachments
