/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { getFlatFileToken } from 'store/actions/unitActions'
import FlatFileImportReact from './components/FlatFileImportReact'

const AddBulkUnits = ({ show, onSuccess, setShow }) => {
  const [token, setToken] = useState('')
  const [isLoadingToken, setIsLoadingToken] = useState(true)
  const [tokenLoadError, setTokenLoadError] = useState('')

  const loadToken = useCallback(() => {
    const fetch = async () => {
      try {
        setIsLoadingToken(true)
        setTokenLoadError(null)
        const _token = await getFlatFileToken()
        setToken(_token)
      } catch (e) {
        console.error(e)
        setTokenLoadError(e)
      } finally {
        setIsLoadingToken(false)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    if (show) {
      loadToken()
    }
  }, [show])

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Add Or Edit Bulk Units Modal'
      aria-describedby='Add Or Edit Bulk Units description'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center'>Bulk Upload Units</h1>
        <div className='relative w-full h-full'>
          {isLoadingToken ? (
            <div>{'Loading FlatFile...'}</div>
          ) : (
            <FlatFileImportReact token={token} onSuccess={onSuccess} />
          )}
          {tokenLoadError?.length > 0 ? (
            <div>{JSON.stringify(tokenLoadError)}</div>
          ) : null}
          <FlatFileWrapper
            className={`flatfile-sdk ${isLoadingToken ? 'hidden' : 'block'}`}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
    </Modal>
  )
}

const FlatFileWrapper = styled.div`
  .flatfile-close {
    position: absolute;
    right: 20px;
    top: 15px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
    z-index: 999999999;
  }
  .flatfile-close:after {
    display: inline-block;
    content: 'X';
    color: white;
  }
  iframe {
    width: 100%;
    height: 100%;
    border-width: 0;
    border-radius: 20px;
  }
  body.flatfile-active {
    overflow: hidden;
    overscroll-behavior-x: none;
  }
`

export default AddBulkUnits
