import React from 'react'
import CustomModal from 'components/Modal'
import { Oval } from 'react-loader-spinner'

const ListDeleteModal = ({ isOpen, onClose, onDelete, loading, listName }) => {
  const boxStyle = {
    width: '500px',
    height: '250px'
  }
  return (
        <CustomModal isOpen={isOpen} closeModal={onClose} >
            {!loading && (
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                    <Oval height="50" width="50" color="black" />
                </div>
            )}
            <div className=' flex flex-col gap-5 justify-evenly items-center p-10' style={boxStyle}>
                <h2 className='text-black text-lg'>Are you sure you want to delete {listName} ?</h2>
                <div className='flex flex-row gap-5'>
                    <button
                        onClick={onClose}
                        className="py-3 px-6 text-base border-2 border-black rounded font-medium m-auto flex gap-2 items-center"
                    >
                        No
                    </button>
                    <button
                        onClick={onDelete}
                        className="py-3 px-6 text-base bg-volt rounded font-medium m-auto flex gap-2 items-center"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </CustomModal>
  )
}

export default ListDeleteModal
