/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useCallback, useContext, useEffect } from 'react'
import { ContactContext } from './context/Contacts'

const buyerTypes = ['Buyer', 'LeadBroker']

const FilterModal = ({ contacts, setFilteredContacts, close }) => {
  const {
    contactRatingFilters,
    setContactRatingFilters,
    showFilters,
    // setShowFilters,
    resetFilters,
    searchFilter,
    selectedWord,
    contactsLists,
    contactsListsChecked,
    setContactsListsChecked,
    listsChecked,
    setListsChecked,
    allListsChecked,
    setAllListsChecked,
    userType,
    setUserType,
    getContacts
  } = useContext(ContactContext)

  const onClick = (e) => {
    if (e.target === e.currentTarget) {
      close()
    }
  }

  const Checkbox = ({ placeholder, onChange = (e) => e, ...props }) => {
    return (
      <label className='flex items-center mt-2'>
        <input
          type='checkbox'
          className='mr-2 rounded'
          onChange={onChange}
          {...props}
        />
        {placeholder}
      </label>
    )
  }

  const checkContactsListsFilter = (contact) => {
    const newContactsListsChecked = { ...contactsListsChecked }
    contactsLists.forEach(list => {
      if (!listsChecked[list._id]) return
      const allUsersOfCheckedList = list.users
      allUsersOfCheckedList.forEach(userId => (newContactsListsChecked[userId] = true))
    })
    let isChecked = false
    for (const listId in listsChecked) {
      const checked = listsChecked[listId]
      if (checked) {
        isChecked = true
        break
      }
    }
    if (isChecked) {
      for (const id in newContactsListsChecked) {
        if (id === contact._id) {
          const checked = newContactsListsChecked[id]
          if (checked) {
            return true
          }
        }
      }
      return false
    } else {
      return true
    }
  }

  const applyFilter = () => {
    const contactsTemp = contacts.filter((contact) => {
      const name = `${contact?.firstName} ${contact?.lastName}`?.toLowerCase()
      const firstWord = contact?.firstName?.[0]?.toLowerCase()
      return (
        (!searchFilter.length && !selectedWord.length) ||
        (searchFilter.length &&
          !selectedWord.length &&
          (name?.includes(searchFilter?.toLowerCase()) ||
            contact?.email?.includes(searchFilter?.toLowerCase()))) ||
        (!searchFilter.length &&
          selectedWord.length &&
          firstWord === selectedWord?.toLowerCase()) ||
        (firstWord === selectedWord?.toLowerCase() &&
          (name?.includes(searchFilter?.toLowerCase()) ||
            contact?.email?.includes(searchFilter?.toLowerCase())))
      )
    })
    setFilteredContacts(
      contactsTemp.filter((contact) => {
        let result = true
        if (showFilters.onlyLeadBrokers && contact.userType !== 'Lead') result = false
        if (!contactRatingFilters?.[contact?.buyerData?.rating]) result = false
        if (contactRatingFilters.Unrated && !contact?.buyerData?.rating) result = true
        if (!checkContactsListsFilter(contact)) result = false
        if (Object.keys(userType).length && !userType?.[contact.userType]) result = false
        return result
      })
    )

    close()
  }

  useEffect(() => {
    if (!contactsLists?.length) getContacts()
  }, [contactsLists])

  const handleContactListChange = useCallback((contact) => {
    const contactsListsCheckedTemp = {}
    const listsCheckedTemp = {}

    let isChecked = false
    for (const listId in listsChecked) {
      if (contact._id === listId) {
        listsCheckedTemp[listId] = !listsChecked[listId]
        if (listsCheckedTemp[listId] === true) {
          isChecked = true
        }
      } else {
        listsCheckedTemp[listId] = listsChecked[listId]
      }
    }

    if (!isChecked) {
      setAllListsChecked(false)
    }

    for (const contactId in contactsListsChecked) {
      contactsListsCheckedTemp[contactId] = contactsListsChecked[contactId]
    }

    const users = contact.users
    for (const index in users) {
      const userID = users[index]

      contactsListsCheckedTemp[userID] = isChecked
    }

    setContactsListsChecked(contactsListsCheckedTemp)

    setListsChecked(listsCheckedTemp)
  }, [listsChecked])

  const handleUserTypesChange = (type) => {
    if (userType?.[type]) return setUserType({})
    setUserType({ [type]: true })
  }

  const checkUncheckContacts = (check) => {
    const contactsListsCheckedTemp = {}
    for (const contactId in contactsListsChecked) {
      contactsListsCheckedTemp[contactId] = check
    }
    setContactsListsChecked(contactsListsCheckedTemp)
  }

  const checkAllLists = () => {
    const listsCheckedTemp = {}
    for (const listId in listsChecked) {
      listsCheckedTemp[listId] = !allListsChecked
    }
    checkUncheckContacts(!allListsChecked)
    setListsChecked(listsCheckedTemp)
    setAllListsChecked((prevState) => !prevState)
  }

  const renderContactLists = () => (
    <div
      style={{
        maxHeight: 200,
        overflowY: 'auto',
        paddingLeft: 4,
        marginBottom: 2
      }}
    >
      <Checkbox
        key='All'
        placeholder='All'
        checked={allListsChecked}
        onChange={(e) => {
          checkAllLists()
        }}
      />
      {contactsLists?.map((contact, index) => (
        <Checkbox
          key={index}
          placeholder={contact?.title}
          checked={listsChecked[contact._id]}
          onChange={() => handleContactListChange(contact)}
        />
      ))}
    </div>
  )

  const renderBuyerTypes = () => (
    <div
      style={{
        maxHeight: 200,
        overflowY: 'auto',
        paddingLeft: 4,
        marginBottom: 2
      }}
    >
      {buyerTypes?.map((type, index) => (
        <Checkbox
          key={index}
          placeholder={type}
          checked={!!userType?.[type]}
          onChange={() => handleUserTypesChange(type)}
        />
      ))}
    </div>
  )

  return (
    <div
      onClick={onClick}
      className='fixed z-1 left-0 top-0 w-full h-full overflow-auto bg-[rgb(0,0,0)] bg-[rgba(0,0,0,0.4)] flex justify-center items-center'
    >
      <div className='min-w-md bg-white flex flex-col p-8'>
        <div className='text-center font-bold relative'>
          Filters
          <button className='absolute right-4' onClick={close}>
            X
          </button>
        </div>

        <div className='flex gap-2 items-center'>
          <svg
            width='16'
            height='14'
            viewBox='0 0 16 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.33203 5.75C0.640365 5.75 0.0820312 6.30833 0.0820312 7C0.0820312 7.69167 0.640365 8.25 1.33203 8.25C2.0237 8.25 2.58203 7.69167 2.58203 7C2.58203 6.30833 2.0237 5.75 1.33203 5.75ZM1.33203 0.75C0.640365 0.75 0.0820312 1.30833 0.0820312 2C0.0820312 2.69167 0.640365 3.25 1.33203 3.25C2.0237 3.25 2.58203 2.69167 2.58203 2C2.58203 1.30833 2.0237 0.75 1.33203 0.75ZM1.33203 10.75C0.640365 10.75 0.0820312 11.3167 0.0820312 12C0.0820312 12.6833 0.648698 13.25 1.33203 13.25C2.01536 13.25 2.58203 12.6833 2.58203 12C2.58203 11.3167 2.0237 10.75 1.33203 10.75ZM3.83203 12.8333H15.4987V11.1667H3.83203V12.8333ZM3.83203 7.83333H15.4987V6.16667H3.83203V7.83333ZM3.83203 1.16667V2.83333H15.4987V1.16667H3.83203Z'
              fill='#323232'
            />
          </svg>
          Show
        </div>
        <div className='flex gap-2 items-center my-2'>
          <svg
            width='18'
            height='17'
            viewBox='0 0 18 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9.0013 13.3917L14.1513 16.5001L12.7846 10.6417L17.3346 6.70008L11.343 6.19175L9.0013 0.666748L6.65964 6.19175L0.667969 6.70008L5.21797 10.6417L3.8513 16.5001L9.0013 13.3917Z'
              fill='#323232'
            />
          </svg>
          Contacts Lists
        </div>
        {renderContactLists()}
        {/* <Checkbox
          placeholder='Only Lead Brokers'
          checked={showFilters.onlyLeadBrokers}
          onChange={(e) => {
            setShowFilters((prev) => {
              return {
                showAll: prev.onlyLeadBrokers,
                onlyLeadBrokers: !prev.onlyLeadBrokers
              }
            })
          }}
        />
        <Checkbox
          placeholder='Show All'
          checked={showFilters.showAll}
          onChange={(e) => {
            setShowFilters((prev) => {
              return { onlyLeadBrokers: prev.showAll, showAll: !prev.showAll }
            })
          }}
        /> */}
        <div className='border-b-2 my-4' />

        <div className='flex gap-2 items-center'>
          <svg
            width='18'
            height='17'
            viewBox='0 0 18 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9.0013 13.3917L14.1513 16.5001L12.7846 10.6417L17.3346 6.70008L11.343 6.19175L9.0013 0.666748L6.65964 6.19175L0.667969 6.70008L5.21797 10.6417L3.8513 16.5001L9.0013 13.3917Z'
              fill='#323232'
            />
          </svg>
          Contact Rating
        </div>
        <Checkbox
          key='All'
          placeholder='All'
          checked={
            contactRatingFilters.A &&
            contactRatingFilters.B &&
            contactRatingFilters.C &&
            contactRatingFilters.D &&
            contactRatingFilters.Unrated
          }
          onChange={(e) => {
            setContactRatingFilters({
              A: e.currentTarget.checked,
              B: e.currentTarget.checked,
              C: e.currentTarget.checked,
              D: e.currentTarget.checked,
              Unrated: e.currentTarget.checked
            })
          }}
        />
        <Checkbox
          key='A'
          placeholder='A'
          checked={contactRatingFilters.A}
          onChange={() =>
            setContactRatingFilters((prev) => {
              return { ...prev, A: !prev.A }
            })
          }
        />
        <Checkbox
          key='B'
          placeholder='B'
          checked={contactRatingFilters.B}
          onChange={() =>
            setContactRatingFilters((prev) => {
              return { ...prev, B: !prev.B }
            })
          }
        />
        <Checkbox
          placeholder='C'
          checked={contactRatingFilters.C}
          onChange={() =>
            setContactRatingFilters((prev) => {
              return { ...prev, C: !prev.C }
            })
          }
        />
        <Checkbox
          placeholder='D'
          checked={contactRatingFilters.D}
          onChange={() =>
            setContactRatingFilters((prev) => {
              return { ...prev, D: !prev.D }
            })
          }
        />
        <Checkbox
          placeholder='Unrated'
          checked={contactRatingFilters.Unrated}
          onChange={() =>
            setContactRatingFilters((prev) => {
              return { ...prev, Unrated: !prev.Unrated }
            })
          }
        />
        <div className='border-b-2 my-4' />
        <div className='flex gap-2 items-center my-2'>
          <svg
            width='18'
            height='17'
            viewBox='0 0 18 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9.0013 13.3917L14.1513 16.5001L12.7846 10.6417L17.3346 6.70008L11.343 6.19175L9.0013 0.666748L6.65964 6.19175L0.667969 6.70008L5.21797 10.6417L3.8513 16.5001L9.0013 13.3917Z'
              fill='#323232'
            />
          </svg>
          Buyer Type
        </div>
        {renderBuyerTypes()}
        <div className='border-b-2 my-4' />
        <div className='flex justify-between items-stretch'>
          <button onClick={resetFilters}>Reset</button>
          <button
            onClick={applyFilter}
            className='bg-yellow-200 px-4 py-2 rounded'
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
