import React, { useCallback, useState } from 'react'

import Header from './components/Header'
import ProgressSteps from './components/ProgressSteps'

import { BuildingIcon } from 'components/Icons'
import SelectParking from './components/SelectParking'
import { CreateOfferProvider } from './context/CreateOfferContext'
import Step1 from './components/Step1'
import SelectBuyer from './components/SelectBuyer'
import SelectExtraBuyer from './components/SelectExtraBuyer'
import SelectDeposit from './components/SelectDeposit'
import SelectUpgrades from './components/SelectUpgradeAndOptions'
import SelectPrivacy from './components/SelectPrivacy'
import Fintrac from './components/Fintrac'
import SelectReview from './components/SelectReview'

const offerSteps = [
  { text: 'Unit Details', Icon: <BuildingIcon />, Component: Step1 },
  { text: 'Buyer', Component: SelectBuyer },
  { text: 'Additional Buyers', Component: SelectExtraBuyer },
  { text: 'Parking', Component: SelectParking },
  { text: 'Upgrades and Options', Component: SelectUpgrades },
  { text: 'Deposite Options', Component: SelectDeposit },
  { text: 'Privacy Consent', Component: SelectPrivacy },
  { text: 'Fintrac', Component: Fintrac },
  { text: 'Review', Component: SelectReview }
]

const CreateOffer = () => {
  const [step, setStep] = useState(0)

  const getComponent = useCallback(() => {
    const currentStep = offerSteps[step]
    const Component = currentStep.Component
    return <Component setStep={setStep} key={currentStep.text} />
  }, [step])

  return (
    <CreateOfferProvider>
      <div>
        <Header />
        <div className='px-8 pt-33.25 pb-8'>
          <ProgressSteps
            steps={offerSteps}
            currentStepIndex={step}
            className='w-70 fixed'
          />
          <div className='pl-82.25 w-full'>
            <div
              className='bg-white rounded p-6 grow w-full overflow-auto'
              style={{ maxHeight: 'calc(100vh - 170px)' }}
            >
              {getComponent()}
            </div>
          </div>
        </div>
      </div>
    </CreateOfferProvider>
  )
}

export default CreateOffer
