import { useState, useEffect, useRef } from 'react'
import { getTasks } from 'store/actions/taskActions'

const useGetUserTasks = (_id) => {
  const mountedRef = useRef(true)
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async () => {
    if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getTasks('', `?buyer=${_id}`)
      if (!mountedRef.current) return null
      setTasks(data)
      setLoading(false)
    } catch (err) {
      if (!mountedRef.current) return null
      setLoading(false)
    }
  }

  return { loading, tasks, refetch: getData }
}

export default useGetUserTasks
