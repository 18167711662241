import React, { useEffect, useRef, useState } from 'react'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Oval } from 'react-loader-spinner'

import { Modal } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { createOrUpdateUnit } from 'store/actions/unitActions'
import useGetFloorPlans from 'hooks/useGetFloorPlans'
import useGetUnitGroups from 'hooks/useGetUnitGroups'
import { useSelector } from 'react-redux'
import { isObject } from 'lodash'
import useCurrencyList from 'hooks/useCurrencyList'
import ProjectFileSelector from 'components/ProjectFileSelector'

const AddParkingModal = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  refetch,
  building
}) => {
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const mountedRef = useRef(true)
  const { floorPlans } = useGetFloorPlans()
  const { unitGroups } = useGetUnitGroups()
  const { formattedCurrencyList: currencyData } = useCurrencyList()
  const initialEmptyValues = {
    parkingNumber: '',
    fullAddress: '',
    strataLotNumber: '',
    status: '',
    price: '',
    priceCurrencyType: '',
    unitGroup: '',
    floorPlan: '',
    floorNumber: '',
    numberOfBedrooms: '',
    numberOfBathrooms: '',
    balcony: '',
    building
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const TEMP_AVAILABILITY_STATUS = {
    open: 'Open',
    soldFirm: 'SoldFirm',
    developerHeld: 'DeveloperHeld',
    reserved: 'reserved',
    reservedBroker: 'ReservedBroker',
    reservedBuyer: 'ReservedBuyer',
    closed: 'Closed',
    underContract: 'UnderContract',
    offerAccepted: 'OfferAccepted'
  }

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const balconyOptions = [
    { id: 'true', value: 'Yes' },
    { id: 'false', value: 'No' }
  ]

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        // @ts-ignore
        _id: value?._id ?? '',
        parkingNumber: value.parkingNumber,
        fullAddress: value.fullAddress,
        strataLotNumber: value.strataLotNumber,
        status: value.status,
        price: value.price,
        priceCurrencyType: value.priceCurrencyType,
        unitGroup: isObject(value.unitGroup)
          ? value.unitGroup?._id
          : value.unitGroup,
        floorPlan: isObject(value.floorPlan)
          ? value.floorPlan?._id
          : value.floorPlan,
        floorNumber: value.floorNumber,
        numberOfBedrooms: value.numberOfBedrooms,
        numberOfBathrooms: value.numberOfBathrooms,
        balcony: value.balcony ?? false,
        building: isObject(value.building)
          ? value.building?._id
          : value.building,
        project: appProject,
        contractPdf: value.contractPdf ?? ''
      }
      createOrUpdateUnit(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setShow(false)
          setEditDetails(data)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setShow(false)
    setEditDetails({})
  }

  const validate = () => {
    for (const key in initialEmptyValues) {
      if (!value[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!mountedRef.current) return null
    if (editDetails?.type === 'Units') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        balcony: editDetails.balcony ? 'true' : 'false'
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleContractPDFChange = (contractObject) => {
    onChange('contractPdf', contractObject._id ?? contractObject.id)
  }

  const handleCurrencyChange = (id) => {
    const temp = currencyData.filter((option) => option.id === id)[0]
    onChange('priceCurrencyType', temp.id)
  }

  const handleBalconyChange = (id) => {
    const temp = balconyOptions.filter((option) => option.id === id)[0]
    onChange('balcony', temp.id)
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div
        className='font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 rounded shadow-lg'
        style={{ width: 1015 }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-center p-0 m-0 mb-6'>
          {!value?._id ? 'Add' : 'Update'} Parking
        </h1>
        <div className='grid grid-cols-2 gap-6 mb-6'>
          <CustomInput
            label='Name *'
            placeholder='Enter name'
            labelClassName='italic'
            value={value.parkingNumber}
            onChange={(e) => {
              onChange('parkingNumber', e.target.value)
            }}
            required='required'
            errorMessage='Please enter name'
            containerClasses={undefined}
            classes={undefined}
          />
          <CustomInput
            label='parking address *'
            placeholder='Enter address'
            labelClassName='italic'
            value={value.fullAddress}
            onChange={(e) => {
              onChange('fullAddress', e.target.value)
            }}
            required='required'
            errorMessage='Please enter parking address'
            containerClasses={undefined}
            classes={undefined}
          />
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='strata lot number *'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.strataLotNumber}
              onChange={(e) => {
                onChange('strataLotNumber', e.target.value)
              }}
              required='required'
              errorMessage='Please enter lot number'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='status *'
              inputClasses='h-11'
              labelClasses={undefined}
              options={STATUS_OPTIONS}
              selectedOption={value.status}
              setSelectedOption={(option) => {
                handleStatusChange(option)
              }}
            />
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='price *'
              placeholder='Enter amount'
              labelClassName='italic'
              value={value.price}
              onChange={(e) => {
                onChange('price', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter price'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='currency *'
              inputClasses='h-11'
              options={currencyData}
              selectedOption={value.priceCurrencyType}
              setSelectedOption={(option) => {
                handleCurrencyChange(option)
              }}
              labelClasses={undefined}
            />
          </div>
          <CustomSelect
            label='parking group *'
            inputClasses='h-11'
            labelClasses={undefined}
            options={unitGroups}
            selectedOption={
              // @ts-ignore
              isObject(value.unitGroup) ? value.unitGroup?._id : value.unitGroup
            }
            toIterateField='name'
            setSelectedOption={(newValue) => {
              onChange('unitGroup', newValue)
            }}
          />
          <CustomSelect
            label='floor plan *'
            options={floorPlans}
            inputClasses='h-11'
            selectedOption={
              // @ts-ignore
              isObject(value.floorPlan) ? value.floorPlan?._id : value.floorPlan
            }
            toIterateField='name'
            setSelectedOption={(newValue) => {
              onChange('floorPlan', newValue)
            }}
            labelClasses={undefined}
          />
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='floor number *'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.floorNumber}
              onChange={(e) => {
                onChange('floorNumber', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter floor number'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomInput
              label='number of bedrooms *'
              value={value.numberOfBedrooms}
              onChange={(e) => {
                onChange('numberOfBedrooms', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter number of bedrooms'
              containerClasses={undefined}
              classes={undefined}
              placeholder={undefined}
            />
          </div>
          <div className='grid grid-cols-2 gap-6'>
            <CustomInput
              label='number of bathrooms *'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.numberOfBathrooms}
              onChange={(e) => {
                onChange('numberOfBathrooms', e.target.value)
              }}
              type='number'
              required='required'
              errorMessage='Please enter number of bathrooms'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='balcony *'
              labelClasses='italic'
              inputClasses='h-11'
              options={balconyOptions}
              setSelectedOption={(value) => {
                handleBalconyChange(value)
              }}
              selectedOption={value.balcony}
            />
          </div>
          <ProjectFileSelector
            title={'Contract'}
            onSuccess={(selectedFileData) => {
              handleContractPDFChange(JSON.parse(selectedFileData))
            }}
          />
        </div>

        <button
          className='py-3 px-6 text-base bg-volt rounded font-medium m-auto flex gap-2 items-center'
          disabled={!validate() || loading}
          onClick={handleSubmit}
        >
          {loading && <Oval height='18' width='18' color='black' />}
          <div className='italic'>{value?._id ? 'Update' : 'Add'} Parking</div>
        </button>
      </div>
    </Modal>
  )
}

export default AddParkingModal
