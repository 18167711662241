import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getFlatFileToken = () => {
  return new Promise((resolve, reject) => {
    api.get('parkings/import/flatfile/token').then((response) => {
      if (response.ok) {
        resolve(response.data.token)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getParkings = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`parkings/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateParking = (props) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = props

    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id ? `parkings/${_id}` : 'parkings'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(
          `${props.parkingNumber} was ${
            _id ? 'successfully updated' : 'created successfully'
          }`
        )
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteParking = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/parkings/${id}`).then((response) => {
      if (response.ok) {
        message.success('Parking deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectParkings = (projectId, isPublicPage = false) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `parkings/project/${projectId}?sortBy=parkingNumber&orderBy=asc&isPublicPage=${isPublicPage}`
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data.data)
        } else {
          ApiErrors(response)
        }
      })
  })

export const reserveParkingToBuyer = (parkingId, buyerList) =>
  new Promise((resolve, reject) => {
    api
      .put(`parkings/${parkingId}/reservation`, {
        buyer: buyerList
      })
      .then((response) => {
        if (response.ok) {
          message.success('Parking reserved successfully')
          resolve()
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const unreserveParking = (parkingId) =>
  new Promise((resolve, reject) => {
    api.put(`parkings/${parkingId}/reservation/cancel`).then((response) => {
      if (response.ok) {
        message.success('Parking unreserved successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReservedParkings = (buyerId) =>
  new Promise((resolve, reject) => {
    api.get(`parkings/reserved/${buyerId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectParkingGroups = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`parkings/project/${projectId}/parking-group`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const reserveParkingsToBroker = (
  brokerList,
  parkings,
  brokerMaxReservationDays
) =>
  new Promise((resolve, reject) => {
    const params = {
      broker: brokerList,
      parkings,
      brokerMaxReservationDays
    }

    api.put('parkings/broker/reservation', params).then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getParkingsCSV = ({ buildingId }) =>
  new Promise((resolve, reject) => {
    api.get(`parkings/download/csv?building=${buildingId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const downloadParkingsCSV = async (buildingId) => {
  try {
    const result = await getParkingsCSV({ buildingId })
    const buffer = Buffer.from(result)
    const blob = new Blob([buffer])
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    // a.style = "display: none"
    a.href = url
    a.download = buildingId + '-parkings.csv'
    a.click()
    window.URL.revokeObjectURL(url)
  } catch (e) {
    console.error('downloadParkingsCSV Error', buildingId, e)
  }
}
