import React, { useCallback, useEffect, useState } from 'react'
import { getConversationMessages, sendConversationMessage } from 'store/actions/usersActions'
import { subscribeToChannel, unsubscribeToChannel, bindChannel, unbindChannel } from '../../services/pusher'

const MessageItem = ({ msgID, author, Author, body, Body, dateCreated, DateCreated, participantSid, ParticipantSid }) => {
  if (!body?.length && !Body?.length) return null

  if (participantSid?.length || ParticipantSid?.length) {
    // replies
    return (
      <li className='bg-white shadow px-4 py-3 sm:rounded-lg p-2 mr-16' key={msgID} id={msgID}>
        <div className='sm:flex sm:items-baseline sm:justify-between'>

          <h3 className='text-base font-medium'>
            <span className='text-gray-900'>{author || Author}</span>
          </h3>
          <p
            className='mt-1 whitespace-nowrap text-sm text-gray-600'
          >
            <time dateTime='2021-01-28T19:24'>{new Date(dateCreated || DateCreated).toLocaleString()}</time>
          </p>
        </div>
        <div className='mt-4 space-y-6 text-sm text-gray-800'>
          <p>{body || Body}</p>
        </div>
      </li>
    )
  } else {
    // my messages
    return (
      <li className='bg-slate-200 px-4 py-3 shadow sm:rounded-lg sm:px-6 ml-16'>
        <div className='sm:flex sm:items-baseline sm:justify-between'>
          <p
            className='mt-1 whitespace-nowrap text-sm text-gray-600 sm:mt-0 sm:ml-3'
          >
            <time dateTime='2021-01-28T19:24'>{new Date(dateCreated || DateCreated).toLocaleString()}</time>
          </p>
          <h3 className='text-base font-medium'>
            <span className='text-gray-900'>{author || Author}</span>
          </h3>
        </div>
        <div className='mt-4 space-y-6 text-sm text-gray-800 text-right'>
          <p>{body || Body}</p>
        </div>
      </li>
    )
  }
}

const MessageConversation = ({ contact }) => {
  const [loading, setLoading] = useState(true)
  const [messages, setMessages] = useState([])
  const [msg, setMsg] = useState('')

  const onNewMessagesCallback = useCallback((newMessage) => {
    setMessages([...messages, { ...newMessage, msgID: newMessage.MessageSid }])
  }, [messages, setMessages])

  useEffect(() => {
    if (contact?.id) {
      getConversationMessages({ userId: contact?.id })
        .then((data) => {
          const updateData = data?.map((msg) => {
            return { ...msg, msgID: msg.sid }
          })
          setMessages([...updateData])
        })
        .catch(e => {
          console.error(e)
        }).finally(() => {
          setLoading(false)
        })
    }
  }, [contact?.id])

  useEffect(() => {
    let smsChannel = null
    if (contact?.id) {
      smsChannel = subscribeToChannel(contact?.id)
      if (smsChannel) {
        bindChannel(smsChannel, onNewMessagesCallback)
      }
    }
    return () => {
      if (contact?.id) {
        if (smsChannel) {
          unbindChannel(smsChannel, onNewMessagesCallback)
        }
        unsubscribeToChannel(contact?.id)
      }
    }
  }, [contact?.id, onNewMessagesCallback])

  const _sendMessage = (msg) => {
    sendConversationMessage({
      userId: contact?.id,
      message: msg
    }).then((data) => {
      setMessages([...messages, { ...data, msgID: data.sid }])
    }).catch((e) => {
      console.error('sendConversationMessage e', e)
    })
  }

  if (loading) return null

  const submitMsg = (e) => {
    _sendMessage(msg)
    setMsg('')
  }

  return (
    <div className='flex w-full h-[768px]'>
      <div className='flex flex-col flex-1'>
        <ul
          role='list'
          id='messages_list'
          className='space-y-2 flex-1 sm:space-y-4'
        >
          {!messages?.length
            ? <div className='font-openSans flex flex-col justify-center items-center w-full h-40 bg-white rounded'>
              <svg
                width='22'
                height='24'
                viewBox='0 0 18 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM4 9H9V14H4V9Z'
                  fill='#2E2B2E'
                />
              </svg>
              <div className='font-semibold mb-2 mt-4'>You don't have any messages yet</div>
              <div className='text-sm text-softBlack70'>
                {`Start with your first message with ${contact?.firstName ?? '...'}`}
              </div>
            </div>
            : messages?.map((message) => (
              <MessageItem {...message} key={message.msgID} />
            ))}
        </ul>
        <div className='sticky bottom-0'>
          <div className='min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6'>
            <div
              className='flex items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0'
            >
              <div className='w-full'>
                <label htmlFor='search' className='sr-only'>Search</label>
                <div className='relative'>
                  <div
                    className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'
                  ></div>
                  <input
                    id='message'
                    name='message'
                    className='block w-full rounded-md border border-gray-300 bg-white py-2 px-3 text-sm placeholder-gray-500 focus:border-gray-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-500 sm:text-sm'
                    placeholder='message'
                    type='text'
                    value={msg}
                    onChange={(e) => { setMsg(e.target.value) }}
                  />
                </div>
              </div>
              <div>
                <button
                  type='button'
                  onClick={(e) => submitMsg(e)}
                  className='inline-flex ml-2 items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2.5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageConversation
