// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'

import { TFilledPenIcon } from 'components'
import { isJson } from 'utils/isJson'
import { TFilledTrashIcon } from 'components/Icons'
import { deleteNote, createOrUpdateNote } from 'store/actions/notesActions'
import DeleteModal from 'components/DeleteModal'

const NoteCard = ({
  note = '',
  notesList,
  setNotesList,
  setNewCard,
  contact,
  user,
  refetch,
  onFocusOutNewNoteBox,
  onDeleteNewNote
}) => {
  const [newNote, setNewNote] = useState({
    buyer: {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      _id: contact?._id,
      id: contact?._id
    },
    noteType: 'General',
    notes: {
      notes: note ? isJson(note.notes) ? JSON.parse(note.notes).notes : note.notes : '',
      title: note ? isJson(note.notes) ? JSON.parse(note.notes).title : note.notes : ''
    },
    contact: contact._id,
    user: user?._id,
    edit: true
  })

  const textAreaRef = useRef()
  const [deleteNoteId, setDeleteNoteId] = useState(0)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const handleDeleteNote = (id) => {
    if (!id) {
      onDeleteNewNote()
      return
    }
    setDeleteNoteId(id)
    setIsDeleteModal(true)
  }
  const handleEditChange = (note) => {
    const index = notesList.findIndex((_note) => _note._id === note._id)
    if (index === -1) return

    const newNotesList = [...notesList]
    newNotesList[index].edit = true
    setNotesList(newNotesList)
  }
  const handleInputChange = (note, e, inputType) => {
    let notes = {}
    if (note !== '') {
      if (inputType === 'title') {
        notes = {
          title: e.target.value,
          notes: newNote?.notes?.notes
        }
      } else if (inputType === 'notes') {
        notes = {
          title: newNote?.notes?.title,
          notes: e.target.value
        }
      }
      setNewNote({ ...newNote, notes })
    }
  }
  const firstUpdate = useRef(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    onSaveNotes()
    return () => clearTimeout(delayDebounceFn)
  }, [newNote])

  let delayDebounceFn = ''
  const onSaveNotes = () => {
    clearTimeout(delayDebounceFn)
    delayDebounceFn = setTimeout(() => {
      if (newNote?.notes?.notes !== '') {
        const notes = JSON.stringify({
          title: newNote?.notes?.title ?? '',
          notes: newNote?.notes?.notes ?? ''
        })
        let params = {}
        if (note) {
          params = { ...newNote, notes, id: note?._id, _id: note?._id }
        } else {
          params = { ...newNote, notes }
        }
        createOrUpdateNote(params)
          .then((resp) => {
            setIsDeleteModal(false)
            setDeleteLoading(false)
            // refetch()
            setNewCard?.({ ...newNote, _id: resp._id, id: resp?._id })
          })
          .catch(() => { setDeleteLoading(false) })
      }
    }, 1500)
  }

  const deleteTheNote = note => {
    setDeleteLoading(true)
    if (deleteNoteId !== 0) {
      deleteNote(deleteNoteId)
        .then(() => {
          setIsDeleteModal(false)
          setDeleteLoading(false)
          refetch()
          const list = notesList.filter(deleteNoteId !== note._id)

          const newNotesList = [...list]
          setNotesList(newNotesList)
        })
        .catch(() => { setDeleteLoading(false) })
    }

    setDeleteNoteId(0)
  }

  const ref = useOnclickOutside(() => {
    clearTimeout(delayDebounceFn)
    if (newNote?.notes?.notes && note.edit) {
      const notes = JSON.stringify({
        title: newNote?.notes?.title ?? '',
        notes: newNote?.notes?.notes ?? ''
      })
      let params = {}
      if (note) {
        params = { ...newNote, notes, id: note?._id, _id: note?._id }
      } else {
        params = { ...newNote, notes }
      }
      createOrUpdateNote(params)
        .then((resp) => {
          setIsDeleteModal(false)
          setDeleteLoading(false)
          setNewCard?.({ ...newNote, _id: resp._id, id: resp?._id })
          onFocusOutNewNoteBox?.({ ...newNote, _id: resp._id, id: resp?._id, edit: false })
          const index = notesList.findIndex((_note) => _note._id === note._id)
          if (index === -1) return
          const newNotesList = [...notesList]
          newNotesList[index].edit = false
          setNotesList(newNotesList)
        })
        .catch(() => { setDeleteLoading(false) })
    }
  })

  useEffect(() => {
    if (textAreaRef?.current) {
      textAreaRef?.current.focus()

      textAreaRef?.current.addEventListener('input', function () {
        this.style.height = 'auto'
        this.style.height = this.scrollHeight + 'px'
      }, false)

      textAreaRef?.current.addEventListener('focus', function () {
        this.style.height = 'auto'
        this.style.height = this.scrollHeight + 'px'
      }, false)

      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = textAreaRef?.current.scrollHeight + 'px'
    }
  }, [textAreaRef?.current])

  return (
    <div
      ref={ref}
      className='relative group flex justify-between font-light font-openSans text-softBlack italic items-center py-4 border text-base border-softBlack-10 rounded bg-white'>
      <div className='flex-1'>
        <div className="font-openSans text-softBlack"><input type='text' name="title" className='italic bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none' defaultValue={newNote?.notes?.title} onChange={e => handleInputChange(note, e, 'title')} placeholder={'Add Title'} disabled={!note.edit} /></div>
        <div className="font-openSans text-softBlack"><textarea ref={textAreaRef} name="note" className='bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none w-full resize-none overflow-auto' cols='70' defaultValue={newNote?.notes?.notes} placeholder={'Type your note here ...'} onChange={e => handleInputChange(note, e, 'notes')} disabled={!note.edit} /></div>
      </div>
      <div className='flex justify-end'>
        {
          !note?.edit && (
            <button className='px-4' onClick={e => handleEditChange(note)}>
              <TFilledPenIcon className='w-4 h-4 text-softBlack_70' />
            </button>
          )}
        <button
          className='px-4'
          onClick={() => handleDeleteNote(note._id ?? null)}>
          <TFilledTrashIcon className='w-4 h-4 text-softBlack_70' />
        </button>
      </div>
      <DeleteModal
        title="Confirm deletion?"
        description="Are you sure to delete this note?"
        confirmButtonTitle="Yes"
        cancelButtonTitle="No"
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={deleteTheNote}
        loading={deleteLoading}
      />
    </div>
  )
}

export default NoteCard
