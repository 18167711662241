import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'ui'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { sendEmail } from 'store/actions/usersActions'
import CustomInput from 'components/CustomInput'
import RichTextField from 'components/RichTextField'
import Button from 'components/Buttons'

import { COLORS } from 'pages/pwaMode/createOfferNew/constants/colors'
import { emailTemplates } from './emailTemplates'

import { isMobile } from 'react-device-detect'
import { EMAIL_ATTACHMENT_SVG } from 'utils/emailUtils'
import { AddUserEmailInput } from './AddUserEmailInput'
import CustomModal from 'components/Modal'
import CategoryButton from 'components/AdminSideBar/CategoryButton'
import useGetAllUserEmailContacts from 'hooks/useGetAllUserEmailContacts'

const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const LANGUAGE_TYPE = [
  {
    title: 'English',
    key: 'english',
    value: 'english'
  },
  {
    title: 'Spanish',
    key: 'spanish',
    value: 'spanish'
  }
]

const initialEmptyValues = {
  from: '',
  to: '',
  cc: '',
  bcc: '',
  subject: '',
  body: ''
}
export const EmailModal = ({
  show,
  handleClose,
  contact,
  onEmailSent = () => {}
}) => {
  const { contacts, loading: isContactLoading } = useGetAllUserEmailContacts()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const userType = userObject?.userType
  const [emailData, setEmailData] = useState({
    ...initialEmptyValues,
    to: [contact?.email]
  })
  const [showConfirmSendingEmailModal, setShowConfirmSendingEmailModal] =
    useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [fileAttachments, setFileAttachments] = useState([])
  const [activeLanguageTab, setActiveLanguageTab] = useState('english')
  const [templates, setTemplates] = useState([...emailTemplates.english])
  const [showTemplates, setShowTemplates] = useState(false)

  const onChange = (fieldName, value) => {
    setEmailData({ ...emailData, [fieldName]: value })
  }

  const onCancelModal = useCallback(() => {
    setShowConfirmSendingEmailModal(false)
  }, [])

  useEffect(() => {
    if (contact?.email) {
      setEmailData((_emailData) => {
        return { ..._emailData, to: [contact?.email] }
      })
    }
  }, [contact?.email])

  useEffect(() => {
    if (!show) setEmailData({ ...initialEmptyValues })
  }, [show])

  const defaultStyles = useCallback((userType, isMobile) => {
    return {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '4999',
        border: 'none',
        padding: '0',
        maxHeight: '95%',
        minWidth: isMobile ? '98%' : userType === 'SalesRep' ? '90%' : '37%',
        maxWidth: isMobile ? '98%' : userType === 'SalesRep' ? '90%' : '37%'
      }
    }
  }, [])

  const mapEmail = (emailList) => {
    if (!emailList?.length) return []
    const filteredEmails = emailList?.filter((email) => email.match(validRegex))
    if (!filteredEmails?.length) return []
    const result =
      filteredEmails?.map((email) => {
        return {
          name: '',
          email
        }
      }) ?? []
    return result
  }

  const _sendEmail = () => {
    setSendingEmail(true)
    setShowConfirmSendingEmailModal(false)
    const emailBody = emailData?.body?.replace(/<p><br \/><\/p>/g, '')
    sendEmail(
      emailBody,
      emailData.subject,
      mapEmail(emailData.to),
      fileAttachments,
      mapEmail(emailData.cc),
      mapEmail(emailData.bcc)
    )
      .then(() => {
        setSendingEmail(false)
        handleCloseModal()
        onEmailSent?.()
      })
      .catch(() => setSendingEmail(false))
  }

  const handleBodyChange = (value) => {
    setEmailData((data) => ({ ...data, body: value }))
  }

  const handleSendEmail = () => {
    if (!emailData.subject) {
      setShowConfirmSendingEmailModal(true)
    } else {
      _sendEmail()
    }
  }

  const handleUseTemplate = (template) => {
    setEmailData((data) => ({
      ...data,
      body: template.text,
      subject: template.subject ?? ''
    }))
  }

  const handleCloseModal = () => {
    handleClose()
    setEmailData((data) => ({ ...data, body: '', subject: '' }))
  }

  const handleLanguageTemplateChange = (language) => {
    setActiveLanguageTab(language)
    setTemplates(emailTemplates[language])
  }

  const TemplatesSection = () => (
    <>
      {templates.map((template, index) => (
        <div className='flex flex-col'>
          <div className='text-softBlack30 italic mb-1'>Number {index + 1}</div>
          <textarea disabled className='w-full max-h-96' rows={28}>
            {template.text.replace(
              /<p style="margin-bottom: 0px;margin-top: 0px;"><br \/><\/p>/g,
              ''
            )}
          </textarea>
          <Button
            buttonClasses='font-openSans mt-2 mx-auto w-32 mb-10'
            label='Use this template'
            type='Primary'
            textColor={COLORS.softBlack}
            buttonBorder={`1px solid ${COLORS.volt}`}
            fontWeight='300'
            labelSize='16px'
            labelFontStyle='italic'
            buttonWidth='150px'
            state={sendingEmail ? 'Disabled' : 'Default'}
            handleClick={() => {
              handleUseTemplate(template)
              isMobile && setShowTemplates(false)
            }}
          />
        </div>
      ))}
    </>
  )

  const Templates = () => {
    if (userType === 'SalesRep' && (!isMobile || (isMobile && showTemplates))) {
      return (
        <>
          <div
            style={{ flex: isMobile && showTemplates ? 1 : 0.6 }}
            className='flex flex-col overflow-x-hidden overflow-y-auto bg-grey-8 p-8 rounded'
          >
            {isMobile && (
              <div
                onClick={() => setShowTemplates(false)}
                className='absolute top-2 left-2 bg-volt p-2 rounded cursor-pointer'
              >
                Back
              </div>
            )}
            <h1 className='font-bold text-xl text-center p-0 m-0 font-openSans tracking-wider'>
              Templates
            </h1>
            <div className='w-full border-1.5 border-softBlack flex rounded-sm h-9.5 mt-2'>
              {LANGUAGE_TYPE.map((_value) => (
                <CategoryButton
                  key={_value.title}
                  category={_value}
                  selected={activeLanguageTab === _value?.value}
                  setSelectedCategory={handleLanguageTemplateChange}
                >
                  {_value.title}
                </CategoryButton>
              ))}
            </div>
            <TemplatesSection />
          </div>
          <div style={{ flex: 0.03, background: 'transparent' }}></div>
        </>
      )
    }
    return null
  }

  const handleAppendBookingLink = () => {
    let emailBody = emailData.body
    emailBody =
      emailBody +
      `<a hred={${userObject?.salesRepData?.bookingLink}}>${userObject?.salesRepData?.bookingLink}</a>`
    onChange('body', emailBody)
  }

  const handleAppendZoomLink = () => {
    let emailBody = emailData.body
    emailBody =
      emailBody +
      `<a hred={${userObject?.salesRepData?.zoomLink}}>${userObject?.salesRepData?.zoomLink}</a>`
    onChange('body', emailBody)
  }

  return (
    <>
      <CustomModal
        isOpen={show}
        closeModal={handleCloseModal}
        contentLabel='New Email Modal'
        customStyles={{ ...defaultStyles(userType, isMobile) }}
      >
        <div
          className={`flex flex-row ${
            userType === 'SalesRep' ? '' : 'justify-center items-center'
          } font-openSans rounded shadow-lg`}
          style={{ width: '100%', height: isMobile ? '98%' : '80%' }}
        >
          <Templates />
          <div
            style={
              userType === 'SalesRep'
                ? {
                    flex: isMobile ? 1 : 0.4,
                    display: showTemplates && isMobile ? 'none' : 'flex'
                  }
                : { flex: 1, maxWidth: 544 }
            }
            className='flex relative bg-grey-8 overflow-x-hidden overflow-y-auto flex-col p-8 rounded'
          >
            <button className='absolute right-10' onClick={handleCloseModal}>
              <CrossIcon />
            </button>
            <h1
              className={`font-bold ${
                isMobile ? 'text-lg' : 'text-xl '
              } text-center p-0 m-0 font-openSans tracking-wider`}
            >
              New Email
            </h1>
            {isMobile && userType === 'SalesRep' && (
              <Button
                buttonClasses={`font-openSans ${
                  isMobile ? '' : 'mt-5 p-2'
                } rounded whitespace-nowrap mx-auto w-32`}
                label='Show Templates'
                type='Primary'
                textColor={COLORS.softBlack}
                buttonBorder={`1px solid ${COLORS.volt}`}
                fontWeight='300'
                labelSize='16px'
                labelFontStyle='italic'
                buttonWidth='125px'
                state={sendingEmail ? 'Disabled' : 'Default'}
                handleClick={() => setShowTemplates(true)}
              />
            )}
            <div className='pt-4 flex flex-col gap-3'>
              <AddUserEmailInput
                contacts={contacts}
                loading={isContactLoading}
                label='To'
                selectedEmails={emailData.to?.length ? emailData.to[0] : null}
                singleSelection
                setSelectedEmails={(_val) => {
                  onChange('to', [_val])
                }}
                placeholder='Enter Receivers Email (use comma for multiple values)'
              />
              <AddUserEmailInput
                contacts={contacts}
                loading={isContactLoading}
                label='CC'
                selectedEmails={emailData.cc}
                setSelectedEmails={(_val) => {
                  onChange('cc', _val)
                }}
                placeholder='Enter CC Email (use comma for multiple values)'
              />
              <AddUserEmailInput
                contacts={contacts}
                loading={isContactLoading}
                label='BCC'
                selectedEmails={emailData.bcc}
                setSelectedEmails={(_val) => {
                  onChange('bcc', _val)
                }}
                placeholder='Enter BCC Email (use comma for multiple values)'
              />
              <CustomInput
                label='Subject'
                placeholder='Enter Subject'
                value={emailData.subject}
                onChange={(e) => {
                  onChange('subject', e.target.value)
                }}
                required
                containerClasses={undefined}
                classes={undefined}
              />
              <div
                className='relative'
                style={{
                  height: '560px',
                  marginBottom: isMobile ? 20 : undefined,
                  overflow: ''
                }}
              >
                {userType === 'SalesRep' && (
                  <>
                    {userObject?.bookingLink && (
                      <div
                        onClick={handleAppendBookingLink}
                        style={{ top: '30px', backgroundColor: '#85878F' }}
                        className='bg-grey-9 text-white p-1 rounded absolute right-0 cursor-pointer'
                      >
                        BOOKING LINK
                      </div>
                    )}
                    {userObject?.zoomLink && (
                      <div
                        onClick={handleAppendZoomLink}
                        style={{
                          top: '30px',
                          right: '110px',
                          backgroundColor: '#85878F'
                        }}
                        className=' text-white p-1 rounded absolute cursor-pointer'
                      >
                        ZOOM LINK
                      </div>
                    )}
                  </>
                )}
                <p
                  className={
                    'mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase'
                  }
                  style={{ letterSpacing: '0.16px' }}
                >
                  EMAIL TEXT
                </p>

                <RichTextField
                  onChangeText={handleBodyChange}
                  text={emailData?.body}
                  placeholder={'Type text here...'}
                  onChangeFileAttachments={(attachments) =>
                    setFileAttachments(attachments)
                  }
                />
              </div>

              <Button
                buttonClasses='font-openSans mx-auto w-32 mt-16'
                label='Send Email'
                type='Primary'
                textColor={COLORS.softBlack}
                buttonBorder={`1px solid ${COLORS.volt}`}
                fontWeight='300'
                labelSize='16px'
                labelFontStyle='italic'
                buttonWidth='125px'
                state={sendingEmail ? 'Disabled' : 'Default'}
                handleClick={handleSendEmail}
              />
            </div>
          </div>
        </div>
      </CustomModal>
      <Modal
        showModal={showConfirmSendingEmailModal}
        title='Confirm sending the email?'
        subtitle="your email doesn't seem to have any subject, would you like to send it anyway?"
        modalType='alert'
        onOk={_sendEmail}
        onCancel={onCancelModal}
      />
    </>
  )
}

export const EmailAttachmentSVGIcon = (contentType) => {
  const Component =
    typeof contentType === 'string'
      ? EMAIL_ATTACHMENT_SVG[contentType] ?? EMAIL_ATTACHMENT_SVG.default
      : EMAIL_ATTACHMENT_SVG.default
  return <Component className='w-10 h-10' />
}

export default EmailModal
