import React, { useContext } from 'react'
import { MdModeEdit } from 'react-icons/md'
import { useFormikContext } from 'formik'
import { CreateOpportunityContext } from 'pages/opportunity/context/CreateOpportunityContext'
import { STEP } from 'pages/opportunity/steps'
import FormSelect from '../FormSelect/FormSelect'
import { needParkings, needsFinancings, opportunityRatings, opportunityStages, testOptions } from '../fieldData'
import FormInput from '../FormInput/FormInput'

const Form = () => {
  const { setStep } = useContext(CreateOpportunityContext)
  const { handleSubmit, handleChange } = useFormikContext()
  return (
        <div className='w-auto flex-1 h-full bg-grey-8 overflow-auto'>
            <div className='w-full'>
                <div className='w-full p-4'>
                    <div className='w-full flex flex-row justify-between items-center border-b-2 border-black p-4'>
                        <div className='text-xl font-bold'>Create Opportunity</div>
                        <div className='flex flex-row gap-2'>
                            <div onClick={() => setStep(STEP.ONE)} className='cursor-pointer p-2 text-md'>Cancel</div>
                            { // @ts-ignore
                                <div onClick={handleSubmit} className='cursor-pointer p-2 text-md bg-volt rounded flex flex-row gap-2 justify-center items-center'><MdModeEdit />Create Opportunity</div>}
                        </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormInput title='OPPORTUNITY NAME*' fieldName='name' placeholder='Opportunity Name' />
                    <FormSelect title='OPPORTUNITY STAGE*' fieldName='stage' options={opportunityStages} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 gap-4'>
                    <FormInput title='BUYER NAME' placeholder='Buyer Name' fieldName='user' />
                    <FormSelect title='OPPORTUNITY RATING*' fieldName='rating' options={opportunityRatings} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 gap-4'>
                    <div className='flex flex-1 flex-col justify-left'>
                        <div className='font-bold text-softBlack_70 italic'>Opportunity Notes</div>
                        <textarea onChange={handleChange('notes')} className='rounded resize-none' rows={4} placeholder='Enter your notes here'></textarea>
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <div className='w-full p-4'>
                    <div className='w-full flex flex-row justify-left items-center border-b-2 border-black p-4'>
                        <div className='text-xl font-bold'>Unit Interest</div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='1ST CHOICE UNIT INTEREST' fieldName='firstChoiceUnit' placeholder='#12021' options={testOptions} />
                    <FormSelect title='FLOOR NUMBERS' fieldName='floorNumbersInterest' placeholder='Select Rating' multi options={testOptions} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='2ND CHOICE UNIT INTEREST' fieldName='secondChoiceUnit' placeholder='New Opportunity' options={testOptions} />
                    <FormSelect title='ORIENTATION' fieldName='orientation' placeholder='Select Rating' options={testOptions} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='3RD CHOICE UNIT INTEREST' fieldName='thirdChoiceUnit' placeholder='New Opportunity' options={testOptions} />
                    <FormSelect title='FLOOR PLANS' fieldName='floorPlansInterest' placeholder='Select Rating' multi options={testOptions} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='UPGRADES' fieldName='upgrades' placeholder='Select Many' multi options={testOptions} />
                    <FormSelect title='NEED PARKING' placeholder='Select Option' fieldName='needParking' options={needParkings} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='MIN SQUARE FEET' placeholder='500SF' fieldName='minimumSquareFeet' options={testOptions} />
                    <FormSelect title='MAX SQUARE FEET' placeholder='2500SF' fieldName='maximumSquareFeet' options={testOptions} />
                </div>
                <div className='w-full p-4'>
                    <div className='w-full flex flex-row justify-left items-center border-b-2 border-black p-4'>
                        <div className='text-xl font-bold'>Budget, Timing & Financing </div>
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='BUDGET' placeholder='500K - 1M' fieldName='budget' options={testOptions} />
                    <FormSelect title='PRIMARY USE' fieldName='primaryUse' placeholder='owner ocupier' multi options={testOptions} />
                </div>
                <div className='flex flex-row justify-center items-center flex-wrap p-4 pb-0 gap-4'>
                    <FormSelect title='Need Financing?' placeholder='Select Option' fieldName='needsFinancing' options={needsFinancings} />
                    <FormSelect title='PRIMARY USE' fieldName='primaryUse' placeholder='owner ocupier' multi options={testOptions} />
                </div>
                <div className='flex flex-row justify-end items-center flex-wrap p-4 pb-0 gap-4'>
                    <div className='cursor-pointer p-2 text-md bg-volt rounded flex flex-row gap-2 justify-center items-center'><MdModeEdit />Save</div>
                </div>
            </div>
        </div>
  )
}

export default Form
