import React from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { changeAppProject } from 'store/actions/appActions'
import LeftPanel from 'components/LeftPanel'

const AdminSideMenu = (props) => {
  return (
    <>
      <LeftPanel {...props} />
    </>
  )
}

const mapStateToProps = state => ({
  userObject: state.authReducer.userObject,
  appProject: state.appReducer.appProject,
  listOfProjects: state.appReducer.listOfProjects
})

const mapDispatchToProps = {
  changeAppProject
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminSideMenu)

AdminSideMenu.propTypes = {
  listOfProjects: propTypes.array,
  changeAppProject: propTypes.func
}
