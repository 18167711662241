import { PageHeader, TFilledPlusIcon } from 'components'
import CategoryButton from 'components/AdminSideBar/CategoryButton'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ADMIN_CREATE_EVENT_PAGE } from 'routes'
import { getEvents, getImage } from 'store/actions/eventsActions'

const Button = (props) => {
  return (
    <button
      className='text-softBlack text-center text-base font-medium py-1.5 px-2 rounded flex items-center bg-volt'
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  )
}

const EventDashboard = (props) => {
  const currentDate = new Date().getTime()
  const [allEvents, setAllEvents] = useState({})
  const EVENT_CATEGORY = [
    {
      title: 'Upcoming',
      key: 'upcoming',
      value: 'upcoming'
    },
    {
      title: 'Past',
      key: 'past',
      value: 'past'
    },
    {
      title: 'Archived',
      key: 'archived',
      value: 'archived'
    }
  ]
  const [selectedCategory, setSelectedCategory] = useState(
    EVENT_CATEGORY[0].value
  )
  const history = useHistory()

  useEffect(() => {
    getEvents().then((response) => {
      setAllEvents({
        upcoming: response.filter((elm) => {
          var date = new Date(elm.endDate).getTime()
          return date > currentDate
        }),
        past: response.filter((elm) => {
          var date = new Date(elm.endDate).getTime()
          return date < currentDate
        }),
        archived: []
      })
    })
  }, [])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <Button onClick={() => history.push(ADMIN_CREATE_EVENT_PAGE)} >
        <TFilledPlusIcon className='h-6 w-3' />
        Add New Events
      </Button>
    </div>
  )

  const EventCard = (event) => {
    const [heroImage, setHeroImage] = useState()
    const [date, setDate] = useState()

    useEffect(() => {
      if (event) {
        var startTime = new Date(event.startDate)
        var endTime = new Date(event.endDate)
        var formattedStartDate = startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        var formattedEndDate = endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
        setDate(formattedStartDate + ' - ' + formattedEndDate)
        if (event.heroImage) {
          getImage(event.heroImage).then((res) => setHeroImage(res)).catch(() => setHeroImage())
        }
      }
    }, [event])

    return (
      <div
        onClick={() => {
          history.push(`/admin/events/preview/${event._id}`)
        }}
        className="border-[1px] overflow-hidden col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 cursor-pointer relative pb-[24px] rounded-[12px] border-[#2e2b2e]"
      >
        <img
          src="/event-img.png"
          className="w-full h-7/20 objet-cover brightness-75 absolute top-0 -z-1"
        />
        <div className="text-white grid grid-cols-12 items-center gap-3 p-4 w-full">
          <div className='col-span-4 h-full flex justify-center overflow-hidden'>
            <img className='h-full rounded-full object-cover' src={heroImage?.url || '/event-detail-image.png'} alt='Hero image' />
          </div>
          <div className='col-span-7'>
            <div className="font-bold text-[24px] truncate">{event.name}</div>
            <div className="text-[16px] italic truncate">{date}</div>
            <div className="text-[16px] italic truncate">
              {event.startTime + ' - ' + event.endTime}
            </div>
            <div className="flex items-center gap-2">
              <img src="/location.png" />
              <div className='truncate'>{event.locationAddress}</div>
            </div>
          </div>
        </div>
        <div className="px-[10px] pt-[8px]">
          <div className="font-bold mt-3  mb-2 text-[20px]">
            Event Attendee Status
          </div>
          <div className="w-full h-[1px] bg-[#2e2b2e]"></div>
          <div className="flex justify-between mt-3 gap-4">
            <div className="bg-[#f2f2f1] border-[1px] p-4 w-full border-black rounded-[3px]">
              <div className="text-[10px] text-[rgba(46,43,46,0.7)] italic">
                RSVP YES
              </div>
              <div className="text-[13px] text-[#2e2b2e] font-bold">88</div>
            </div>
            <div className="bg-[#f2f2f1] border-[1px] p-4  w-full border-black rounded-[3px]">
              <div className="text-[10px] text-[rgba(46,43,46,0.7)] italic">
                NOT ATTENDING
              </div>
              <div className="text-[13px] text-[#2e2b2e] font-bold">45</div>
            </div>
            <div className="bg-[#f2f2f1] border-[1px] p-4  w-full  border-black rounded-[3px]">
              <div className="text-[10px] text-[rgba(46,43,46,0.7)] italic">
                INVITED
              </div>
              <div className="text-[13px] text-[#2e2b2e] font-bold">300</div>
            </div>
          </div>
          <div className="flex gap-5 w-full  mt-[14px]">
            <button className="bg-[#6FCF97]  items-center flex gap-2 px-9 w-full justify-center py-[10px] rounded-[3px]">
              <img src="/person-grp.png" />
              <div className="font-bold italic text-[14px]">
                Check In Guests
              </div>
            </button>
            <button className="border-[1px] flex gap-2 w-full justify-center items-center px-4 lg:px-8 xl:px-14 py-[10px] border-[rgba(46,43,46,0.7)] rounded-[3px]">
              <img src="/edit-pen-icon.png" />
              <div className="text-[14px] font-light italic">View Event</div>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <PageHeader title='Events' rightContent={<RightContent />} />
      <div className='mx-auto px-4 sm:px-6 lg:px-8 h-full '>
        <div className=" flex flex-col items-center justify-center w-full">
          <div className="w-full container mx-auto flex flex-col mt-6 justify-between">
            <div className="flex w-full justify-between">
              {EVENT_CATEGORY.map((category, index) => (
                <CategoryButton
                  key={category.title}
                  category={category}
                  selected={selectedCategory === category?.value}
                  setSelectedCategory={setSelectedCategory}
                >
                  <div className="py-2 border-[1px] border-black font-bold italic text-[20px]">
                    {category.title}
                  </div>
                </CategoryButton>
              ))}
            </div>
          </div>
          {!allEvents[selectedCategory] && <p className='font-medium text-xl my-8'>Events loading...</p>}
          {allEvents[selectedCategory] && <div className="grid grid-cols-12 justify-between gap-8 flex-wrap my-6 w-full container mx-auto">
            {
              allEvents[selectedCategory].map((event) => {
                return (
                  <EventCard {...event} key={event._id} />
                )
              })
            }
          </div>}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EventDashboard)
