import React from 'react'
import propTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { getRandomInteger } from 'utils'
import { Card } from 'ui'
import { TableActionButtons } from 'components'

const TableCardWrapper = (props) => {
  const {
    loading, title, onEditClick, onDeleteClick,
    id, executeAfterDelete, children
  } = props

  return (
    <Card
      title={loading ? <Skeleton width={getRandomInteger(80, 250)} height={30} /> : title}
      bordered={false}
      extra={
        <TableActionButtons
          onEditClick={() => onEditClick(id)}
          onDeleteClick={onDeleteClick}
          executeAfterDelete={executeAfterDelete}
          id={id}
        />
      }
    >
      {children}
    </Card>
  )
}

export default TableCardWrapper

TableCardWrapper.propTypes = {
  loading: propTypes.bool,
  title: propTypes.string,
  onEditClick: propTypes.func,
  onDeleteClick: propTypes.func,
  id: propTypes.string,
  executeAfterDelete: propTypes.func,
  children: propTypes.node
}
