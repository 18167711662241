// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import TableHead from './components/TableHead'
import TableBody from './components/TableBody'
import TablePagination from './components/TablePagination'
import TableSaveContact from './components/TableSaveContact'
import ContactListCreateModal from './components/ContactListCreateModal'
import TableAddToList from './components/TableAddToList'
import AddToListModal from './components/AddToListModal'
import AssignSalesrep from './components/AssignSalesrep'
import AssignSalesrepModal from './components/AssignSalesrepModal'
import { useSelector } from 'react-redux'

const ContactsTailwindTable = React.memo((props) => {
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const [page, setPage] = useState(1)
  const [open, setOpen] = useState(false)
  const [addToListOpen, setAddToListOpen] = useState(false)
  const [assignSalesrepOpen, setAssignSalesrepOpen] = useState(false)

  const LoadingContacts = () => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title='Loading contacts...' />
    </FadedAnimatedDiv>
  )

  if (props.isLoading) return <LoadingContacts></LoadingContacts>

  return (
    <>
      {open ? <ContactListCreateModal open={open} setOpen={setOpen} {...props} /> : null}
      {addToListOpen ? <AddToListModal open={addToListOpen} setOpen={setAddToListOpen} /> : null}
      {['SuperAdmin', 'DeveloperAdmin', 'SalesRep'].includes(userObject.userType) &&
        <>{assignSalesrepOpen ? <AssignSalesrepModal open={assignSalesrepOpen} setOpen={setAssignSalesrepOpen} /> : null}</>
      }
      <div style={{ paddingRight: '0.6rem' }}>
        <div className='flex flex-row gap-2'>
          <TableSaveContact setOpen={setOpen} {...props} />
          <TableAddToList setOpen={setAddToListOpen} {...props} />
          {['SuperAdmin', 'DeveloperAdmin', 'SalesRep'].includes(userObject.userType) && <AssignSalesrep
            setOpen={setAssignSalesrepOpen}
          />}
        </div>
        <div className='mt-4 flex flex-col'>
          <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
            <div
              style={{ paddingLeft: '2.7rem', paddingRight: '1.9rem' }}
              className='inline-block min-w-full py-2 align-middle'
            >
              <div className='relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                <table className='min-w-full table-fixed divide-y divide-gray-300'>
                  <TableHead page={page} {...props} />
                  <TableBody page={page} {...props} />
                </table>
                <TablePagination page={page} setPage={setPage} {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default ContactsTailwindTable
