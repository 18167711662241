import React from 'react'
import { Select } from 'antd'
import { isMobile } from 'react-device-detect'

const LeadBrokerSelect = ({ userType, broker, setBroker, brokers, marginTop, forceMobile = false }) => (
  userType === 'Lead' && (
        <div
          style={{
            position: 'relative',
            overflow: 'visible',
            marginLeft: isMobile || forceMobile ? '' : '40px',
            marginTop
          }}
        >
          <div
            className='text-softBlack70'
            style={{
              position: 'absolute',
              width: '100%',
              fontSize: '18px',
              fontWeight: '300',
              top: isMobile || forceMobile ? '-25px' : '-20px',
              left: '10px'
            }}
          >
            Broker
          </div>
          <Select
            showSearch={true}
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            value={broker}
            onChange={(value) => setBroker(value)}
            className='rating-select text-sm bg-white border border-softBlack30 h-8 w-48 rounded text-softBlack placeholder-softBlack70 py-1'
          >
            {brokers?.map((_item, index) => {
              return (
                <Select.Option value={_item.id} key={index} label={`${_item.firstName} ${_item.lastName}`}>
                  <div className='flex gap-2 items-center'>
                    <span>
                      {_item.firstName} {_item.lastName}
                    </span>
                  </div>
                </Select.Option>
              )
            })}
          </Select>
        </div>
  )
)

export default LeadBrokerSelect
