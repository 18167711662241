import React, { useCallback, useEffect, useState } from 'react'
import EmailMessagesList from './EmailMessagesList'
import SelectedMailSection from './SelectedMailSection'
import { searchByEmail } from 'store/actions/usersActions'
import ContactCard from '../../pages/dashboard/Sidebar/ContactCard/ContactCard'
import useSidebar from 'hooks/useSidebar'

const EmailsCard = ({ childProps, showUserInfo = false }) => {
  const [selectedContactObject, setSelectedContactObject] = useState(null)
  const { Sidebar, fullScreen, setFullScreen, showPanel, hidePanel } =
    useSidebar()

  useEffect(() => {
    if (childProps?.selectedThreadContent?.senderEmail?.length) {
      setSelectedContactObject(null)
      searchByEmail(childProps?.selectedThreadContent?.senderEmail)
        .then((result) => {
          const userObject = result?.data?.[0]
          if (userObject && Object.keys(userObject)?.length) {
            setSelectedContactObject(userObject)
          }
        })
        .catch((e) => {})
    }
  }, [childProps.selectedThreadContent])

  const onFullScreenClick = useCallback(() => {
    console.log('*** onFullScreenClick: fullScreen', fullScreen)
    if (fullScreen) {
      setFullScreen(false)
      hidePanel()
    } else {
      setFullScreen(true)
      showPanel()
    }
  }, [fullScreen, showPanel, hidePanel])

  const ContactChild = useCallback(() => {
    if (!showUserInfo || !selectedContactObject) {
      return null
    }

    return (
      <>
        <div className='flex flex-col rounded-lg border-2 border-gray-1 p-2 overflow-y-scroll max-h-screen'>
          <button
            className='flex justify-center items-center m-4 py-4 bg-volt rounded gap-2 italic text-black'
            onClick={() => {
              onFullScreenClick()
            }}
          >
            See Full Profile
          </button>
          <ContactCard
            fullScreen={false}
            setSelectedContact={null}
            contact={selectedContactObject}
            tasksList={[]}
            contactId={selectedContactObject?._id}
          />
        </div>
        <Sidebar title={'User Info'} isFullScreen={fullScreen}>
          <div className='flex flex-row gap-2 w-full'>
            <div className='flex flex-row w-full overflow-y-auto rounded-lg border-2 border-gray-1 p-4'>
              <ContactCard
                fullScreen={true}
                setSelectedContact={null}
                contact={selectedContactObject}
                tasksList={[]}
                contactId={selectedContactObject?._id}
              />
            </div>
          </div>
        </Sidebar>
      </>
    )
  }, [showUserInfo, selectedContactObject, fullScreen, Sidebar])

  return (
    <div className='min-h-0 flex-1 flex overflow-hidden h-full w-full'>
      <main className='min-w-0 flex-1 border-t border-gray-200 flex'>
        {childProps.isMobile && childProps.showMessageList && (
          <EmailMessagesList {...childProps} />
        )}
        {!childProps.isMobile && <EmailMessagesList {...childProps} />}
        {childProps.isMobile && !childProps.showMessageList && (
          <SelectedMailSection {...childProps} />
        )}
        {!childProps.isMobile && <SelectedMailSection {...childProps} />}
      </main>
      {ContactChild()}
    </div>
  )
}

export default EmailsCard
