import React, { useCallback, useState } from 'react'
import { Modal } from '@material-ui/core'
import { ImageUploaderPrimary } from 'components/ImageUploader'
import CustomInput from 'components/CustomInput'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import CustomSelect from 'components/CustomSelect'

const PaymentReceipt = ({
  title,
  onClose,
  onSucess,
  projectId,
  userId,
  show,
  onUploadDocument,
  fileTags
}) => {
  const [documentName, setDocumentName] = useState('')
  const [paymentReceiptImage, setPaymentReceiptImage] = useState(null)
  const [selectedTag, setSelectedTag] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  const uploadDocument = useCallback(async () => {
    setIsUploading(true)
    try {
      const data = await onUploadDocument({
        docName: documentName,
        file: paymentReceiptImage,
        projectId,
        userId,
        tag: selectedTag
      })
      onSucess({
        ...data
      })
    } catch (e) {
    } finally {
      setIsUploading(false)
    }
  }, [documentName, paymentReceiptImage])

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby='Private Document Upload Modal'
      aria-describedby='Private Document Edit Modal for uploading document'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-4 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: '512px' }}
      >
        <div className='flex flex-col gap-5 justify-center items-center rounded-2xl'>
          <div className='flex justify-between items-center text-center w-full pt-2'>
            <h1 className='flex h-full text-2xl text-italic text-center p-0 m-0'>
              {title}
            </h1>
            <button onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <div className='flex flex-col gap-3 items-center w-full'>
            <div className='flex flex-col items-start justify-start gap-2 w-full'>
              <div className='w-full'>
                <CustomInput
                  label=''
                  placeholder='Enter name of Document'
                  value={documentName}
                  onChange={(e) => {
                    setDocumentName(e.target.value)
                  }}
                  containerClasses={undefined}
                  classes=''
                  required
                />
              </div>
              <div className='w-full'>
                <CustomSelect
                  label='Select Tag'
                  labelClasses='text-left'
                  inputClasses='h-10 bg-white'
                  selectedOption={selectedTag}
                  options={fileTags}
                  setSelectedOption={(e) => setSelectedTag(e)}
                  defaultValue={undefined}
                  disabled={false}
                />
              </div>
              <div className='w-full'>
                <ImageUploaderPrimary
                  onFileChange={(e) =>
                    setPaymentReceiptImage(e?.currentTarget?.files?.[0] ?? '')
                  }
                  value='Drag & Drop Files Here'
                  disabled={isUploading}
                />
              </div>
            </div>
            <button
              className='flex justify-center items-center font-bold py-4  px-12 bg-volt rounded cursor-pointer'
              onClick={uploadDocument}
              disabled={isUploading}
            >
              Upload Document
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default PaymentReceipt
