import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { PlusIcon } from '@heroicons/react/solid'
import propTypes from 'prop-types'
import { AdminPageWrapper, PageHeader } from 'components'
import { Modal, Table, TButton } from 'ui'
import { deleteContractTemplate, getProjectContractTemplates } from 'store/actions/contractTemplateActions'
import ContractTeplateSideBar from './contractTemplateSideBar'
import { useSelector } from 'react-redux'

// import {} from './styles'

const contractTemplatesColumns = [
  {
    Title: 'Name',
    accessor: 'name'
  },
  {
    Title: 'Template Number',
    accessor: 'templateNumber'
  }
]

const RightContent = memo(({ onClickNewContract }) => (
  <div className='flex flex-row items-center'>
    <TButton
      onClick={onClickNewContract}
      className='ml-3 my-4'
    >
      <PlusIcon className='h-5 w-5 text-white' />
      New Contract Template
    </TButton>
  </div>
))

const ContractTemplatesTable = memo(({
  dataSource, onClickViewEdit, onClickDelete, loading, deleting, idBeingDeleted
}) => (
  <Table
    tailwindTable
    columns={contractTemplatesColumns}
    dataSource={dataSource}
    className='mt-5'
    onViewEditClick={({ original }) => onClickViewEdit(original)}
    onDeleteClick={({ original }) => onClickDelete(original)}
    loading={loading}
    deleting={deleting}
    idBeingDeleted={idBeingDeleted}
  />
))

const CustomContractTemplateSideBar = memo(({ showContractTemplateSider, onCancelClick, _id, executeAfterSave }) => (
  <ContractTeplateSideBar
    showContractTemplateSider={showContractTemplateSider}
    onCancelClick={onCancelClick}
    executeAfterSave={executeAfterSave}
    _id={_id}
  />
))

const DeleteContractModal = memo(({ showModal, onCancel, onOk, contractName }) => (
  <Modal
    modalType='danger'
    showModal={showModal}
    onCancel={onCancel}
    onOk={onOk}
    title='Confirm removing template?'
    subtitle={`${contractName} will be removed permanently!`}
  />
))

const ContractTemplates = () => {
  const mountedRef = useRef(true)
  const [gettingContractTemplates, setGettingContractTemplates] = useState(false)
  const [contractTemplates, setContractTemplates] = useState([])
  const [showContractTemplateSider, setShowContractTemplateSider] = useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = useState('')
  const [selectedTemplateName, setSelectedTemplateName] = useState('')
  const [showDeleteContractModal, setShowDeleteContractModal] = useState(false)
  const [deletingTemplate, setDeletingTemplate] = useState(false)
  const _id = useSelector((state) => state.appReducer.appProject)

  const getTemplates = useCallback(() => {
    if (!mountedRef.current) return null
    setGettingContractTemplates(true)

    getProjectContractTemplates(_id)
      .then((contractTemplates) => {
        if (!mountedRef.current) return null
        setContractTemplates(contractTemplates)
        setGettingContractTemplates(false)
      })
      .catch(() => setGettingContractTemplates(false))
  }, [])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    getTemplates()
  }, [getTemplates])

  const onClickNewContract = useCallback(() => {
    setSelectedTemplateId('')
    setShowContractTemplateSider(true)
  }, [])

  const onEditContractTemplate = useCallback((contract) => {
    setSelectedTemplateId(contract._id)
    setShowContractTemplateSider(true)
  }, [])

  const prepareToDeleteTemplate = useCallback(({ name, _id }) => {
    setSelectedTemplateId(_id)
    setSelectedTemplateName(name)

    setShowDeleteContractModal(true)
  }, [])

  const onDeleteTemplate = useCallback(async () => {
    setDeletingTemplate(true)
    setShowDeleteContractModal(false)

    try {
      await deleteContractTemplate(selectedTemplateId)
      getTemplates()
    } catch (e) {

    }

    setSelectedTemplateId('')
    setSelectedTemplateName('')
    setDeletingTemplate(false)
  }, [getTemplates, selectedTemplateId])

  const onCancelSideBar = useCallback(() => {
    setSelectedTemplateId('')
    setShowContractTemplateSider(false)
  }, [])

  const executeAfterSave = useCallback((contractTemplateParams) => {
    setSelectedTemplateId('')
    setShowContractTemplateSider(false)

    getTemplates()
  }, [getTemplates])

  const onCancelDeleteContractModal = useCallback(() => {
    setShowDeleteContractModal(false)
    setSelectedTemplateName('')
    setSelectedTemplateId('')
  }, [])
  return (
    <>
      <DeleteContractModal
        showModal={showDeleteContractModal}
        onCancel={onCancelDeleteContractModal}
        onOk={onDeleteTemplate}
        contractName={selectedTemplateName}
      />
      <CustomContractTemplateSideBar
        showContractTemplateSider={showContractTemplateSider}
        onCancelClick={onCancelSideBar}
        executeAfterSave={executeAfterSave}
        _id={selectedTemplateId}
      />
      <PageHeader
        title='Contract Templates'
        showImage={false}
        rightContent={<RightContent onClickNewContract={onClickNewContract}/>}
      />
      <AdminPageWrapper>
        <ContractTemplatesTable
          dataSource={contractTemplates}
          onClickViewEdit={onEditContractTemplate}
          onClickDelete={prepareToDeleteTemplate}
          loading={gettingContractTemplates}
          deleting={deletingTemplate}
          idBeingDeleted={selectedTemplateId}
        />
      </AdminPageWrapper>
    </>
  )
}

export default ContractTemplates

RightContent.propTypes = {
  onClickNewContract: propTypes.func
}

ContractTemplatesTable.propTypes = {
  dataSource: propTypes.array,
  onClickViewEdit: propTypes.func,
  onClickDelete: propTypes.func,
  loading: propTypes.bool,
  deleting: propTypes.bool,
  idBeingDeleted: propTypes.string
}

CustomContractTemplateSideBar.propTypes = {
  showContractTemplateSider: propTypes.bool,
  onCancelClick: propTypes.func,
  _id: propTypes.string,
  executeAfterSave: propTypes.func
}

DeleteContractModal.propTypes = {
  showModal: propTypes.bool,
  onCancel: propTypes.func,
  onOk: propTypes.func,
  contractName: propTypes.string
}
