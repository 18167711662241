/* eslint-disable no-unused-vars */
import { Drawer, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import useContactContext from '../hooks/useContactContext'
import { IoMdClose } from 'react-icons/io'
import useGetLists from 'hooks/useGetLists'
import { RiPencilFill } from 'react-icons/ri'
import { MdDelete } from 'react-icons/md'
import CreateNewList from './CreateNewList/CreateNewList'
import useListsMutation from 'hooks/useListsMutation'
import DeleteListModal from 'pages/contacts/popups/DeleteList'
import { listDelete } from 'store/actions/usersActions'
import { useQueryClient } from '@tanstack/react-query'

const AddToListDrawer = () => {
  // @ts-ignore
  const { state, closeAddToListDrawer, toggleCheckList } = useContactContext()
  const [width80, setWidth80] = useState(window.innerWidth)
  const client = useQueryClient()
  const [listId, setListId] = useState()
  const [listName, setListName] = useState()
  const [showDeleteContact, setShowDeleteContact] = useState(false)
  const { lists, isLoading } = useGetLists()
  const [search, setSearch] = useState('')
  const { addUsersToLists } = useListsMutation()

  useEffect(() => {
    setWidth80(window.innerWidth < 1400 ? window.innerWidth * 0.55 : window.innerWidth * 0.75)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: width80,
      flexShrink: 0
    },
    drawerPaper: {
      width: width80,
      height: 'max-content'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  const handleAddToList = () => {
    const listOfUsers = Object.keys(state.checkedContacts).filter(key => state.checkedContacts[key] === true)
    const listOfIds = Object.keys(state.checkedLists).filter(key => state.checkedLists[key] === true)
    const previousData = {}
    lists?.filter(list => listOfIds.includes(list?._id)).forEach(list => {
      previousData[list?._id] = list.users
    })
    addUsersToLists({ listOfIds, listOfUsers, previousData })
  }

  return (
    <Drawer
      open={state.isAddToListDrawerOpen}
      variant="persistent"
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className='mt-20 relative' style={{ height: 'max-content' }}>
        <button onClick={() => closeAddToListDrawer()} className='absolute top-0 right-5'><IoMdClose fontSize="22px" /></button>
        <div style={{ height: 'max-content' }} className='w-full flex flex-row p-5'>
          <div className='flex flex-1 flex-col gap-4'>
            <div className='text-3xl font-bold'>
              ADD TO LISTS
            </div>
            <div className='flex w-full'>
              <input placeholder='search' onChange={(e) => setSearch(e.currentTarget.value)} />
            </div>
            <div className='w-full flex items-center justify-end'>
              <button disabled={!Object.values(state.checkedContacts).filter(val => val === true).length} onClick={handleAddToList} className='px-2 h-10 bg-volt rounded'>Add to list</button>
            </div>
            <div style={{ maxHeight: '35%' }} className='flex flex-col gap-2 overflow-y-auto pr-4'>
              {isLoading && 'loading lists....'}
              {!isLoading && lists?.length && lists.filter(list => search ? list.title.toLowerCase().includes(search.toLowerCase().trim()) : true).reverse()
                .map(list => <div className='flex flex-row w-full justify-between items-center gap-5 border-2 border-gray-2 rounded p-2'>
                  <div className='flex flex-row items-center gap-2'>
                    <input checked={state.checkedLists?.[list?._id] ?? false} onChange={e => toggleCheckList(list?._id)} type="checkbox" className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer' />
                    <div>{list.title}</div>
                  </div>
                  <div className='flex flex-row items-center gap-2'>
                    <div className='cursor-pointer'><RiPencilFill /></div>
                    <div className='cursor-pointer' onClick={() => {
                      setListId(list?._id)
                      setShowDeleteContact(true)
                      setListName(list?.title)
                    }} ><MdDelete /></div>
                  </div>
                </div>)}
            </div>
          </div>
          <div style={{ height: 'max-content' }} className='flex flex-1 justify-center'>
            <CreateNewList />
          </div>
          <DeleteListModal
            isOpen={showDeleteContact}
            listName={listName}
            onClose={() => setShowDeleteContact(false)}
            onDelete={async () => {
              await listDelete(listId)
              setShowDeleteContact(false)
              client.invalidateQueries(['getLists'])
            }}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default AddToListDrawer
