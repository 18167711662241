import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Calendar as CalendarComponent, Views } from 'react-big-calendar'
import propTypes from 'prop-types'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'

import 'react-big-calendar/lib/sass/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

import CalendarToolbar from './CalendarToolbar'

import { CalendarWrapper } from './styles'
import { connect } from 'react-redux'
import { createOrUpdateCalendarEvent, getCalendarEvents } from 'store/actions/usersActions'
import { AnimatePresence } from 'framer-motion'
import { FadedAnimatedDiv, LoadingIcon, StartExternalProviderConfiguration } from 'components'
import { message } from 'antd'
import NewMeeting from 'components/NewMeeting'
import useBuyers from 'hooks/useGetBuyers'

const DragAndDropCalendar = withDragAndDrop(CalendarComponent)

const globalizeLocalizer = localizer(globalize)

const Calendar = (props) => {
  const { userObject } = props
  const { buyers, getBuyersData } = useBuyers()
  const [events, setEvents] = useState([])
  const [draggedEvent, setDraggedEvent] = useState({})
  const [showMeetingModal, setShowMeetingModal] = useState(false)
  const [gettingEvents, setGettingEvents] = useState(false)
  const [gettingSelectedEvent, setGettingSelectedEvent] = useState(false)
  const [editingEvent, setEditingEvent] = useState(null)

  const refEvents = useRef(events)
  const refDraggedEvent = useRef(draggedEvent)

  const updateEvents = (newState) => {
    refEvents.current = newState
    setEvents(newState)
  }

  const getEvents = useCallback(() => {
    setGettingEvents(true)

    getCalendarEvents()
      .then((events) => {
        const tmpEvents = events.map(event => ({
          id: event.id,
          title: event.title,
          start: new Date((event.when.start_time || event.when.time) * 1000),
          end: new Date((event.when.end_time || event.when.time) * 1000),
          desc: event.description
        }))

        updateEvents(tmpEvents)
        setGettingEvents(false)
      })
      .catch(() => setGettingEvents(false))
  }, [])

  useEffect(() => {
    if (userObject.providerToken) {
      getEvents()
      getBuyersData()
    }
  }, [getEvents, userObject])

  const updateDraggedEvent = (newState) => {
    refDraggedEvent.current = newState
    setDraggedEvent(newState)
  }

  const moveEvent = useCallback(({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    message.loading('Updating event...')

    const updatedEvent = Object.assign({},
      event,
      {
        _id: event.id,
        notify_participants: true,
        when: { start_time: start, end_time: end }
      }
    )

    createOrUpdateCalendarEvent(updatedEvent)

    const events = refEvents.current

    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent
    })

    updateEvents(nextEvents)
  }, [])

  const resizeEvent = useCallback(({ event, start, end }) => {
    message.loading('Updating event...')

    const updatedEvent = Object.assign({},
      event,
      {
        _id: event.id,
        notify_participants: true,
        when: { start_time: start, end_time: end }
      }
    )

    createOrUpdateCalendarEvent(updatedEvent)

    const events = refEvents.current

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    updateEvents(nextEvents)
  }, [])

  const newEvent = useCallback((event) => {
    const { slots } = event
    const _emptyEvent = Object.assign({},
      event,
      {
        when: {
          start_time: new Date(slots[0]).getTime() / 1000,
          end_time: new Date(slots[slots.length - 1]).getTime() / 1000,
          object: 'timespan'
        }
      }
    )

    if (!showMeetingModal) {
      setEditingEvent(_emptyEvent)
      setShowMeetingModal(true)
    }
  }, [showMeetingModal])

  const handleDragStart = useCallback((event) => {
    updateDraggedEvent(event)
  }, [])

  const executeAfterSaveDeleteCalendarEvent = useCallback(() => {
    setShowMeetingModal(false)
    setEditingEvent(null)

    getEvents()
  }, [getEvents])

  const openEvent = useCallback((_event) => {
    setGettingSelectedEvent(true)
    getCalendarEvents(_event.id)
      .then((event) => {
        setEditingEvent({ ...event, _id: event?.id })
        setShowMeetingModal(true)
      })
      .catch(() => setShowMeetingModal(false)).finally(() => setGettingSelectedEvent(false))
  }, [])

  return (
    <CalendarWrapper className='h-full w-full relative'>
      {
        Object.keys(userObject).length && !userObject.providerToken
          ? <StartExternalProviderConfiguration pageName='Calendar' />
          : (
            <>
              <AnimatePresence>
                {
                  (gettingEvents || gettingSelectedEvent) &&
                  <FadedAnimatedDiv
                    className='absolute top-1/3 left-2/4 z-10 shadow-xl rounded-full bg-white'
                  >
                    <LoadingIcon className='h-22 w-22 text-blue-400 p-1' />
                  </FadedAnimatedDiv>
                }
              </AnimatePresence>
              <NewMeeting isOpen={showMeetingModal} setOpen={(value) => { setShowMeetingModal(value) }} contact={{ ...userObject }} meeting={editingEvent} onSuccess={executeAfterSaveDeleteCalendarEvent} buyers={buyers} canDelete={true} />
              <DragAndDropCalendar
                selectable
                localizer={globalizeLocalizer}
                events={events}
                onEventDrop={moveEvent}
                resizable
                onEventResize={resizeEvent}
                onSelectSlot={newEvent}
                defaultView={Views.MONTH}
                defaultDate={new Date()}
                popup={true}
                handleDragStart={handleDragStart}
                components={{ toolbar: CalendarToolbar }}
                onSelectEvent={openEvent}
              />
            </>
            )
      }
    </CalendarWrapper>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)

Calendar.propTypes = {
  userObject: propTypes.object
}
