// @ts-nocheck
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import useGetUserNotes from 'hooks/useGetUserNotes'
import NoteCard from 'components/NotesCard'

export const NotesTab = ({ contact, user }) => {
  const tmpNote = {
    buyer: {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      _id: contact?._id,
      id: contact?._id
    },
    noteType: 'General',
    contact: contact?._id,
    user: user?._id,
    edit: true,
    note: ''
  }

  const { notes, loading, refetch } = useGetUserNotes(contact?._id)
  const [isNewNote, setNewNote] = useState(false)
  const [newCard, setNewCard] = useState({ ...tmpNote })

  const [notesList, setNotesList] = useState([])

  const createNewNote = () => {
    if (newCard?._id) {
      setNotesList([
        { ...newCard, notes: JSON.stringify(newCard.notes) },
        ...notesList
      ])
      setNewCard({ ...tmpNote })
      setNewNote(false)
      setTimeout(() => setNewNote(true), 1000)
    } else {
      setNewNote(true)
    }
  }

  const onFocusOutNewNoteBox = (_note) => {
    setNotesList([
      { ..._note, notes: JSON.stringify(_note.notes) },
      ...notesList
    ])
    setNewCard({ ...tmpNote })
    setNewNote(false)
  }

  const onDeleteNewNote = () => {
    setNewCard({ ...tmpNote })
    setNewNote(false)
  }

  useEffect(() => {
    const notesData = []
    if (notes) {
      const sortednotes = notes.reverse()
      sortednotes.forEach(function (note, index) {
        notesData.push({ ...note, edit: false })
      })

      setNotesList(notesData)
    }
  }, [notes])

  return (
    <>
      {loading && (
        <div className='flex flex-col gap-6 mt-8'>
          <Skeleton height={100} />
        </div>
      )}
      {isNewNote && (
        <NoteCard
          note={newCard}
          onFocusOutNewNoteBox={onFocusOutNewNoteBox}
          setNewCard={setNewCard}
          notesList={notesList}
          setNotesList={setNotesList}
          refetch={refetch}
          contact={contact}
          user={user}
          onDeleteNewNote={onDeleteNewNote}
        />
      )}
      {!loading && notesList.length === 0 && (
        <div className='flex flex-col justify-center items-center h-full w-full p-6'>
          {!isNewNote && (
            <>
              <svg
                width='30'
                height='27'
                viewBox='0 0 16 14'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13.3417 1.83324L12.3333 0.824902C11.6833 0.174902 10.625 0.174902 9.975 0.824902L7.74167 3.05824L0.5 10.2999V13.6666H3.86667L11.15 6.38324L13.3417 4.19157C14 3.54157 14 2.48324 13.3417 1.83324ZM3.175 11.9999H2.16667V10.9916L9.38333 3.7749L10.3917 4.78324L3.175 11.9999ZM7.16667 13.6666L10.5 10.3332H15.5V13.6666H7.16667Z'
                  fill='#323232'
                />
              </svg>
              <div className='font-semibold mt-6 mb-2'>
                You don't have any notes yet
              </div>
              <div>Create your first note</div>
            </>
          )}
          <button
            onClick={createNewNote}
            className='bg-volt font-light italic px-6 py-3 font-openSans rounded mt-4 m-auto'
          >
            Create Note
          </button>
        </div>
      )}
      {notesList.length > 0 && (
        <div>
          <div className='my-8 flex flex-col gap-6'>
            <div className='text-center'>
              <button
                onClick={createNewNote}
                className='bg-volt font-light italic px-6 py-3 font-openSans rounded mt-4 m-auto'
              >
                Create Note
              </button>
            </div>
            {notesList.map((note, index) => (
              <NoteCard
                note={note}
                notesList={notesList}
                setNotesList={setNotesList}
                contact={contact}
                user={user}
                refetch={refetch}
                key={note._id}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}
