import { useState, useEffect, useRef } from 'react'
import { getBuyerNotes } from 'store/actions/notesActions'

const useGetUserNotes = (_id) => {
  const mountedRef = useRef(true)
  const [notes, setNotes] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const getData = async () => {
    if (!mountedRef.current) return null
    setLoading(true)
    try {
      const data = await getBuyerNotes(_id)
      if (!mountedRef.current) return null
      setNotes(data)
      setLoading(false)
    } catch (err) {
      if (!mountedRef.current) return null
      setLoading(false)
    }
  }

  return { loading, notes, refetch: getData }
}

export default useGetUserNotes
