import React, { useContext } from 'react'
import { CreateOpportunityContext } from '../context/CreateOpportunityContext'
import BuyerInfo from './BuyerInfo/BuyerInfo'
// import Form from './Form/Form'
import { Formik } from 'formik'

import Form from './Form/Form'
import DemandNotesForm from './DemandNotesForm/DemandNotesForm'
import { createOpportunity } from 'store/actions/usersActions'

const CreatePage = () => {
  const { selectedBuyers } = useContext(CreateOpportunityContext)
  const onSubmit = (values) => {
    createOpportunity(values)
  }
  return (
        <Formik
            initialValues={{
              name: '',
              user: '',
              rating: '',
              stage: '',
              floorPlansInterest: [],
              floorNumbersInterest: [],
              numberOfBedrooms: [],
              firstChoiceUnit: '',
              secondChoiceUnit: '',
              thirdChoiceUnit: '',
              minimumSquareFeet: '',
              maximumSquareFeet: '',
              budget: '',
              orientation: '',
              primaryUse: [],
              needParking: true,
              numberOfParkingSpots: 0,
              upgrades: [],
              needsFinancing: '',
              notes: '',
              createdAt: '',
              project: '',
              salesRep: ''
            }}
          onSubmit={onSubmit}
        >
            {() => <div style={{ height: '100vh' }} className="flex flex-row gap-2 w-full">
            <div style={{ width: '20%' }} className="h-full flex flex-col gap-4 p-2 overflow-auto">
                <div className='flex justify-between pb-5 items-start relative'>
                    {selectedBuyers && <BuyerInfo contact={selectedBuyers} />}
                </div>
                <DemandNotesForm />
                <div className='flex flex-col gap-1'>
                    <div>Past Buyer Notes</div>
                    <div style={{ height: '400px' }} className='bg-white rounded'>
                    </div>
                </div>
            </div>
            <Form />
        </div>}
        </Formik>
  )
}

export default CreatePage
