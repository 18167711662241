import React, { useState } from 'react'
import moment from 'moment'

import DeleteModal from 'components/DeleteModal'

import { deleteCall } from 'store/actions/callsActions'

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'

import { CallModal } from './CallModal'

const CallCard = ({ call, contact, onSuccess }) => {
  const [loading, setLoading] = useState(false)
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)

  const onConfirmDelete = async () => {
    setLoading(true)
    try {
      await deleteCall(call._id)
      setIsDeleteModal(false)
      onSuccess()
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const date = moment(call.date)

  return (
    <>
      <div className='group border border-softBlack_10 bg-white p-6 flex gap-4 rounded'>
        <div className='bg-volt text-softBlack h-11.75 w-14 flex justify-center items-center flex-col text-center'>
          <div className='text-sm font-semibold'>{date.format('DD')}</div>
          <div className='text-xm'>{date.format('MMM YY')}</div>
        </div>
        <div className='w-full'>
          <div className='flex justify-between w-full'>
            <div className='text-base font-medium mb-1'>
              {call?.description}
            </div>
            <div className='flex gap-4 invisible group-hover:visible'>
              <button onClick={() => setIsEditModal(true)}><EditIcon /></button>
              <button onClick={() => setIsDeleteModal(true)}><TrashIcon /></button>
            </div>
          </div>
          <div className='text-base text-softBlack70'>
            {call.notes}
          </div>
        </div>
      </div>
      <DeleteModal
        title="Confirm deletion?"
        description="This call will be deleted permanently!"
        confirmButtonTitle="Delete Call"
        cancelButtonTitle="Cancel"
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={onConfirmDelete}
        loading={loading}
      />
      <CallModal call={call} show={isEditModal} setClose={setIsEditModal} contact={contact} onSuccess={onSuccess} />
    </>
  )
}

export default CallCard
