/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './styles/richTextEditor.css'

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

import { getToolbarOptions, attachmentIcon } from './config/config'

const RichTextField = ({
  text,
  onChangeText,
  placeholder = '',
  hasFileAttachments = true,
  onChangeFileAttachments = null
}) => {
  const [fileAttachments, setFileAttachments] = useState([])
  const fileInputRef = useRef(null)

  const handleFileAttachment = () => {
    const fileInput = fileInputRef.current
    fileInput.click()
  }

  const handleFileSelect = () => {
    const fileAttachmentsTemp = []
    const fileInput = fileInputRef.current
    const currentFileAttachments = [...fileAttachments]

    for (let i = 0; i < fileInput.files.length; ++i) {
      fileAttachmentsTemp.push(fileInput.files.item(i))
    }

    setFileAttachments([...currentFileAttachments, ...fileAttachmentsTemp])
  }

  const handleFileAttachmentDelete = (file) => {
    setFileAttachments(fileAttachments.filter((fileTemp) => fileTemp !== file))
  }

  if (hasFileAttachments) {
    const icons = ReactQuill.Quill.import('ui/icons')
    icons.attachment = attachmentIcon
  }

  const [modules] = useState(
    hasFileAttachments
      ? {
          toolbar: {
            container: getToolbarOptions(hasFileAttachments),
            handlers: {
              attachment: handleFileAttachment
            }
          }
        }
      : {
          toolbar: {
            container: getToolbarOptions(hasFileAttachments)
          }
        }
  )

  useEffect(() => {
    onChangeFileAttachments && onChangeFileAttachments(fileAttachments)
  }, [fileAttachments])

  return (
    <>
      <ReactQuill
        theme='snow'
        className='border-black'
        value={text}
        modules={modules}
        onChange={(html) => {
          onChangeText && onChangeText(html)
        }}
        style={{ background: 'white', height: '560px' }}
        placeholder={placeholder}
      />
      <input
        type={'file'}
        className='hidden'
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
      {hasFileAttachments && (
        <div className='mt-3'>
          {fileAttachments?.length > 0 && (
            <p
              className={'mb-1 text-softBlack70 text-xs font-medium uppercase'}
              style={{ letterSpacing: '0.16px' }}
            >
              ATTACHMENTS
            </p>
          )}
          {fileAttachments?.map((file, index) => (
            <div
              key={index}
              style={{ color: 'blue' }}
              className='underline flex gap-1'
            >
              <span
                style={{
                  color: 'blue',
                  maxWidth: '50%',
                  textOverflow: 'ellipsis'
                }}
                className='overflow-hidden whitespace-nowrap'
              >
                {file.name}
              </span>
              <div onClick={() => handleFileAttachmentDelete(file)}>
                <CrossIcon
                  style={{ width: 15, height: 23 }}
                  className='hover:cursor-pointer'
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default RichTextField
