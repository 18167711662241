import React, { useState, useCallback } from 'react'
import { Modal as MUIModal } from '@material-ui/core'
import { Modal } from 'ui'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { createUserConversations } from 'store/actions/usersActions'
import CustomInput from 'components/CustomInput'

const initialEmptyValues = {
  from: '',
  to: ''
}

export const MessageModal = ({ show, handleClose, contact }) => {
  const [messageData, setMessageData] = useState({
    ...initialEmptyValues,
    from: '+12056622925',
    to: contact?.buyerData?.phoneNumber
  })
  const [showConfirmSendingMessageModal, setShowConfirmSendingMessageModal] =
    useState(false)
  const [sendingMessage, setSendingMessage] = useState(false)

  const onChange = (fieldName, value) => {
    setMessageData({ ...messageData, [fieldName]: value })
  }

  const onCancelModal = useCallback(() => {
    setShowConfirmSendingMessageModal(false)
  }, [])

  const createNewConversation = () => {
    setSendingMessage(true)
    setShowConfirmSendingMessageModal(false)
    createUserConversations({ user: contact?.id, phone: messageData.to }).then((res) => {
      setSendingMessage(false)
      setMessageData({ ...initialEmptyValues })
      handleClose(true)
    }).catch(e => {
      console.error('createNewConversation => e', e)
      setSendingMessage(false)
    })
  }

  const handleSendMessage = () => {
    if (!messageData.from || !messageData.to) {
      setShowConfirmSendingMessageModal(true)
    } else {
      createNewConversation()
    }
  }

  return (
    <>
      <Modal
        showModal={showConfirmSendingMessageModal}
        title='Please fill up details?'
        subtitle="Phone number for sender or receiver is missing?"
        modalType='alert'
        // onOk={onCancelModal}
        onCancel={onCancelModal}
        cancelText="Close"
      />
      <MUIModal open={show} onClose={() => { handleClose(false) }}>
        <div
          className={'flex flex-row justify-center items-center font-openSans absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded shadow-lg'}
          style={{ width: '90%', height: '80%' }}
        >
          <div style={{ flex: 1, maxWidth: 544 }} className='flex relative bg-grey-8 overflow-x-hidden overflow-y-auto flex-col p-8 rounded'>
            <button className='absolute right-10' onClick={() => { handleClose(false) }}>
              <CrossIcon />
            </button>
            <h1 className='font-bold text-xl text-center p-0 m-0 font-openSans tracking-wider'>
              Create New Conversation
            </h1>
            <div className='pt-8 flex flex-col gap-6'>
              <CustomInput
                label='From'
                value={messageData.from}
                onChange={(e) => {
                  onChange('from', e.target.value)
                }}
                placeholder='Enter Sender Phone Number'
                type='phone'
                required='required'
                errorMessage='Please enter Sender Phone Number'
                containerClasses={undefined}
                classes={undefined}
              />
              <CustomInput
                label='To'
                value={messageData.to}
                onChange={(e) => {
                  onChange('to', e.target.value)
                }}
                placeholder='Enter Receiver Phone Number'
                type='phone'
                required='required'
                errorMessage='Please enter Receiver Phone Number'
                containerClasses={undefined}
                classes={undefined}
              />
              <button className='bg-yellow-200 font-semibold px-4 py-2 rounded mt-6' onClick={handleSendMessage} disabled={sendingMessage}>
                Start Conversation
              </button>
            </div>
          </div>
        </div>
      </MUIModal>
    </>
  )
}

export default MessageModal
