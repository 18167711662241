export const opportunityStages = [
  'CREATED',
  'RESERVATION',
  'OFFER',
  'WON-SOLD FIRM',
  'LOST'
]

export const opportunityRatings = [
  'A',
  'B',
  'C',
  'D',
  'E'
]

export const needParkings = [
  'Yes',
  'No'
]

export const needsFinancings = [
  'Yes',
  'No'
]

export const testOptions = [
  'Test Select Value'
]
