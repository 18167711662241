import React from 'react'
import propTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import {
  BROKER_DASHBOARD_PAGE,
  BROKER_HOME_PAGE,
  // BROKER_MENU_PAGE,
  BROKER_DOCUMENTS_PAGE
} from 'routes'

const itemMenuVariants = {
  hidden: {
    opacity: 0,
    x: -50
  },
  visible: (custom) => ({
    opacity: 1,
    x: 0
  })
}

const ItemMenu = ({ onClick, index, title, setSelectedMenuId, selected }) => (
  <motion.button
    variants={itemMenuVariants}
    initial='hidden'
    animate='visible'
    custom={index}
    onClick={() => {
      onClick()
    }}
    transition={{
      delay: 0.25 + index * 0.07,
      x: { type: 'spring', stiffness: 300, damping: 30 }
    }}
    className={`text-2xl text-left mb-3 hover:bg-black hover:text-white rounded-md 
    px-3 -ml-3 focus:outline-none ${selected && 'underline'}`}
  >
    {title}
  </motion.button>
)

const BrokerMenus = (props) => {
  const { selectedMenuId, setSelectedMenuId, setShowMenu } = props

  const history = useHistory()

  const menus = [
    {
      key: BROKER_HOME_PAGE,
      title: 'Home',
      onClick: () => history.push(BROKER_HOME_PAGE),
      visible: true
    },
    {
      key: BROKER_DASHBOARD_PAGE,
      title: 'Dashboard',
      onClick: () => history.push(BROKER_DASHBOARD_PAGE),
      visible: true
    },
    // {
    //   key: BROKER_MENU_PAGE,
    //   title: 'Menus',
    //   onClick: () => history.push(BROKER_MENU_PAGE),
    //   visible: true
    // },
    {
      key: BROKER_DOCUMENTS_PAGE,
      title: 'Documents',
      onClick: () => history.push(BROKER_DOCUMENTS_PAGE),
      visible: true
    }
  ]

  return (
    <div className='flex flex-col'>
      <span className='text-4xl mb-5'>Menu</span>
      {menus
        .filter((menu) => menu.visible)
        .map((menu, index) => {
          const { key, title, onClick } = menu
          return (
            <ItemMenu
              key={key}
              title={title}
              onClick={() => {
                setSelectedMenuId(key)
                onClick()
                setShowMenu(false)
              }}
              index={index}
              setSelectedMenuId={setSelectedMenuId}
              selected={selectedMenuId === key}
            />
          )
        })}
    </div>
  )
}

export default BrokerMenus

BrokerMenus.propTypes = {
  selectedMenuId: propTypes.string,
  setSelectedMenuId: propTypes.func,
  setShowMenu: propTypes.func
}

ItemMenu.propTypes = {
  onClick: propTypes.func,
  index: propTypes.string,
  title: propTypes.string,
  setSelectedMenuId: propTypes.func,
  selected: propTypes.bool
}
