import React from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'

const ConfirmDeleteModal = ({
  onClose,
  onSuccess,
  show,
  deleteId
}) => {
  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby='Private Document Upload Modal'
      aria-describedby='Private Document Edit Modal for uploading document'
    >
      <div
        className='relative flex flex-col font-openSans bg-grey-8 p-4 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{ width: '512px' }}
      >
        <div className='flex flex-col gap-10 relative justify-center items-center rounded-3xl p-4'>
          <div className='flex flex-col items-center text-center w-4/5 pt-5'>
            <h1 className='text-2xl text-italic'>Are you sure?</h1>
            <p className='text-2xl text-italic'>Do you want to delete it?</p>
            <button className='absolute top-0 right-0' onClick={onClose}>
              <CrossIcon />
            </button>
          </div>
          <div className='flex justify-center gap-3 w-full items-center'>
            <button
              className='flex justify-center items-center font-bold text-lg py-4 px-6 bg-volt rounded cursor-pointer'
              onClick={onClose}
            >
              No
            </button>
            <button
              className='flex justify-center items-center font-bold text-lg py-4 px-6 bg-volt rounded cursor-pointer'
              onClick={() => {
                onSuccess(deleteId)
              }}
            >
              Yes, delete it
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ConfirmDeleteModal
