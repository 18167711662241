import MobileCard from 'components/HorizontalCardProfile/MobileCard'
import useBuyerScore from 'hooks/useBuyerScore'
import useGetLeadBrokers from 'hooks/useGetLeadBrokers'
import useGetReferralAgent from 'hooks/useGetReferralAgent'
import useGetSalesReps from 'hooks/useGetSalesReps'
import useLeadSource from 'hooks/useLeadSource'
import useLeadStatus from 'hooks/useLeadStatus'
import useUpdateUsers from 'hooks/useUpdateUsers'
import { ContactContext } from 'pages/contacts/context/Contacts'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createBuyerToSalesRepAssignment, deleteUser, updateBuyerToSalesRepAssignment, updateLeadSource, updateLeadStatus } from 'store/actions/usersActions'

const BuyerInfo = ({ contact }) => {
  const { updateContact } = useContext(ContactContext)
  const { createOrUpdateUser } = useUpdateUsers()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { formattedLeadsources: leadSources } = useLeadSource()
  const { formattedLeadStatuses: leadStatuses } = useLeadStatus()
  const { formattedScores: buyerScores } = useBuyerScore()
  const [salesrep, setSalesRep] = useState(null)
  const [, setOpenAddToList] = useState(false)
  const [broker, setBroker] = useState('Select Broker')
  const [referralAgent, setReferralAgent] = useState('Select Referral Agent')
  const [selectedLeadSource, setSelectedLeadSource] = useState(userObject?.buyerData?.leadSource ?? 'None')

  const { data: brokers, refetchBrokers } = useGetLeadBrokers()
  const { data: referralAgents, refetch: refetchReferralAgents } = useGetReferralAgent()
  const { salesReps, refetch } = useGetSalesReps()

  useEffect(() => {
    if (userObject?.buyerData?.leadSource) setSelectedLeadSource(userObject?.buyerData?.leadSource)
  }, [userObject?.buyerData?.leadSource])

  useEffect(() => {
    if (brokers?.length) return
    refetchBrokers()
  }, [brokers, refetchBrokers])

  useEffect(() => {
    if (referralAgents?.length) return
    refetchReferralAgents()
  }, [referralAgents, refetchReferralAgents])

  useEffect(() => {
    if (salesReps?.length) return
    refetch()
  }, [salesReps, refetch])

  useEffect(() => {
    if (!brokers?.length || !salesReps?.length || !contact?._id) return
    const leadBroker = contact?.buyerData?.assignments.find(assignment => assignment?.leadBroker !== undefined)?.leadBroker
    const salesRep = contact?.buyerData?.assignments.find(assignment => assignment.project === projectId && assignment?.salesRep?._id !== undefined)?.salesRep
    setSalesRep(salesRep)
    setBroker(leadBroker)
    setReferralAgent(contact?.buyerData?.referralAgent)
  }, [contact, brokers, salesReps, setSalesRep, setBroker, setReferralAgent, projectId])

  const handleLeadSourceChange = async (leadSource) => {
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (newUserData.buyerData && Object.keys(newUserData.buyerData).length) {
      newUserData.buyerData.leadSource = leadSource
    }

    try {
      updateContact(newUserData)
      await updateLeadSource(leadSource, contact._id)
    } catch (err) {
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleLeadStatusChange = async (type) => {
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (newUserData.buyerData && Object.keys(newUserData.buyerData).length) {
      newUserData.buyerData.leadStatus = type
    }

    try {
      updateContact(newUserData)
      await updateLeadStatus(type, contact._id)
    } catch (err) {
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleRatingChange = async (value) => {
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (newUserData.buyerData && Object.keys(newUserData.buyerData).length) {
      newUserData.buyerData.rating = value
    }

    try {
      updateContact(newUserData)
      await createOrUpdateUser(newUserData, false, false)
    } catch (err) {
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleSalesRepChange = (type, params) => {
    if (type?.create) return createBuyerToSalesRepAssignment(params)
    updateBuyerToSalesRepAssignment(params)
  }

  const handleContactDelete = (id) => {
    deleteUser(id)
  }

  const getSalesRepOwner = (contact) => {
    const firstName =
      contact?.buyerData?.assignments[0]?.salesRep?.firstName ?? ''
    const lastName =
      contact?.buyerData?.assignments[0]?.salesRep?.lastName ?? ''
    return firstName + ' ' + lastName
  }

  return <MobileCard
        broker={broker}
        brokers={brokers}
        setBroker={setBroker}
        referralAgent={referralAgent}
        referralAgents={referralAgents}
        setReferralAgent={setReferralAgent}
        leadStatuses={leadStatuses}
        scores={buyerScores}
        onClickDelete={handleContactDelete}
        salesRepOwner={getSalesRepOwner(contact)}
        email={contact?.email} firstName={contact?.firstName} lastName={contact?.lastName}
        leadSources={leadSources}
        leadStatus={contact?.buyerData?.leadStatus || 'New Lead'}
        handleLeadSourceChange={handleLeadSourceChange}
        handleLeadStatusChange={handleLeadStatusChange}
        handleRatingChange={handleRatingChange}
        imgsrc={''}
        rating={contact?.buyerData?.rating}
        selectedLeadSource={selectedLeadSource}
        setSelectedLeadSource={setSelectedLeadSource}
        userType={contact?.userType}
        buyerData={contact?.buyerData}
        buyerEmail={contact?.email}
        developerAdminId={userObject?._id}
        handleSalesRepChange={handleSalesRepChange}
        projectId={projectId}
        salesRep={salesReps}
        salesrep={salesrep}
        setSalesRep={setSalesRep}
        setOpenAddToList={setOpenAddToList}
        forceMobile
        showDeleteButton={false}
    />
}

export default BuyerInfo
