import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import DeleteIcon from 'components/Icons/DeleteIcon'
import useContactContext from 'pages/newContacts/hooks/useContactContext'

const TableBody = ({ contacts }) => {
  const { setSelectedContact, state, toggleCheckContact } = useContactContext()
  // const history = useHistory()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const columns = useCallback(
    (userType) => {
      const salesRepKey =
        userType?.toLowerCase?.() === 'buyer' ? 'buyerData' : 'leadBrokerData'
      const result =
        !state.selectedContact && !state.isAddToListDrawerOpen
          ? [
              { name: 'Name', property: 'firstName' },
              { name: 'User Type', property: 'userType' },
              { name: 'Email', property: 'email' },
              {
                name: 'Phone Number',
                property: { name: salesRepKey, property: 'phoneNumber' }
              },
              {
                name: 'Sales Rep Owner',
                property: { name: salesRepKey, property: 'salesRep' }
              },
              {
                name: 'Buyer Score',
                property: { name: salesRepKey, property: 'rating' }
              },
              {
                name: 'Lead Status',
                property: { name: salesRepKey, property: 'leadStatus' }
              }
            ]
          : [{ name: 'Name', property: 'firstName' }]
      return result
    },
    [state.selectedContact, state.isAddToListDrawerOpen]
  )

  const getDeleteButton = () => (
    <button>
      <DeleteIcon height={20} width={20} />
    </button>
  )

  const getStringFromProperty = (contact, col) => {
    if (col.name === 'Phone Number') return ''
    if (col?.property?.name === 'Delete Button') return getDeleteButton()
    if (contact?.userType === 'LeadBroker') {
      return contact?.leadBrokerData?.assignments?.[0]?.salesRep?.firstName
    }
    if (userObject?.userType !== 'SalesRep') {
      return contact[col?.property?.name]?.assignments[0]?.salesRep?.firstName
    }
    const assignemnts = contact[col?.property?.name]?.assignments
    if (!assignemnts?.length) return ''
    const found = assignemnts?.some?.(
      (assignment) => assignment?.salesRep?._id === userObject?._id
    )
    if (!found) {
      return contact[col?.property?.name]?.assignments[0]?.salesRep?.firstName
    }
    return userObject?.firstName
  }

  return (
    <tbody className='divide-y divide-gray-200 bg-white'>
    {/* <DeleteFromListModal isOpen={openDelFromListModal} contact={targetContact} onClose={() => setOpenDeleteFromListModal(false)} /> */}
    {contacts.map((contact, contactIndex) => (
      <tr key={contactIndex}>
        {columns(contact.userType).map((col, columnIndex) => (
          <React.Fragment key={`${contactIndex}-${columnIndex}`}>
            {columnIndex === 0
              ? (
                <td
                  key={`${contactIndex}-${columnIndex - 1}`}
                  className='relative w-12 px-6 sm:w-16 sm:px-8'
                >
                  <div className='absolute inset-y-0 left-0 w-0.5 bg-indigo-600'></div>
                  <input
                    data-id={`${contact._id}`}
                    onChange={(e) => toggleCheckContact(contact?._id)
                      // handleSaveList(e, contact, columnIndex, contactIndex)
                    }
                    checked={state.checkedContacts?.[contact?._id] ?? false}
                    type='checkbox'
                    className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
                  />
                </td>
                )
              : null}
            {
              // @ts-ignore
              col.property.name === 'Delete Button'
                ? <td
                  style={{ textAlign: 'left', cursor: 'pointer' }}
                  className='whitespace-nowrap w-25 pr-3 text-sm font-medium text-gray-900'
                  // @ts-ignore
                  // onClick={() => openDeleteFromListModal(contact)}
                >
                  {typeof col.property === 'string'
                    ? (
                        col.property === 'firstName'
                          ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '16px'
                            }}
                          >
                            <div className='rounded-full bg-gray-200 text-softBlack p-3 my-2 w-14 h-14 flex justify-center items-center font-sans hover:shadow hover:cursor-pointer'>
                              {`${contact.firstName?.[0] ?? ''}${contact?.lastName?.[0] ?? ''}`}
                            </div>
                            {(contact[col.property] + ' ' + contact?.lastName ?? '').slice(0, 25)}
                          </div>
                            )
                          : (
                              contact[col.property]?.trim()
                            )
                      )
                    : typeof contact[col.property.name]?.[
                      // @ts-ignore
                      col.property.property
                    ] === 'string'
                      ? (
                        // @ts-ignore
                          contact[col.property.name]?.[col.property.property]
                        )
                      : (
                          getStringFromProperty(contact, col)
                        )}
                </td>
                : <td
                  style={{ textAlign: 'left', cursor: 'pointer' }}
                  className='whitespace-nowrap w-25 pr-3 text-sm font-medium text-gray-900'
                  onClick={() => setSelectedContact(contact)}
                >
                  {typeof col.property === 'string'
                    ? (
                        col.property === 'firstName'
                          ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: '16px'
                            }}
                          >
                            <div className='rounded-full bg-gray-200 text-softBlack p-3 my-2 w-14 h-14 flex justify-center items-center font-sans hover:shadow hover:cursor-pointer'>
                              {`${contact.firstName?.[0] ?? ''}${contact?.lastName?.[0] ?? ''}`}
                            </div>
                            {(contact[col.property] + ' ' + contact?.lastName ?? '').slice(0, 25)}
                          </div>
                            )
                          : (
                              contact[col.property]?.trim()
                            )
                      )
                    : typeof contact[col.property.name]?.[
                      // @ts-ignore
                      col.property.property
                    ] === 'string'
                      ? (
                        // @ts-ignore
                          contact[col.property.name]?.[col.property.property]
                        )
                      : (
                          getStringFromProperty(contact, col)
                        )}
                </td>}
          </React.Fragment>
        ))}
      </tr>
    ))}
  </tbody>
  )
}

export default TableBody
