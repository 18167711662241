import {
  CHANGE_USER_SELECTED_TAB,
  CHANGE_PROJECT_MANAGEMENT_SELECTED_TAB,
  CLEAR_APP_REDUCER,
  CHANGE_PINNED_HEADER,
  CHANGE_APP_PROJECT,
  UPDATE_LIST_OF_PROJECTS,
  SHOW_HIDE_SIDEBAR,
  HOVER_TOGGLER
} from 'store/actions/actionTypes'

export const initialState = {
  userSelectedTab: 'SalesRep',
  projectManagementSelectedTab: 'Projects',
  pinnedHeader: true,
  appProject: '',
  appDeveloper: '',
  newProject: false,
  listOfProjects: [],
  isHover: false
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_USER_SELECTED_TAB:
      return {
        ...state,
        userSelectedTab: action.payload
      }

    case CHANGE_PROJECT_MANAGEMENT_SELECTED_TAB:
      return {
        ...state,
        projectManagementSelectedTab: action.payload
      }

    case CHANGE_PINNED_HEADER:
      return {
        ...state,
        pinnedHeader: action.payload
      }

    case CHANGE_APP_PROJECT:
      return {
        ...state,
        appProject: action.payload.projectId,
        appDeveloper: action.payload.developerId,
        newProject: action.payload.newProject
      }

    case UPDATE_LIST_OF_PROJECTS:
      return {
        ...state,
        listOfProjects: action.payload
      }

    case SHOW_HIDE_SIDEBAR:
      return {
        ...state,
        show: action.payload
      }

    case CLEAR_APP_REDUCER:
      return initialState

    case HOVER_TOGGLER:
      return {
        ...state,
        isHover: action.payload
      }

    default: return state
  }
}

export default appReducer
