import useGetSearchUsers from 'hooks/useGetSearchUsers'
import useGetUsers from 'hooks/useGetUsers'
import useGetUsersByParam from 'hooks/useGetUsersByParam'
import useSearchParams from 'hooks/useSearchParams'
import moment from 'moment'
import React, { createContext, useEffect, useRef, useState } from 'react'
import useGetFilterUsers from '../../../hooks/useGetFilterUsers'

export const sortContacts = (a, b) => {
  if (moment(a.createdAt).isBefore(b.createdAt)) return 1
  else if (moment(a.createdAt).isAfter(b.createdAt)) return -1
  return 0
}

export const filterNullAssignments = (assignments) => {
  if (!assignments?.length) return false
  let result = null
  result = assignments?.some?.(
    (_assignment) =>
      _assignment?.salesRep?._id?.length || _assignment?.salesRep?.length
  )
  return result ?? true
}

export const filterAssignmentsBySaleRepId = (
  assignments,
  userObject,
  skipForDevAdmin = false,
  salesRepId = null
) => {
  if ((!skipForDevAdmin && !assignments?.length) || !userObject) {
    return false
  }
  let result = null
  if (userObject?.userType === 'SalesRep') {
    result = assignments?.some?.((_assignment) => {
      return (
        _assignment?.salesRep?._id === (salesRepId ?? userObject?._id) ||
        _assignment?.salesRep === (salesRepId ?? userObject?._id)
      )
    })
  } else {
    if (userObject?.userType === 'DeveloperAdmin' && skipForDevAdmin) {
      result = true
    } else {
      result = filterNullAssignments(assignments)
    }
  }
  return result ?? true
}

export const MainContactContext = createContext({
  openFilterDrawer: () => {},
  closeFilterDrawer: () => {},
  applyBuyerFilter: () => {},
  applyBrokerFilter: () => {},
  openContactDetailDrawer: () => {},
  closeContactDetailDrawer: () => {},
  setSelectedContact: (contact) => {},
  setSearch: (search) => {},
  setFilters: (filters) => {},
  toggleCheckContact: (contactId) => {},
  toggleCheckAllContacts: () => {},
  resetFilters: () => {},
  openAddToListDrawer: () => {},
  closeAddToListDrawer: () => {},
  toggleCheckList: (listId) => {},
  state: {
    isFilterDrawerOpen: true,
    contactUserType: null,
    isContactDetailDrawerOpen: false,
    isAddToListDrawerOpen: false,
    selectedContact: null,
    contacts: [],
    search: '',
    filters: {},
    checkedContacts: {},
    checkedLists: {},
    referralAgent: '',
    leadSource: ''
  }
})

const MainContactProvider = ({ children }) => {
  const searchParams = useSearchParams()
  const id = searchParams.get('id')
  const userType = searchParams.get('userType')
  const [state, setState] = useState({
    isFilterDrawerOpen: true,
    contactUserType: userType ?? 'Buyer',
    isContactDetailDrawerOpen: false,
    isAddToListDrawerOpen: false,
    selectedContact: null,
    contacts: [],
    search: '',
    filters: {},
    checkedContacts: {},
    checkedLists: {},
    referralAgent: '',
    leadSource: ''
  })
  const { users, isLoading: isUserLoading } = useGetUsers()

  const { searchUsers, searching } = useGetSearchUsers(
    state.search,
    state.contactUserType
  )
  const { filteredUsers, filtering } = useGetFilterUsers(
    state.contactUserType,
    state.filters
  )

  const getParamName = () => {
    if (state.referralAgent) return 'referralAgent'
    if (state.leadSource) return 'leadSource'
    return ''
  }

  const getParamId = () => {
    if (state.referralAgent) return state.referralAgent
    if (state.leadSource) return state.leadSource
    return ''
  }

  const { usersByParam } = useGetUsersByParam(
    getParamName(),
    getParamId(),
    state.contactUserType,
    state.contactUserType === 'Buyer' ? 'buyerData' : 'leadBrokerData'
  )

  const openFilterDrawer = () =>
    setState({
      ...state,
      isFilterDrawerOpen: true,
      isContactDetailDrawerOpen: false,
      isAddToListDrawerOpen: false,
      selectedContact: null
    })
  const closeFilterDrawer = () =>
    setState({ ...state, isFilterDrawerOpen: false })
  const applyBuyerFilter = () =>
    setState({ ...state, filters: {}, contactUserType: 'Buyer' })
  const applyBrokerFilter = () =>
    setState({ ...state, filters: {}, contactUserType: 'LeadBroker' })
  const setFilters = (filters) => setState({ ...state, filters })
  const resetFilters = (resetContactUserType) => {
    setState({
      ...state,
      ...(resetContactUserType && { contactUserType: 'Buyer' }),
      filters: {}
    })
  }

  const openContactDetailDrawer = () =>
    setState({
      ...state,
      isFilterDrawerOpen: false,
      isContactDetailDrawerOpen: true
    })
  const closeContactDetailDrawer = () =>
    setState({
      ...state,
      isContactDetailDrawerOpen: false,
      selectedContact: null
    })

  const openAddToListDrawer = () =>
    setState({ ...state, isAddToListDrawerOpen: true })
  const closeAddToListDrawer = () =>
    setState({ ...state, isAddToListDrawerOpen: false })

  const setSelectedContact = (contact) =>
    setState({
      ...state,
      selectedContact: contact,
      isContactDetailDrawerOpen: true,
      isFilterDrawerOpen: false
    })

  const toggleCheckContact = (contactId) =>
    setState({
      ...state,
      checkedContacts: {
        ...state.checkedContacts,
        [contactId]: !state.checkedContacts?.[contactId]
      }
    })
  const toggleCheckAllContacts = () => {
    const value =
      Object.values(state.checkedContacts).filter((val) => val === true)
        .length === users?.length
    const checkedContacts = {}
    if (searchUsers?.data?.length) {
      searchUsers?.data?.forEach?.(
        (user) => (checkedContacts[user?._id] = !value)
      )
    } else if (filteredUsers?.data?.length) {
      filteredUsers?.data?.forEach?.(
        (user) => (checkedContacts[user?._id] = !value)
      )
    } else users?.forEach?.((user) => (checkedContacts[user?._id] = !value))
    setState({ ...state, checkedContacts })
  }

  const toggleCheckList = (listId) =>
    setState({
      ...state,
      checkedLists: {
        ...state.checkedLists,
        [listId]: !state.checkedLists?.[listId]
      }
    })

  const setSearch = (search) => setState({ ...state, search })

  const setContacts = useRef((contacts) => {})
  setContacts.current = (contacts = []) => setState({ ...state, contacts })

  useEffect(() => {
    if (!id || !users?.length || (searching && filtering) || isUserLoading) {
      return
    }
    const _selectedContact = users.filter((user) => user._id === id)?.[0]
    if (_selectedContact) {
      setSelectedContact(_selectedContact)
    }
  }, [id, users, searching, filtering, isUserLoading])

  useEffect(() => {
    if (userType) {
      setState({ ...state, contactUserType: userType })
    }
  }, [userType])

  useEffect(() => {
    if (!state.search && filteredUsers?.data) {
      setContacts.current(filteredUsers.data)
    }
  }, [state.search])

  useEffect(() => {
    if (!state.referralAgent) {
      setContacts.current(filteredUsers?.data || searchUsers?.data)
    }
  }, [state.referralAgent])

  useEffect(() => {
    if (!state.leadSource) {
      setContacts.current(filteredUsers?.data || searchUsers?.data)
    }
  }, [state.leadSource])

  useEffect(() => {
    if (searchUsers?.data !== undefined) {
      setContacts.current(searchUsers.data)
    }
  }, [searchUsers])

  useEffect(() => {
    if (filteredUsers?.data !== undefined) {
      setContacts.current(filteredUsers.data)
    }
  }, [filteredUsers])

  useEffect(() => {
    if (usersByParam) {
      setContacts.current(usersByParam)
    }
  }, [usersByParam])

  return (
    <MainContactContext.Provider
      value={{
        state,
        setFilters,
        resetFilters,
        openFilterDrawer,
        closeFilterDrawer,
        applyBuyerFilter,
        applyBrokerFilter,
        openContactDetailDrawer,
        closeContactDetailDrawer,
        setSelectedContact,
        setSearch,
        toggleCheckContact,
        toggleCheckAllContacts,
        openAddToListDrawer,
        closeAddToListDrawer,
        toggleCheckList
      }}
    >
      {children}
    </MainContactContext.Provider>
  )
}

export default MainContactProvider
