import useGetUsers from 'hooks/useGetUsers'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import TableBody from './TableBody/TableBody'
import TableHead from './TableHead/TableHead'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import TablePagination from './TablePagination/TablePagination'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import { filterAssignmentsBySaleRepId, sortContacts } from 'pages/newContacts/context/MainContactContext'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'

const limit = 50
const UserTable = () => {
  const [page, setPage] = useState(1)
  const { isLoading } = useGetUsers()
  const { state } = useContactContext()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)

  const LoadingContacts = useCallback(() => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title='Loading contacts...' />
    </FadedAnimatedDiv>
  ), [])

  useEffect(() => {
    setPage(1)
  }, [state.contacts, state?.contactUserType])

  const filtertedContacts = useMemo(() => {
    const _contacts = state.contacts
    const _filterContacts = _contacts.filter((contact) => {
      const salesType = contact?.userType?.toLowerCase?.()
      if (state?.contactUserType?.toLowerCase?.() === 'leadbroker') {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
          filterAssignmentsBySaleRepId(
            contact?.leadBrokerData?.assignments,
            userObject,
            true,
            state?.filters?.salesRepOwner
          )
        )
      } else if (state?.contactUserType?.toLowerCase?.() === 'buyer') {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
          filterAssignmentsBySaleRepId(
            contact?.buyerData?.assignments,
            userObject,
            true,
            state?.filters?.salesRepOwner
          )
        )
      } else {
        return false
      }
    })
    return _filterContacts ?? []
  }, [limit, page, userObject, state?.contacts, state?.contactUserType, state?.filters?.salesRepOwner])

  useEffect(() => {
    if (filtertedContacts?.length) {
      toast.success(`Found ${filtertedContacts?.length} contacts`)
    }
  }, [filtertedContacts])

  if (isLoading) return <LoadingContacts />
  return (
    <div className='mt-8 flex flex-col justify-center'>
      <div
        // style={{ paddingLeft: '2.7rem', paddingRight: '1.9rem' }}
        className={'inline-block py-2 align-middle w-full'}
      >
        <div className='relative overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
          <table className='min-w-full table-fixed divide-y divide-gray-300'>
            <TableHead page={page} />
            <TableBody contacts={filtertedContacts.slice((page - 1) * limit, limit * page).sort(sortContacts)} />
          </table>
          <TablePagination
            page={page}
            setPage={setPage}
            customContacts={null}
            limit={limit}
            contacts={filtertedContacts.sort(sortContacts)}
          />
        </div>
      </div>
    </div>
  )
}

export default UserTable
