import React, { useState } from 'react'
import CustomModal from 'components/Modal'
import CustomSelect from 'components/CustomSelect'

const AllocationNameModal = ({
  open,
  setOpen,
  setAllocationConfirmModal,
  setOpenCreationModal,
  allocationData,
  handleSubmit,
  loading
}) => {
  const [selectedOption, setSelectedOption] = useState({ id: 0 })

  const setAllocationName = (e) => {
    setSelectedOption({ id: e })
  }

  const onConfirm = () => {
    setOpen(false)
    setAllocationConfirmModal(true)
    handleSubmit()
  }

  const closeModal = () => {
    setOpen(false)
  }
  return (
    <CustomModal
      isOpen={open}
      closeModal={closeModal}
      contentLabel='Cancel Modal'
    >
      <div className='w-136 max-w-full p-10 text-center rounded bg-grey-8 h-96'>
        <h1 className='text-softBlack font-bold text-xl mb-0 font-openSans'>
          Name your Allocation
        </h1>
        <p className='mb-6 mt-6 text-softBlack font-openSans font-normal text-base'>
          Select or create a new name for your allocation
        </p>
        <CustomSelect
          label='ALLOCATION NAME'
          options={allocationData}
          selectedOption={selectedOption?.id}
          setSelectedOption={(e) => setAllocationName(e)}
          labelClasses={'text-left'}
          inputClasses={undefined}
          defaultValue={undefined}
          createNewOptionText={'Create New'}
          setOpenCreationModal={setOpenCreationModal}
        />
        <button
          className='rounded bg-volt text-base text-softBlack font-light italic font-openSans py-3 px-6 mt-8'
          onClick={onConfirm}
          disabled={selectedOption.id === 0}
        >
          Confirm Allocation{' '}
        </button>
      </div>
    </CustomModal>
  )
}

export default AllocationNameModal
