// @ts-nocheck
import React, { useContext } from 'react'

import Checkbox from 'components/Checkbox'
import CustomTextArea from 'components/CustomTextArea'
import CustomRadio from 'components/CustomRadio'

import BottomActions from './BottomActions'
import ThirdPartyRecord from './ThirdPartyRecord'
import ClientRisk from './ClientRisk'

import { CreateOfferContext } from '../context/CreateOfferContext'

import { BUYING_AS, AGENT_CHECKBOXES } from './constants'

const Fintrac = ({ setStep }) => {
  const {
    isThirdParty,
    setIsThirdParty,
    riskType,
    setRiskType,
    selectedOption,
    setSelectedOption,
    checkedProperties,
    setCheckedProperties,
    thirdPartDescription,
    setThirdPartDescription,
    riskExplanation,
    setRiskExplanation,
    businessRelationshipExplanation,
    setBusinessRelationshipExplanation
  } = useContext(CreateOfferContext)

  const handleCheckedProperties = (_option) => {
    const { id } = _option
    const index = checkedProperties.findIndex((_property) => _property.id === id)
    if (index !== -1) {
      const filteredProperties = checkedProperties?.filter((_property) => _property.id !== id)
      setCheckedProperties([...filteredProperties])
    } else {
      setCheckedProperties([...checkedProperties, _option])
    }
  }

  const onChangeThirdPartyDescription = (e) => {
    setThirdPartDescription(e.target.value)
  }

  const handleBusinessExplanation = (e) => {
    setBusinessRelationshipExplanation(e.target.value)
  }

  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    setStep((prev) => prev + 1)
  }

  return (
    <div className='text-softBlack'>
      <div className="pb-4 border-b-2 border-softBlack">
        <p className="my-0 text-xl font-bold">Fintrac</p>
      </div>

      <div className="mt-10">
        <p className='my-0 font-medium text-lg mb-6'>Are you buying this Unit for a 3rd party?</p>
        <div className='flex gap-10 mt-6'>
          {BUYING_AS?.map((_option, index) => (
            <CustomRadio
              key={index}
              label={_option?.label}
              checked={isThirdParty === _option.value}
              onClick={() => setIsThirdParty(_option.value)}
            />
          ))}
        </div>
      </div>

      <CustomTextArea label="Why you think your client may be acting on behalf a 3rd party" containerClasses='mt-6' value={thirdPartDescription} onChange={onChangeThirdPartyDescription} />

      {isThirdParty && <ThirdPartyRecord />}

      <ClientRisk
        riskType={riskType}
        setRiskType={setRiskType}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        riskExplanation={riskExplanation}
        setRiskExplanation={setRiskExplanation}
      />

      <div className='mb-8 mt-10'>
        <p className='font-bold text-xl mb-6'>Business Relationship</p>
        <p className='my-0 text-base'>Purpose and Intended Nature of the Business Relationship</p>
      </div>

      <div>
        <p className='mt-0 text-base font-bold mb-6'>Acting as an Agent for the purchase or sale of:</p>
        <div className='grid grid-cols-2 gap-6'>
          {AGENT_CHECKBOXES?.map((_option, index) => (
            <Checkbox
              {..._option}
              key={index}
              labelClasses='text-base'
              checked={checkedProperties.findIndex(_prop => _prop.id === _option.id) !== -1}
              handleChange={() => handleCheckedProperties(_option)}
            />
          ))}
        </div>
        {checkedProperties.findIndex(_prop => _prop.id === 5) !== -1 && (
          <CustomTextArea placeholder='Describe here' value={businessRelationshipExplanation} onChange={handleBusinessExplanation} />
        )}
      </div>
      <BottomActions onBack={onBack} onNext={onNext} />
    </div>
  )
}

export default Fintrac
