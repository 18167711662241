// @ts-nocheck
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getReservations } from 'store/actions/reservationActions'

const useGetReservationsById = () => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { data, isLoading, refetch } = useQuery(
    ['getResrvationById', projectId],
    ({ queryKey }) => getReservations(queryKey[1]),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false
    }
  )
  return { reservations: data, isLoading, refetch }
}

export default useGetReservationsById
