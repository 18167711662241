/* eslint-disable multiline-ternary */
import React, {
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react'
import { useSelector } from 'react-redux'
import { HorizontalCardContact } from 'components/HorizontalCardProfile'

import TableHeader from 'components/TableHeader'

import {
  updateLeadSource,
  updateLeadStatus,
  updateSalesRep
} from 'store/actions/usersActions'

import { ContactContext } from './context/Contacts'

import { InfoTab } from './InfoTab'
import MeetingTab from './MeetingTab'
import { TaskTab } from './TaskTab'
import { NotesTab } from './NotesTab'
import EmailTab from './EmailTab'
import MessagesTab from './MessagesTab'
import ActivityTab from './ActivityTab'

import EditContactModal from './EditContactModal'
import BrokerClientsTab from './BrokerClientsTab/BrokerClientsTab'
import { useHistory } from 'react-router-dom'
import { HistoryContext } from 'global/HistoryContext'

import { toast } from 'react-hot-toast'
import { CallTab } from './CallTab'
import useUpdateUsers from 'hooks/useUpdateUsers'
import { FadedAnimatedDiv } from 'components'
import Searching from './Searching'

const ContactItem = ({
  handleContactDelete,
  isSalesRep,
  showBackButton = true
}) => {
  // const mountedRef = useRef(true)
  const { createOrUpdateUser: createOrUpdateUserAPI } = useUpdateUsers()
  const {
    updateContact,
    getUsersData,
    selectedContactObject: contact,
    isLoading
  } = useContext(ContactContext)
  // @ts-ignore
  const [selectedHeader, setSelectedHeader] = useState(
    // @ts-ignore
    window.openTasksTab ? 'Tasks' : 'Info'
  )
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [broker, setBroker] = useState('Select Broker')
  const [referralAgent, setReferralAgent] = useState('Select Referral Agent')
  const { lastAction } = useContext(HistoryContext)
  const history = useHistory()

  const createOrUpdateUser = useCallback(
    (
      params,
      showUpdateMessage = true,
      updateLoggedUserObjectAfterSave = false
    ) => {
      if (
        !params.buyerData.referralAgent ||
        params.buyerData.referralAgent?.length === 0
      ) {
        delete params.buyerData.referralAgent
      }
      createOrUpdateUserAPI(
        params,
        showUpdateMessage,
        updateLoggedUserObjectAfterSave
      )
    },
    [createOrUpdateUserAPI]
  )

  const handleBrokerChange = async (value) => {
    if (!value || value === 'Select Broker') return
    setBroker(value)
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (newUserData?.buyerData?.assignments?.[0]) {
      newUserData.buyerData.assignments[0].leadBroker = value
    }
    try {
      updateContact(newUserData)
      await createOrUpdateUser(newUserData, false, false)
    } catch (err) {
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleReferralAgentChange = async (value) => {
    if (!value || value === 'Select Referral Agent') return
    setReferralAgent(value)
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    newUserData.buyerData.referralAgent = value
    try {
      updateContact(newUserData)
      await createOrUpdateUser(newUserData, false, false)
    } catch (err) {
      updateContact(prevValue)
      console.log(err)
    }
  }

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setBroker(
        contact?.buyerData?.assignments?.[0]?.leadBroker || 'Select Broker'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.buyerData?.assignments?.[0]?.leadBroker])

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setReferralAgent(
        contact?.buyerData?.referralAgent || 'Select Referral Agent'
      )
    }
  }, [contact?.buyerData?.referralAgent])

  const handleRatingChange = async (value) => {
    const toastId = toast.loading('loading...')
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (contact?.userType === 'LeadBroker') {
      if (Object.keys(newUserData?.leadBrokerData)?.length) {
        newUserData.leadBrokerData.rating = value
      }
    } else {
      if (Object.keys(newUserData?.buyerData)?.length) {
        newUserData.buyerData.rating = value
      }
    }

    try {
      updateContact(newUserData)
      await createOrUpdateUser(newUserData, false, false)
      toast.dismiss(toastId)
      toast.success('Rating updated successfully')
    } catch (err) {
      toast.dismiss(toastId)
      toast.error("Rating couldn't be updated")
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleBrokerageCompanyChange = async (company) => {
    const toastId = toast.loading('loading...')
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (newUserData.leadBrokerData) {
      newUserData.leadBrokerData.brokerageCompany = company
    } else {
      newUserData.leadBrokerData = { brokerageCompany: company }
    }

    try {
      updateContact(newUserData)
      await createOrUpdateUser(newUserData, false, false)
      toast.dismiss(toastId)
      toast.success('Brokerage company updated successfully')
    } catch (err) {
      toast.dismiss(toastId)
      toast.error("Brokerage company couldn't be updated")
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleLeadStatusChange = async (type) => {
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (Object.keys(newUserData?.buyerData).length) {
      newUserData.buyerData.leadStatus = type
    }

    try {
      updateContact(newUserData)
      await updateLeadStatus(type, contact._id)
    } catch (err) {
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleLeadSourceChange = async (leadSource) => {
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (Object.keys(newUserData?.buyerData).length) {
      newUserData.buyerData.leadSource = leadSource
    }

    try {
      updateContact(newUserData)
      await updateLeadSource(leadSource, contact._id)
    } catch (err) {
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleReasonForBuyerLostChange = async (reasonForLost) => {
    const toastId = toast.loading('updating Reason for lost...')
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (newUserData.buyerData) {
      newUserData.buyerData.reasonForLost = reasonForLost
    } else {
      newUserData.buyerData = { reasonForLost: reasonForLost }
    }
    try {
      updateContact(newUserData)
      await createOrUpdateUser(newUserData, false, false)
      toast.dismiss(toastId)
      toast.success('Reason for lost updated successfully')
    } catch (err) {
      toast.dismiss(toastId)
      toast.error("Reason for lost couldn't be updated")
      updateContact(prevValue)
      console.log(err)
    }
  }

  const handleBuyerLeadApproachChange = async (leadApproach) => {
    const toastId = toast.loading('updating lead approach...')
    const prevValue = { ...contact }
    const newUserData = { ...contact }
    if (newUserData.buyerData) {
      newUserData.buyerData.leadApproach = leadApproach
    } else {
      newUserData.buyerData = { leadApproach: leadApproach }
    }
    try {
      updateContact(newUserData)
      await createOrUpdateUser(newUserData, false, false)
      toast.dismiss(toastId)
      toast.success('Lead approach updated successfully')
    } catch (err) {
      toast.dismiss(toastId)
      toast.error("Lead approach couldn't be updated")
      updateContact(prevValue)
      console.log(err)
    }
  }

  // @ts-ignore
  const handleSalesRepChange = async (type, params) => {
    const toastId = toast.loading('loading...')
    try {
      await updateSalesRep({
        salesRep: params.salesRep,
        contactId: contact?._id
      })
      toast.dismiss(toastId)
      toast.success('SalesRep updated successfully')
    } catch (error) {
      toast.dismiss(toastId)
      toast.error("SalesRep couldn't be updated")
    }
  }

  const onClickEdit = useCallback(() => {
    // @ts-ignore
    setShowCreateModal(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact])

  const onSuccess = () => {
    getUsersData()
  }

  const getSalesRepOwner = (contact) => {
    const firstName =
      contact?.buyerData?.assignments[0]?.salesRep?.firstName ?? ''
    const lastName =
      contact?.buyerData?.assignments[0]?.salesRep?.lastName ?? ''
    return firstName + ' ' + lastName
  }

  const getTableHeaders = useCallback(() => {
    const headers = [
      'Info',
      'Broker Clients',
      'Activity',
      'Tasks',
      'Meetings',
      'Messages',
      'Emails',
      'Notes',
      'Calls'
    ]
    if (contact?.userType !== 'LeadBroker') {
      return headers.filter((header) => header !== 'Broker Clients')
    }
    return headers
  }, [contact])

  const matchHeader = (header) => selectedHeader === header && !isLoading

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setBroker(
        contact?.buyerData?.assignments?.[0]?.leadBroker || 'Select Broker'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.buyerData?.assignments?.[0]?.leadBroker])

  const LoadingContacts = useCallback(
    () => (
      <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
        <Searching
          title={'Loading contact info...'}
        />
      </FadedAnimatedDiv>
    ),
    []
  )

  if (isLoading) {
    return <LoadingContacts />
  }

  return (
    <div
      className='p-6 w-full flex flex-col bg-grey-8'
      style={{ minHeight: '90vh' }}
    >
      <EditContactModal
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        onSuccess={onSuccess}
        contact={contact}
        updateContact={updateContact}
      />
      {showBackButton && (
        <div>
          <button
            onClick={() => {
              lastAction ? history.goBack() : history.push('/admin/contacts')
            }}
            className='px-2 text-gray-400 hover:text-yellow-400'
          >
            {'< Back'}
          </button>
        </div>
      )}
      <div className='mb-10'>
        <HorizontalCardContact
          {...contact}
          rating={
            contact?.userType === 'LeadBroker'
              ? contact?.leadBrokerData?.rating
              : contact?.buyerData?.rating
          }
          leadStatus={contact?.buyerData?.leadStatus || 'New Lead'}
          handleRatingChange={handleRatingChange}
          handleLeadStatusChange={handleLeadStatusChange}
          onClickEdit={onClickEdit}
          broker={broker}
          referralAgent={referralAgent}
          setBroker={setBroker}
          salesRepOwner={getSalesRepOwner(contact)}
          handleLeadSourceChange={handleLeadSourceChange}
          handleReasonForBuyerLostChange={handleReasonForBuyerLostChange}
          handleBuyerLeadApproachChange={handleBuyerLeadApproachChange}
          handleSalesRepChange={handleSalesRepChange}
          handleBrokerChange={handleBrokerChange}
          handleReferralAgentChange={handleReferralAgentChange}
          handleBrokerageCompanyChange={handleBrokerageCompanyChange}
          developerAdminId={userObject?._id}
          projectId={projectId}
          buyerEmail={contact?.email}
          onClickDelete={handleContactDelete}
          isSalesRep={isSalesRep}
        />
      </div>
      <div className='w-full grow'>
        <TableHeader
          headers={getTableHeaders()}
          handleSelectHeader={(header) => setSelectedHeader(header)}
          selectedHeader={selectedHeader}
        />
        {selectedHeader === 'Broker Clients' && (
          <BrokerClientsTab contact={contact} />
        )}
        {selectedHeader === 'Meetings' && <MeetingTab contact={contact} />}
        {selectedHeader === 'Tasks' && <TaskTab contact={contact} />}
        {!['Meetings', 'Tasks'].includes(selectedHeader) && (
          <div className='p-4 rounded-md h-full'>
            {matchHeader('Info') && <InfoTab contact={contact} user={userObject?._id} />}
            {selectedHeader === 'Notes' && (
              <NotesTab contact={contact} user={userObject?._id} />
            )}
            {selectedHeader === 'Activity' && <ActivityTab contact={contact} />}
            {selectedHeader === 'Emails' && <EmailTab contact={contact} />}
            {selectedHeader === 'Messages' && <MessagesTab contact={contact} />}
            {selectedHeader === 'Calls' && <CallTab contact={contact} />}
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactItem
