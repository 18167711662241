// @ts-nocheck
/* eslint-disable no-unused-vars */
import { api, ApiErrors } from 'services/api'
import { message } from 'antd'
import { getRandomInteger, updateLoggedUserObject } from 'utils'

export const getUsers = (id = '', filter = '') =>
  new Promise((resolve, reject) => {
    api.get(`users/${id}${filter}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteList = (
  listId = '',
  contactId = '',
  setDeleteLoading = ''
) =>
  new Promise((resolve, reject) => {
    api.delete(`lists/${listId}/contacts/${contactId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
        setDeleteLoading(false)
      } else {
        ApiErrors(response)
        setDeleteLoading(false)
        reject()
      }
    })
  })

export const listDelete = (listId = '', setDeleteLoading = '') =>
  new Promise((resolve, reject) => {
    api.delete(`lists/${listId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
        setDeleteLoading(false)
      } else {
        ApiErrors(response)
        setDeleteLoading(false)
        reject()
      }
    })
  })

export const getAllLeads = (filter = '') =>
  new Promise((resolve, reject) => {
    api.get(`leads/${filter}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getLeadsReport = (type = 'today') =>
  new Promise((resolve, reject) => {
    // type =  today | 7days
    api.get(`users/reports/daily/leads?type=${type}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getAllBrokerUsers = () =>
  new Promise((resolve, reject) => {
    api.get('users/?userType=LeadBroker').then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getBrokerUsers = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`users/leadbrokers/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getAllReferralAgents = () =>
  new Promise((resolve, reject) => {
    api.get('users/?userType=ReferralAgent').then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateUser = ({
  params,
  showUpdateMessage = true,
  updateLoggedUserObjectAfterSave = false
}) =>
  new Promise((resolve, reject) => {
    const { id, ...restOfParams } = params

    // If id, so we have to update the record, or we're gonna create
    const verb = id ? 'put' : 'post'
    const url = id ? `users/${id}` : 'users'

    api[verb](url, restOfParams).then((response) => {
      if (response.ok) {
        if (showUpdateMessage) {
          message.success(
            `${params.firstName} was ${
              id ? 'successfully updated' : 'created successfully'
            }`
          )
        }

        if (updateLoggedUserObjectAfterSave) {
          updateLoggedUserObject(response.data)
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createBuyerToSalesRepAssignment = (params) =>
  new Promise((resolve, reject) => {
    api.post('users/buyer/assignments/create', params).then((response) => {
      if (response.ok) {
        message.success('Assigment was succesfully created')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateBuyerToSalesRepAssignment = (params) =>
  new Promise((resolve, reject) => {
    api.post('users/buyer/assignments/update', params).then((response) => {
      if (response.ok) {
        message.success('Assigment was succesfully updated')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteUser = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/users/${id}`).then((response) => {
      if (response.ok) {
        message.success('User deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const changeUserImages = (props, showSuccessMessage) =>
  new Promise((resolve, reject) => {
    const { frontPhotoId, backPhotoId } = props

    const params = new FormData()

    if (frontPhotoId) {
      params.append('frontPhotoId', frontPhotoId)
    }

    if (backPhotoId) {
      params.append('backPhotoId', backPhotoId)
    }

    api.post(`/users/${props.userId}/images`, params).then((response) => {
      if (response.ok) {
        if (showSuccessMessage) {
          message.success('Image successfully updated')
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteUserImage = (props) =>
  new Promise((resolve, reject) => {
    // Photo side: front or back
    const { userId, photoSide } = props

    api.delete(`users/${userId}/images/${photoSide}`, {}).then((response) => {
      if (response.ok) {
        message.success('Image successfully deleted')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const photoIDOCR = (imageFile) =>
  new Promise((resolve, reject) => {
    const params = new FormData()
    params.append('frontImage', imageFile)

    // resolve({
    //   result: {
    //     documentNumber: 'K12-34-5678',
    //     firstName: 'NICOLAS',
    //     middleName: 'ELIZABETH',
    //     lastName: 'SAMPLE',
    //     fullName: 'CARON ELIZABETH SAMPLE',
    //     sex: 'F',
    //     height: '5'-06\'',
    //     weight: '140',
    //     age: 55,
    //     dob: '1966/01/11',
    //     dob_day: 11,
    //     dob_month: 1,
    //     dob_year: 1966,
    //     expiry: '2023/01/11',
    //     expiry_day: 11,
    //     expiry_month: 1,
    //     expiry_year: 2023,
    //     daysToExpiry: 419,
    //     issued: '2017/01/11',
    //     issued_day: 11,
    //     issued_month: 1,
    //     issued_year: 2017,
    //     daysFromIssue: 1773,
    //     address1: '123 NORTH STREET\nAPT. 2',
    //     address2: 'TOPEKA, KS',
    //     postcode: '66612-1234',
    //     optionalData: 'XX123XWMXX1',
    //     optionalData2: '23XWMX123XWM12',
    //     vehicleClass: 'A',
    //     restrictions: 'NONE',
    //     endorsement: 'NONE',
    //     documentType: 'D',
    //     documentSide: 'FRONT',
    //     issuerOrg_region_full: 'Kansas',
    //     issuerOrg_region_abbr: 'KS',
    //     issuerOrg_full: 'United States',
    //     issuerOrg_iso2: 'US',
    //     issuerOrg_iso3: 'USA',
    //     nationality_full: 'United States',
    //     nationality_iso2: 'US',
    //     nationality_iso3: 'USA',
    //     eyeColor: 'BRO',
    //     internalId: '14'
    //   },
    //   authentication: {
    //     score: 0,
    //     breakdown: {
    //       data_visibility: {
    //         passed: true
    //       },
    //       image_quality: {
    //         passed: true
    //       },
    //       feature_referencing: {
    //         passed: true
    //       },
    //       exif_check: {
    //         passed: true
    //       },
    //       publicity_check: {
    //         passed: false,
    //         code: 140,
    //         reason: 'This document could be obtained in the public domain',
    //         severity: 'high'
    //       },
    //       text_analysis: {
    //         passed: true
    //       },
    //       biometric_analysis: {
    //         passed: false,
    //         code: 161,
    //         reason: 'Predicted age of photo is 30, 55 shown on document',
    //         severity: 'low'
    //       },
    //       security_feature_check: {
    //         passed: true
    //       },
    //       recapture_check: {
    //         passed: true
    //       }
    //     },
    //     warning: [
    //       'This document could be obtained in the public domain',
    //       'Predicted age of photo is 30, 55 shown on document'
    //     ]
    //   },
    //   vaultid: 'eLFxfvat6VvXV2yiRqq8f1W9NRAlhvDt',
    //   matchrate: 1,
    //   executionTime: 8.546661853790283,
    //   responseID: 'ea8d9c30eb5f6f9d7fce8b5f367eb88a',
    //   quota: 5,
    //   credit: 36,
    //   imageUrl: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/1637346012118-0409%20Kansas_Real_ID.1.jpg'
    // })

    api.post('/users/document/ocr', params).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const changeUserAvatarImage = ({ file, _id }) =>
  new Promise((resolve, reject) => {
    const params = new FormData()
    params.append('userId', _id)
    params.append('userAvatar', file)

    api.post('users/avatar', params).then((response) => {
      if (response.ok) {
        message.success('User avatar successfully updated')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createAssignment = (params, showSuccessMessage = false) =>
  new Promise((resolve, reject) => {
    api.post('users/buyer/assignments/create', params).then((response) => {
      if (response.ok) {
        if (showSuccessMessage) {
          message.success('Assignment successfully created')
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateAssignment = (params, showSuccessMessage = false) =>
  new Promise((resolve, reject) => {
    api.post('users/buyer/assignments/update', params).then((response) => {
      if (response.ok) {
        if (showSuccessMessage) {
          message.success('Assignment successfully updated')
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const externalGetBrokers = (filter) =>
  new Promise((resolve, reject) => {
    api.get(`users/brokers${filter}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const userSendSurvey = (params) =>
  new Promise((resolve, reject) => {
    api.post('users/survey', params).then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getBuyers = () =>
  new Promise((resolve, reject) => {
    api.get('users/buyers').then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectBuyers = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`users/buyers/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const startOAuthAuthorization = () =>
  new Promise((resolve, reject) => {
    api.get('users/oauth/redirect').then((response) => {
      if (response.ok) {
        resolve(response.data.url)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getUserEmails = ({
  id = null,
  offset = 0,
  limit = 50,
  search = ''
}) =>
  new Promise((resolve, reject) => {
    const url = id ? `emails/${id}` : 'emails'
    api
      .get(`users/provider/${url}`, { offset, limit, search: search.trim() })
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const getEmailAttachment = ({ attachmentId }) =>
  new Promise((resolve, reject) => {
    const params = {
      attachment: attachmentId
    }
    api.post('users/provider/emails/attachment', params).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getUserThreads = ({
  threadId = null,
  offset = 0,
  limit = 50,
  search = ''
}) =>
  new Promise((resolve, reject) => {
    const url = threadId ? `threads/${threadId}` : 'threads'
    api
      .get(`users/provider/${url}`, { offset, limit, search: search.trim() })
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const sendEmail = (body, subject, to, fileAttachments, cc, bcc) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('body', body)
    formData.append('subject', subject)
    formData.append('to', JSON.stringify(to))
    formData.append('cc', JSON.stringify(cc))
    formData.append('bcc', JSON.stringify(bcc))

    fileAttachments.forEach((file, i) => {
      formData.append('attachments', file)
    })

    api.post('users/provider/emails', formData).then((response) => {
      if (response.ok) {
        message.success('Email successfully sent!')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const forgotPassword = (email) =>
  new Promise((resolve, reject) => {
    const params = {
      email
    }

    api.post('users/forget-password', params).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const setResetPassword = (resetToken, newPassword) =>
  new Promise((resolve, reject) => {
    const params = {
      token: resetToken,
      newPassword
    }

    api.post('users/set-password', params).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getCalendarEvents = (id = '', filter = '') =>
  new Promise((resolve, reject) => {
    api.get(`users/provider/calendar/${id}${filter}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateCalendarEvent = (params) =>
  new Promise((resolve, reject) => {
    const { _id } = params

    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id
      ? `users/provider/calendar/${_id}`
      : 'users/provider/calendar'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(
          `Event was ${_id ? 'successfully updated' : 'created successfully'}`
        )
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteCalendarEvent = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/users/provider/calendar/${id}`).then((response) => {
      if (response.ok) {
        message.success('Event deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectBrokers = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`users/brokers/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const removeBrokerFromAssignment = (buyerEmail, project) =>
  new Promise((resolve, reject) => {
    const params = {
      buyer: buyerEmail,
      project
    }

    api
      .post('users/buyer/assignments/remove-broker', params)
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const getCrmReportData = (projectId) =>
  new Promise((resolve, reject) => {
    const apis = {
      totalCallsUrl: `calls/totals/project/${projectId}`,
      allTimeCalls: `calls/totals/project/${projectId}`,
      totalMeetingsUrl: `meetings/totals/project/${projectId}`,
      totalTasksUrl: `tasks/totals/project/${projectId}`,
      allTimeTasks: `tasks/totals/project/${projectId}`,
      totalLeadsUrl: `users/totals/project/${projectId}/leads/all`,
      totalBuyerBrokerLeads: `users/counts/leadtypes/today/${projectId}`,
      allTimeBuyerLeads: `users/counts/buyers/alltime/${projectId}`,
      allTimeBrokerLeads: `users/counts/brokers/alltime/${projectId}`,
      allSalesrepAndLeadsCount: `users/totals/project/${projectId}/leads/salesrep`,
      maleFemaleLeadsToday: `users/counts/gender/today/${projectId}`,
      allTimeMaleFemaleLeads: `users/counts/genders/alltime/${projectId}`,
      opportunities: `opportunities/today/count/${projectId}`,
      allTimeOpportunities: `users/counts/opportunity/alltime/${projectId}`,
      reservations: `reservation/today/count/${projectId}`,
      allTimeReservations: `users/counts/reservation/alltime/${projectId}`,
      leadsBySource: `users/counts/sources/salesrep/all/${projectId}`,
      allTimeLeadsBySource: `users/counts/sources/salesrep/alltime/${projectId}`,
      allTimeLeadsByStage: `users/counts/status/salesrep/alltime/${projectId}`,
      totalProspectsToday: `users/counts/prospects/today/${projectId}`,
      allTimeProspects: `users/counts/prospects/alltime/${projectId}`,
      totalContractsToday: `users/counts/contracts/today/${projectId}`,
      buyerRating: `users/counts/rating/today/${projectId}`,
      allTimeContracts: `users/counts/contract/alltime/${projectId}`,
      allTimeDemandUnits: `users/counts/demand/unit/alltime/${projectId}`,
      allTimeDemanddBudget: `users/counts/demand/budget/alltime/${projectId}`,
      allTimeProjectInterests: `users/counts/demand/unit/alltime/${projectId}`,
      allTimeFloorInterests: `users/counts/demand/floor/alltime/${projectId}`,
      allTimeTimelineToPurchase: `users/counts/demand/timeline/alltime/${projectId}`,
      allTimeNeedsFinancing: `users/counts/demand/financing/alltime/${projectId}`,
      allTimeContactSum: `users/counts/contacts/alltime/${projectId}`,
      todaysBuyerRating: `users/counts/rating/today/${projectId}`
    }

    const promises = []

    Object.keys(apis).forEach(async (key) => {
      promises.push(api.get(apis[key]))
    })

    Promise.all(promises)
      .then((res) => {
        const response = {}
        const keys = Object.keys(apis)
        Object.values(res).forEach((item, index) => {
          response[keys[index]] = item.data
        })
        response.totalBuyerLeads = {
          today: response?.totalBuyerBrokerLeads?.buyerLeadToday
        }
        response.totalBrokerLeads = {
          today: response?.totalBuyerBrokerLeads?.brokerLeadToday
        }
        response.allTimeBuyerLeads = { today: response?.allTimeBuyerLeads }
        response.allTimeBrokerLeads = { today: response?.allTimeBrokerLeads }
        response.totalProspectsToday = { today: response?.totalProspectsToday }
        response.totalContractsToday = { today: response?.totalContractsToday }
        response.allTimeContracts = { today: response?.allTimeContracts }
        response.allTimeContactSum = { today: response?.allTimeContactSum }
        response.allTimeProjectInterests = [
          ...response?.allTimeProjectInterests?.map(({ _id, count }) => ({
            _id: _id?._id,
            count
          }))
        ]
        resolve(response)
      })
      .catch((error) => {
        ApiErrors(error)
        reject(error)
      })
  })

export const getUserLists = () =>
  new Promise((resolve, reject) => {
    api.get('lists').then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateUserLists = (
  listOfIds = [],
  listOfUsers = [],
  previousData = {}
) =>
  new Promise((resolve, reject) => {
    const promises = []
    listOfIds.forEach((listId) => {
      if (!previousData[listId]) {
        promises.push(api.put(`lists/${listId}`, { users: listOfUsers }))
      } else {
        promises.push(
          api.put(`lists/${listId}`, {
            users: [...listOfUsers, ...previousData[listId]]
          })
        )
      }
    })

    Promise.all(promises).then((response) => resolve(response))
  })

export const updateUserListsNew = ({
  listOfIds = [],
  listOfUsers = [],
  previousData = {}
}) =>
  new Promise((resolve, reject) => {
    const promises = []
    listOfIds.forEach((listId) => {
      if (!previousData[listId]) {
        promises.push(api.put(`lists/${listId}`, { users: listOfUsers }))
      } else {
        const listToSend = [
          ...new Set([...listOfUsers, ...previousData[listId]])
        ]
        promises.push(api.put(`lists/${listId}`, { users: listToSend }))
      }
    })

    Promise.all(promises).then((response) => resolve(response))
  })

export const getBrokerClients = (id) =>
  new Promise((resolve, reject) => {
    api.get(`users/leadbrokers/${id}/buyers`).then((response) => {
      if (response.ok) return resolve(response.data)
      ApiErrors(response)
      reject()
    })
  })

export const updateLeadSource = (leadSource, userId) =>
  new Promise((resolve, reject) => {
    api.put(`users/${userId}/leadsource`, { leadSource }).then((response) => {
      if (response.ok) return resolve(response.data)
      ApiErrors(response)
      reject()
    })
  })

export const updateLeadStatus = (leadStatus, userId) =>
  new Promise((resolve, reject) => {
    api.put(`users/${userId}/leadstatus`, { leadStatus }).then((response) => {
      if (response.ok) return resolve(response.data)
      ApiErrors(response)
      reject()
    })
  })

export const createUserConversations = ({ user, phone }) =>
  new Promise((resolve, reject) => {
    api.post('conversations', { user, phone }).then((response) => {
      if (response.ok) {
        message.success('Created User Conversation')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getUserConversations = ({ userId }) =>
  new Promise((resolve, reject) => {
    api.get(`conversations/${userId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const sendConversationMessage = ({ userId, message }) =>
  new Promise((resolve, reject) => {
    api.post(`conversations/sms/${userId}`, { message }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getConversationMessages = ({ userId }) =>
  new Promise((resolve, reject) => {
    api.get(`conversations/sms/${userId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateSalesRep = ({ salesRep, contactId }) =>
  new Promise((resolve, reject) => {
    api.put(`users/${contactId}/salesrep`, { salesRep }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

// Nylas Scheduler
export const getNylasSchedulerAccessToken = () =>
  new Promise((resolve, reject) => {
    api.get('users/provider/scheduler/access-token').then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

// Nylas Scheduler
export const getNylasSchedulerPages = () =>
  new Promise((resolve, reject) => {
    api.get('users/provider/scheduler/pages').then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOpportunity = (payload) =>
  new Promise((resolve, reject) => {
    api.post('opportunities', payload).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getAllDropDowns = (id) =>
  new Promise((resolve, reject) => {
    api.get(`dropdowns/${id}`).then((res) => {
      if (res.ok) {
        resolve(res.data)
      } else {
        ApiErrors(res)
        reject()
      }
    })
  })

export const getSalesrepDashboardData = (id) =>
  new Promise((resolve, reject) => {
    const apis = {
      opportunitiesWon: `opportunities/salesrep/${id}/stage/Won`,
      contactsToday: `users/counts/contacts/salesrep/${id}/today`,
      opportunitiesLost: `opportunities/salesrep/${id}/stage/Lost `,
      totalOpportunities: `opportunities/salesrep/${id}`,
      totalContacts: `users/counts/contacts/salesrep/${id}/all `,
      totalReservations: `reservation/alltime/count/salesrep/${id}`,
      newContactsToday: `users/counts/contacts/salesrep/${id}/today`
    }

    const promises = []

    Object.values(apis).forEach(async (link) => {
      promises.push(api.get(link))
    })

    Promise.all(promises).then((res) => {
      const response = {}
      const keys = Object.keys(apis)
      Object.values(res).forEach((item, index) => {
        response[keys[index]] = item.data
      })
      resolve(response)
    })
  })

export const updateUserPhotos = ({ formData, userId }) =>
  new Promise((resolve, reject) => {
    api.post(`users/${userId}/images`, formData).then((response) => {
      if (response.ok) {
        return resolve(response.data)
      }
      ApiErrors(response)
      reject()
    })
  })

export const updateSalesrepProfile = ({ params, userId }) =>
  new Promise((resolve, reject) => {
    api.put(`users/new/${userId}`, params).then((response) => {
      if (response.ok) {
        return resolve(response.data)
      }
      ApiErrors(response)
      reject()
    })
  })

export const getBrokerageCompanies = () =>
  new Promise((resolve, reject) => {
    api.get('/users/broker/brokerage-companys').then((response) => {
      if (response.ok) {
        const result = {
          values: response.data
        }
        return resolve(result)
      }
      ApiErrors(response)
      reject()
    })
  })

export const getDyanamicDropdownData = (projectId, key) =>
  new Promise((resolve, reject) => {
    api.get(`dropdowns/${projectId}/${key}`).then((response) => {
      if (response.ok) {
        return resolve(response.data ?? { values: [] })
      }
      ApiErrors(response)
      reject()
    })
  })

export const getPendingTasks = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`tasks/list/user/pending/${projectId}`).then((response) => {
      if (response.ok) {
        return resolve(response.data)
      }
      ApiErrors(response)
      reject()
    })
  })

export const getClosedTasks = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`tasks/list/user/closed/${projectId}`).then((response) => {
      if (response.ok) {
        return resolve(response.data)
      }
      ApiErrors(response)
      reject()
    })
  })

export const getAllEmailContacts = () =>
  new Promise((resolve, reject) => {
    api.get('users/provider/contacts').then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createDropDown = (payload) =>
  new Promise((resolve, reject) => {
    api.post('dropdowns', payload).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateDropDown = (payload) =>
  new Promise((resolve, reject) => {
    api.put(`dropdowns/${payload._id}`, payload).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getFilterContacts = (filters) =>
  new Promise((resolve, reject) => {
    const params = new URLSearchParams(filters).toString()
    api.get(`/users/filtered?${params}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getSearchContacts = (search, userType) =>
  new Promise((resolve, reject) => {
    api
      .get(`/users/search?search=${search}&userType=${userType}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const searchByEmail = (email) => new Promise((resolve, reject) => {
  api.get(`/users/search?search=${email}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createList = ({ title, users }) => new Promise((resolve, reject) => {
  api.post('lists', { title, users })
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getGenericGetEndPoint = (endPoint) =>
  new Promise((resolve, reject) => {
    api.get(endPoint).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getGenericPostEndPoint = (endPoint, body) =>
  new Promise((resolve, reject) => {
    api.post(endPoint, body).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getGenericPutEndPoint = (endPoint, body) =>
  new Promise((resolve, reject) => {
    api.put(endPoint, body).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getGenericDeleteEndPoint = (endPoint) =>
  new Promise((resolve, reject) => {
    api.delete(endPoint).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const uploadDocumentImage = (id, docName, file, projectId, type) =>
  new Promise((resolve, reject) => {
    const formData = new FormData()
    const fileName = `${type}-${docName}-${file.name || getRandomInteger()}`
    formData.append('documentName', fileName)
    formData.append('file', file)
    formData.append('reservation_id', id)
    formData.append('project', projectId)
    formData.append('type', type)
    api.post('privatedocuments/upload', formData).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReferralAgents = (projectId) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `users/?userType=ReferralAgent&referralAgentData.project=${projectId}`
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const getUsersByParam = (paramName, paramId, userType, dataType) =>
  new Promise((resolve, reject) => {
    api
      .get(`users/?userType=${userType}&${dataType}.${paramName}=${paramId}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })
