/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useCallback,
  useRef
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'

import { Table } from 'ui'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'
import AllOpportunities from './AllOpportunities'

const LoadingContext = createContext()

export const offerStatus = {
  Available: { text: 'Available', backgroundColor: 'bg-[#B8E04A]' },
}

const ColoredStatus = ({ status }) => (
  <div
    className={`w-32 font-medium py-1 text-base text-black uppercase text-center
      ${offerStatus[status].backgroundColor || 'bg-white'} 
    `}
  >
    {offerStatus[status].text}
  </div>
)

const ActionButton = ({ text, onClick }) => (
  <div
    className="font-medium py-1 text-base text-black uppercase text-center border border-black w-32"
  >
    View / Edit
  </div>
)

const offers = [
  {
    opportunity: "Onze Zen #1024",
    buyer: "Omar Bator",
    rating: "B",
    stage: "Available",
    edit: "View/Edit"
  }, 
  {
    opportunity: "Onze Zen #1224",
    buyer: "Cathy Chan",
    rating: "A",
    stage: "Available",
    edit: "View/Edit"
  }, 
  {
    opportunity: "Onze Zen #2114",
    buyer: " Austin Ho",
    rating: "A",
    stage: "Available",
    edit: "View/Edit"
  }, 
  {
    opportunity: "Onze Zen #1340",
    buyer: "Carlos Velasco",
    rating: "B",
    stage: "Available",
    edit: "View/Edit"
  }, 
  {
    opportunity: "Onze Zen #8001",
    buyer: "Douglas Muzuma",
    rating: "A",
    stage: "Available",
    edit: "View/Edit"
  }
];

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <Table
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
    />
  )
}

const ProjectOpportunities = ({ appProject, userObject }) => {
  const mountedRef = useRef(true)
  const [projectOffers, setProjectOffers] = useState([])
  const [filterProjectOffers, setFilterProjectOffers] = useState([])
  const [idBeingDeleted] = useState('')
  const [cancelingOffer] = useState(false)
  const [sortByBuyer, setSortByBuyer] = useState('asc')

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (sortByBuyer && projectOffers?.length > 0) {
      if (!mountedRef.current) return null
      setFilterProjectOffers([...sortedByBuyerName])
    }
  }, [sortByBuyer, projectOffers])

  const sortedByBuyerName = projectOffers.sort((a, b) => {
    if (sortByBuyer === 'asc') return a.buyer.localeCompare(b.buyer)
    if (sortByBuyer === 'desc') return b.buyer.localeCompare(a.buyer)
    return 0
  })

  const projectOffersColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Opportunity Name</span>
            <button
              onClick={() =>
                setSortByBuyer((prev) => (prev === 'asc' ? 'desc' : 'asc'))
              }
            >
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'opportunity',
        dataIndex: 'opportunity',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: 'Buyer',
        accessor: 'buyer',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: 'Rating',
        accessor: 'rating',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: 'Stage',
        accessor: 'stage',
        Cell: (props) => <ColoredStatus status={props.cell.value} />
      },
      {
        Title: '',
        accessor: 'edit',
        Cell: (props) => (
          <ActionButton text={props.cell.value} />
        )
      }
    ],
    []
  )

  const getData = useCallback(() => {
    if (appProject) {
      setProjectOffers([])
      const tmpProjectOffers = offers.map(({ opportunity, buyer, rating, stage, edit }, index) => {
        const tmpOffer = { opportunity, buyer, rating, stage, edit }
        return tmpOffer
      })

      setProjectOffers(tmpProjectOffers)
      setFilterProjectOffers(tmpProjectOffers)

    }
  }, [appProject])

  useEffect(() => {
    getData()
  }, [getData])

  const values = useMemo(
    () => ({}), []
  )

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col items-center'>
        <AllOpportunities title="Opportunities by Stages" />
        <div className='mt-5 w-full lg:overflow-x-hidden'>
          <MemoTable
            dataSource={filterProjectOffers}
            columns={projectOffersColumns}
            loading={false}
            idBeingDeleted={idBeingDeleted}
            deleting={cancelingOffer}
          />
        </div>
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectOpportunities)

ProjectOpportunities.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}