import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getUserLists } from 'store/actions/usersActions'

const useGetLists = () => {
  // @ts-ignore
  const userId = useSelector((state) => state.authReducer.userObject)
  const { data, isLoading } = useQuery(
    ['getLists'],
    () => getUserLists(),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
  )
  return { lists: data, isLoading }
}

export default useGetLists
