// @ts-nocheck
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { getSubscriberActivity } from 'store/actions/emailCampaignActions'

const NoActivityView = () => {
  return (
    <div className='font-openSans flex flex-col justify-center items-center h-96 w-full'>
      <div className='flex flex-col justify-center items-center'>
        <svg
          width='24'
          height='24'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M10 6.5H15V4.5H10V6.5ZM10 13.5H15V11.5H10V13.5ZM16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18ZM3 8H8V3H3V8ZM4 4H7V7H4V4ZM3 15H8V10H3V15ZM4 11H7V14H4V11Z'
            fill='#2E2B2E'
          />
        </svg>
        <div className='font-semibold mb-2 mt-4'>
          You don't have any activities yet
        </div>
      </div>
    </div>
  )
}

const ActivityTab = ({ contact }) => {
  const [isLoadingActivity, setIsLoadingActivity] = useState(true)
  const [LoadError, setLoadError] = useState('')
  const [activities, setActivities] = useState({ campaigns: [], history: [] })

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoadingActivity(true)
        setLoadError(null)
        const _data = await getSubscriberActivity(contact?.email)
        setActivities(_data)
      } catch (e) {
        console.log(e)
        setLoadError(JSON.stringify(e))
      } finally {
        setIsLoadingActivity(false)
      }
    }
    fetch()
  }, [])

  return (
    <>
      <div>{LoadError ? <div>{JSON.stringify(LoadError)}</div> : null}</div>
      {isLoadingActivity
        ? (
          <div className='flex flex-col gap-6 mt-8'>
            <Skeleton height={100} />
          </div>
        )
        : (
          <div>
            {activities?.campaigns?.length
            ? (
              <div>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead>
                    <tr>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                      >
                        Campaign
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                      >
                        Date Sent
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                      >
                        Opened
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                      >
                        Open Date
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                      >
                        Clicked
                      </th>
                      <th
                        scope='col'
                        className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'
                      >
                        Click Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200'>
                    {activities?.campaigns.map((campaign) => {
                      const activities = campaign.activity
                      let isOpened = false
                      let isClicked = false
                      let openDate = null
                      let clickDate = null
                      for (let i = 0; i < activities.length; i++) {
                        const activity = activities[i]
                        if (activity.action === 'open' && !isOpened) {
                          isOpened = true
                          openDate = new Date(activity.timestamp)
                        }
                        if (activity.action === 'click' && !isClicked) {
                          isClicked = true
                          clickDate = new Date(activity.timestamp)
                        }
                      }
                      return (
                        <tr key={campaign.id}>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                            {campaign.campaign_title}
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                            {new Date(campaign.send_time).toLocaleDateString()}
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                            {isOpened && 'YES'}
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                            {isOpened && openDate.toLocaleDateString()}
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                            {isClicked && 'YES'}
                          </td>
                          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                            {isClicked && clickDate.toLocaleDateString()}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )
            : (
              <NoActivityView />
            )}
          </div>
        )
      }
    </>
  )
}

export default ActivityTab
