import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { IoMdClose } from 'react-icons/io'
import { classNames } from 'utils'

const SidePanel = ({
  open,
  setOpen,
  children,
  title = 'Panel Title',
  fullScreen = false,
  onClickFullProfile = null
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-2' onClose={setOpen}>
        <div className='fixed inset-0' />
        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden shadow-lg'>
            <div
              className={classNames(
                'fixed inset-y-0 right-0 flex md:pl-80',
                fullScreen ? 'w-screen' : 'max-w-full'
              )}
            >
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel
                  className={classNames(
                    'w-screen',
                    fullScreen ? 'max-w-full' : 'max-w-2xl'
                  )}
                >
                  <div className='flex flex-col h-screen overflow-y-scroll bg-white py-6 pt-20 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-center justify-between content-center text-center'>
                        <Dialog.Title
                          style={{ fontSize: '24px' }}
                          className='text-base font-semibold leading-6 text-gray-900 mb-0'
                        >
                          {title}
                        </Dialog.Title>
                        <div className=' flex flex-1 items-center justify-end content-center'>
                          {onClickFullProfile != null && (
                            <button
                              className='py-3 px-6 text-base bg-volt rounded font-medium gap-2'
                              onClick={onClickFullProfile}
                            >
                              {fullScreen
                                ? 'Exit Full Profile'
                                : 'Full Profile'}
                            </button>
                          )}
                          <div className='ml-3 flex h-7 items-center'>
                            <button
                              type='button'
                              className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                              onClick={() => setOpen(false)}
                            >
                              <IoMdClose fontSize='28px' />
                              <span className='sr-only'>Close panel</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-3 flex-1 px-4 sm:px-6'>
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SidePanel
