// @ts-nocheck

import ToggleSwitch from 'components/ToggleSwitch'
import React, { useContext } from 'react'
import { CreateOfferContext } from '../context/CreateOfferContext'

const SelectPrivacy = ({ setStep }) => {
  const { privacy, setPrivacy } = useContext(CreateOfferContext)
  const { allowAdditionalServices, allowSurveys } = privacy

  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => setStep((prev) => prev + 1)

  const setAllowAdditionalServices = () => {
    setPrivacy({
      ...privacy,
      allowAdditionalServices: !allowAdditionalServices
    })
  }

  const setAllowSurveys = () => {
    setPrivacy({
      ...privacy,
      allowSurveys: !allowSurveys
    })
  }

  return (
    <div>
      <div className="pb-4 border-b-2 border-softBlack">
        <p className="my-0 text-xl font-bold">Privacy Consent</p>
      </div>
      <div className="flex gap-4 flex-wrap ">
        <div
          className="p-6 mt-6 flex flex-col justify-between rounded-sm"
          style={{
            backgroundColor: '#F2F2F1',
            maxWidth: '450px',
            minHeight: '140px'
          }}
        >
          <p className="text-[#2E2B2E] font-medium text-base">
            Other BROKERS may communicate with you to determine if you require
            additional real estate services.
          </p>
          <ToggleSwitch
            checked={allowAdditionalServices}
            setChecked={setAllowAdditionalServices}
          />
        </div>
        <div
          className="p-6 mt-6 flex flex-col justify-between rounded-sm"
          style={{
            backgroundColor: '#F2F2F1',
            maxWidth: '450px',
            minHeight: '140px'
          }}
        >
          <p className="text-[#2E2B2E] font-medium text-base">
            The boards, and other BROKERS or their brokerage participate in
            surveys.
          </p>
          <ToggleSwitch checked={allowSurveys} setChecked={setAllowSurveys} />
        </div>
      </div>

      <div className="flex justify-between mt-32">
        <button
          className="py-3 px-6 text-base border border-softBlack text-softBlack rounded font-medium"
          onClick={onBack}
        >
          Back
        </button>
        <button
          className="py-3 px-6 text-base bg-volt rounded font-medium"
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default SelectPrivacy
