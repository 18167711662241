// @ts-nocheck
import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import clsx from 'clsx'
import Button from 'components/Buttons'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as BulkContact } from 'assets/icons/bulkContact.svg'
import { ReactComponent as SingleContact } from 'assets/icons/singleContact.svg'
import { isMobile } from 'react-device-detect'

const CardRadio = ({ title, Icon, onClick, selected }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        'group cursor-pointer flex flex-col w-full items-center justify-center h-[120px] bg-menuGray rounded overflow-hidden border',
        selected ? 'border-softBlack' : 'border-transparent'
      )}
    >
      {Icon && (
        <span className='px-1.5 text-softBlack flex items-center justify-center'>
          {Icon}
        </span>
      )}
      <span className='mt-4 font-light text-base text-softBlack italic'>
        {title}
      </span>
    </div>
  )
}

const ContactEditMethodModal = ({
  show,
  setShow,
  onMethodSelected = (selected) => {}
}) => {
  const [selectedRadio, setSelectedRadio] = useState('')

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Contact Edit Method Selection Modal'
      aria-describedby='Contact Edit Method Selection Modal description'
    >
      <div
        className='font-openSans relative bg-grey-8 p-10 text-center items-center justify-center text-softBlack rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
        style={{
          width: isMobile ? '100%' : 1015,
          height: isMobile ? 'max-content' : 400
        }}
      >
        <button className='absolute right-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-softBlack'>New Contact</h1>
        <p className='font-normal text-base text-softBlack mt-8'>
          Choose the method you want to use to create the buyer
        </p>
        <div
          className={`flex mt-8 ${
            isMobile ? 'flex-col gap-4' : 'mx-10 space-x-6'
          }`}
        >
          <CardRadio
            key='Buyer'
            title='Create Buyer'
            selected={selectedRadio === 'Buyer'}
            onClick={() => {
              setSelectedRadio('Buyer')
            }}
            Icon={<SingleContact />}
          />
          <CardRadio
            key='LeadBroker'
            title='Create Broker'
            selected={selectedRadio === 'LeadBroker'}
            onClick={() => {
              setSelectedRadio('LeadBroker')
            }}
            Icon={<SingleContact />}
          />
          <CardRadio
            key='referral'
            title='Referral Agent'
            selected={selectedRadio === 'referral'}
            onClick={() => {
              setSelectedRadio('referral')
            }}
            Icon={<SingleContact />}
          />
          <CardRadio
            key='bulk'
            title='Bulk Upload Create Contacts'
            selected={selectedRadio === 'bulk'}
            onClick={() => {
              setSelectedRadio('bulk')
            }}
            Icon={<BulkContact />}
          />
        </div>
        <div className='flex mt-8 justify-center'>
          <Button
            type='Primary'
            label='Continue'
            handleClick={() => {
              handleClose()
              onMethodSelected(selectedRadio)
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ContactEditMethodModal
