// @ts-nocheck
/* eslint-disable */
import React from "react";

export const SelectTaskStatus = ({ handleChange, value, label = "Status" }) => {
  const options = [{ label: 'Pending', value: "pending" }, {label: 'Closed', value: "closed"}];

  return (
    <div>
      <p
        className="mt-0 mb-2 text-softBlack70 text-xs font-medium uppercase"
        style={{ letterSpacing: "0.16px" }}
      >
        {label}
      </p>
      <select
        placeholder="Select status"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className="w-full text-base bg-white border border-softBlack30 h-12 rounded text-softBlack placeholder-softBlack70"
      >
        <option disabled="disabled" value="" className="text-softBlack70">Select status</option>
        {options?.map((_option, index) => <option key={index} value={_option.value}>{_option.label}</option>)}
      </select>
    </div>
  );
};
