// @ts-nocheck
/* eslint-disable no-tabs */
/* eslint-disable no-irregular-whitespace */
export const emailTemplates = {
  english: [
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Thank you for your interest in the new One Zen Residential Tower in Zoho City, Puerto Vallarta’s newest luxury condominiums. My name is {FirstName} {LastName}, I’m a Property Specialist and am happy to assist you with any questions you may have.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      One Zen will offer studio to three bedroom, and penthouse condominiums right in the heart of Puerto Vallarta offering the opportunity to own in the most convenient of locations.  These Residences will offer resort-like amenities and services and is the next opportunity to purchase in the exciting master-planned community of Zoho City, featuring an evolved level of healthy living.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      At your convenience, I would like to schedule a visit with you at our Discovery Center where we have a scale architectural model of the building, vignettes, and other fun surprises.   We are open daily by appointment with easy parking. Feel free to reach out to me at any time or reply with a convenient time for me to reach out directly.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I look forward to hearing from you soon.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best Regards,`,
      subject: 'THE ONE.  ONE ZEN'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I wanted to follow up with you and thank you again for your interest in the new One Zen Condominiums in Zoho City. The building’s architecture designed by renowned Guadalajara architectural firm 3.14 is looking stunning and the selection of different floorplans we have is extensive!
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      If you haven’t already done so, I encourage you to plan a visit to our Discovery Center.  We have sample floorplans and a virtual program with sample views taken from the construction site for you to review.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      In the meantime, I invite you to visit our website, www.OneZenLiving.com to learn more. 
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Our Discovery Center is open daily by appointment, with easy parking.  Please give me a call at {User.Phone}, or reply letting me know a time that works for you.  I look forward to meeting with you to discuss your future One Zen Residence.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best Regards`,
      subject: 'ONE ZEN IS VALLARTA LUXURY'
    },
    {
      text: `Hello {!Contact.FirstName},
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        Just following up again regarding your interest in the new One Zen Residences in Zoho City.
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        There is still a great opportunity to acquire one of our Residences as our Priority Purchase Program will begin early in the New Year.
            •	Studio Residences
            •	One Bedroom Residences
            •	Two Bedroom Residences
            •	Three Bedroom Residences
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        All of the residences boast generous outdoor living spaces as well as well as fully equipped kitchens, spa inspired bathrooms and some amazing views.  Pricing starts around (PRICE) U.S..
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        If you’d like to learn more, I would like to schedule a time for you to join me at our Discovery Center to learn more about Puerto Vallarta’s newest luxury condominiums with resort-like amenities and services that are completely unique to the market.
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        Our Discovery Center is located at Av. Francisco Medina Ascencio 2740-A, Zona Hotelera with easy parking. You can reach me directly at {!User.Phone}.
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        Best regards,`,
      subject: 'YOUR PUERTO VALLARTA HOME'
    },
    {
      text: `Hello {!Contact.FirstName},
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        I don’t want you to think I’ve forgotten about you, so I am reaching out again to see if you have any questions regarding ownership at the new One Zen Residences in Zoho City.
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        As Puerto Vallarta’s premier new property, One Zen will redefine the meaning of luxury and lifestyle in our urban setting. From access to the One Zen’s amenities and services, the Owner’s Only pool and fitness facility, and all with the conveniences that living in Zoho City offers such as the onsite medical center, shopping, dining…all at residents’ fingertips.
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        For more details and to schedule an appointment to visit our Discovery Center, please contact me at {!User.Phone}.
        <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
        Best regards,`,
      subject: ' DON’T MISS OUT ON ONE ZEN'
    },
    {
      text: `Dear {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I haven’t heard back from you, and I wanted to reach out one last time.  The One Zen Residences in Zoho City are the most exciting new residential opportunity around and I don’t want you to miss out if you’re still interested.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Our website contains an overview of the design, architecture, amenities and services, as well as floor plans and sample view shots from the construction site. 
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      If there is any additional information you’re looking for, please let me know. Otherwise, I will assume your interest has changed and as a courtesy, I will remove you from my personal contact list.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Thanks again and warm regards,`,
      subject: 'ONE ZEN SALES STARTING'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Thank you for taking the time to meet with me and learn more about the new One Zen Residences in Zoho City. 
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Your appointment is confirmed for [DATE] at [TIME].
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I’ll meet you at our Zoho City Discovery Center, located at Av. Francisco Medina Ascencio 2740-A, Zona Hotelera.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      For your appointment, you are welcome to park right adjacent to the Discovery Center.  Just follow the signage. 
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      If you have any questions at all, or need to reschedule your appointment time, please do not hesitate to reach me at {!User.MobilePhone}.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I look forward to meeting with you soon!
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best regards,`,
      subject: 'YOUR ONE ZEN APPOINTMENT'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I wanted to personally thank you for visiting Our Zoho City Discovery Center, meeting with me, and learning more about this amazing new opportunity in Puerto Vallarta. It was a pleasure to meet you and I look forward to working with you going forward.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      This exclusive enclave of studio to three bedroom residences provides a rare chance to live the ultimate Puerto Vallarta lifestyle, complete with upscale services and amenities in such an iconic location.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Please know that I am here for you and can answer any questions that may come up. You can reach me at {!User.MobilePhone}.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Thank you,`,
      subject: 'THANKS FOR VISITING OUR ONE ZEN DISCOVERY CENTER'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      It was a pleasure to connect with you and discuss the amazing opportunity in Zoho City of One Zen.  
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      As Zoho City’s newest full-service property, One Zen will redefine the meaning of luxury and healthy lifestyle in Puerto Vallarta setting. Combining architecture and design from renowned 3.14 of Guadalajara, One Zen will be the signature centerpiece on the Avenida Francisco Medina Ascensio, an icon overlooking the Vallarta coast.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I would like to find a time to meet with you at our Discovery Center to discuss more about what you are looking for and get closer to figuring out if the One Zen is a fit.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Please let me know a time that works best for you.  With our VIP Priority Purchaser Program beginning soon, I look forward to meeting with you to discuss your future One Zen residence.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Looking forward to speaking with you.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best regards,`,
      subject: 'BE AT ONE.  ONE ZEN'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I am following up again regarding your interest in the new One Zen Residences in Zoho City.  Time is getting short as we are preparing to move into the release of these unique residences in the heart of Puerto Vallarta.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I would like to find a time to meet with you at our Sales Gallery to discuss more about what you are looking for and get closer to figuring out if the One Zen is a fit.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Please let me know a time that works best for you.  With our VIP Priority Purchaser Program beginning soon, I look forward to meeting with you to discuss your future One Zen residence.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Looking forward to speaking with you.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best regards,`,
      subject: 'ONE ZEN AGAIN'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I am just following up with you again regarding your interest in the One Zen Residences in Zoho City. The design is coming along quickly on the building, and we are still on pace to break ground in Spring 2023.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      By the way, in case you haven’t been around Zoho City lately, there’s a lot of new construction activity with the new La Joya Medical Center additional almost complete as well!
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I would like to catch up with you to hear what your latest thoughts are regarding One Zen. Please give me a call at your earliest convenience at {!User.MobilePhone} or let me know a good time to connect with you.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best regards,`,
      subject: 'ZOHO CITY IS ON THE MOVE'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I haven’t heard back from you, and I wanted to make sure you had a chance to get any remaining questions answered before you’re removed from my direct-contact interest list.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      With a limited number of residences at the One Zen in Zoho City, this a rare opportunity to own in the newest and most prestigious, fully-serviced building in the area.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      If you’re still interested in acquiring at One Zen, some of our floor plans are available to view online and I encourage you to visit our Discovery Center.   You can reach me directly at {!User.Phone} with any questions and to schedule a time to visit our gallery.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best regards,`,
      subject: 'DON’T MISS OUT ON ONE ZEN'
    },
    {
      text: `Dear {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I haven’t heard back from you, and I wanted reach out one last time.  If you’re still considering a purchase at One Zen, I want to make sure you don’t miss out on our VIP Priority Purchase Program:
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
          •	Preferred Pricing!
          •	Select before our release to the General Public!
          •	Invitations to VIP Events
          •	Zendo Gift Bag
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      One Zen offers a limited collection of One, Two and Three bedroom residences and our website contains an overview of the design, architecture, amenities, services, as well as floor plans and sample view shots. 
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      If there is any additional information you’re looking for, please let me know. Otherwise, I will assume your interest has changed and as a courtesy, I will remove you from my personal contact list so you will no longer receive calls.  Thanks for your interest.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best regards,`,
      subject: 'ONE ZEN IS SELLING SOON'
    },
    {
      text: `Hi {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      As promised, I am sending you our purchase documents for One Zen for your review.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Executive Summary – Provides you a high level orientation of each of the documents included. I suggest you start with this document to familiarize yourself.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Action Documents – We have included in this package all documents that will require your execution at time of selecting your residence. To be clear this package is only for your review at this time. You can download this document by clicking here.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Governing Documents – We have included in this package all governing documents that are provided for your review. None of these documents require your execution. You can download these PDF documents by clicking here.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I look forward to answering any questions you may have regarding the purchase documents being shared.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best Regards,`,
      subject: 'YOUR ONE ZEN LEGAL DOCUMENTS'
    },
    {
      text: `Dear {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      If you are choosing to finance the purchase of your One Zen residence, we will be providing you with very important information over the next few weeks about our designated lenders who will help guide you through the process of getting qualified.  
      Financing the purchase of a pre-construction condominium is often unfamiliar for many traditional lenders and buyers often run into issues that can be avoided. Therefore, we have assembled a specialized team of lenders who are well versed in this unique offering and know how to navigate the process of not only getting you qualified but getting the project qualified.  We highly encourage you to consult with one or more of these lenders as soon as possible.  It’s never too early to start the conversation!
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Using a One Zen designated lender offers many other advantages for a smooth loan process. They are familiar with the close of escrow process for a new construction condominium and the policies and requirements, which ensures quick and accurate answers to all your questions. Our designated lenders may also extend special incentives to help save you money.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Advantages of using a Designated Lender:
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
          •	Product, project & builder familiarity ensures a smoother process
          •	Streamlined high-volume closings
          •	Immediate pre-qualifications & pre-approvals
          •	Competitive interest rates
          •	Special financing or closing cost credits may be available
          •	Full range of financing options available
          •	Extended rate lock programs may be available
          •	Expert mortgage counseling
          •	Consistent & frequent status updates
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>   
      We encourage you to reach out to one or more of the designated lenders.  Please click here to find a list of our designated lenders.  Please note, only the specific representatives listed are considered our designated lenders.  
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best Regards,`,
      subject: 'ONE ZEN PREFERRED LENDERS'
    },
    {
      text: `Hi {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Hope you are doing well!  It’s been an exciting time for us, and we’ve had an amazing amount of interest in One Zen!
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Thank you for your continued support of One Zen and for sharing this opportunity with your Clients. For your convenience we have assembled our Realtor Asset Kit. This package includes documents and renderings that you will find useful in marketing to your clients.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      If you have any questions I can be reached at {!User.Phone}. I look forward to working with you and helping your clients find their new home at One Zen in Zoho City. 
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best Regards, `,
      subject: 'ONE ZEN AGENT KIT'
    },
    {
      text: `Hello {!Contact.FirstName},
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Thank you for your interest in the new One Zen Residential Tower in Zoho City, Puerto Vallarta’s newest luxury condominiums. My name is {FirstName} {LastName}, I’m a Property Specialist and am happy to assist you with any questions you may have.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      One Zen will offer studio to three bedroom, and penthouse condominiums right in the heart of Puerto Vallarta offering the opportunity to own in the most convenient of locations.  These Residences will offer resort-like amenities and services and is the next opportunity to purchase in the exciting master-planned community of Zoho City, featuring an evolved level of healthy living.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I have attached our Vision brochure which looks into the future of Zoho City and what it might be like in 2030.  I think you appreciate all that Puerto Vallarta’s only urban resort masterplanned community will have to offer.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      At your convenience, I would like to schedule a visit with you at our Discovery Center where we have created a wonderful environment to tell the Zoho City story.   We are open daily by appointment with easy parking. Feel free to reach out to me at any time or reply with a convenient time for me to reach out directly.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      I look forward to hearing from you soon.
      <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
      Best Regards,`,
      subject: 'ZOHO CITY IN 2030'
    }
  ],
  spanish: [
    {
      text: `Hola {Nombre del contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Gracias por tu interés en la nueva Torre Residencial One Zen en Zoho City, los condominios de lujo más nuevos de Puerto Vallarta. Mi nombre es {Nombre} {Apellido}, soy especialista en propiedades y me complace ayudarte con cualquier duda que puedas tener.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    One Zen ofrecerá condominios desde estudios de una recámara hasta tres recámaras y penthouses situados en el corazón de Puerto Vallarta, brindándote la oportunidad de ser propietario en la ubicación más privilegiada de la bahía. Nuestros departamentos ofrecerán amenidades y servicios similares a los de un resort y tendrás la oportunidad de ser parte del emocionante plan maestro y comunidad de Zoho City, que representa un nuevo nivel de estilo de vida saludable.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Si deseas conocer un poco más del proyecto , me gustaría programar una visita contigo en nuestro Discovery Center, donde te presentaremos nuestro modelo arquitectónico a escala del edificio, ilustraciones y otras sorpresas divertidas. Estamos abiertos todos los días con previa cita y tenemos fácil acceso de estacionamiento. No dudes en comunicarte conmigo en cualquier momento o brindarme un horario que sea conveniente para que me comunique contigo directamente.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Quedo atent@ a tu respuesta.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Hola {Nombre del contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Te escribo nuevamente para dar seguimiento y agradecer tu interés en los nuevos condominios One Zen en Zoho City. La arquitectura del edificio, diseñada por el renombrado estudio de arquitectura de Guadalajara 3.14, se ve impresionante, y tenemos una extensa selección en nuestros modelos y tipos de plantas.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Si aún no lo has hecho, te invito a programar una visita a nuestro Discovery Center. Donde podrás conocer nuestros diferentes modelos de departamentos, plantas y  tener una experiencia virtual con vistas tomadas del sitio de construcción y revisar nuestras increíbles ubicaciones.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Mientras tanto, te invito a visitar nuestro sitio web, www.OneZenLiving.com para que  puedas obtener más información.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Nuestro Discovery Center está abierto todos los días con previa cita y fácil acceso de estacionamiento. Por favor, llámame al {Teléfono vendedor} o responde este correo con el horario conveniente  para llamarte directamente. Espero reunirme contigo para hablar sobre tu próxima propiedad en  One Zen Residences.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos
    `
    },
    {
      text: `Hola {Nombre del contacto },
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Te escribo nuevamente dando seguimiento con respecto a tu interés en los nuevos departamentos One Zen Residences en Zoho City.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    No te quedes fuera, todavía hay una gran oportunidad de adquirir uno de nuestros departamentos en ésta etapa de lanzamiento, ya que nuestro programa exclusivo para inversionistas comenzará a principios del Año Nuevo.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    • Los departamentos tipo estudio varían de aproximadamente 40 a 50 metros cuadrados
    
    • Los departamentos de una recámara van desde
    
    • Los departamentos de dos recámaras van desde
    
    • Los departamentos de tres recámaras van desde 
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Todas las unidades cuentan con generosos espacios al aire libre, así como cocinas totalmente equipadas, baños estilo spa y vistas increíbles. El precio inicia desde los $167,000 USD.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Si deseas conocer más de nuestro proyecto, me gustaría programar una cita en nuestro Discovery Center donde te brindaremos más información sobre los condominios de lujo más nuevos de Puerto Vallarta, que te brindarán amenidades y servicios estilo resort, que son completamente únicos en el mercado.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Nuestro Discovery Center está ubicado sobre av. Francisco Medina Ascencio 2740-A, Zona Hotelera con fácil estacionamiento. Puedes comunicarte conmigo directamente al {teléfono vendedor}.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Hola {Nombre del contacto },
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    No nos hemos olvidado de tu interés en nuestro desarrollo, así que me comunico nuevamente para ver si tienes alguna duda sobre los beneficios de adquirir  tu departamento en One Zen de Zoho City.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    One Zen redefinirá el significado de lujo y estilo de vida, como propietario del proyecto más novedoso de Puerto Vallarta, tendrás acceso a nuestras exclusivas amenidades como gimnasio, alberca y servicio de concierge. Además, serás parte de la comunidad de Zoho City donde disfrutarás de servicios como restaurantes, centro comercial y un centro médico.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Para obtener más detalles y programar una cita para visitar nuestro Discovery Center, comunícate conmigo al {teléfono vendedor}.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Estimado {Nombre del contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    He tratado de comunicarme contigo y no he tenido éxito, si aún estás interesado no me gustaría que te perdieras la oportunidad de ser parte de One Zen en Zoho City, el desarrollo de departamentos de lujo  más novedoso en Puerto Vallarta.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Nuestro sitio web contiene una descripción general del diseño, la arquitectura, las amenidades y los servicios, así como los tipos de plantas, modelos y vistas reales de los departamentos.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Quedo atent@ si hay alguna información adicional que estés buscando. De lo contrario, asumiré que tu interés ha cambiado y, como cortesía, te eliminaré de mi lista de contactos personales.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Gracias de nuevo y un cordial saludo,
    `
    },
    {
      text: `Hola {Nombre del contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Muchas gracias por tomarte el tiempo para reunirte conmigo y obtener más información sobre el nuevo proyecto  de departamentos One Zen Residences en Zoho City.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Tu cita está confirmada para el [FECHA] a las [HORA].
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Nos vemos en nuestro Zoho City Discovery Center, ubicado en Av. Francisco Medina Ascencio 2740-A, Zona Hotelera.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Para tu cita, puedes estacionarte justo al lado del Discovery Center. Simplemente sigue la señalización.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Si tienes alguna pregunta o necesitas reprogramar la hora de tu cita, no dudes en comunicarte conmigo al  {teléfono de vendedor}.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    ¡Te espero con gusto en nuestra reunión!
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Hola {Nombre del contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Te escribo para agradecerte personalmente por visitar nuestro Zoho City Discovery Center, reunirte conmigo y aprender más sobre esta nueva e increíble oportunidad de inversión en Puerto Vallarta. Fue un placer conocerte y espero trabajar contigo en el futuro.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    One Zen es un exclusivo  proyecto de departamentos  que te ofrece desde estudios de una recámara hasta departamentos de 2 y  3 recámaras, brindándote la oportunidad de vivir el mejor estilo de vida de Puerto Vallarta,  con servicios y amenidades de primer nivel en una ubicación privilegiada.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Ten en cuenta que estoy aquí para ti y puedo responder cualquier pregunta que pueda surgirte. Puedes comunicarte conmigo al {celular del vendedor}.
    `
    },
    {
      text: `Hola {Nombre del contacto },
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Fue un placer contactar contigo y hablar sobre la increíble oportunidad de invertir en Zoho City y One Zen.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    One Zen es la torre de departamentos más nueva dentro de Zoho City, que redefinirá el significado de lujo y estilo de vida saludable en Puerto Vallarta.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Combinando la arquitectura y el diseño del renombrado estudio 3.14 de Guadalajara, One Zen será la pieza central del desarrollo ubicado en la Avenida Francisco Medina Ascencio, será un proyecto icónico con vistas a toda la bahía.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Me gustaría encontrar un momento para reunirme contigo en nuestro Discovery Center, platicar más sobre lo que estás buscando, y averiguar si One Zen es el proyecto adecuado para ti.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Por favor, hazme saber el horario que funcione mejor para ti. Nuestro Programa de Comprador Prioritario comenzará pronto, espero reunirme contigo para hablar sobre tu futura unidad en One Zen.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Hola {Nombre contacto },
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Te escribo para dar seguimiento a tu interés en el nuevo proyecto de departamentos One Zen Residences en Zoho City. El tiempo se está acortando mientras nos preparamos para pasar al lanzamiento del desarrollo con la  mejor ubicación en Puerto Vallarta.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Me gustaría reunirme contigo en nuestras oficinas para analizar más a detalle lo que estás buscando y podamos determinar si One Zen es el proyecto adecuado para ti.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Por favor, hazme saber el horario que te funcione mejor. Nuestro Programa de Comprador Prioritario comenzará pronto, espero reunirme contigo para hablar sobre tu futura unidad en One Zen.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Hola {Nombre de contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Te escribo nuevamente para dar seguimiento a tu interés en el nuevo proyecto de departamentos One Zen Residences en Zoho City. El diseño del edificio avanza rápidamente, y estamos muy próximos a iniciar la construcción en primavera del 2023.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Por cierto, en caso de que no hayas estado en Zoho City últimamente, ¡hay mucho avance de obra en la construcción, además el nuevo Centro Médico La Joya está casi listo  también!
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Me gustaría actualizarte y ponernos al día, para saber qué has pensado con respecto a One Zen. Llámame lo antes posible al {teléfono vendedor} o dime en qué horario podría contactarme contigo.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Hola {nombre contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    No he tenido noticias tuyas y quería asegurarme si aún tienes alguna duda sobre el proyecto antes de eliminarte de mi lista de clientes interesados.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Con un número limitado de departamentos en One Zen Residences en Zoho City, ésta es una  oportunidad única de ser propietario del edificio más nuevo y prestigioso con las mejores amenidades de la zona.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Si todavía estás interesado en invertir en One Zen Residences, algunos de nuestros modelos aún están disponibles para ver en línea y te invito a que nos visites en nuestro Discovery Center. Puedes comunicarte conmigo directamente al {teléfono de vendedor} con cualquier pregunta y para programar un horario para visitar nuestro showroom.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Estimado {Nombre del contacto },
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    No he tenido noticias tuyas y me comunico contigo por última vez. Si todavía estás considerando comprar en One Zen, quiero asegurarme de que no te pierdas de los beneficios de nuestro programa exclusivo de lanzamiento para inversionistas:
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    • ¡Precios exclusivos de lanzamiento!
    • ¡Accedes al inventario antes de nuestro lanzamiento al público en general!
    • ¡Las tasas de interés están en niveles mínimos históricos!
    • ¡Ocupación en 2024!
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    One Zen te ofrece unidades de una, dos y tres recámaras y nuestro sitio web contiene una descripción general del diseño, la arquitectura, las amenidades, los servicios, así como los tipos de planta y las vistas.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Si hay alguna información adicional que estés buscando, házmelo saber. De lo contrario, asumiré que tu interés ha cambiado y, como cortesía, te eliminaré de mi lista de contactos personales para que ya no recibas llamadas. Gracias por tu pronta respuesta.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Hola {Nombre contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Tal como le prometí, te envío nuestros documentos de compra en One Zen para tu revisión.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Resumen Ejecutivo – Proporciona una orientación de alto nivel de cada uno de los documentos incluidos. Sugiero que comienceS con este documento para familiarizarte.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Documentos de acción: hemos incluido en este paquete todos los documentos que requerirán tu ejecución al momento de seleccionar tu departamento. Para ser claros, este paquete es sólo para tu revisión en este momento. Puedes descargar este documento haciendo clic aquí.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Documentación legal: hemos incluido en éste paquete todos los documentos legales que se proporcionan para tu revisión. Ninguno de estos documentos requiere su ejecución. Puede descargar estos documentos PDF haciendo clic aquí.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Espero responder cualquier pregunta que puedas tener con respecto a los documentos de compra que se comparten.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Saludos,
    `
    },
    {
      text: `Estimado {Nombre contacto},
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Si eliges financiar la compra de tu departamento en One Zen residences, te proporcionaremos información muy importante durante las próximas semanas sobre nuestras empresas hipotecarias designadas que te ayudarán a guiarte a través del proceso de calificación.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Financiar la compra de una propiedad antes de la construcción a menudo no es familiar para muchas empresas hipotecarias tradicionales y los compradores a menudo se encuentran con problemas que pueden evitarse. Por lo tanto, hemos reunido un equipo especializado de asesores hipotecarios que conocen bien esta oferta única y saben cómo navegar el proceso no sólo para que puedas calificar, sino también para que el proyecto califique. Te recomendamos encarecidamente que consultes con uno o más de estos brokers hipotecarios lo antes posible. ¡Nunca es demasiado temprano para comenzar la conversación!
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    El uso de un crédito hipotecario designado por One Zen ofrece muchas otras ventajas para un proceso de préstamo sin problemas. Están familiarizados con el proceso de cierre de depósito en garantía para un departamento en preventa, las políticas y requisitos, lo que garantiza respuestas rápidas y precisas a todas tus preguntas. Nuestros agentes hipotecarios designados también pueden extender incentivos especiales para ayudarte a ahorrar dinero.
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Ventajas de usar un crédito hipotecario:
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    • La familiaridad con el producto, el proyecto y el constructor garantiza un proceso más fluido
    • Cierres de alto volumen optimizados
    • Precalificaciones y pre evaluaciones inmediatas
    • Tasas de interés competitivas
    • Es posible que se encuentren disponibles créditos de costos de cierre o financiamiento especial
    • Gama completa de opciones de financiación disponibles
    • Es posible que estén disponibles programas de bloqueo de tasa extendida
    • Asesoramiento hipotecario experto
    • Actualizaciones de estado constantes y frecuentes
    <p style="margin-bottom: 0px;margin-top: 0px;"><br /></p>
    Te invitamos a comunicarte con uno o más de nuestros brokers hipotecarios. Haz clic aquí para encontrar una lista de nuestros brokers hipotecarios designados. Ten en cuenta que solo los representantes específicos enumerados  en la siguiente lista se consideran nuestros  designados.
    `
    }
  ]
}
