import { api, ApiErrors } from 'services/api'

export const getProjectDeposits = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`deposits/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectDepositStatistics = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`deposits/project/${projectId}/statistics`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
