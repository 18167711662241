import React, { useState } from 'react'
import ProjectManagement from '../projectManagement/components/ProjectManagement'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'

const LoadingProjectDetails = () => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title='Loading Project Information...' />
    </FadedAnimatedDiv>
)
const CreateEditProject = () => {
  const [isLoading, setIsLoading] = useState(true)
  return (
    <>
      <div className='bg-menuGray min-h-screen relative'>
      <div className='p-6 flex justify-between items-center'>
        <span className='font-bold text-3xl font-openSans'>Project Management</span>
      </div>
      <div className='border-b-2 border-black' />
      <div className='m-6 p-6 bg-grey-8 rounded'>
        <h1 className='font-black text-lg pt-3 font-openSans'>General Information</h1>
        <div className='border-b-2 border-black my-2' />
        <h2 className="font-openSans">Please enter the details about the project</h2>
        {isLoading && <LoadingProjectDetails />}
        {!isLoading && (
            <ProjectManagement setIsLoading={setIsLoading} />
        )}
      </div>
    </div>
    </>
  )
}

export default CreateEditProject
