import {
  CHANGE_USER_LOGGED_STATUS,
  CLEAR_APP_REDUCER
} from 'store/actions/actionTypes'
import { login, logout } from 'services/auth'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'
import { message } from 'antd'

export const handleLogin = (email, password, updateLocalStorage) => dispatch => new Promise((resolve, reject) => {
  const params = {
    email,
    password
  }

  api.post('/users/signin', params)
    .then((response) => {
      if (response.ok) {
        // Check if needs to update localStorage
        if (updateLocalStorage) {
          login(response.data.token)
          // @ts-ignore
          api.setHeader('Authorization', getTokenRequestHeader())
          dispatch({ type: CHANGE_USER_LOGGED_STATUS, payload: true })
        }

        resolve(response.data.token)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const changeUserLoggedStatus = (status) => dispatch => {
  dispatch({
    type: CHANGE_USER_LOGGED_STATUS,
    payload: status
  })
}

export const handleLogout = () => async dispatch => {
  await logout()

  dispatch({ type: CHANGE_USER_LOGGED_STATUS, payload: false })
  dispatch({ type: CLEAR_APP_REDUCER })
}

export const handleChangeUserPassword = (userId, userPassword, token) => new Promise((resolve, reject) => {
  const header = `Bearer ${token}`

  api.setHeader('Authorization', header)

  api.put(`/users/${userId}`, { password: userPassword })
    .then((response) => {
      if (response.ok) {
        message.success('Your password was successfully updated')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
