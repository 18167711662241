import useContactContext from 'pages/newContacts/hooks/useContactContext'
import React, { useMemo } from 'react'

const TableHead = ({ page }) => {
  const { state, toggleCheckAllContacts } = useContactContext()

  const columns = useMemo(() => !state.selectedContact && !state.isAddToListDrawerOpen
    ? ([
        { name: 'Name', property: 'firstName' },
        { name: 'User Type', property: 'userType' },
        { name: 'Email', property: 'email' },
        {
          name: 'Phone Number',
          property: { name: 'buyerData', property: 'phoneNumber' }
        },
        {
          name: 'Sales Rep Owner',
          property: { name: 'buyerData', property: 'salesRep' }
        },
        {
          name: 'Buyer Score',
          property: { name: 'buyerData', property: 'rating' }
        },
        {
          name: 'Lead Status',
          property: { name: 'buyerData', property: 'leadStatus' }
        }
      ])
    : ([
        { name: 'Name', property: 'firstName' }
      ]), [state.selectedContact, state.isAddToListDrawerOpen])

  return <thead className='bg-gray-50'>
    <tr>
      <th
        scope='col'
        className='relative w-25 px-6 sm:w-16 sm:px-8'
      >
        <input
          type='checkbox'
        //   checked={selected[page]}
          onChange={() => toggleCheckAllContacts()}
          className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
        />
      </th>
      {columns.map((col, index) => (
        <th
          scope='col'
          className='relative w-25 px-6 sm:w-16 sm:px-8 whitespace-nowrap py-3 pr-6'
          style={{ textAlign: 'left', paddingLeft: 0 }}
          key={index}
        >
          {col.name}
        </th>
      ))}
    </tr>
  </thead>
}

export default TableHead
