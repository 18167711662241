import React, { Suspense, useEffect, useRef } from 'react'
import propTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import {
  Loader,
  AdminContainer,
  BuyerContainer,
  AnimatedTransitionWrapper,
  BrokerContainer
} from 'ui'
// import OfferContainer from 'components/Offer/OfferContainer'
import { isAuthenticated } from 'services/auth'

import Login from 'pages/login'
import LayoutModeSelector from 'pages/layoutModeSelector'
import RouteDecisor from 'routeDecisor'
import PageUnderConstruction from 'pages/underConstruction'

// Buyer Pages
import BuyerAvailabilityBoard from 'pages/buyerPages/availabilityBoard'
import BuyerCreateOffer from 'pages/buyerPages/buyerCreateOffer'

// Broker Pages
import BrokerDashboardPage from 'pages/leadBrokerPages/pages/dashboardScreen'
import BrokerDocumentPage from 'pages/leadBrokerPages/pages/documentScreen'
import BrokerMainPage from 'pages/leadBrokerPages/pages/mainScreen'
// import BrokerMenuPage from 'pages/leadBrokerPages/pages/menuScreen'
import BrokerLandingPageScreen from 'pages/leadBrokerPages/pages/landingPageScreen'
import BrokerProfileScreen from 'pages/leadBrokerPages/pages/brokerProfileScreen'

// Admin Pages
import OldAdminDashboard from 'pages/desktopMode/dashboard'
import AdminUsers from 'pages/desktopMode/users'
import AdminProjectManagement from 'pages/desktopMode/projectManagement/edit/EditGeneralInfo'
import AdminOffers from 'pages/desktopMode/offers'
import AdminDeposits from 'pages/desktopMode/deposits'
import AdminOpportunities from 'pages/desktopMode/opportunities'
import AdminReservations from 'pages/desktopMode/reservations'
import AdminBrokerLeads from 'pages/desktopMode/brokerLeads'
import AdminOffer from 'pages/desktopMode/createOffer'
import AdminProfile from 'pages/desktopMode/profilePage'
import SchedulerMeetings from 'pages/desktopMode/profilePage/SchedulerMeetings'
import SchedulerSettings from 'pages/desktopMode/profilePage/SchedulerSettings'
import AdminCreateEditUser from 'pages/desktopMode/createEditUser'
import AdminCreateEditDeveloper from 'pages/desktopMode/createEditDeveloper'
import AdminCreateEditProject from 'pages/desktopMode/createEditProject'
import AdminCreateEditBuilding from 'pages/desktopMode/createEditBuilding'
import AdminCreateEditFloorPlan from 'pages/desktopMode/createEditFloorPlan'
import AdminCreateEditUnitGroup from 'pages/desktopMode/createEditUnitGroup'
import AdminCreateEditUnit from 'pages/desktopMode/createEditUnit'
import SalesPipeline from 'pages/desktopMode/salesPipeline'
import AdminReports from 'pages/desktopMode/AdminReports/AdminReports'
import SalesReps from 'pages/team/salesReps'
import CoopBrokers from 'pages/team/coopBroker'
import Contacts from 'pages/contacts/Contacts'
import NewContacts from 'pages/newContacts/NewContacts'
import AvailabilityBoard from 'pages/desktopMode/availabilityBoard'
import Allocation from 'pages/desktopMode/allocations'
// import AllocateInventory from 'pages/desktopMode/allocateInventory'
import UnitsInventory from 'pages/desktopMode/unitsInventory'
import UnitsInventoryGraph from 'pages/desktopMode/unitsInventoryGraph'
import EmailInbox from 'pages/desktopMode/emailInbox'
import AdminProjectFiles from 'pages/desktopMode/projectFiles'
import Settings from 'pages/settings'
import Calendar from 'pages/calendar'
import ContractTemplates from 'pages/contractTemplates'

// admin new routes
import AdminDashboardNewDesign from 'pages/dashboard/_new'

import CreateOffer from 'pages/offer/new'
import OfferPreview from 'pages/offerPreview/new'
import CreateOpportunity from 'pages/opportunity/new'
import CreateReservation from 'pages/reservation/new'
import ReserveUnit from 'pages/reserveUnit'
import CreateProject from 'pages/desktopMode/projectManagement/create'

// PWA Pages
import PwaProjects from 'pages/pwaMode/projects'
import PwaProjectScreen from 'pages/pwaMode/projectScreen'
import PwaCreateOffer from 'pages/pwaMode/createOffer'
import PwaCreateOfferNew from 'pages/pwaMode/createOfferNew'
import PwaProjectOffers from 'pages/pwaMode/projectOffers'
import PwaProjectAvailabilityBoard from 'pages/pwaMode/projectAvailabilityBoard'
import PwaProjectFloorPlans from 'pages/pwaMode/projectFloorPlans'

// Buyer Discovery Survey
import BuyerDiscoverySurvey from 'pages/buyerDiscoverySurvey'

// Buyer Sign Contract
import BuyerSignContract from 'pages/buyerSignContract'

// Inventory Public Page
import InventoryPublicPage from 'pages/InventoryPublicPage'

// Edit Project
import EditProjectImages from 'pages/desktopMode/projectManagement/edit/EditProjectImages'
import EditProjectParking from 'pages/desktopMode/projectManagement/edit/EditProjectParking'
import EditProjectColors from 'pages/desktopMode/projectManagement/edit/EditProjectColors'
import EditProjectUpgrades from 'pages/desktopMode/projectManagement/edit/EditProjectUpgrades'
import EditProjectPayments from 'pages/desktopMode/projectManagement/edit/EditProjectPayments'

// Buildings
import AdminBuildings from 'pages/desktopMode/buildings'
import AddBuilding from 'pages/desktopMode/buildings/add/AddBuilding'
import AdminEditBuilding from 'pages/desktopMode/buildings/edit'
import CreateInventoryAllocation from 'pages/desktopMode/inventory/Allocation/AllocateInventory'
import { useIntercom } from 'react-use-intercom'
import { updateLoggedUserObject } from 'utils'
import { useSelector } from 'react-redux'
import Profile from 'pages/profile/Profile'
import EmailCampaignPage from 'pages/emailCampaigns'
import EmailCampaignDetail from 'pages/emailCampaigns/details'
import CreateBrokerLead from 'pages/leadPage/new'
import DynamicDropDown from 'pages/desktopMode/dynamicDropDown'
import ColumbiaComponent from 'components/ColumbiaComponent'
import { AdminDashboardComponent } from 'ui/containers/AdminContainer'
import ColumbiaSalesReport from 'components/ColumbiaSalesReport'
import BuyerHomeComponent from 'pages/buyerPages/buyerHome'
import BuyerDashBoard from 'pages/buyerPages/buyerDashboard'
import AdminLeadReport from 'pages/desktopMode/AdminLeadReport'
import AdminBrokerShannon from 'pages/desktopMode/brokerProfile/ShannonMckenzie'
import AdminReportSalesActivity from 'pages/desktopMode/AdminReports/AdminReportSalesActivity'
import AdminLeadStefan from 'pages/desktopMode/AdminLeadReport/AdminLeadStefan'
import AdminEventPage from 'pages/desktopMode/Events'
import AdminEventDetailPage from 'pages/desktopMode/Events/EventName'
import AdminCreateEditEventPage from 'pages/desktopMode/Events/CreateAndEditEvent'
import ParkingsInventory from 'pages/desktopMode/parkingsInventory'
import ParkingInventoryPublicPage from 'pages/parkingInventoryPublicPage'
import EmailCampaignBuilderPage from 'pages/emailCampaignsBuilder'
import EditEmailTemplate from 'pages/emailCampaignsBuilder/editEmailTemplate'

// Route Decisor
export const ROUTE_DECISOR = '/'

// PUBLIC ROUTES
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const SET_PASSWORD = '/set-password'

// new admin routes
export const NEW_ADMIN_DASHBOARD = '/new/admin'
export const ADMIN_OFFER = '/new/admin/offer'
export const ADMIN_OFFER_PREVIEW = '/new/admin/offer_preview/:id'
export const ADMIN_OPPORTUNITY = '/new/admin/opportunity'
export const ADMIN_RESERVE_UNIT = '/new/admin/reserve_unit'
export const ADMIN_CREATE_INVENTORY_ALLOCATION = '/new/admin/allocations'

// PRIVATE ROUTES
export const LAYOUT_MODE_SELECTOR = '/layout_mode_selector'
export const ADMIN_DASHBOARD = '/admin'
export const ADMIN_DASHBOARD_COLUMBIA = '/admin/columbia'
export const ADMIN_DASHBOARD_COLUMBIA_REPORT = '/admin/columbia/report'
export const ADMIN_DASHBOARD_OLD = '/admin/old'
export const ADMIN_DASHBOARD_NEW = '/admin/new'
export const ADMIN_USERS = '/admin/users'
export const ADMIN_LEADREPORT = '/admin/leadreport'
export const ADMIN_BROKER_SHANNON = '/admin/broker/shannon'
export const ADMIN_SALES_REPORT = '/admin/report/salesactivity'
export const ADMIN_LEAD_STEFAN = '/admin/lead/stefan'
export const ADMIN_EVENT_PAGE = '/admin/events'
export const ADMIN_EVENT_DETAIL_PAGE = '/admin/events/preview/:eventId'
export const ADMIN_CREATE_EVENT_PAGE = '/admin/events/create'
export const ADMIN_EDIT_EVENT_PAGE = '/admin/events/edit/:eventId'
// Project Management and its Submenus
export const ADMIN_PROJECT_MANAGEMENT = '/admin/project_management'
export const ADMIN_PROJECT_IMAGES = '/admin/project/images'
export const ADMIN_PROJECT_PARKING = '/admin/project/parking'
export const ADMIN_PROJECT_COLORS = '/admin/project/colors'
export const ADMIN_PROJECT_UPGRADES = '/admin/project/upgrades'
export const ADMIN_PROJECT_PAYMENTS = '/admin/project/payments'

// Buildings
export const ADMIN_BUILDINGS = '/admin/project/buildings'
export const ADMIN_EDIT_BUILDING = '/admin/project/buildings/view'
export const ADMIN_ADD_BUILDING = '/new/admin/project/buildings/add'

// Inventory and its Submenus
export const ADMIN_PROJECT_UNIT_INVENTORY = '/admin/project/units_inventory'
export const ADMIN_PROJECT_PARKING_INVENTORY = '/admin/project/parkings_inventory'
export const ADMIN_PROJECT_UNIT_INVENTORY_GRAPH = '/admin/project/units_inventory_graph'
export const ADMIN_PROJECT_ALLOCATIONS = '/admin/project/allocations'

// Files
export const ADMIN_PROJECT_FILES = '/admin/project/files'

// Create Project
export const ADMIN_CREATE_PROJECT = '/new/admin/project/create'
export const ADMIN_PROJECT_IMAGES_NEW = '/new/admin/project/images'
export const ADMIN_PROJECT_PARKING_NEW = '/new/admin/project/parking'
export const ADMIN_PROJECT_COLORS_NEW = '/new/admin/project/colors'
export const ADMIN_PROJECT_UPGRADES_NEW = '/new/admin/project/upgrades'
export const ADMIN_PROJECT_PAYMENTS_NEW = '/new/admin/project/payments'
export const ADMIN_PROJECT_ALLOCATIONS_NEW = '/new/admin/project/allocations'

export const ADMIN_OFFERS = '/admin/offers'
export const ADMIN_OPPORTUNITIES = '/admin/opportunities'
export const ADMIN_RESERVATIONS = '/admin/reservations'
export const ADMIN_RESERVATION_NEW = '/admin/reservations/new'
export const ADMIN_RESERVATION_DETAIL = '/admin/reservations/:id'
export const ADMIN_BROKER_LEADS = '/admin/brokerLeads'
export const ADMIN_BROKER_LEADS_NEW = '/admin/brokerLeads/new'
export const ADMIN_BROKER_LEADS_DETAIL = '/admin/brokerLeads/:id'
export const ADMIN_PROFILE_PAGE = '/admin/profile'
export const ADMIN_SCHEDULER_MEETINGS = '/admin/scheduler_meetings'
export const ADMIN_SCHEDULER_SETTINGS = '/admin/scheduler_settings'
export const ADMIN_CREATE_EDIT_USER = '/admin/user'
export const ADMIN_CREATE_EDIT_DEVELOPER = '/admin/developer'
export const ADMIN_CREATE_EDIT_PROJECT = '/admin/project'
export const ADMIN_CREATE_EDIT_BUILDING = '/admin/building'
export const ADMIN_CREATE_EDIT_FLOOR_PLAN = '/admin/floorplan'
export const ADMIN_CREATE_EDIT_UNIT_GROUP = '/admin/unitgroup'
export const ADMIN_EMAIL_CAMPAIGNS = '/admin/campaigns'
export const ADMIN_EMAIL_CAMPAIGNS_BUILDER = '/admin/email-builder'
export const ADMIN_EDIT_EMAIL_TEMPLATE = '/admin/email-builder/edit/:templateId'
export const ADMIN_EMAIL_CAMPAIGNS_DETAIL = '/admin/campaigns/:id'
export const ADMIN_CREATE_EDIT_UNIT = '/admin/unit'
export const ADMIN_TEAM_SALES_REPS = '/admin/team/sales_reps'
export const ADMIN_TEAM_COOP_BROKER = '/admin/team/coop_broker'
export const ADMIN_CONTACTS = '/admin/contacts'
export const ADMIN_CONTACTS_ID = '/admin/contacts/:id'
export const ADMIN_NEW_CONTACTS = '/admin/newcontacts'
export const SALES_PIPELINE = '/admin/sales_pipeline'
export const ADMIN_REPORTS = '/admin/reports'
export const PWA_PROJECTS = '/pwa'
export const PWA_PROJECT_SCREEN = '/pwa/project'
export const PWA_CREATE_OFFER = '/pwa/project/create_offer'
export const PWA_CREATE_OFFER_NEW = '/pwa/project/create_offer_new'
export const PWA_PROJECT_OFFERS = '/pwa/project/project_offers'
export const PWA_PROJECT_AVAILABILITY_BOARD =
  '/pwa/project/project_availability_board'
export const PWA_PROJECT_FLOOR_PLANS = '/pwa/project/project_floor_plans'
export const BUYER_DASHBOARD = '/buyer'
export const BUYER_HOME = '/buyer/home'
export const BUYER_HOME_DASHBOARD = '/buyer/dashboard'
export const BUYER_SIGN_DOCS = '/buyer/sign_docs'
export const BUYER_MAKE_PAYMENT = '/buyer/make_payment'
export const BUYER_CREATE_OFFER = '/buyer/create_offer'

export const BROKER_HOME_PAGE = '/broker'
// export const BROKER_MENU_PAGE = '/broker/menu'
export const BROKER_DASHBOARD_PAGE = '/broker/dashboard'
export const BROKER_DOCUMENTS_PAGE = '/broker/documents'
export const BROKER_LANDING_PAGE = '/broker/brokerLeads'
export const BROKER_PROFILE_PAGE = '/broker/profile'

export const AVAILABILITY_BOARD = '/admin/availability_board'
export const ALLOCATIONS = '/admin/allocations'
export const ALLOCATE_INVENTORY = '/admin/allocate_inventory'
export const UNITS_INVENTORY = '/admin/units_inventory'
export const PARKINGS_INVENTORY = '/admin/parkings_inventory'
export const PAGE_UNDER_CONSTRUCTION = '/page_under_construction'

export const EMAIL_INBOX = '/admin/email_inbox'
export const SETTINGS = '/admin/settings'
export const CALENDAR = '/admin/calendar'
export const CONTRACT_TEMPLATES = '/admin/contract_templates'

// old admin routes
export const ADMIN_OFFER_OLD = '/admin/offer'

// BUYER DISCOVERY SURVEY
export const BUYER_DISCOVERY_SURVEY = '/discovery_survey'

// BUYER SIGN CONTRACT
export const BUYER_SIGN_CONTRACT = '/public/sign'

// INVENTORY
export const PUBLIC_INVENTORY_TABLE_SCREEN = '/public/inventory'
export const PUBLIC_PARKING_INVENTORY_TABLE_SCREEN = '/public/parking_inventory'

// DESPOSITE PAYMENT
export const ADMIN_DEPOSIT_PAYMENT = '/admin/deposits'

export const ADMIN_PROJECT_DROPDOWNS = [
  {
    key: 'leadSource',
    path: '/admin/Lead_Source'
  },
  {
    key: 'leadStatus',
    path: '/admin/Lead_Status'
  },
  {
    key: 'brokerageCompany',
    path: '/admin/Brokerage_Company'
  },
  {
    key: 'buyerScore',
    path: '/admin/Buyer_Score'
  },
  {
    key: 'brokerScore',
    path: '/admin/Broker_Score'
  },
  {
    key: 'reasonForLost',
    path: '/admin/Reason_For_Buyer_Lost'
  },
  {
    key: 'leadApproach',
    path: '/admin/Buyer_Lead_Approach'
  },
  {
    key: 'currencyList',
    path: '/admin/Currency_List'
  },
  {
    key: 'fileTags',
    path: '/admin/File_Tags'
  }
]

const ROUTES = [
  {
    path: ROUTE_DECISOR,
    KEY: ROUTE_DECISOR,
    exact: true,
    component: RouteDecisor
  },
  { path: LOGIN, key: LOGIN, exact: true, component: Login },
  { path: LOGOUT, key: LOGOUT, exact: true, component: Login },
  { path: SET_PASSWORD, key: SET_PASSWORD, exact: false, component: Login },
  {
    path: LAYOUT_MODE_SELECTOR,
    key: LAYOUT_MODE_SELECTOR,
    exact: true,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return <LayoutModeSelector {...props} />
    }
  },
  {
    path: PAGE_UNDER_CONSTRUCTION,
    key: PAGE_UNDER_CONSTRUCTION,
    exact: false,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return (
        <AdminLayout>
          <PageUnderConstruction {...props} />
        </AdminLayout>
      )
    },
    routes: [
      {
        path: ADMIN_DASHBOARD_COLUMBIA,
        key: ADMIN_DASHBOARD_COLUMBIA,
        exact: true,
        component: ColumbiaComponent
      }
    ]
  },
  {
    path: NEW_ADMIN_DASHBOARD,
    key: NEW_ADMIN_DASHBOARD,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return (
        <AdminNewLayout>
          <RenderRoutes {...props} />
        </AdminNewLayout>
      )
    },
    routes: [
      {
        path: ADMIN_DASHBOARD_COLUMBIA,
        key: ADMIN_DASHBOARD_COLUMBIA,
        exact: true,
        component: ColumbiaComponent
      },
      {
        path: ADMIN_OFFER,
        key: ADMIN_OFFER,
        exact: true,
        component: CreateOffer
      },
      {
        path: ADMIN_OFFER_PREVIEW,
        key: ADMIN_OFFER_PREVIEW,
        exact: true,
        component: OfferPreview
      },
      {
        path: ADMIN_OPPORTUNITY,
        key: ADMIN_OPPORTUNITY,
        exact: true,
        component: CreateOpportunity
      },
      {
        path: ADMIN_RESERVATION_NEW,
        key: ADMIN_RESERVATION_NEW,
        exact: true,
        component: CreateReservation
      },
      {
        path: ADMIN_RESERVATION_DETAIL,
        key: ADMIN_RESERVATION_DETAIL,
        exact: true,
        component: CreateReservation
      },
      {
        path: ADMIN_BROKER_LEADS_NEW,
        key: ADMIN_BROKER_LEADS_NEW,
        exact: true,
        component: CreateBrokerLead
      },
      {
        path: ADMIN_BROKER_LEADS_DETAIL,
        key: ADMIN_BROKER_LEADS_DETAIL,
        exact: true,
        component: CreateBrokerLead
      },
      {
        path: ADMIN_CREATE_PROJECT,
        key: ADMIN_CREATE_PROJECT,
        exact: true,
        component: CreateProject
      },
      {
        path: ADMIN_ADD_BUILDING,
        key: ADMIN_ADD_BUILDING,
        exact: true,
        component: AddBuilding
      },
      {
        path: ADMIN_RESERVE_UNIT,
        key: ADMIN_RESERVE_UNIT,
        exact: true,
        component: ReserveUnit
      },
      {
        path: ADMIN_PROJECT_ALLOCATIONS_NEW,
        key: ADMIN_PROJECT_ALLOCATIONS_NEW,
        exact: true,
        component: CreateInventoryAllocation
      },
      // { path: ADMIN_CREATE_INVENTORY_ALLOCATION, key: ADMIN_CREATE_INVENTORY_ALLOCATION, exact: true, component: CreateInventoryAllocation }
      {
        path: ADMIN_CREATE_INVENTORY_ALLOCATION,
        key: ADMIN_CREATE_INVENTORY_ALLOCATION,
        exact: true,
        component: CreateInventoryAllocation
      }
    ]
  },
  {
    path: ADMIN_DASHBOARD,
    key: ADMIN_DASHBOARD,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }

      return (
        <AdminLayout>
          <RenderRoutes {...props} />
        </AdminLayout>
      )
    },
    routes: [
      { path: ADMIN_DASHBOARD, key: ADMIN_DASHBOARD, exact: true, component: AdminDashboardComponent },
      { path: ADMIN_DASHBOARD_NEW, key: ADMIN_DASHBOARD_NEW, exact: true, component: AdminDashboardNewDesign },
      { path: ADMIN_DASHBOARD_OLD, key: ADMIN_DASHBOARD_OLD, exact: true, component: OldAdminDashboard },
      { path: ADMIN_USERS, key: ADMIN_USERS, exact: true, component: AdminUsers },
      { path: ADMIN_PROJECT_MANAGEMENT, key: ADMIN_PROJECT_MANAGEMENT, exact: true, component: AdminProjectManagement },
      { path: ADMIN_PROJECT_IMAGES, key: ADMIN_PROJECT_IMAGES, exact: true, component: EditProjectImages },
      { path: ADMIN_PROJECT_PARKING, key: ADMIN_PROJECT_PARKING, exact: true, component: EditProjectParking },
      { path: ADMIN_PROJECT_COLORS, key: ADMIN_PROJECT_COLORS, exact: true, component: EditProjectColors },
      { path: ADMIN_PROJECT_UPGRADES, key: ADMIN_PROJECT_UPGRADES, exact: true, component: EditProjectUpgrades },
      { path: ADMIN_PROJECT_PAYMENTS, key: ADMIN_PROJECT_PAYMENTS, exact: true, component: EditProjectPayments },
      { path: ADMIN_BUILDINGS, key: ADMIN_BUILDINGS, exact: true, component: AdminBuildings },
      { path: ADMIN_PROJECT_UNIT_INVENTORY, key: ADMIN_PROJECT_UNIT_INVENTORY, exact: true, component: UnitsInventory },
      { path: ADMIN_PROJECT_PARKING_INVENTORY, key: ADMIN_PROJECT_PARKING_INVENTORY, exact: true, component: ParkingsInventory },
      { path: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH, key: ADMIN_PROJECT_UNIT_INVENTORY_GRAPH, exact: true, component: UnitsInventoryGraph },
      { path: ADMIN_PROJECT_ALLOCATIONS, key: ADMIN_PROJECT_ALLOCATIONS, exact: true, component: Allocation },
      { path: ADMIN_PROJECT_FILES, key: ADMIN_PROJECT_FILES, exact: true, component: AdminProjectFiles },
      { path: ADMIN_EDIT_BUILDING, key: ADMIN_EDIT_BUILDING, exact: true, component: AdminEditBuilding },
      { path: ADMIN_OFFERS, key: ADMIN_OFFERS, exact: true, component: AdminOffers },
      { path: ADMIN_OPPORTUNITIES, key: ADMIN_OPPORTUNITIES, exact: true, component: AdminOpportunities },
      { path: ADMIN_RESERVATION_NEW, key: ADMIN_RESERVATION_NEW, exact: true, component: CreateReservation },
      { path: ADMIN_RESERVATION_DETAIL, key: ADMIN_RESERVATION_DETAIL, exact: true, component: CreateReservation },
      { path: ADMIN_RESERVATIONS, key: ADMIN_RESERVATIONS, exact: true, component: AdminReservations },
      { path: ADMIN_BROKER_LEADS_NEW, key: ADMIN_BROKER_LEADS_NEW, exact: true, component: CreateBrokerLead },
      { path: ADMIN_BROKER_LEADS_DETAIL, key: ADMIN_BROKER_LEADS_DETAIL, exact: true, component: CreateBrokerLead },
      { path: ADMIN_BROKER_LEADS, key: ADMIN_BROKER_LEADS, exact: true, component: AdminBrokerLeads },
      { path: ADMIN_OFFER_OLD, key: ADMIN_OFFER_OLD, exact: true, component: AdminOffer },
      { path: ADMIN_PROFILE_PAGE, key: ADMIN_PROFILE_PAGE, exact: true, component: AdminProfile },
      { path: ADMIN_SCHEDULER_MEETINGS, key: ADMIN_SCHEDULER_MEETINGS, exact: true, component: SchedulerMeetings },
      { path: ADMIN_SCHEDULER_SETTINGS, key: ADMIN_SCHEDULER_SETTINGS, exact: true, component: SchedulerSettings },
      { path: ADMIN_CREATE_EDIT_USER, key: ADMIN_CREATE_EDIT_USER, exact: true, component: AdminCreateEditUser },
      { path: ADMIN_CREATE_EDIT_DEVELOPER, key: ADMIN_CREATE_EDIT_DEVELOPER, exact: true, component: AdminCreateEditDeveloper },
      { path: ADMIN_CREATE_EDIT_PROJECT, key: ADMIN_CREATE_EDIT_PROJECT, exact: true, component: AdminCreateEditProject },
      { path: ADMIN_CREATE_EDIT_BUILDING, key: ADMIN_CREATE_EDIT_BUILDING, exact: true, component: AdminCreateEditBuilding },
      { path: ADMIN_CREATE_EDIT_FLOOR_PLAN, key: ADMIN_CREATE_EDIT_FLOOR_PLAN, exact: true, component: AdminCreateEditFloorPlan },
      { path: ADMIN_CREATE_EDIT_UNIT_GROUP, key: ADMIN_CREATE_EDIT_UNIT_GROUP, exact: true, component: AdminCreateEditUnitGroup },
      { path: ADMIN_CREATE_EDIT_UNIT, key: ADMIN_CREATE_EDIT_UNIT, exact: true, component: AdminCreateEditUnit },
      { path: SALES_PIPELINE, key: SALES_PIPELINE, exact: true, component: SalesPipeline },
      { path: ADMIN_REPORTS, key: ADMIN_REPORTS, exact: true, component: AdminReports },
      { path: ADMIN_TEAM_SALES_REPS, key: ADMIN_TEAM_SALES_REPS, exact: true, component: SalesReps },
      { path: ADMIN_TEAM_COOP_BROKER, key: ADMIN_TEAM_COOP_BROKER, exact: true, component: CoopBrokers },
      { path: ADMIN_CONTACTS, key: ADMIN_CONTACTS, exact: true, component: Contacts },
      { path: ADMIN_CONTACTS_ID, key: ADMIN_CONTACTS_ID, exact: true, component: Contacts },
      { path: ADMIN_EMAIL_CAMPAIGNS, key: ADMIN_EMAIL_CAMPAIGNS, exact: true, component: EmailCampaignPage },
      { path: ADMIN_EMAIL_CAMPAIGNS_BUILDER, key: ADMIN_EMAIL_CAMPAIGNS_BUILDER, exact: true, component: EmailCampaignBuilderPage },
      { path: ADMIN_EDIT_EMAIL_TEMPLATE, key: ADMIN_EDIT_EMAIL_TEMPLATE, exact: true, component: EditEmailTemplate },
      { path: ADMIN_EMAIL_CAMPAIGNS_DETAIL, key: ADMIN_EMAIL_CAMPAIGNS_DETAIL, exact: true, component: EmailCampaignDetail },
      { path: ADMIN_NEW_CONTACTS, key: ADMIN_NEW_CONTACTS, exact: true, component: NewContacts },
      { path: AVAILABILITY_BOARD, key: AVAILABILITY_BOARD, exact: true, component: AvailabilityBoard },
      { path: ADMIN_DEPOSIT_PAYMENT, key: ADMIN_DEPOSIT_PAYMENT, exact: true, component: AdminDeposits },
      { path: ALLOCATIONS, key: ALLOCATIONS, exact: true, component: Allocation },
      { path: UNITS_INVENTORY, key: UNITS_INVENTORY, exact: true, component: UnitsInventory },
      { path: PARKINGS_INVENTORY, key: PARKINGS_INVENTORY, exact: true, component: ParkingsInventory },
      { path: EMAIL_INBOX, key: EMAIL_INBOX, exact: true, component: EmailInbox },
      {
        path: ADMIN_DASHBOARD_COLUMBIA, key: ADMIN_DASHBOARD_COLUMBIA, exact: true, component: ColumbiaComponent
      },
      {
        path: ADMIN_DASHBOARD_COLUMBIA_REPORT, key: ADMIN_DASHBOARD_COLUMBIA_REPORT, exact: true, component: ColumbiaSalesReport
      },
      {
        path: ADMIN_LEADREPORT, key: ADMIN_LEADREPORT, exact: true, component: AdminLeadReport
      },
      {
        path: ADMIN_SALES_REPORT, key: ADMIN_SALES_REPORT, exact: true, component: AdminReportSalesActivity
      },
      {
        path: ADMIN_BROKER_SHANNON, key: ADMIN_BROKER_SHANNON, exact: true, component: AdminBrokerShannon
      },
      {
        path: ADMIN_LEAD_STEFAN, key: ADMIN_LEAD_STEFAN, exact: true, component: AdminLeadStefan
      },
      {
        path: ADMIN_EVENT_PAGE, key: ADMIN_EVENT_PAGE, exact: true, component: AdminEventPage
      },
      {
        path: ADMIN_EVENT_DETAIL_PAGE, key: ADMIN_EVENT_DETAIL_PAGE, exact: true, component: AdminEventDetailPage
      },
      {
        path: ADMIN_CREATE_EVENT_PAGE, key: ADMIN_CREATE_EVENT_PAGE, exact: true, component: AdminCreateEditEventPage
      },
      {
        path: ADMIN_EDIT_EVENT_PAGE, key: ADMIN_EDIT_EVENT_PAGE, exact: true, component: AdminCreateEditEventPage
      },
      { path: SETTINGS, key: SETTINGS, exact: true, component: Settings },
      { path: CALENDAR, key: CALENDAR, exact: true, component: Calendar },
      {
        path: CONTRACT_TEMPLATES,
        key: CONTRACT_TEMPLATES,
        exact: true,
        component: ContractTemplates
      },
      {
        path: '/admin/salesrep/profile',
        key: '/admin/salesrep/profile',
        exact: true,
        component: Profile
      },
      ...ADMIN_PROJECT_DROPDOWNS.map((dropdown) => ({
        path: dropdown.path,
        key: dropdown.key,
        exact: true,
        component: () => {
          return <DynamicDropDown itemKey={dropdown.key} />
        }
      }))
    ]
  },
  {
    path: PWA_PROJECTS,
    key: PWA_PROJECTS,
    component: (props) => {
      if (!isAuthenticated()) {
        return <Redirect to={LOGIN} />
      }
      return (
        <PwaLayout>
          <RenderRoutes {...props} />
        </PwaLayout>
      )
    },
    routes: [
      {
        path: PWA_PROJECTS,
        key: PWA_PROJECTS,
        exact: true,
        component: PwaProjects
      },
      {
        path: PWA_PROJECT_SCREEN,
        key: PWA_PROJECT_SCREEN,
        exact: true,
        component: PwaProjectScreen
      },
      {
        path: PWA_CREATE_OFFER,
        key: PWA_CREATE_OFFER,
        exact: true,
        component: PwaCreateOffer
      },
      {
        path: PWA_CREATE_OFFER_NEW,
        key: PWA_CREATE_OFFER_NEW,
        exact: true,
        component: PwaCreateOfferNew
      },
      {
        path: PWA_PROJECT_OFFERS,
        key: PWA_PROJECT_OFFERS,
        exact: true,
        component: PwaProjectOffers
      },
      {
        path: PWA_PROJECT_AVAILABILITY_BOARD,
        key: PWA_PROJECT_AVAILABILITY_BOARD,
        exact: true,
        component: PwaProjectAvailabilityBoard
      },
      {
        path: PWA_PROJECT_FLOOR_PLANS,
        key: PWA_PROJECT_FLOOR_PLANS,
        exact: true,
        component: PwaProjectFloorPlans
      }
    ]
  },
  {
    path: BUYER_DASHBOARD,
    key: BUYER_DASHBOARD,
    component: (props) => {
      if (!isAuthenticated()) {
        // Check if has a URL search to be passed
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              search: props.location.search || ''
            }}
          />
        )
      }

      return (
        <BuyerLayout>
          <RenderRoutes {...props} />
        </BuyerLayout>
      )
    },
    routes: [
      {
        path: BUYER_DASHBOARD,
        key: BUYER_DASHBOARD,
        exact: true,
        component: BuyerAvailabilityBoard
      },
      {
        path: BUYER_CREATE_OFFER,
        key: BUYER_CREATE_OFFER,
        exact: true,
        component: BuyerCreateOffer
      },
      {
        path: BUYER_HOME,
        key: BUYER_HOME,
        exact: true,
        component: BuyerHomeComponent
      },
      {
        path: BUYER_HOME_DASHBOARD,
        key: BUYER_HOME_DASHBOARD,
        exact: true,
        component: BuyerDashBoard
      }
    ]
  },
  {
    path: BROKER_HOME_PAGE,
    key: BROKER_HOME_PAGE,
    component: (props) => {
      if (!isAuthenticated()) {
        // Check if has a URL search to be passed
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              search: props.location.search || ''
            }}
          />
        )
      }

      return (
        <BrokerLayout>
          <RenderRoutes {...props} />
        </BrokerLayout>
      )
    },
    routes: [
      {
        path: BROKER_HOME_PAGE,
        key: BROKER_HOME_PAGE,
        exact: true,
        component: BrokerMainPage
      },
      // {
      //   path: BROKER_MENU_PAGE,
      //   key: BROKER_MENU_PAGE,
      //   exact: true,
      //   component: BrokerMenuPage
      // },
      {
        path: BROKER_DASHBOARD_PAGE,
        key: BROKER_DASHBOARD_PAGE,
        exact: true,
        component: BrokerDashboardPage
      },
      {
        path: BROKER_DOCUMENTS_PAGE,
        key: BROKER_DOCUMENTS_PAGE,
        exact: true,
        component: BrokerDocumentPage
      },
      {
        path: BROKER_LANDING_PAGE,
        key: BROKER_LANDING_PAGE,
        exact: true,
        component: BrokerLandingPageScreen
      },
      {
        path: BROKER_PROFILE_PAGE,
        key: BROKER_PROFILE_PAGE,
        exact: true,
        component: BrokerProfileScreen
      }
    ]
  },
  {
    path: BUYER_DISCOVERY_SURVEY,
    key: BUYER_DISCOVERY_SURVEY,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: BUYER_DISCOVERY_SURVEY,
        key: BUYER_DISCOVERY_SURVEY,
        exact: true,
        component: BuyerDiscoverySurvey
      }
    ]
  },
  {
    path: BUYER_SIGN_CONTRACT,
    key: BUYER_SIGN_CONTRACT,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: BUYER_SIGN_CONTRACT,
        key: BUYER_SIGN_CONTRACT,
        exact: true,
        component: BuyerSignContract
      }
    ]
  },
  {
    path: PUBLIC_INVENTORY_TABLE_SCREEN,
    key: PUBLIC_INVENTORY_TABLE_SCREEN,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: PUBLIC_INVENTORY_TABLE_SCREEN,
        key: PUBLIC_INVENTORY_TABLE_SCREEN,
        exact: true,
        component: InventoryPublicPage
      }
    ]
  },
  {
    path: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
    key: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
    component: (props) => <RenderRoutes {...props} />,
    routes: [
      {
        path: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
        key: PUBLIC_PARKING_INVENTORY_TABLE_SCREEN,
        exact: true,
        component: ParkingInventoryPublicPage
      }
    ]
  }
]

export default ROUTES

function RouteWithSubRoutes (route) {
  const { path, exact, component: Component, routes } = route
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => <Component {...props} routes={routes} />}
    />
  )
}

function AdminLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <AdminContainer>
        <Switch location={children.props.location}>
          {children}
          {/* <Route path='*' component={() => <h1>Not found</h1>} /> */}
        </Switch>
      </AdminContainer>
    </Suspense>
  )
}

function AdminNewLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      {/* <OfferContainer> */}
      <AdminContainer>
        <Switch location={children.props.location}>{children}</Switch>
      </AdminContainer>
      {/* </OfferContainer> */}
    </Suspense>
  )
}

function BuyerLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <BuyerContainer>
        <Switch location={children.props.location}>{children}</Switch>
      </BuyerContainer>
    </Suspense>
  )
}

function BrokerLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <BrokerContainer>
        <Switch location={children.props.location}>{children}</Switch>
      </BrokerContainer>
    </Suspense>
  )
}

function PwaLayout ({ children }) {
  return (
    <Suspense fallback={<Loader />}>
      <AnimatedTransitionWrapper history={children.props.history}>
        <TransitionGroup>
          <CSSTransition
            key={children.props.location.key}
            timeout={{ enter: 300, exit: 300 }}
            classNames='fade'
          >
            <section className='route-section'>
              <Switch location={children.props.location}>
                {children}
                {/* <Route path='*' component={() => <h1>Not found</h1>} /> */}
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </AnimatedTransitionWrapper>
    </Suspense>
  )
}

export function RenderRoutes ({ routes }) {
  const { update } = useIntercom()
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)
  const hasUser = useRef(false)

  useEffect(() => {
    if (!userObject?._id) return
    if (hasUser.current) return
    updateLoggedUserObject(userObject, update, false)
    hasUser.current = true
  }, [userObject])

  return (
    <Switch>
      {routes.map((route, i) => {
        return <RouteWithSubRoutes key={i} {...route} />
      })}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  )
}

AdminLayout.propTypes = {
  children: propTypes.node
}

BuyerLayout.propTypes = {
  children: propTypes.node
}

BrokerLayout.propTypes = {
  children: propTypes.node
}

PwaLayout.propTypes = {
  children: propTypes.node
}

RenderRoutes.propTypes = {
  routes: propTypes.array
}
