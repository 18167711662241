// @ts-nocheck
import React, { memo, useCallback, useEffect, useState } from 'react'
// import propTypes from 'prop-types'
import { Sider } from 'components'
import { InputLabel, Modal, Table, TButton } from 'ui'
import { getRandomInteger, userTypesData, validateRequiredInputs } from 'utils'
import {
  createOrUpdateContractTemplate,
  getContractTemplates
} from 'store/actions/contractTemplateActions'
import { useSelector } from 'react-redux'
import ProjectFileSelector from 'components/ProjectFileSelector'

const orderData = Array.from({ length: 30 }, (e, k) => ({
  id: k + 1,
  value: k + 1
}))

const ModalContractField = memo(
  ({ showModal, onCancel, onOk, name, type, value, _id }) => {
    const [fieldName, setFieldName] = useState(name)
    const [fieldType, setFieldType] = useState(type)
    const [fieldValue, setFieldValue] = useState(value)
    const [fieldId, setFieldId] = useState(_id)
    useEffect(() => {
      setFieldName(name)
      setFieldType(type)
      setFieldValue(value)
      setFieldId(_id)
    }, [name, type, value, _id])

    const cleanValues = () => {
      setFieldName('')
      setFieldType('')
      setFieldValue('')
      setFieldId('')
    }

    const _onOk = () => {
      onOk({
        name: fieldName,
        type: fieldType,
        value: fieldValue,
        _id: fieldId || getRandomInteger()
      })

      setTimeout(() => {
        cleanValues()
      }, 600)
    }

    const _onCancel = () => {
      onCancel()

      setTimeout(() => {
        cleanValues()
      }, 600)
    }

    return (
      <Modal
        modalType='custom'
        onOk={_onOk}
        onCancel={_onCancel}
        showModal={showModal}
        okText='Ok'
      >
        <div className='min-w-xs '>
          <span className='text-2xl'>{`${
            name ? 'Editing' : 'Creating'
          } contract field`}</span>
          <br />
          <span className='text-base'>set up here the contract field</span>

          <div className='space-y-2 mt-10'>
            <InputLabel
              id='fieldName'
              key='fieldName'
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
              label='Name'
            />
            <InputLabel
              id='fieldType'
              key='fieldType'
              value={fieldType}
              onChange={(e) => setFieldType(e.target.value)}
              label='Type'
            />
            <InputLabel
              id='fieldValue'
              key='fieldValue'
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              label='Value'
            />
          </div>
        </div>
      </Modal>
    )
  }
)

const templateFieldsColumns = [
  {
    Title: 'name',
    accessor: 'name',
    Cell: ({
      row: {
        original: { name, type, value }
      }
    }) => <span>{`${name} | ${type} | ${value}`}</span>
  }
]

const DeleteFieldModal = memo(({ showModal, onCancel, onOk, fieldName }) => (
  <Modal
    modalType='danger'
    showModal={showModal}
    onCancel={onCancel}
    onOk={onOk}
    title='Confirm removing field from this template?'
    subtitle={`${fieldName} will be removed permanently!`}
  />
))

const ContractTemplateSideBar = ({
  _id,
  showContractTemplateSider,
  onCancelClick,
  executeAfterSave
}) => {
  const project = useSelector((state) => state.appReducer.appProject)
  const [contractTemplateName, setContractTemplateName] = useState('')
  const [contractTemplateDescription, setContractTemplateDescription] =
    useState('')
  const [contractTemplateNumber, setContractTemplateNumber] = useState('')

  const [allowedToSee, setAllowedToSee] = useState([])
  const [allowedToSign, setAllowedToSign] = useState([])
  const [contractOrder, setContractOrder] = useState('')
  const [contractUnitLocation, setContractUnitLocation] = useState(null)
  const [contractFloorPlanLocation, setContractFloorPlanLocation] = useState(null)
  const [contractParkingLocation, setContractParkingLocation] = useState(null)

  const [selectedFieldName, setSelectedFieldName] = useState('')
  const [selectedFieldType, setSelectedFieldType] = useState('')
  const [selectedFieldValue, setSelectedFieldValue] = useState('')
  const [selectedFieldId, setSelectedFieldId] = useState('')

  const [showFieldModal, setShowFieldModal] = useState(false)
  const [contractTemplateFields, setContractTemplateFields] = useState([])
  const [gettingData, setGettingData] = useState(false)

  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState(false)
  const [savingTemplate, setSavingTemplate] = useState(false)

  const [contractPdf, setContractPdf] = useState({})

  const getContractTemplateData = useCallback(() => {
    if (_id) {
      setGettingData(true)

      getContractTemplates(_id)
        .then((data) => {
          const {
            name,
            description,
            templateNumber,
            allowedToSign,
            allowedToSee,
            order,
            contractFields
          } = data

          setContractTemplateName(name)
          setContractTemplateDescription(description)
          setContractTemplateNumber(templateNumber)
          setAllowedToSee(allowedToSee || [])
          setAllowedToSign(allowedToSign || [])
          setContractOrder(order)

          setContractTemplateFields(contractFields)

          setGettingData(false)
        })
        .catch(() => {
          setGettingData(false)
        })
    }
  }, [_id])

  useEffect(() => {
    getContractTemplateData()
  }, [getContractTemplateData])

  const onCancelFieldModal = useCallback(() => {
    setShowFieldModal(false)

    setTimeout(() => {
      setSelectedFieldName('')
      setSelectedFieldType('')
      setSelectedFieldValue('')
      setSelectedFieldId('')
    }, 400)
  }, [])

  // FIELDS LIST MANAGEMENT
  const removeFieldFromFields = useCallback(
    (fieldName) => {
      const tmpFields = contractTemplateFields.filter(
        (field) => field.name !== fieldName
      )

      setContractTemplateFields(tmpFields)
    },
    [contractTemplateFields]
  )

  const addFieldToFields = useCallback(
    (values) => {
      const tmpFields = [...contractTemplateFields]
      tmpFields.push(values)

      setContractTemplateFields(tmpFields)
    },
    [contractTemplateFields]
  )

  const editFieldInTheFields = useCallback(
    (values) => {
      const tmpFields = [...contractTemplateFields]

      const indexOf = tmpFields.findIndex((field) => field._id === values._id)
      tmpFields[indexOf] = values

      setContractTemplateFields(tmpFields)
      setShowFieldModal(false)
    },
    [contractTemplateFields]
  )

  const onOkFieldModal = useCallback(
    (newFieldValues) => {
      addFieldToFields(newFieldValues)
      setShowFieldModal(false)
    },
    [addFieldToFields]
  )

  const onNewField = useCallback(() => {
    setShowFieldModal(true)
  }, [])

  const prepareToEditField = useCallback((field) => {
    const {
      original: { name, type, value, _id }
    } = field

    setSelectedFieldName(name)
    setSelectedFieldType(type)
    setSelectedFieldValue(value)
    setSelectedFieldId(_id)

    setShowFieldModal(true)
  }, [])

  const prepareToDeleteField = useCallback((field) => {
    setSelectedFieldName(field.original.name)
    setShowDeleteFieldModal(true)
  }, [])

  const clearValues = useCallback(() => {
    setContractTemplateName('')
    setContractTemplateDescription('')
    setContractTemplateNumber('')
    setAllowedToSee([])
    setAllowedToSign([])
    setContractOrder('')
    setSelectedFieldName('')
    setSelectedFieldType('')
    setSelectedFieldValue('')
    setSelectedFieldId('')
    setContractTemplateFields([])
    setContractUnitLocation(null)
    setContractFloorPlanLocation(null)
    setContractParkingLocation(null)
  }, [])

  const onSiderSaveClick = useCallback(() => {
    validateRequiredInputs('contractTemplate').then(() => {
      setSavingTemplate(true)

      const params = {
        _id,
        name: contractTemplateName,
        description: contractTemplateDescription,
        templateNumber: contractTemplateNumber,
        contractFields: contractTemplateFields.map(({ name, type, value }) => ({
          name,
          type,
          value
        })),
        allowedToSee,
        allowedToSign,
        project,
        order: contractOrder,
        contractPdf: contractPdf?.id ?? contractPdf?._id
      }

      if (contractUnitLocation) {
        params.unitLocationInPdf = contractUnitLocation
      }

      if (contractFloorPlanLocation) {
        params.floorPlanLocationInPdf = contractFloorPlanLocation
      }

      if (contractParkingLocation) {
        params.parkingLocationInPdf = contractParkingLocation
      }

      createOrUpdateContractTemplate(params)
        .then(() => {
          setSavingTemplate(false)
          clearValues()
          executeAfterSave()
        })
        .catch(() => setSavingTemplate(false))
    })
  }, [
    _id,
    allowedToSee,
    allowedToSign,
    contractOrder,
    contractTemplateDescription,
    contractTemplateFields,
    contractTemplateName,
    contractTemplateNumber,
    executeAfterSave,
    clearValues,
    contractPdf,
    contractUnitLocation,
    contractFloorPlanLocation,
    contractParkingLocation
  ])

  const onSiderCancelClick = useCallback(() => {
    clearValues()

    onCancelClick(false)
  }, [onCancelClick, clearValues])

  const closeShowConfirmDeleteFieldModal = useCallback(() => {
    setSelectedFieldName('')
    setShowDeleteFieldModal(false)
  }, [])

  return (
    <Sider
      title={`${_id ? 'Editing' : 'Creating'} Contract Template`}
      subtitle={
        _id
          ? 'Edit your template infos'
          : 'Fill the infos below to create a new template'
      }
      showMenu={
        typeof showContractTemplateSider === 'boolean'
          ? showContractTemplateSider
          : true
      }
      position='right'
      fixed={false}
      width='min-w-2xl'
      onSaveClick={onSiderSaveClick}
      onCancelClick={onSiderCancelClick}
      saving={savingTemplate}
    >
      <ModalContractField
        showModal={showFieldModal}
        onCancel={onCancelFieldModal}
        onOk={selectedFieldName ? editFieldInTheFields : onOkFieldModal}
        name={selectedFieldName}
        type={selectedFieldType}
        value={selectedFieldValue}
        _id={selectedFieldId}
      />
      <DeleteFieldModal
        showModal={showDeleteFieldModal}
        onCancel={closeShowConfirmDeleteFieldModal}
        onOk={() => {
          removeFieldFromFields(selectedFieldName)
          setShowDeleteFieldModal(false)
          setSelectedFieldName('')
        }}
        fieldName={selectedFieldName}
      />

      <div className='px-5 space-y-5 min-w-xs'>
        <InputLabel
          id='templateName'
          key='templateName'
          value={contractTemplateName || ''}
          onChange={(e) => setContractTemplateName(e.target.value)}
          required
          validateKey='contractTemplate'
          label='Name'
          placeholder='Fill the Template Name'
          loading={gettingData}
        />
        <InputLabel
          id='templateDescription'
          key='templateDescription'
          value={contractTemplateDescription}
          onChange={(e) => setContractTemplateDescription(e.target.value)}
          required
          typeInput='textarea'
          validateKey='contractTemplate'
          label='Description'
          placeholder='Fill here the description'
          loading={gettingData}
        />
        <InputLabel
          id='templateNumber'
          key='templateNumber'
          value={contractTemplateNumber}
          onChange={(e) => setContractTemplateNumber(e.target.value)}
          required
          validateKey='contractTemplate'
          label='Hellosign template number'
          placeholder='Copy the Hellosign Template Number and paste here'
          loading={gettingData}
        />
        <InputLabel
          id='allowedToSign'
          key='allowedToSign'
          value={allowedToSign}
          onChange={(items) => setAllowedToSign(items)}
          typeInput='select'
          mode='multiple'
          data={userTypesData || []}
          label='Who must sign the document?'
          placeholder='Who will need to sign?'
          loading={gettingData}
          required
          validateKey='contractTemplate'
        />
        <InputLabel
          id='allowedToSee'
          key='allowedToSee'
          value={allowedToSee}
          onChange={(items) => setAllowedToSee(items)}
          typeInput='select'
          mode='multiple'
          data={userTypesData || []}
          label='Who can view the document?'
          placeholder='Who is gonna view the document?'
          loading={gettingData}
          required
          validateKey='contractTemplate'
        />
        Selected viewers will be able to see the documents even they don't need
        to sign.
        <InputLabel
          id='contractOrder'
          key='contractOrder'
          value={contractOrder}
          onChange={(order) => setContractOrder(order)}
          typeInput='select'
          data={orderData}
          label='Order'
          placeholder='Configure the order of the document'
          loading={gettingData}
        />
        <InputLabel
          id='contractUnitLocation'
          key='contractUnitLocation'
          value={contractUnitLocation}
          onChange={(e) => setContractUnitLocation(Number(e.target.value))}
          type='number'
          label='Index of UNIT section in contract PDF'
          placeholder='Configure the unit section in contract PDF'
          loading={gettingData}
        />
        <InputLabel
          id='contractFloorPlanLocation'
          key='contractFloorPlanLocation'
          value={contractFloorPlanLocation}
          onChange={(e) => setContractFloorPlanLocation(Number(e.target.value))}
          type="number"
          label='Index of FLOOR-PLAN section in contract PDF'
          placeholder='Configure the Floor-Plan section in contract PDF'
          loading={gettingData}
        />
        <InputLabel
          id="contractParkingLocation"
          key="brokerCommissionFirstRange"
          type="number"
          value={contractParkingLocation}
          onChange={(e) => setContractParkingLocation(Number(e.target.value))}
          label='Index of PARKING section in contract PDF'
          placeholder='Configure the Parking section in contract PDF'
          loading={gettingData}
        />
        <ProjectFileSelector
          title={'Contract'}
          onSuccess={(selectedFileData) => {
            setContractPdf(JSON.parse(selectedFileData))
          }}
        />
        <div>
          <div className='w-full flex flex-row justify-between'>
            <span className='text-lg'>Contract Fields</span>
            <TButton loading={gettingData} onClick={onNewField}>
              New Field
            </TButton>
          </div>
          <Table
            columns={templateFieldsColumns}
            dataSource={contractTemplateFields}
            tailwindTable
            onViewEditClick={prepareToEditField}
            onDeleteClick={prepareToDeleteField}
            className='mt-5'
            loading={gettingData}
          />
        </div>
      </div>
    </Sider>
  )
}

export default ContractTemplateSideBar

// ContractTemplateSideBar.propTypes = {
//   _id: propTypes.string,
//   showContractTemplateSider: propTypes.bool,
//   onCancelClick: propTypes.func,
//   executeAfterSave: propTypes.func
// }

// ModalContractField.propTypes = {
//   showModal: propTypes.bool,
//   onCancel: propTypes.func,
//   onOk: propTypes.func,
//   name: propTypes.string,
//   type: propTypes.string,
//   value: propTypes.string,
//   _id: propTypes.string
// }

// DeleteFieldModal.propTypes = {
//   showModal: propTypes.bool,
//   onCancel: propTypes.func,
//   onOk: propTypes.func,
//   fieldName: propTypes.string
// }
