import useContactContext from 'pages/newContacts/hooks/useContactContext'
import React, { useEffect, useState } from 'react'

const SearchBox = () => {
  const { setSearch } = useContactContext()
  const [text, setText] = useState('')

  useEffect(() => {
    const id = setTimeout(() => setSearch(text), 400)
    return () => clearTimeout(id)
  }, [text, setSearch])

  return (
    <input onChange={(e) => setText(e.currentTarget.value)} className='bg-white p-2 rounded w-72 text-softBlack' placeholder='Search' />
  )
}

export default SearchBox
