import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { setReservationDetails } from 'store/actions/reservationActions'

const useCreateReservation = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)

  const callApi = useCallback(
    async (projectId, NewReservation) => {
      try {
        setLoading(true)

        let updatedObject = null
        if (!NewReservation?._id) {
          updatedObject = {
            primaryBuyer: NewReservation?.name,
            project: projectId,
            createdBy: userObject?._id,
            paymentMethod: NewReservation?.paymentMethod,
            contractLanguage: NewReservation?.contractLanguage,
            address: NewReservation?.address,
            email: NewReservation?.email,
            notes: NewReservation.notes,
            bankNumber: NewReservation.bankNumber,
            nameOnAccount: NewReservation.accountName,
            bankName: NewReservation.bankName,
            currencyType: NewReservation.currency,
            clabe: NewReservation.clabe,
            phoneNumber: NewReservation.phoneNumber
          }
        }

        const response = await setReservationDetails(
          NewReservation?._id ? NewReservation : updatedObject
        )
        setData(response)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.log('Failed to load data for', e)
      }
    },
    [userObject]
  )

  return [callApi, loading, data]
}

export default useCreateReservation
